/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import FormLoader from '../../../../components/loader/formLoader';

import Loader from '../../../../components/loader';
import Snackbar from '../../../../components/snackbar';
import axios from "axios";
import { Form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';


class PoolSlicingParameter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                currency: "",
                collateralvalue: "",
                collateralamt: "",
                collateralvalueclassA: "",
                collateralvalueclassB: "",
                collateralvalueclassC: "",
                nominalamtclassA: "",
                nominalamtclassB: "",
                nominalamtclassC: "",
                issuepriceclassA: "",
                issuepriceclassB: "",
                issuepriceclassC: "",
                sizeclassA: "",
                sizeclassB: "",
                sizeclassC: "",
                interestratetypeclassA: "",
                interestratetypeclassB: "",
                interestratetypeclassC: "",
                couponrateclassA: "",
                couponrateclassB: "",
                couponrateclassC: "",
                interestratefreqclassA: "",
                interestratefreqclassB: "",
                interestratefreqclassC: "",
                facevalueclassA: "",
                facevalueclassB: "",
                facevalueclassC: "",
                minsubclassA: "",
                minsubclassB: "",
                minsubclassC: "",
                totalunitclassA: "",
                totalunitclassB: "",
                totalunitclassC: "",
                noteperiodclassA: "",
                noteperiodclassB: "",
                noteperiodclassC: "",



            },
            loading: false,
            getLoansLoader: false,

        };

        // this.viewloansredirect = this.viewloansredirect(value);

    }

    handleChange = (event) => {
        const { formData } = this.state;

        formData[event.target.name] = event.target.value;

        this.setState({ formData });
        console.log(formData);
    }

    onCloseModal1 = () => {
        this.setState({ open1: false })
        this.props.action('done')
    }


    submitHandler = (evt) => {
        evt.preventDefault();

        if (this.state.formData.currency.length !== 0 && this.state.formData.collateralvalueclassA.length !== 0 && this.state.formData.nominalamtclassA.length !== 0 && this.state.formData.issuepriceclassA.length !== 0 && this.state.formData.sizeclassA.length !== 0 && this.state.formData.interestratetypeclassA.length !== 0 && this.state.formData.couponrateclassA.length !== 0 && this.state.formData.interestratefreqclassA.length !== 0 && this.state.formData.facevalueclassA.length !== 0 && this.state.formData.minsubclassA.length !== 0 && this.state.formData.totalunitclassA.length !== 0 && this.state.formData.noteperiodclassA.length !== 0) {

            this.setState({ getLoansLoader: true })
            console.log(this.state.formData);
            var currency = this.state.formData.currency;
            var collateralvalue = this.state.formData.collateralvalue;
            var collateralamt = this.state.formData.collateralamt;
            var collateralvalueclassA = this.state.formData.collateralvalueclassA;
            var collateralvalueclassB = this.state.formData.collateralvalueclassB;
            var collateralvalueclassC = this.state.formData.collateralvalueclassC;
            var nominalamtclassA = this.state.formData.nominalamtclassA;
            var nominalamtclassB = this.state.formData.nominalamtclassB;
            var nominalamtclassC = this.state.formData.nominalamtclassC;
            var issuepriceclassA = this.state.formData.issuepriceclassA;
            var issuepriceclassB = this.state.formData.issuepriceclassB;
            var issuepriceclassC = this.state.formData.issuepriceclassC;
            var sizeclassA = this.state.formData.sizeclassA;
            var sizeclassB = this.state.formData.sizeclassB;
            var sizeclassC = this.state.formData.sizeclassC;
            var interestratetypeclassA = this.state.formData.interestratetypeclassA;
            var interestratetypeclassB = this.state.formData.interestratetypeclassB;
            var interestratetypeclassC = this.state.formData.interestratetypeclassC;
            var couponrateclassA = this.state.formData.couponrateclassA;
            var couponrateclassB = this.state.formData.couponrateclassB;
            var couponrateclassC = this.state.formData.couponrateclassC;
            var interestratefreqclassA = this.state.formData.interestratefreqclassA;
            var interestratefreqclassB = this.state.formData.interestratefreqclassB;
            var interestratefreqclassC = this.state.formData.interestratefreqclassC;
            var facevalueclassA = this.state.formData.facevalueclassA;
            var facevalueclassB = this.state.formData.facevalueclassB;
            var facevalueclassC = this.state.formData.facevalueclassC;
            var minsubclassA = this.state.formData.minsubclassA;
            var minsubclassB = this.state.formData.minsubclassB;
            var minsubclassC = this.state.formData.minsubclassC;
            var totalunitclassA = this.state.formData.totalunitclassA;
            var totalunitclassB = this.state.formData.totalunitclassB;
            var totalunitclassC = this.state.formData.totalunitclassC;
            var noteperiodclassA = this.state.formData.noteperiodclassA;
            var noteperiodclassB = this.state.formData.noteperiodclassB;
            var noteperiodclassC = this.state.formData.noteperiodclassC;

            const peers = JSON.parse(localStorage.getItem("peers"));
            const token = localStorage.getItem('token');

            var input = {
                "PoolID": this.state.value,
                "colatralPer": collateralvalue,
                "colatralVal": collateralamt,
                "CollateralPerValueA": collateralvalueclassA,
                "NominalAmtA": nominalamtclassA,
                "issuePriceA": issuepriceclassA,
                "SizeA": sizeclassA,
                "InterestRateTypeA": interestratetypeclassA,
                "CouponRateA": couponrateclassA,
                "InterestRateFrequencyA": interestratefreqclassA,
                "FaceValueA": facevalueclassA,
                "MinimumUnitsA": minsubclassA,
                "TotalUnitsAvailableA": totalunitclassA,
                "NotePeriodA": noteperiodclassA,
                "CollateralPerValueB": collateralvalueclassB,
                "NominalAmtB": nominalamtclassB,
                "issuePriceB": issuepriceclassB,
                "SizeB": sizeclassB,
                "InterestRateTypeB": interestratetypeclassB,
                "CouponRateB": couponrateclassB,
                "InterestRateFrequencyB": interestratefreqclassB,
                "FaceValueB": facevalueclassB,
                "MinimumUnitsB": minsubclassB,
                "TotalUnitsAvailableB": totalunitclassB,
                "NotePeriodB": noteperiodclassB,
                "CollateralPerValueC": collateralvalueclassC,
                "NominalAmtC": nominalamtclassC,
                "issuePriceC": issuepriceclassC,
                "SizeC": sizeclassC,
                "InterestRateTypeC": interestratetypeclassC,
                "CouponRateC": couponrateclassC,
                "InterestRateFrequencyC": interestratefreqclassC,
                "FaceValueC": facevalueclassC,
                "MinimumUnitsC": minsubclassC,
                "TotalUnitsAvailableC": totalunitclassC,
                "NotePeriodC": noteperiodclassC,
                "currency": currency,
                "peers": peers,
                "token": token

            }
            var result = JSON.stringify(input);
            axios(process.env.react_app_base_url + 'api/V1/tranche/slicing', {
                method: 'POST',
                data: result,
                dataType: 'json',
                processData: true,
                headers: {
                    "Content-Type": "application/json"
                }

            }).then(res => {
                console.log(res.data);
                if (res.status === 204) {
                    this.setState({ getLoansLoader: false, open: true })

                    const message = "Data not available";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 2000
                    });
                }
                else if (res.status == 200) {
                    if (res.data.token == '-1') {
                        this.setState({ getLoansLoader: false, open: true })

                        const message = "Invalid Token!";
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 2000
                        });
                        this.props.history.push({
                            pathname: '/'
                        })

                    } else {

                        if (res.data.isSuccess === true) {
                            const message = "Tranche creation successful!";
                            this.props.enqueueSnackbar(message, {
                                variant: 'info',
                                autoHideDuration: 2000
                            });


                            this.setState({ getLoansLoader: false, open: true })


                            // setTimeout(() => {
                            //     this.setState({ showModal: false })
                            //     this.props.action('done')
                            // }, 1500);
                            setTimeout(() => {
                                this.setState({ open1: false })
                                this.props.action('done')
                            }, 1500);
                        }
                    }
                }
                else {
                    alert("Failed");
                    // setTimeout(() => {
                    //     this.setState({ showModal: false })
                    //     this.props.action('done')
                    // }, 100);
                    setTimeout(() => {
                        this.setState({ open1: false })
                        this.props.action('done')
                    }, 100);
                }


            }).catch(error => {
                console.log(error);
                if (error.response.status == 400) {
                    this.setState({ getLoansLoader: false, open: true })

                    console.log("getLoans API error", error);
                    console.log("error.response", error.response.data.message);

                    const message = error.response.data.message;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                } else {
                    this.setState({ getLoansLoader: false, open: true })


                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });


                }

            })

        }
        else {
            alert("please fill the required fields!!")


        }
    }


    async componentDidMount() {
        var value = this.props.dataFromParent;
        this.setState({
            value: value
        })
        // console.log("LOANID"+LoanID)


    }
    render() {
        const { formData } = this.state;

        return (

            <React.Fragment>


                <div id="tablewrapper" className="poolslicingparameter">
                <div className="container-fluid">
                    
            
                        <div id="duedeligence_tablecontentpoolslicing">
                            <Form>
                                <div className="poolslicingfirst row">
                                    <div className="poolattridropdown col-2">
                                        <Form.Group controlId="formGroupID">

                                            <Form.Control required placeholder="" hidden >

                                            </Form.Control>
                                        </Form.Group>
                                    </div>

                                    <div className="poolattridropdown col-3">
                                        <Form.Group controlId="formGroupName">
                                            <Form.Label>Currency*</Form.Label>
                                            <Form.Control placeholder="" required="true" name="currency" value={formData.currency} onChange={this.handleChange} >

                                            </Form.Control>
                                        </Form.Group>
                                    </div>





                                    <div className="poolattridropdown col-3">
                                        <Form.Group controlId="formGroupID">
                                            <Form.Label>% of Collateral Value*</Form.Label>
                                            <Form.Control required placeholder="" name="collateralvalue" value={formData.collateralvalue} onChange={this.handleChange}  >

                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                    <div className="poolattridropdown col-3">
                                        <Form.Group controlId="formGroupID">
                                            <Form.Label>Collateral Amount*</Form.Label>
                                            <Form.Control required placeholder="" name="collateralamt" value={formData.collateralamt} onChange={this.handleChange}  >

                                            </Form.Control>
                                        </Form.Group>
                                    </div>

                                </div>
                                <hr />


                                <div className="poolslicingfirst row">
                                    <div className="poolattridropdown col-2">


                                    </div>

                                    <div className="poolattridropdownclasses col-3">
                                        <Form.Group controlId="formGroupName" style={{ marginTop: "3px" }}>
                                            <Form.Label>Class A</Form.Label>

                                        </Form.Group>
                                    </div>





                                    <div className="poolattridropdownclasses col-3">
                                        <Form.Group controlId="formGroupID" style={{ marginTop: "3px" }}>
                                            <Form.Label>Class B</Form.Label>

                                        </Form.Group>
                                    </div>
                                    <div className="poolattridropdownclasses col-3">
                                        <Form.Group controlId="formGroupID" style={{ marginTop: "3px" }}>
                                            <Form.Label>Class C</Form.Label>

                                        </Form.Group>
                                    </div>

                                </div>

                                <div id="poolslicescrollable">

                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2">

                                            <p id="headingparah">% of the Collateral Value*</p>

                                        </div>

                                        <div className="poolattridropdown col-3" style={{ marginTop: "10px" }}>
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="collateralvalueclassA" value={formData.collateralvalueclassA} onChange={this.handleChange} >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3" style={{ marginTop: "10px" }}>
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="collateralvalueclassB" value={formData.collateralvalueclassB} onChange={this.handleChange} >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3" style={{ marginTop: "10px" }}>
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="collateralvalueclassC" value={formData.collateralvalueclassC} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>







                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Nominal Amount*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="nominalamtclassA" value={formData.nominalamtclassA} onChange={this.handleChange}  >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="nominalamtclassB" value={formData.nominalamtclassB} onChange={this.handleChange} >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="nominalamtclassC" value={formData.nominalamtclassC} onChange={this.handleChange} >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>




                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Issue Price(%)*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="issuepriceclassA" value={formData.issuepriceclassA} onChange={this.handleChange}  >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="issuepriceclassB" value={formData.issuepriceclassB} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="issuepriceclassC" value={formData.issuepriceclassC} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>





                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Size*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="sizeclassA" value={formData.sizeclassA} onChange={this.handleChange}  >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="sizeclassB" value={formData.sizeclassB} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="sizeclassC" value={formData.sizeclassC} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>





                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Interest Rate Type*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control required placeholder="" name="interestratetypeclassA" value={formData.interestratetypeclassA} onChange={this.handleChange} as="select" >
                                                    <option selected>None</option>
                                                    <option >Floating Rate</option>
                                                    <option >Fixed Rate</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" as="select" name="interestratetypeclassB" value={formData.interestratetypeclassB} onChange={this.handleChange} >
                                                    <option selected>None</option>
                                                    <option >Floating Rate</option>
                                                    <option >Fixed Rate</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" as="select" name="interestratetypeclassC" value={formData.interestratetypeclassC} onChange={this.handleChange}  >
                                                    <option selected>None</option>
                                                    <option >Floating Rate</option>
                                                    <option >Fixed Rate</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>




                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Coupon Rate(per annum)*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="couponrateclassA" value={formData.couponrateclassA} onChange={this.handleChange}  >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="couponrateclassB" value={formData.couponrateclassB} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="couponrateclassC" value={formData.couponrateclassC} onChange={this.handleChange}   >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>




                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Interest Rate Frequency*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control required placeholder="" as="select" name="interestratefreqclassA" value={formData.interestratefreqclassA} onChange={this.handleChange}  >
                                                    <option selected>None</option>
                                                    <option >Monthly</option>
                                                    <option >Quaterly</option>
                                                    <option >Yearly</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" as="select" name="interestratefreqclassB" value={formData.interestratefreqclassB} onChange={this.handleChange} >
                                                    <option selected>None</option>

                                                    <option >Monthly</option>
                                                    <option >Quaterly</option>
                                                    <option >Yearly</option>            </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" as="select" name="interestratefreqclassC" value={formData.interestratefreqclassC} onChange={this.handleChange} >
                                                    <option selected>None</option>
                                                    <option >Monthly</option>
                                                    <option >Quaterly</option>
                                                    <option >Yearly</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>




                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Face Value*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="facevalueclassA" value={formData.facevalueclassA} onChange={this.handleChange} >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="facevalueclassB" value={formData.facevalueclassB} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="facevalueclassC" value={formData.facevalueclassC} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>







                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Minimum Units of Subscription*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="minsubclassA" value={formData.minsubclassA} onChange={this.handleChange}  >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="minsubclassB" value={formData.minsubclassB} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="minsubclassC" value={formData.minsubclassC} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>



                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Total Units available*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control placeholder="" name="totalunitclassA" value={formData.totalunitclassA} onChange={this.handleChange} >
                                                    {/* <option selected="selected">Select</option> */}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="totalunitclassB" value={formData.totalunitclassB} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" name="totalunitclassC" value={formData.totalunitclassC} onChange={this.handleChange}  >

                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>



                                    <div className="poolslicingfirst row">
                                        <div className="poolattridropdown col-2" style={{ marginTop: "-8px" }}>

                                            <p id="headingparah">Note Period*</p>

                                        </div>

                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupName">
                                                {/* <Form.Label>Document Tag</Form.Label> */}
                                                <Form.Control required placeholder="" as="select" name="noteperiodclassA" value={formData.noteperiodclassA} onChange={this.handleChange}  >
                                                    <option selected>None</option>
                                                    <option >12 Month</option>
                                                    <option >24 Month</option>
                                                    <option >36 Month</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>





                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">
                                                {/* <Form.Label>Owner ID</Form.Label> */}
                                                <Form.Control required placeholder="" as="select" name="noteperiodclassB" value={formData.noteperiodclassB} onChange={this.handleChange}  >
                                                    <option selected>None</option>
                                                    <option >12 Month</option>
                                                    <option >24 Month</option>
                                                    <option >36 Month</option>

                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="poolattridropdown col-3">
                                            <Form.Group controlId="formGroupID">

                                                <Form.Control required placeholder="" as="select" name="noteperiodclassC" value={formData.noteperiodclassC} onChange={this.handleChange} >
                                                    <option selected>None</option>
                                                    <option >12 Month</option>
                                                    <option >24 Month</option>
                                                    <option >36 Month</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>

                                    </div>
                                </div>
                                {this.state.getLoansLoader === false ? '' : <FormLoader />}


                            </Form>

                        </div>
                    
               
               
               

                    <div className="modalsubmit">
                        <div className="submitbuttonbg">
                            <hr className="hrbottom" />

                            <div className="row justify-content-end">
                                <Button variant="contained" id="optionalbutton" onClick={this.onCloseModal1} > Cancel  </Button>
                                <Button variant="contained" color="primary" type="submit" onClick={this.submitHandler} >Create Tranche</Button>
                            </div>

                        </div>
                    </div>
                    </div>

                    </div>
                {/* // {this.state.open === true ? <React.Fragment><Snackbar msg={"Data Saved Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}

            </React.Fragment>
        );
    }
}

export default withSnackbar(PoolSlicingParameter)