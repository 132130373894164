/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';

class PreProcessingViewDocumentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
    };
  }

  async componentDidMount() {
    var loanId = this.props.dataFromParent;
    this.loanDetails(loanId);
  };


  async loanDetails(loanId){
    this.setState({ getLoansLoader: true, assignbutton: true });
    try {
        const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/loandocs?offset=' + loanId);
        const resp = res.data;
        if (res.status === 200) {
            if (resp.token == '-1') {
                const message = "Invalid Token!";
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000
                });
                this.props.history.push({
                    pathname: '/'
                })
            } else {
                console.log("poolloans", resp);
                this.setState({loading: true, tableData: resp, getLoansLoader: true,  });
                const message = "Successfully fetched the data";
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 2000,
                });
            }

        } else if (res.status === 204) {
            const message = "No content available";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        } else {
            const message = "something went wrong, please try again";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }



    } catch (error) {

        if (error.response.status == 400) {

            console.log("getLoans API error", error);
            console.log("error.response", error.response.data.message);

            const message = error.response.data.message;
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });

        } else {

            const message = "Went something wrong, please try again";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });


        }

    }
}

  render() {


    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("valuee" + selected);
        console.log(allRows);
        this.setState({
          rowsSelected: selected,


        });

        // if (allRows.length < 1) {

        //   var assignbutton = true
        //   this.setState({
        //     assignbutton: assignbutton
        //   })
        // }
        // else {
        //   this.setState({
        //     assignbutton: false
        //   })
        // }




      },


      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'LoanID',
        label: 'Loan Contract Number',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'LDocID',
        label: 'Document ID',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'LDocName',
        label: 'Document Name',
        options: {
          filter: true,
          sort: false,
        },
      },

      //   {
      //     name: 'PoolDesc',
      //     label: 'Action',
      //     options: {
      //       filter: true,
      //       sort: false,
      //     },
      //   },

      {
        name: "LDocPath",
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (

              <React.Fragment>
                
                <a target="_blank" href={process.env.react_app_base_url + "root_folder/" + value}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                  //   onClick={this.downloadDoc.bind(this, value)}
                  >
                    <GetAppIcon />
                  </IconButton>
                </a>
              </React.Fragment>
            )
          }








        }
      },
    ];



    return (

      <React.Fragment>


        <div className="page-content">

          {this.state.loading === true ?
            <React.Fragment>
              <MUIDataTable
                data={this.state.tableData}
                columns={columns}
                options={options}

              />

            </React.Fragment>

            : <Loader msg={"Please wait, Loading document details"} />}

          {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
        </div>


      </React.Fragment>
    );
  }
}


export default withSnackbar(PreProcessingViewDocumentsTable);