/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { Form } from 'react-bootstrap';
import { withSnackbar } from 'notistack';

class AssetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
     
    };

  }

  async componentDidMount() {
    var loan_id = this.props.dataFromParent;
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    this.loanDetails(loan_id, peer, token)
}

  async loanDetails(loan_id, peer, token) {
    try {
        const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/loancollateral?loanId=' + loan_id + "&peer=" + peer + "&token=" + token);
        const resp = res.data;
        console.log("loandetails", res.data);
        if (res.status === 200) {
            console.log("loandetails", resp);
            var result = resp;


            this.setState({ Collateraldetails: result, loadingmodal: true });
            const message = "Successfully fetched the data";
            this.props.enqueueSnackbar(message, {
                variant: 'info',
                autoHideDuration: 2000,
            });
        } else {
            alert("Failed");
        }
    } catch (error) {
        alert("Failed");
        console.log("getLoans API error", error);
    }
}


  render() {


    return (
     
      <React.Fragment>
       
            
       <div>
       {this.state.Collateraldetails!=null && this.state.loadingmodal === true ? 
                            <Form id="labelform">
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Group controlId="formGroupName">
                                            <Form.Label>Class of vehicle</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].ClassOfVehicle}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-4">
                                        <Form.Group controlId="formImage">
                                            <Form.Label>Makers name</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].MakersName} />
                                        </Form.Group>
                                    </div>

                                    <div className="col-4">
                                        <Form.Group controlId="formGroupDescription">
                                            <Form.Label>Type of body</Form.Label>
                                            <Form.Control required disabled placeholder=""value={this.state.Collateraldetails[0].TypeOfBody} />
                                        </Form.Group>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-4">
                                        <Form.Group controlId="formGroupName">
                                            <Form.Label>Horsepower</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].Horsepower}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-4">
                                        <Form.Group controlId="formImage">
                                            <Form.Label>Chassis number</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].ChassisNumber}/>
                                        </Form.Group>
                                    </div>

                                    <div className="col-4">
                                        <Form.Group controlId="formGroupDescription">
                                            <Form.Label>Number of cylinders</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].NumberOfCylinders}/>
                                        </Form.Group>
                                    </div>
                                </div>



                                <div className="row">
                                    <div className="col-4">
                                        <Form.Group controlId="formGroupName">
                                            <Form.Label>Year of manufacturer</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].YearOfManufacturer}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-4">
                                        <Form.Group controlId="formImage">
                                            <Form.Label>Engine number</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].EngineNumber}/>
                                        </Form.Group>
                                    </div>

                                    <div className="col-4">
                                        <Form.Group controlId="formGroupDescription">
                                            <Form.Label>Colour</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].Colour}/>
                                        </Form.Group>
                                    </div>
                                </div>


                            </Form>
                     :  <Loader msg={"Please wait, Loading Asset details"} /> }
                        </div>
       
      </React.Fragment>
    );
  }
}


export default withSnackbar(AssetDetails);