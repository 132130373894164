/* eslint-disable require-jsdoc */
import React, { Component } from 'react';

import MUIDataTable from 'mui-datatables';
import Loader from '../../../../components/loader';
import Snackbar from '../../../../components/snackbar';
import axios from "axios";
import { withSnackbar } from 'notistack';

// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import GetAppIcon from '@material-ui/icons/GetApp';


// import IconButton from '@material-ui/core/IconButton';




class TrancheDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
     
    };
   

  }
  
 


  
  async componentDidMount() {
    var poolid=this.props.dataFromParent;
// console.log("pooId"+poolId)

const peer = localStorage.getItem("peer");
const token = localStorage.getItem('token');
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/tranche/tranches?peer='+peer+"&token="+token + "&poolid=" + poolid);
      const resp = res.data;
      if (res.status === 204) {

        const message = "Data not available";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
    }
    else if(res.status== 200){
      if(res.data.token == '-1'){
    
        const message = "Invalid Token!";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
      this.props.history.push({
        pathname: '/'
      })
    
      } else {
    
          const message = "Successfully fetched the data!";
          this.props.enqueueSnackbar(message, { 
            variant: 'info',
            autoHideDuration: 2000
        });

        this.setState({ tableData: resp, loading: true });
      }
     } else {
        alert("Failed");
      }
    } catch (error) {
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }
    }

  };

  render() {
 

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("valuee" + selected);
        console.log(allRows);
        this.setState({
          rowsSelected: selected,


        });

        


      },


      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      
      {
        name: 'ClassName',
        label: 'Tranche Name',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'TotalUnitsAvailable',
        label: 'Tranche Units',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'CouponRate',
        label: 'Coupon Rate',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'NotePeriod',
        label: 'Lock in Period',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'Currency',
        label: 'Currency',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'NominalAmount',
        label: 'Total Amount',
        options: {
          filter: true,
          sort: false,
        },
      },
    
      
   
    ];



    return (
     
      <React.Fragment>
       
            
            <div className="page-content">

              {this.state.loading === true ?
                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

                : <Loader msg={"Please wait, Loading document details"} />}

              {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '}
            </div>
       
       
      </React.Fragment>
    );
  }
}
export default withSnackbar(TrancheDetails)

