/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Iframe from "react-iframe";
import $ from "jquery";
import CompareItem from "../../../components/pageTitle/CompareItem";
import * as moment from "moment";
import { withSnackbar } from "notistack";
import {
  compareData,
  UpdateData,
  ReviewStatus,
} from "../../../servies/services";
import { testHighlights } from "../../../Response";
import sample from "../../../images/singlesample.pdf";
import credibly from "../../../images/credibly.pdf";
import "./HighlightCoords.css";
// * react-pdf-highlighter --start
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";

// import testHighlights from "./test-highlights";

import Spinner from "./Spinner";
import PDFHighlighterSidebar from "./PDFHighlighterSidebar";
const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const HighlightPopup = ({ content, comment }) => {
  let lmsLoanText = Object.values(content?.lmsloan)[0];
  return lmsLoanText ? (
    <div className="Highlight__popup">{lmsLoanText}</div>
  ) : null;
};

// const PRIMARY_PDF_URL = JSON.parse(sessionStorage.getItem('activeLoanData'))?.loandocpath
// const PRIMARY_PDF_URL = credibly

// const searchParams = new URLSearchParams(document.location.search);

// const initialUrl = searchParams.get("url") || PRIMARY_PDF_URL;

// * react-pdf-highlighter --end
const token = localStorage.getItem("token");

class CompareData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
      msgNewData: null,
      compare: [],
      fields: {},
      PoolId: localStorage.getItem("runddpoolid"),
      PoolName: localStorage.getItem("runddpoolname"),
      // * react-pdf-highlighter --start
      url: JSON.parse(sessionStorage.getItem("activeLoanData"))?.loandocpath,
      highlights: JSON.parse(sessionStorage.getItem("activeLoanData"))[
        JSON.parse(sessionStorage.getItem("activeLoanData"))?.loandocpath
      ]
        ? [
            ...JSON.parse(sessionStorage.getItem("activeLoanData"))[
              JSON.parse(sessionStorage.getItem("activeLoanData"))?.loandocpath
            ],
          ]
        : [],
      // highlights: testHighlights.res.data[0][initialUrl]
      //   ? [...testHighlights.res.data[0][initialUrl]]
      //   : []
      // * react-pdf-highlighter --end
    };
    this.saveDate = this.saveDate.bind(this);
  }
  // * react-pdf-highlighter --end

  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };

  // toggleDocument = () => {
  //   const newUrl =
  //     this.state.url === PRIMARY_PDF_URL ? SECONDARY_PDF_URL : PRIMARY_PDF_URL;

  //   this.setState({
  //     url: newUrl,
  //     highlights: testHighlights[newUrl] ? [...testHighlights[newUrl]] : []
  //   });
  // };

  scrollViewerTo = (highlight) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());
    console.log("Hashchange render", highlight);
    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  getHighlightById(id) {
    const { highlights } = this.state;
    return highlights.find((highlight) => highlight.id == id);
  }

  addHighlight(highlight) {
    let { highlights } = this.state;

    console.log("Saving highlight", highlight);
    highlight.content = {
      lmsloan: { Key: highlight.content.text },
      agreementloan: { Key: highlight.content.text },
    };
    this.setState({
      highlights: [{ ...highlight, id: getNextId() }, ...highlights],
    });
  }

  updateHighlight(highlightId, position, content) {
    console.log("Updating highlight", highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }
  // * react-pdf-highlighter --end

  async handleClick(value) {
    this.setState({ popupLoader: true, open2: true });
    var data = {
      dealidlist: [value],
      poolid: this.state.PoolId,
      poolname: this.state.PoolName,
    };
    console.log("getLoans dataString", data);
    // try {
    // const res = await compareData(data, token)
    const res = { status: 200 };

    console.log("datasssssssssss", res);
    const resp = testHighlights;

    console.log("data", resp);
    // if (res.status === 500) {
    //   this.setState({ getLoansLoader: false, open: true, loading: false });
    //   const message = 'Loan file is not available'
    //   this.props.enqueueSnackbar(message, {
    //     variant: 'error',
    //     autoHideDuration: 3000,
    //   });
    // }
    if (res.status === 200) {
      console.log("eligibleloans", resp.res.data[0]);
      // console.log(JSON.stringify(resp[0].loandocpath))

      this.setState({
        popupLoader: false,
        open: true,
        msgNewData: resp.res.data[0],
        loading: true,
        loandocpath: resp.res.data[0].loandocpath,
        loanpath: resp.res.data[0].loandocpath,
      });
      let varies = Object.keys(resp.res.data[0].lmsloan);
      this.setState({ compare: varies });
      var formData = new FormData();
      formData.dealid = this.props.msgData;
      formData.poolid = this.state.PoolId;
      const simpleres = await ReviewStatus(formData, token);

      console.log("simpleres------" + simpleres);

      // this.getContent()
    } else {
      this.setState({ getLoansLoader: false, open: true, loading: false });
      const message = "Loan file is not available";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }
  // catch (error) {
  //   alert('Failedddddddddd');
  //   console.log('getLoans API error', error);
  // }

  comparision = (i) => {
    return (
      <CompareItem
        id={i + 1}
        mask={false}
        title={Object.keys(this.state.msgNewData.agreementloan)[i]}
        lmsLoan={
          this.state.msgNewData.lmsloan[
            Object.keys(this.state.msgNewData.agreementloan)[i]
          ]
        }
        agreementLoan={
          this.state.msgNewData.agreementloan[
            Object.keys(this.state.msgNewData.agreementloan)[i]
          ]
        }
        match={
          this.state.msgNewData.attributewise_matched[
            Object.keys(this.state.msgNewData.agreementloan)[i]
          ]
        }
      ></CompareItem>
    );
  };
  //   handleChange(e) {
  //     const fields = this.state.fields;
  //     fields[e.target.name] = e.target.value;
  //     this.setState({
  //         fields,
  //     });
  //     console.log('fields', this.state.fields);
  // }

  async componentDidMount() {
    const value = this.props.msgData;
    // this.handleClick(value);
  }

  async saveDate() {
    let savedata = sessionStorage.getItem("activeLoanData");
    console.log("hhhhhhhhh", savedata);
    console.log("sssssss", this.state.highlights);

    let agree = {};
    let newArr = [];
    for (let i = 0; i < this.state.highlights.length; i++) {
      newArr.push(this.state.highlights[i].content.lmsloan);
    }
    console.log("heyeyeyeyeyyeyeyeye", newArr);
    const obj = Object.assign({}, ...newArr);
    console.log("heyeyeyeyeyyeyeyeye", obj);

    let newArr1 = [];
    for (let i = 0; i < this.state.highlights.length; i++) {
      newArr1.push(this.state.highlights[i].content.agreementloan);
    }
    console.log("heyeyeyeyeyyeyeyeye11111", newArr1);
    const obj1 = Object.assign({}, ...newArr1);
    console.log("heyeyeyeyeyyeyeyeye", obj1);

    let dups = {
      lmsloan: obj,
      agreementloan: obj1,
      poolid: this.state.PoolId,
    };

    // var senddata = {}
    // let dups = {}
    // dups['lmsloan'] = this.state.msgNewData.lmsloan
    // dups['agreementloan'] = this.state.msgNewData.agreementloan

    // $('#viewMore tbody tr').each(function (row, tr) {
    // senddata[row] = {
    // 'lms': $(tr).find("td:eq(0) input[type='text']").val(),
    // 'contract': $(tr).find("td:eq(1) input[type='text']").val()
    // };
    // });
    // let change = JSON.parse(localStorage.getItem('fieldss'))
    // console.log('changes', change)
    // Object.keys(change).map((value) => {
    // console.log('chnagess', change[value].lmsLoan)
    // dups.lmsloan[value] = change[value].lmsLoan;
    // dups.agreementloan[value] = change[value].agreementLoan
    // })
    // dups.lmsloan[change[3]]=change[4]
    // dups.agreementloan[change[3]]=change[5]
    // dups['poolid'] = this.state.PoolId
    // console.log('dups', dups)
    // console.log("save button::" + JSON.stringify(senddata))

    // var savedata = {
    // "poolid":this.state.PoolId
    // }
    console.log("saveeeeeeeeeeeeee", dups);
    const res = await UpdateData(dups, token);
    if (res.status === 200) {
      if (res.data.isSuccess == true) {
        const message = "Data Updated";

        this.props.action({ close: true, reload: true });

        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 4000,
        });
      } else {
        this.props.action({ close: true, reload: false });
        const message = "Something went Wrong Please Try Again!!";
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
      }
    } else {
      alert("Saving Failed");
    }
  }
  //   getContent= async()=> {

  //     const header = {"authorization" : `Bearer ${token}`};
  //     const iframe = document.getElementById('myId').contentDocument;
  //       axios.get("https://umbtest.intainabs.com/backendapiumb/uploads/uploads/data/input/Job_17/Warehouse6-3-2021/534406/Audit%20Trail.pdf", {headers:header})
  //         .then((response) => {
  //             // iframe.write(response.data);
  //             // iframe.url = response.config.url;
  //             // iframe.src = response.data

  //             // var obj = document.createElement('object');
  //             // obj.style.width = '100%';
  //             // obj.style.height = '842pt';
  //             // obj.type = 'application/pdf';
  //             // obj.data = 'data:application/pdf;base64,' + response.data;
  //             // iframe.src= document.body.appendChild(obj);

  //             const file = new Blob(
  //               [response.data],
  //               {type: 'application/pdf'});
  //               const fileURL = URL.createObjectURL(file);
  //               iframe.src = fileURL
  //         })
  // }

  render() {
    const { url, highlights } = this.state;
    window.addEventListener("hashchange", this.scrollToHighlightFromHash);
    // const dataman = Object.keys(this.state.msgNewData)
    // console.log('dataman',dataman)
    return (
      
      <React.Fragment>
         {url !== undefined ?
        <div id="comparesave">
          <Button
            onClick={this.saveDate}
            variant="contained"
            color="primary"
            type="submit"
          >
            {" "}
            SAVE{" "}
          </Button>
        </div>
        :
       ''}
        {url !== undefined ?
        <div className="matchscroll">
          {/* // * react-pdf-highlighter --start */}
          <div style={{ display: "flex", height: "100vh" }}>
            <PDFHighlighterSidebar
              highlights={highlights}
              resetHighlights={this.resetHighlights}
              // toggleDocument={this.toggleDocument}
            />
            <div
              style={{
                height: "100vh",
                width: "75vw",
                position: "relative",
              }}
            >
              <PdfLoader
                // url={url}
                url={
                  process.env.react_app_base_url + "/root_folder/uploads/" + url
                }
                beforeLoad={<Spinner />}
              >
                {(pdfDocument) => (
                  <PdfHighlighter
                    pdfDocument={pdfDocument}
                    enableAreaSelection={(event) => event.altKey}
                    onScrollChange={resetHash}
                    // pdfScaleValue="page-width"
                    scrollRef={(scrollTo) => {
                      this.scrollViewerTo = scrollTo;

                      this.scrollToHighlightFromHash();
                    }}
                    onSelectionFinished={(
                      position,
                      content,
                      hideTipAndSelection,
                      transformSelection
                    ) => (
                      <Tip
                        onOpen={transformSelection}
                        onConfirm={(comment) => {
                          this.addHighlight({ content, position, comment });

                          hideTipAndSelection();
                        }}
                      />
                    )}
                    highlightTransform={(
                      highlight,
                      index,
                      setTip,
                      hideTip,
                      viewportToScaled,
                      screenshot,
                      isScrolledTo
                    ) => {
                      const isTextHighlight = !Boolean(
                        highlight.content && highlight.content.image
                      );

                      const component = isTextHighlight ? (
                        <Highlight
                          isScrolledTo={isScrolledTo}
                          position={highlight.position}
                          comment={highlight.comment}
                        />
                      ) : (
                        <AreaHighlight
                          highlight={highlight}
                          onChange={(boundingRect) => {
                            this.updateHighlight(
                              highlight.id,
                              { boundingRect: viewportToScaled(boundingRect) },
                              { image: screenshot(boundingRect) }
                            );
                          }}
                        />
                      );

                      return (
                        <Popup
                          popupContent={<HighlightPopup {...highlight} />}
                          onMouseOver={(popupContent) =>
                            setTip(highlight, (highlight) => popupContent)
                          }
                          onMouseOut={hideTip}
                          key={index}
                          children={component}
                        />
                      );
                    }}
                    highlights={highlights}
                  />
                )}
              </PdfLoader>
            </div>
          </div>
          {/* // * react-pdf-highlighter --end */}
        </div>
        :
        <h6 className="text-center loanfilesetting">Loan file is not available</h6>}
      </React.Fragment>
    );
  }
}

export default withSnackbar(CompareData);
