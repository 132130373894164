/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { Form } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';

class PreProcessingRatingDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			getLoansLoader: false,
			Customerdetails: null,
			Assetdetails: null,
			Loandetails: null
		};
	}

	

	async componentDidMount() {

		const loanId = this.props.dataFromParent;
		this.loans(loanId);
		this.customersDetails(loanId);
		this.assetDetails(loanId);

	}


	async customersDetails(loanId){


		try {
			const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/customers?loanId=' + loanId);
			const resp = res.data;
			if (res.status === 200) {
				if (resp.token == '-1') {
					const message = "Invalid Token!";
					this.props.enqueueSnackbar(message, {
						variant: 'error',
						autoHideDuration: 3000
					});
					this.props.history.push({
						pathname: '/'
					})
				} else {
					console.log("poolloans", resp);
					this.setState({ Customerdetails: resp, loadingmodal: true });
					const message = "Successfully fetched the data";
					this.props.enqueueSnackbar(message, {
						variant: 'info',
						autoHideDuration: 2000,
					});
				}
	
			} else if (res.status === 204) {
				const message = "No content available";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			} else {
				const message = "something went wrong, please try again";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
	
	
	
		} catch (error) {
	
			if (error.response.status == 400) {
	
				console.log("getLoans API error", error);
				console.log("error.response", error.response.data.message);
	
				const message = error.response.data.message;
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
	
			} else {
	
				const message = "Went something wrong, please try again";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
	
	
			}
	
		}
	}


	async assetDetails(loanId){

		try {
			const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/asset?loanId=' + loanId);
			const resp = res.data;
			if (res.status === 200) {
				if (resp.token == '-1') {
					const message = "Invalid Token!";
					this.props.enqueueSnackbar(message, {
						variant: 'error',
						autoHideDuration: 3000
					});
					this.props.history.push({
						pathname: '/'
					})
				} else {
					console.log("poolloans", resp);
					this.setState({ Assetdetails: resp, loadingmodal: true });
					const message = "Successfully fetched the data";
					this.props.enqueueSnackbar(message, {
						variant: 'info',
						autoHideDuration: 2000,
					});
				}
	
			} else if (res.status === 204) {
				const message = "No content available";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			} else {
				const message = "something went wrong, please try again";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
	
	
	
		} catch (error) {
	
			if (error.response.status == 400) {
	
				console.log("getLoans API error", error);
				console.log("error.response", error.response.data.message);
	
				const message = error.response.data.message;
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
	
			} else {
	
				const message = "Went something wrong, please try again";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
	
	
			}
	
		}
	}


	async loans(value) {

		try {
			const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/loans?loanId=' + value);
			const resp = res.data;
			if (res.status === 200) {
				if (resp.token == '-1') {
					const message = "Invalid Token!";
					this.props.enqueueSnackbar(message, {
						variant: 'error',
						autoHideDuration: 3000
					});
					this.props.history.push({
						pathname: '/'
					})
				} else {
					console.log("loandetails", resp);
					console.log(resp[0].defaultRating)
					this.setState({ Loandetails: resp, loadingmodal: true });
					this.setState({ loading: true });
					var first = resp[0].defaultRating;
					var second = Math.abs((parseInt(resp[0].defaultRating) - 100));
					console.log(first + second);
					const first_label = 'Default Probability';
					const second_label = 'Positive Probability';
					var first = parseInt(first);
					var second = parseInt(second);
					this.setState({ first: first, second: second });
	
					const data = [
						first,
						second,
	
					];
					const labels = [first_label + ' : ' + first + '%', second_label + ' : ' + second + '%'];
					const bgColor = ['#FFC200', '#24822b'];
	
					const myChart = {
						labels: labels,
						datasets: [
							{
								data: data,
								backgroundColor: bgColor,
							}],
					};
					this.setState({ myChart: myChart });

					const message = "Successfully fetched the data";
					this.props.enqueueSnackbar(message, {
						variant: 'info',
						autoHideDuration: 2000,
					});
				}
	
			} else if (res.status === 204) {
				const message = "No content available";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			} else {
				const message = "something went wrong, please try again";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
	
	
	
		} catch (error) {
	
			if (error.response.status == 400) {
	
				console.log("getLoans API error", error);
				console.log("error.response", error.response.data.message);
	
				const message = error.response.data.message;
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
	
			} else {
	
				const message = "Went something wrong, please try again";
				this.props.enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
				});
	
	
			}
	
		}

	}


	render() {
		const { Customerdetails, Assetdetails, Loandetails } = this.state;
		// const {Assetdetails}=this.state;
		// const Loandetails=this.state;
		return (

			<React.Fragment>


				<div>



					<div className="middleContent">

						<div className="mb-3">

							<div className="card-body mt-5">



								<div className="row" id="ratingDetails">

									<div className="col-md-6 col-lg-6 col-sm-12 col-12">
										<div className="card-body">
											<Doughnut data={this.state.myChart} />
										</div>

										<div className="grap-section-text3">

											<h5>
												<span className="grp-dot2"> </span> Positive Probability [{this.state.second} %]
																		</h5>
											<h5>
												<span className="grp-dot"> </span> Default Probability [{this.state.first} %]
																		</h5>



										</div>

									</div>


									<div className="col-md-6 col-lg-6 col-sm-12 col-12">
										<div className="scrollrating">
											{Customerdetails != null ?
												<React.Fragment>
													<p>


														<label>Gender :</label><span id="gender"> </span>{Customerdetails[0].gender}
													</p>
													<p>
														<label>Marital Status :</label><span id="maritalStatus">  </span>{Customerdetails[0].maritalStatus}
													</p>
													<p>
														<label>Qualification :</label><span id="qualification">  </span>{Customerdetails[0].qualification}
													</p>
													<p>
														<label>Employment Status :</label><span id="employmentStatus">  </span>{Customerdetails[0].employmentStatus}
													</p>
													<p>
														<label>Occupation :</label><span id="occupation">  </span>{Customerdetails[0].occupation}
													</p>
													<p>
														<label>Source Of Income :</label><span id="sourceOfIncome">  </span>{Customerdetails[0].sourceOfIncome}
													</p>
													<p>
														<label>Gross Income :</label><span id="grossIncome">  </span>{Customerdetails[0].grossIncome}
													</p>
													<p>
														<label>ADDRESS TYPE1 :</label><span id="addressType1">  </span>{Customerdetails[0].addressType1}
													</p>
													<p>
														<label>City1 :</label><span id="city1">  </span>{Customerdetails[0].city1}
													</p>
													<p>
														<label>State1 :</label><span id="city2">  </span>{Customerdetails[0].city2}
													</p>
													<p>
														<label>PinCode1 :</label><span id="state1">  </span>{Customerdetails[0].state1}
													</p>
													<p>
														<label>ADDRESS TYPE2 :</label><span id="pincode1">  </span>{Customerdetails[0].pincode1}
													</p>
													<p>
														<label>City2 : </label><span id="addressType2">  </span>{Customerdetails[0].addressType2}
													</p>
													<p>
														<label>State 2 : </label><span id="state2">  </span>{Customerdetails[0].state2}
													</p>
												</React.Fragment>
												: ''}
											{Assetdetails != null && Loandetails != null ?
												<React.Fragment>

													<p>
														<label>Make/Brand :  </label><span id="makersName">  </span>{Assetdetails[0].makersName}
													</p>
													<p>
														<label>Invoice Price :  </label><span id="invoicePrice">  </span>{Assetdetails[0].invoicePrice}
													</p>
													<p>
														<label>LTV : </label><span id="LTV">  </span>{Assetdetails[0].LTV}
													</p>


													<p>
														<label>Dealer Name :  </label><span id="dealerName">  </span>{Loandetails[0].dealerName}
													</p>

													<p>
														<label>Loan Amount : </label><span id="loanOrFacilityAmount">  </span>{Loandetails[0].loanOrFacilityAmount}
													</p>
													<p>
														<label>IRR : </label><span id="defaultInterestRatePerAnnum">  </span>{Loandetails[0].defaultInterestRatePerAnnum}
													</p>
													<p>
														<label>Tenor : </label><span id="loanOrFacilityTermInMonths">  </span>{Loandetails[0].loanOrFacilityTermInMonths}
													</p>
													<p>
														<label>EMI Amount : </label><span id="emiPayable">  </span>{Loandetails[0].emiPayable}
													</p>
													<p>
														<label>Processing Fee : </label><span id="applicationProcessingFee">  </span>{Loandetails[0].applicationProcessingFee}
													</p>
													<p>
														<label>Net Disb Amount : </label><span id="netDisbAmount">  </span>{Loandetails[0].netDisbAmount}
													</p>
													<p>
														<label>Source Branch :</label><span id="sourceBranch">  </span>{Loandetails[0].sourceBranch}
													</p>
												</React.Fragment>
												: ''}
										</div>
									</div>


								</div>
							</div>
						</div>


					</div>
				</div>

			</React.Fragment>
		);
	}
}


export default withSnackbar(PreProcessingRatingDetails);