/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import Modal from 'react-responsive-modal';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import CompareData from './compareData';
import Match from '../../../images/match.png';
import Mismatch from '../../../images/mismatch.png';
import { withSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import ReactModal from 'react-modal';
import * as moment from 'moment';
import { widgets, CustomFieldTemplate, customStyles, customStylesauto } from '../../../components/customscripts/customscript';
import FormLoader from '../../../components/loader/formLoader';


function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
      var res = str.substring(2, str.length);
      var rep = res.replace(/[a-z]/gi, 'x')
      return str.replace(res, rep);
  }

  return str;
}

const Form = withTheme(MuiTheme);
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');

class poolFormation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open1: false,
      schema: schema,
      uiSchema: uiSchema,
      loading: false,
      getLoansLoader: false,
      open: false,
      openPopup: false,
      message: null,
      popupLoader: false,
      msgData: null,
      open2: false,
      searchText: '',
      rowsSelected: null
    };

    this.childHandler = this.childHandler.bind(this)

  }

  childHandler(dataFromChild) {
    console.log("dataFromChilddataFromChilddataFromChilddataFromChilddataFromChild", dataFromChild);
    if (dataFromChild.close == true) {
      this.setState({ open2: false });
    }
    
    const idToRemove = dataFromChild.loanContractNumber;
    const filteredPeople = this.state.tableData.filter((item) => item.lmsLoan.loanContractNumber === idToRemove);
    filteredPeople[0].matched = 1;
    console.log('filteredPeople', filteredPeople);

  }


  onOpenModal1 = () => {
    console.log("inside modal");
    this.setState({ open1: true });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false });
  };


  onOpenModal2 = () => {
    console.log("inside modal");
    this.setState({ open2: true });
  };
  onCloseModal2 = () => {
    this.setState({ open2: false });
  };

  onSubmit = (value) => {
    console.log('onSubmit:', value.formData);
    let data = value.formData;

    console.log('onSubmit data:', data.PoolStartedDate)
    let newDateSubmit1 = moment(data.PoolStartedDate).format('DD-MM-YYYY')
    console.log("newDateSubmit", newDateSubmit1)
    data.PoolStartedDate = newDateSubmit1


    console.log('onSubmit data:', data.PoolExpiryDate)
    let newDateSubmit2 = moment(data.PoolExpiryDate).format('DD-MM-YYYY')
    console.log("newDateSubmit", newDateSubmit2)
    data.PoolExpiryDate = newDateSubmit2

    console.log('onSubmit data:', data);
    data.input = localStorage.getItem('poolFormation');
    data.PoolStatus = 'Pending assignment to Issuer';
    this.createPool(data);
  }

  onCancel = () => {
    const { data } = this.props;
    this.setState({
      ...data,
    });
  }

  backBtn = () => {

    this.props.history.push({
      pathname: '/portfolio-setup-wizard/due-diligence'
    });

  }


  async createPool(data) {
    this.setState({ getLoansLoader: true });

    const dataString = data;
    dataString.peers = JSON.parse(localStorage.getItem("peers"))
    dataString.token = localStorage.getItem("token")
    console.log("createPool", dataString);

    try {
      const res = await axios.post(process.env.react_app_base_url + 'api/V1/portfolio/poolcreation', dataString);
      const resp = res.data;
      if (res.status === 200) {

        this.setState({ getLoansLoader: false, openPopup: true, message: resp.message, loading: true });
        if (resp.isSuccess === true) {
          // alert("success");
          const poolID = resp.poolId;
          this.props.history.push({
            pathname: '/portfolio-setup-wizard/pool-details/' + poolID
          })

          const message = "Pool Created Succefully";
          this.props.enqueueSnackbar(message, {
            variant: 'info',
            autoHideDuration: 3000,
          });


        }

        else {

        }

      } else {
        alert("Failed");
      }
    } catch (error) {
      // alert("Failed");
      this.setState({ getLoansLoader: false });
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
        });

      } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
        });


      }
    }

  };


  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);


  };

  async componentDidMount() {

    localStorage.removeItem('poolFormation');

    if (localStorage.getItem("dueDiligence") !== null) {

      const data = localStorage.getItem('dueDiligence');
      this.getLoans(data);

    } else {
      alert('Please select the parameters');
      window.location.assign('/portfolio-setup-wizard/eligible-portfolio-import');

    }


  }

  async handleClick(value) {

    this.setState({ msgData: value })
    this.setState({ popupLoader: true, open2: true });
  }

  async getLoans(data) {
    this.setState({ getLoansLoader: true });

    const dataString = {
      "input": data
    }

    console.log('getLoans dataString', dataString);

    try {
      const res = await axios.post(process.env.react_app_base_url + 'api/V1/portfolio/rundd', dataString);

      const resp = res.data;
      if (res.status === 200) {
        console.log('eligibleloans', resp);
        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
        console.log("this.state.tableData.lmsLoan", this.state.tableData.lmsLoan)
        console.log("this.state.tableData", this.state.tableData)
        console.log("this.state.tableData", this.state.tableData[0].lmsLoan)

        const message = "Record Fetched Successfully";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 3000,
        });

      } else {
        alert('Failed');
      }
    } catch (error) {
      alert('Failed');
      console.log('getLoans API error', error);
    }
  };
  async selectedpoolid(selected) {
    const arr = []


    for (var i = 0; i < selected.length; i++) {

      var j = selected[i];
      let loanId = this.state.tableData[j].lmsLoan.loanContractNumber;
      arr.push(loanId);
    }
    console.log(arr);
    localStorage.setItem('poolFormation', arr)
  }


  render() {
    const { open1, open2 } = this.state;
    const { uiSchema } = this.state;

    const options = {
      filterType: 'dropdown',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: true,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },


      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },
      loading: true,

      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };




    const columns = [
      {
        name: 'source',
        label: 'Source',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <p>LMS</p>
                <p>Contract</p>
              </React.Fragment>
            );
          },

        }
      },

      {
        // 1
        name: "lmsLoan",
        label: 'Loan Contract Number',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>

                <p>{value.loanContractNumber}</p>
                <p>{tableMeta.rowData[6].loanContractNumber}</p>
              </React.Fragment>
            );
          },

        }
      },
      {
        // 2
        name: "lmsLoan",
        label: 'Date Of Loan Agreement',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <p>{moment(value.dateOfLoanAgreement).format('MM-DD-YYYY')}</p>
                <p> {tableMeta.rowData[7].dateOfLoanAgreement ? moment(tableMeta.rowData[7].dateOfLoanAgreement).format('MM-DD-YYYY') : ''}</p>
              </React.Fragment>
            );
          },

        }
      },
      {
        // 3
        name: "lmsLoan",
        label: 'Lender',
        options: {
          filter: true,
          sort: false,
          empty: true,
          display:"exclude",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>

                <p>{myFunction(value.lender)}</p>
                <p>{myFunction(tableMeta.rowData[8].lender)}</p>
              </React.Fragment>
            );
          },

        }
      },

      {
        // 4
        name: "lmsLoan",
        label: 'Date Of Loan Application',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <p>{moment(value.dateOfLoanApplication).format('MM-DD-YYYY')}</p>
                {/* <p>{value}</p> */}
                {/* <p>{tableMeta.rowData[9] }</p> */}
                <p> {tableMeta.rowData[9].dateOfLoanApplication ? moment(tableMeta.rowData[9].dateOfLoanApplication).format('MM-DD-YYYY') : ''}</p>
              </React.Fragment>
            );
          },

        }
      },

      {
        // 5
        name: "lmsLoan",
        label: 'Signed and Delivered By',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>

                <p>{myFunction(value.signedAndDeliveredBy)}</p>
                <p>{myFunction(tableMeta.rowData[10].signedAndDeliveredBy)}</p>
              </React.Fragment>
            );
          },

        }
      },
      {
        // 6
        name: 'agreementLoan',
        label: 'Date Of Loan Agreement',
        options: {
          filter: false,
          sort: false,
          display: 'exclude'
        },
      },
      {
        // 7
        name: 'agreementLoan',
        label: 'Date Of Loan Agreement',
        options: {
          filter: false,
          sort: false,
          display: 'exclude'
        },
      },
      {
        // 8
        name: 'agreementLoan',
        label: 'Lender',
        options: {
          filter: false,
          sort: false,
          display: 'exclude'
        },
      },

      {
        // 9
        name: 'agreementLoan',
        label: 'Date Of Loan Application',
        options: {
          filter: false,
          sort: false,
          display: 'exclude'
        },
      },
      {
        // 10
        name: 'agreementLoan',
        label: 'Signed And Delivered By',
        options: {
          filter: false,
          sort: false,
          display: 'exclude'
        },
      },

      {
        // 11
        name: "matched",
        label: 'Match / Mismatch',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {/* {JSON.stringify(value)} */}
                <div className="mismatchbox">  {value === 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
              </React.Fragment>
            );
          },
        }
      },

      {
        // 12
        name: "lmsLoan",
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className="">

                  <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value.loanContractNumber)} >


                    View More   </Button>


                </div>
              </React.Fragment>
            );
          },
        }
      },

    ];
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Portfolio Setup Wizard'} activeStep={'stepActive3'} stepNav={true}></Header></div>

            {/* <div className="tableSearch">
              <div className="row">
              <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}
                </div>
                <div className="col-md-6 col-sm-12">
                </div>
              </div>
            </div> */}

            <div className="page-content">




              <React.Fragment>

                <div className="poolFormationTableWrapper">
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                  />
                </div>
                {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
                <div className="navbarSteps navbarStepsBtm">
                  <div className="row justify-content-end">


                    <Button variant="contained" id="optionalbutton" onClick={this.backBtn} > Back  </Button>

                    {this.state.rowsSelected !== null && this.state.rowsSelected.length !== 0 ?
                      <Button variant="contained" color="primary" type="submit" onClick={this.onOpenModal1.bind(this)}  >
                        {/* {this.state.getLoansLoader === true ? <CircularProgress size="30px" color="secondary" /> : ''} */}

                      Create Pool  </Button> :
                      <Button variant="contained" color="primary" disabled type="submit" onClick={this.onOpenModal1.bind(this)}  >

                        Create Pool  </Button>
                    }
                  </div>
                </div>

                <div id="modal">
                  {/* <Modal open={open1} onClose={this.onCloseModal1} center>
                    <div id="modalheader">
                      <h5>Create a Pool</h5>

                    </div> */}
                  <ReactModal
                    isOpen={this.state.open1}
                    onRequestClose={this.onCloseModal1}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >

                    <React.Fragment>
                      <div className="modalPopup">
                        <h2> Create Pool </h2>
                        <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}


                        <div>
                          <Form
                            schema={this.state.schema}
                            uiSchema={uiSchema}
                            onSubmit={this.onSubmit}
                            onChange={this.onFormChanged}
                            widgets={widgets}
                            FieldTemplate={CustomFieldTemplate}
                          >

                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <hr className="hrbottom" />
                                <div className="row justify-content-end">

                                  <Button variant="contained" id="optionalbutton" onClick={this.onCloseModal1} > Cancel  </Button>
                                  <Button variant="contained" color="primary" type="submit" > Create Pool  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>


                          {this.state.getLoansLoader === false ? '' : <FormLoader></FormLoader>}

                          {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '}


                        </div>
                      </div>
                    </React.Fragment>



                  </ReactModal>
                </div>



                <ReactModal
                  isOpen={this.state.open2}
                  contentLabel="Minimal Modal Example"
                  onRequestClose={this.onCloseModal2}
                  style={customStyles}
                >

                  <React.Fragment>
                    <div className="modalPopup">
                      <h2>Match Unmatch Data </h2>
                      <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal2}> <CloseIcon></CloseIcon> </Button>


                      <CompareData action={this.childHandler} msgData={this.state.msgData}></CompareData>

                    </div>
                  </React.Fragment>



                </ReactModal>

              </React.Fragment>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(poolFormation);