/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Loader from '../../../components/loader';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import { withSnackbar } from 'notistack';
import Iframe from 'react-iframe';

class bdbPoolSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            myChart: {},
            myChart2: {},
            myChart3: {},
            myChart4: {},
            bdbUrl: null,
            poolId: null
        };
    }

    async componentDidMount() {
        let poolId = this.props.match.params.id;
        const peer = localStorage.getItem('peer');
        const token = localStorage.getItem('token');

        this.setState({ poolId: poolId })
        this.getSummery(poolId, peer, token);

    };



    async getSummery(poolId, peer, token) {

        console.log(poolId);

        let arr = [];
        arr.push(poolId);

        let poolidold = JSON.stringify({
            'PoolId': {
                'type': 'in',
                'value': arr,
            },
        });
        try {
            const res = await axios.get(process.env.react_app_base_url_relative + "backendapi_intainavb_bdb/api/v1/bdb/link");

            let UrlBdbNew = 'https://bdb.intainavb.com/home/#/opendocument?data=' + res.data + '&customGlobalFilter=' + encodeURIComponent(poolidold);
            //   window.open(UrlBdbNew, '_blank');
            this.setState({ bdbUrl: UrlBdbNew, loading: true })

        } catch (error) {
            alert("Failed");
            console.log("getLoans API error", error);
        }

    }

    render() {

        return (
            <React.Fragment>
                <div className="page">
                    <Sidebar></Sidebar>
                    <div className="content">
                        <div className="header"><Header backTitle={'Pool Details'} backButton={'/pool-details'} pageTitle={'Pool Data Analytics'}></Header></div>



                        <div className="page-content">


                            {this.state.loading === true ?
                                <div>
                                    <div className="row">



                                    </div>
                                    <h1 className="bdb_title">Pool Data Analytics - {this.state.poolId} </h1>
                                    <iframe
                                        src={this.state.bdbUrl}
                                        width="100%"
                                        height="700px"
                                        id="myId"
                                        className="iframeOuter"
                                        display="initial"
                                        position="relative"
                                        frameBorder="0"
                                    >

                                    </iframe>

                                </div> :
                                <Loader msg={'Please wait, loading data'} />}

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(bdbPoolSummary);