import React, { Component } from 'react';
import './viewLoans.scss'
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import axios from "axios";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EmiScheduleTable from './emiScheduleTable';
import CollectionHistoryTable from './collectionHistoryTable';
import ViewDocumentsTable from './viewDocuments';
import LoanDetails from './loanDetails';
import CustomerDetails from './customerDetails';
import AssetDetails from './assetDetails';
import ReactModal from 'react-modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import * as moment from 'moment';
import { withSnackbar } from 'notistack';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as viewLoansActions from "../../store/viewLoans/actions";

import { customStyles, customStylesauto } from '../../components/customscripts/customscript';

function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
      var res = str.substring(2, str.length);
      var rep = res.replace(/[a-z]/gi, 'x')
      return str.replace(res, rep);
  }

  return str;
}

class viewLoans extends Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingmodal: false,
      getLoansLoader: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      Loandetails: null,
      Customerdetails: null,
      Collateraldetails: null,
      Docdetails: null,
      userRole: localStorage.getItem('OrgName') || null,
    }
    // this.open = this.open.bind(this);
    // this.close = this.close.bind(this);
  };

  state = {
    // open: false,
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
    Loandetails: null,
    Customerdetails: null,
    Collateraldetails: null,
    Docdetails: null
  }
  // this.open = this.open.bind(this);
  // this.close = this.close.bind(this);


  state = {
    // open: false,
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
  };


  onOpenModal1(value) {
    console.log("MODAL " + value);
    this.setState({ open1: true, value: value });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false, loadingmodal: false });
  };

  onOpenModal2(value) {
    this.setState({ open2: true, value: value });

  };
  onCloseModal2 = () => {
    this.setState({ open2: false, loadingmodal: false });
  };

  onOpenModal3(value) {
    this.setState({ open3: true, value: value });
  };


  onCloseModal3 = () => {
    this.setState({ open3: false, loadingmodal: false });
  };
  onOpenModal4(value) {
    this.setState({ open4: true, value: value });
  };


  onCloseModal4 = () => {
    this.setState({ open4: false, loadingmodal: false });
  };
  onOpenModal5(value) {
    this.setState({ open5: true, value: value });
  };
  onCloseModal5 = () => {
    this.setState({ open5: false, loadingmodal: false });
  };
  onOpenModal6(value) {
    this.setState({ open6: true, LoanID: value });

  };
  onCloseModal6 = () => {
    this.setState({ open6: false });
  };

  async componentDidMount() {

    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');

    if (this.state.userRole === "whlender") {
      this.setState({ getLoansLoader: true, assignbutton: true });
      var company = localStorage.getItem("company");

      this.warehousepool(company,peer,token);

    }

    else {
      
      var poolId = this.props.match.params.id;
      this.setState({ getLoansLoader: true, assignbutton: true });

      this.poolloan(poolId, peer, token)



    }


  };

  async poolloan(poolId, peer, token){
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/poolloan?poolId='+poolId+ "&peer="+peer+"&token="+token);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        // const columns = resp;


        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
        const message = "Successfully fetched the data";     
        this.props.enqueueSnackbar(message, { 
          variant: 'info',
          autoHideDuration: 2000,
      });
      } else {
        alert("Failed");
      }
    } catch (error) {
      alert("Failed")
      console.log("getLoans API error", error);
    }
  }

  async warehousepool(company,peer,token){

    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/warehousepool/loans?warehouseLenderName=' + "HSBC"+"&peer="+peer+"&token="+token);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        // const columns = resp;


        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
      } else {
        alert("Failed");
      }
    } catch (error) {
      alert("Failed")
      console.log("getLoans API error", error);
    }
  }

  render() {



    // const { open } = this.state;
    const { open1 } = this.state;
    const { open2 } = this.state;
    const { open3 } = this.state;
    const { open4 } = this.state;
    const { open5 } = this.state;
    const { open6 } = this.state;
    //  const {Loandetails}=this.state;
    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      loading: true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'LoanID',
        label: 'Loan ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'LoanContractNumber',
        label: 'Loan Contract Number',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'DateOfLoanAgreement',
        label: 'Date Of Loan Agreement',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {moment(value).format('MM-DD-YYYY')}
              </React.Fragment>
            );
          },
        },
      },

      {
        name: 'Lender',
        label: 'Lender',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
              {myFunction(value)}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: 'DateOfLoanApplication',
        label: 'Date Of Loan Application',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {moment(value).format('MM-DD-YYYY')}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: 'SignedAndDeliveredBy',
        label: 'Signed and Delivered By',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
              {myFunction(value)}
              </React.Fragment>
            );
          },
        },
      },

      {
        name: 'TypeOfLoan',
        label: 'Type Of Loan',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "LoanID",
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (

              <React.Fragment>
                {/* {JSON.stringify(tableMeta.rowData[1])} */}
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        {...bindTrigger(popupState)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        {/* <MenuItem onClick={popupState.close}> {value}</MenuItem>  */}
                        {/* onClick={popupState.close,this.onOpenModal1.bind(this) } */}
                        <MenuItem onClick={() => { this.onOpenModal1(value); popupState.close(); }}  ><img alt="" src={require('../../images/Viewpoolsummary.png')} style={{ marginRight: "7px" }} ></img>View Loan Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal2(value); popupState.close(); }}><img alt="" src={require('../../images/ViewLoans.png')} style={{ marginRight: "7px" }} ></img>View Customer Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal3(value); popupState.close(); }}><img alt="" src={require('../../images/Pooldataanalytics.png')} style={{ marginRight: "7px" }} ></img>View Asset Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal4(value); popupState.close(); }}><img alt="" src={require('../../images/Upload.png')} style={{ marginRight: "7px" }} ></img>View Emi Schedule</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal5(value); popupState.close(); }}><img alt="" src={require('../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View collection history</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal6(tableMeta.rowData[1]); popupState.close(); }}><img alt="" src={require('../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View documents</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </React.Fragment>



            );

          },

        }
      }
    ];



    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header">
              {this.state.userRole === "WHLender" ? <Header pageTitle={'View Loans'}></Header> :
                <Header backTitle={'Pool Details'} backButton={'/pool-details'} pageTitle={'View Loans'}></Header>}

            </div>
            <div className="page-content">

              {JSON.stringify(this.state.goBack)}
              {/* {this.state.loading === true ? */}
              <React.Fragment>
                <MUIDataTable
                  data={this.state.tableData}
                  columns={columns}
                  options={options}

                />

              </React.Fragment>

            </div>

          </div>
        </div>
        <ReactModal
          isOpen={this.state.open1}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal1}

        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Loan Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>


              <div>
                <LoanDetails dataFromParent={this.state.value} />

              </div>


            </div>
          </React.Fragment>
        </ReactModal>




        <ReactModal
          isOpen={this.state.open2}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal2}

        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Customer Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal2}> <CloseIcon></CloseIcon> </Button>
              <div>
                <CustomerDetails dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>



        <ReactModal
          isOpen={this.state.open3}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal3}

        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Asset Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal3}> <CloseIcon></CloseIcon> </Button>
              <div>
                <AssetDetails dataFromParent={this.state.value} />
              </div>
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.open4}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal4}

        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>EMI Schedule</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal4}> <CloseIcon></CloseIcon> </Button>

              <div>
                <EmiScheduleTable dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.open5}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal5}

        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Collection History</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal5}> <CloseIcon></CloseIcon> </Button>

              <div>
                <CollectionHistoryTable dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>



        <ReactModal
          isOpen={this.state.open6}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal6}

        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>View Documents</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal6}> <CloseIcon></CloseIcon> </Button>

              <div>
                <ViewDocumentsTable dataFromParent={this.state.LoanID} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>
      </React.Fragment>
    );
  }
}

export default withSnackbar(viewLoans);