/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { withSnackbar } from "notistack";
import $ from 'jquery';

class Certificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
        tableData: [],
        servicerData: [
                {
                    "Dated": "4/1/2022",
                    "Deal Id": 664636,
                    "Agreement Id": "78C6DD8D",
                    "Merchant Legal Name" : "Northeastern Remodeling LLC",
                    "Funded Amount" : "$72,900.00",
                    "Receivables File Received" : "Yes",
                    "Merchant Agreement Number" : "Yes",
                    "name of the related Merchant" : "Yes",
                    "Funded Amount": "Yes",
                    "Exceptions": "Yes",
                    "Assigned" : "KM",
                    "Exception Details" : "Northeastern Remodeling LLC",
        },
                {
                    "Dated": "4/1/2022",
                    "Deal Id": 664636,
                    "Agreement Id": "78C6DD8D",
                    "Merchant Legal Name" : "Northeastern Remodeling LLC",
                    "Funded Amount" : "$72,900.00",
                    "Receivables File Received" : "Yes",
                    "Merchant Agreement Number" : "Yes",
                    "name of the related Merchant" : "Yes",
                    "Funded Amount": "Yes",
                    "Exceptions": "Yes",
                    "Assigned" : "KM",
                    "Exception Details" : "Northeastern Remodeling LLC",
                },
                {
                    "Dated": "4/1/2022",
                    "Deal Id": 664636,
                    "Agreement Id": "78C6DD8D",
                    "Merchant Legal Name" : "Northeastern Remodeling LLC",
                    "Funded Amount" : "$72,900.00",
                    "Receivables File Received" : "Yes",
                    "Merchant Agreement Number" : "Yes",
                    "name of the related Merchant" : "Yes",
                    "Funded Amount": "Yes",
                    "Exceptions": "Yes",
                    "Assigned" : "KM",
                    "Exception Details" : "Northeastern Remodeling LLC",
                },
            ],
    };
  }

  fistTableData = (tableData) => {
    if (tableData == undefined) {
      return <div></div>;
    } else {
      console.log("tableData", tableData);
      const headingArr = Object.keys(tableData[0]);
      console.log(tableData);
      return (
        <table className="table-servicer">
          <tr>
            {headingArr.map((heading) => (
              <th className="servicer-data-table-heading">{heading}</th>
            ))}
          </tr>
          {tableData.map((data) => (
            <tr>
              {headingArr.map((element) => (
                <td>
                  {element == "Balance" ? (
                    data[element]
                  ) : (
                    data[element]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </table>
      );
    }
  };

  Download = () => {
    const data = $("#pdfdata2").html();
    console.log("data", JSON.stringify(data))
  }

  async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header">
              <Header pageTitle={"Issue Certificate"}></Header>
              <button onClick={()=> this.Download()}>Download</button>
            </div>

            <div className="page-content">
              <React.Fragment>
                <div id="pdfdata2">
                  <h4 className="certicate-heading">Certificate</h4>
                  <div className="certificate-para1">
                    <address>
                      TRUIST B ANK (f/k/a SunTrust Bank), as Administrative
                      Agent
                      <br />
                      Mail Code GA ATL 3950, <br />
                      3333 Peachtree Road NE , <br />
                      10th Floor, <br />
                      East Atlanta, GA 30326
                      <br />
                    </address>
                  </div>
                  <div className="certificate-para1">
                    Re: Amended and Restated Loan and Security Agreement
                    (together with all schedules, riders and exhibits thereto,
                    the “Agreement”) dated as of November 1, 2018, among Retail
                    Capital SPV LLC, a Delaware limited liability company, as
                    borrower (the “Borrower”), Retail Capital LLC, a New York
                    limited liability company, as servicer (together with its
                    permitted successors and assigns, the “Servicer”), the
                    financial institutions party to this Agreement from time to
                    time as lenders (collectively, “Lenders”), Truist Bank (as
                    successor by merger to SunTrust Bank, “Truist”), as
                    administrative agent for the Secured Parties (as hereinafter
                    defined) (in such capacity, together with its successors and
                    assigns, “Administrative Agent”), and U.S. Bank National
                    Association, as custodian for the Secured Parties (in such
                    capacity, together with its successors and assigns, the
                    “Existing Custodian”).
                  </div>
                  <div className="certificate-para1">
                    Appointment of Successor Custodian and Amendment No. 8 to
                    Amended and Restated Loan and Security Agreement
                  </div>
                  <div className="certificate-para1">
                    In accordance with the provision of Section 14.13 of the
                    Agreement, the undersigned, as Successor Custodian, hereby
                    certifies that as to each Receivable listed on the attached
                    Schedule 1, it has reviewed the related Collateral Package
                    Items and has determined that, except as indicated on
                    Schedule 2 attached hereto:
                  </div>
                  <div className="certificate-para1">
                    <ol className="orderedlist">
                      <li>
                        All Collateral Package Items required to be provided to
                        the Successor Custodian with respect to such Receivable
                        pursuant to Section 7.4(e) of the Agreement have been
                        provided to the Successor Custodian;
                      </li>
                      <li className="certificate-para1">
                        Such Collateral Package Items have been reviewed and, in
                        the Successor Custodian’s good faith judgment, appear
                        regular on their face and relate to such Receivable; and
                      </li>
                      <li className="certificate-para1">
                        Based on its examination, the information set forth in
                        Schedule 1 is correct in all material respects.
                      </li>
                    </ol>
                  </div>
                  <div className="certificate-para1-page-break">
                    The Successor Custodian hereby confirms that it is tracking
                    and reviewing each such Collateral Package Items on behalf
                    of and for benefit of the Administrative Agent and the
                    Secured Parties pursuant to the terms of the Agreement. No
                    person or entity other than the Administrative Agent and the
                    Secured Parties may rely or shall be entitled to rely on
                    this Certification. Capitalized terms used but not defined
                    herein shall have the meanings ascribed thereto in the
                    Agreement.
                  </div>

                  <div className="certificate-para1-right">
                      <h6>
                        UMB Bank, National Association,
                        <br />
                        as Successor Custodian
                      </h6>
                      <span className="span-class">
                        <h6>By:</h6>
                        <img
                        alt="logo"
                        className="wellslogodeal"
                      />
                    </span>
                    <span className="span-class">
                        <h6>Name:</h6>
                        <p>Jenna Kaufman</p>
                    </span>
                    <span className="span-class">
                        <h6>Title:</h6>
                        <p>SVP</p>
                    </span>
                  </div>
                  <div className="certificate-para1">
                  <span className="span-class-scheduled">
                        <h6>Schedule 1:</h6>
                        <p>Receivables Schedule</p>
                    </span>
                    <span className="span-class-scheduled">
                        <h6>Schedule 2:</h6>
                        <p>List of Exceptions (if any)</p>
                    </span>
                    </div>

                    <div className="certificate-para1-center">
                        <h6>Schedule 2:</h6>
                    {this.state.servicerData == undefined ? (
                        <div></div>
                      ) : (
                        this.fistTableData(this.state.servicerData)
                      )}
                    </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Certificate);