/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FormLoader from "../../../components/formLoader";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import LinkItem from "../../../components/linkItem";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  CustomFieldTemplate,
  widgets,
} from "../../../components/customscripts/customscript";

import { withSnackbar } from "notistack";
import ReactModal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import {
  authenticate,
  getAllUserRoles,
  GetAllDealsLogin,
  jwtToken,
  ForgotPassword,
} from "../../../servies/services";
import { TextField } from "@mui/material";

const customStylesautosmallmodal = {
  content: {
    top: "25%",
    left: "50%",
    // right: 'auto',
    bottom: "auto",
    // marginRight: '-50%',
    transform: "translate(-50%, 0%)",
    width: "542px",
    zIndex: "10000",
    borderRadius: "10px",
  },
};
const Form = withTheme(MuiTheme);
const schema = require("./schema.json");
const uiSchema = {
  email: {
    "ui:widget": "email",
  },
  password: {
    "ui:widget": "password",
  },
};


class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: schema,
      uiSchema: uiSchema,
      formData: {
        emailid: "",
        password: "",
        userrole: "",
      },
      formData1: {
        emailid: "",
        userrole: ""
      },
      loading: false,
      OrgLoading: false,
      remainingAttempts: false,
      open1: false,
      open2: false,
    };
  }

  onFormChanged=()=>{
    console.log("onFormChanged");
  }

  onOpenModal = () => {
    console.log("inside modal1");
    this.setState({ open1: true }, () => {
      console.log("checkk", this.state.formData.emailid);
    });
  };

  onCloseModal = () => {
    this.setState({ open1: false });
  };

  handleEmailChange = (e) => {
    this.setState({ formData1: { ...this.state.formData1, emailid: e.target.value } });
  };

  handleUserRoleChange = (e) => {
    this.setState({ formData1: { ...this.state.formData1, userrole: e.target.value } });
  };


  onSubmit1 = async (value) => {
    console.log("onSubmit1:", value.formData);
    let data ={
      emailid: this.state.formData1.emailid,
      userrole:this.state.formData1.userrole

    };
    this.setState({ loading: true });
    const APIResponse = await ForgotPassword(data);
    console.log("ForgotPassword", APIResponse);
    this.setState({ loading: false });
    if (APIResponse !== null || APIResponse !== undefined) {
      if (APIResponse.status !== 200) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        if (APIResponse.data.success == false) {
          const message = APIResponse.data.message;
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        } else {
          if (APIResponse.status == 200) {
            const message = APIResponse.data.message;
            this.props.enqueueSnackbar(message, {
              variant: "info",
              autoHideDuration: 2000,
            });
            this.setState({ open1: false });
          }
        }
      }
    }
  }


  onSubmit = async (value) => {
    console.log("onSubmit:", value.formData);

    this.setState({ formData: value.formData });
    let data = value.formData;
    this.setState({ loading: true });
    const APIResponse = await jwtToken(data);
    console.log("authenticate", APIResponse);
    this.setState({ loading: false });
    if (APIResponse !== null || APIResponse !== undefined) {
      if (APIResponse.status !== 200) {
        if (
          APIResponse.data.message.includes("Your Account") ||
          APIResponse.data.message.includes("Account") === true
        ) {
          console.log("Account is locked");
          this.setState({ loading: true });
          if (APIResponse.data.message.includes("Your Account")) {
            let x = APIResponse.data.message.split("").slice(32, 33).join("");

            var seconds = x * 60;
            console.log("minutes", seconds);
            setTimeout(
              () => this.setState({ loading: false, remainingAttempts: true }),
              seconds * 1000
            );
            const message = APIResponse.data.message;
            this.props.enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 5000,
            });
          } else {
            let x = APIResponse.data.message.split("").slice(39, 40).join("");

            var seconds = x * 60;
            console.log("minutes", seconds);
            setTimeout(
              () => this.setState({ loading: false, remainingAttempts: true }),
              seconds * 1000
            );
            const message = APIResponse.data.message;
            this.props.enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        } else {
          console.log("Account is not locked");
          const message = APIResponse.data.message;
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      } else {
        if (APIResponse.data.success == false) {
          const message = APIResponse.data.message;
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        } else {
          if (APIResponse.data != "undefined") {
            console.log("data", APIResponse.data);
            console.log("data.result", APIResponse.data.result);
            console.log(
              "APIResponse.data.result.username",
              APIResponse.data.result.username
            );
            localStorage.setItem("userrole", APIResponse.data.result.userrole);
            localStorage.setItem("userid", APIResponse.data.result.userid);
            localStorage.setItem("username", APIResponse.data.result.username);
            localStorage.setItem(
              "userLastName",
              APIResponse.data.result.userLastName
            );
            localStorage.setItem("token", APIResponse.data.token);

            if (APIResponse.data.result.userrole == "Admin") {
              this.setState({ formLoader: false });
              const message = "Logged in successfully";
              this.props.enqueueSnackbar(message, {
                variant: "info",
                autoHideDuration: 2000,
              });
              // admin/users/:orgname/:status
              window.location.assign("/admin/dashboard/");
            } else {
              if (APIResponse.data.result.userrole == "Processor") {
                this.setState({ formLoader: false });
                const message = "Logged in successfully";
                this.props.enqueueSnackbar(message, {
                  variant: "info",
                  autoHideDuration: 2000,
                });
                window.location.assign("/processor/dashboard");
              }
            }
          } else {
            const message = "Something went wrong, please try again";
            this.props.enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        }
      }
    }
  };

  async componentDidMount() {
    localStorage.clear();
  }

  render() {
    console.log("checkkingg", this.state.formData); 
    return (
      <React.Fragment>
        {this.state.loading === true ? <FormLoader></FormLoader> : ""}

        <h4>Log in to your account to access Intain ABS</h4>
        <div className="form_row_single">
          {this.state.OrgLoading === true ? (
            <React.Fragment>
              <FormLoader></FormLoader>
              <p className="loading_text">Loading, Please wait...</p>
            </React.Fragment>
          ) : (
            <Form
              schema={this.state.schema}
              formData={this.state.formData}
              onSubmit={this.onSubmit}
              // onChange={this.onFormChanged}
              widgets={widgets}
              FieldTemplate={CustomFieldTemplate}
              uiSchema={this.state.uiSchema}
              // data-testid="login-form"
            >
              <div id="form-btn">
                <div className="container-fluid text-center">
                  <div className="row">
                    <Button
                      className="col-md-12"
                      variant="contained"
                      size="large"
                      color="primary"
                      id="signinbutton"
                      type="submit"
                      disabled={this.state.loading === true ? true : false}
                      
                    >
                      {" "}
                      Sign in{" "}
                    </Button>
                  </div>
                  <div  className="forgot-password-container">
                <div
                  type="button"
                  onClick={() => this.onOpenModal()}
                  // onClick={()=>console.log("clicked")}
                  className="login-sign_up-links"
                >
                  Forgot Password?
                </div>

                {/* <Link to={''} className="login-sign_up-links" onClick={this.onOpenModal.bind(this)}>Forgot Password?</Link> */}
              </div>
                </div>
              </div>
           
            </Form>
          )}
        </div>
        <div className="loginCheckbox">
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                value="checkedI"
                color="primary"
                onChange={this.onChangeCheckbox}
              />
            }
            label="Keep me signed-in"
          />
        </div>
        <div className="loginCheckbox">
          <p className="lineDivider">
            <span>New to Intain ABS?</span>
          </p>
          <LinkItem
            to={"/register"}
            variant="contained"
            className="loginBtn"
            title={"Create your Account"}
          >
            {" "}
          </LinkItem>
        </div>

        <>
        <div id="modal1">
          <ReactModal
            isOpen={this.state.open1}
            onRequestClose={this.onCloseModal}
            contentLabel="Minimal Modal Example"
            style={customStylesautosmallmodal}
          >
            <div style={{ overflow: "hidden" }}>
              <h4 className="popupheading">Confirm your Mail ID</h4>
              <button
                type="button"
                className="closePopup"
                style={{ minWidth: "30px" }}
                onClick={this.onCloseModal}
              >
                {" "}
                <CloseIcon></CloseIcon>{" "}
              </button>
              <div className="modalshiftcontent">
                <h6 className="card1__title">
                  We will send the link to reset your password on your
                  registered mail ID
                </h6>
                <div className="input-container">
                  <label className="label">Email Address:</label>
                  <TextField
                    required
                    placeholder="Type here"
                    className="input"
                    type="text"
                    variant="outlined"
                    onChange={this.handleEmailChange}
                    value={this.state.formData1.email1}
                    style={{ marginBottom: "5px" }}
                  />
                  <label className="label">User Role:</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      User Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.formData1.userrole}
                      label="User Role"
                      onChange={this.handleUserRoleChange}
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Processor">Processor</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="modalsubmit">
                  <div className="submitbuttonbg">
                    <div className="row">
                      <div style={{ display: "flex", marginLeft: "268px" }}>
                        <button
                          type="button"
                          className="popupbutton2"
                          onClick={this.onCloseModal}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.onSubmit1}
                        >
                          Confirm
                          {this.state.formLoader1 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        </div>
        </>
      </React.Fragment>
    );
  }
}

export default withSnackbar(login);
