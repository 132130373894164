import React, { Component } from 'react';
// import './viewLoans.scss'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PreProcessingEmiScheduleTable from './preProcessingEmiSchedule';
import PreProcessingCollectionHistoryTable from './preProcessingCollectionHistory';
import PreProcessingViewDocumentsTable from './preProcessingViewDocuments';
import PreProcessingLoanDetails from './preProcessingLoanDetails';
import PreProcessingCustomerDetails from './preProcessingCustomerDetails';
import PreProcessingAssetDetails from './preProcessingAssetDetails';
import PreProcessingRatingDetails from './preProcessingRatingDetails';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactModal from 'react-modal';
import { customStyles, customStylesauto } from '../../components/customscripts/customscript';
import { withSnackbar } from 'notistack';
import moment from 'moment';


function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
      var res = str.substring(2, str.length);
      var rep = res.replace(/[a-z]/gi, 'x')
      return str.replace(res, rep);
  }

  return str;
}



class preProcessingViewLoans extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingmodal: false,
      getLoansLoader: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      open7: false,
      open8: false,
      // Loandetails:null,
      // Customerdetails:null,
      // Collateraldetails:null,
      // Docdetails:null,
      userRole: localStorage.getItem('OrgName') || null,
      searchText: '',
      offset: 0,
      limit: 100,
      isLoading: false
    }
    // this.open = this.open.bind(this);
    // this.close = this.close.bind(this);
  };

  state = {
    // open: false,
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
    open7: false,
    open8: false,
    Loandetails: null,
    Customerdetails: null,
    Collateraldetails: null,
    Docdetails: null
  }
  // this.open = this.open.bind(this);
  // this.close = this.close.bind(this);


  state = {
    // open: false,
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
    open7: false,
    open8: false,
  };

  next = () => {
    console.log(this.state.offset);
    this.setState({
      offset: this.state.offset + 100, isLoading: true
    });

  };
  previous = () => {
    console.log(this.state.offset);
    this.setState({
      offset: this.state.offset - 100, isLoading: true
    });

  };

  onOpenModal1(value) {
    console.log("MODAL " + value);
    this.setState({ open1: true, value: value });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false, loadingmodal: false });
  };

  onOpenModal2(value) {
    this.setState({ open2: true, value: value });

  };
  onCloseModal2 = () => {
    this.setState({ open2: false, loadingmodal: false });
  };

  onOpenModal3(value) {
    this.setState({ open3: true, value: value });
  };


  onCloseModal3 = () => {
    this.setState({ open3: false, loadingmodal: false });
  };

  onOpenModal4(value) {
    this.setState({ open4: true, value: value });
  };


  onCloseModal4 = () => {
    this.setState({ open4: false, loadingmodal: false });
  };
  onOpenModal5(value) {
    this.setState({ open5: true, value: value });
  };
  onCloseModal5 = () => {
    this.setState({ open5: false, loadingmodal: false });
  };


  onOpenModal6(value) {
    this.setState({ open6: true, LoanID: value });

  };
  onCloseModal6 = () => {
    this.setState({ open6: false });
  };

  onOpenModal7(value) {
    this.setState({ open7: true, LoanID: value });

  };
  onCloseModal7 = () => {
    this.setState({ open7: false });
  };

  async onOpenModal8(value) {
    this.setState({ open8: true, loading: false });
    try {
      const res = await axios.get('http://35.200.132.221:8080/Emulya/UpdateRating');
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        // const columns = resp;


        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true, isLoading: false });
      } else {
        alert("Failed");
      }
    } catch (error) {
      alert("Failed")
      console.log("getLoans API error", error);
    }


  };
  onCloseModal8 = () => {
    this.setState({ open8: false });
  };


  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);


  };
  async generateTable(updatedoffset, updatedlimit) {
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/loansbyrange?offset=' + updatedoffset + "&limits=" + updatedlimit);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp.data);
        // const columns = resp;


        this.setState({ getLoansLoader: false, open: true, tableData: resp.data, loading: true, isLoading: false });
        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 2000,
        });

      } else {
        alert("Failed");
      }
    } catch (error) {
      alert("Failed")
      console.log("getLoans API error", error);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.offset !== nextState.offset) {
      const updatedoffset = nextState.offset;
      const updatedlimit = this.state.limit;
      this.generateTable(updatedoffset, updatedlimit);;
    }
    return true;
  }

  async componentDidMount() {

    const updatedoffset = this.state.offset;
    const updatedlimit = this.state.limit;
    this.generateTable(updatedoffset, updatedlimit);
    // generateTable(offset,limits);

    this.setState({ getLoansLoader: true, assignbutton: true });

  }


  render() {



    // const { open } = this.state;
    const { open1 } = this.state;
    const { open2 } = this.state;
    const { open3 } = this.state;
    const { open4 } = this.state;
    const { open5 } = this.state;
    const { open6 } = this.state;
    const { open7 } = this.state;
    const { open8 } = this.state;
    //  const {Loandetails}=this.state;
    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      // onRowsSelect: (rowsSelected, allRows) => {
      //   const selected = allRows.map((item) => item.index);
      //   console.log("valuee" + selected);
      //   console.log(allRows);
      //   this.setState({
      //     rowsSelected: selected,


      //   });

      //   // if (allRows.length < 1) {

      //   //   var assignbutton = true
      //   //   this.setState({
      //   //     assignbutton: assignbutton
      //   //   })
      //   // }
      //   // else {
      //   //   this.setState({
      //   //     assignbutton: false
      //   //   })
      //   // }




      // },



      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },
      loading: true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'loanId',
        label: 'Loan ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'loanContractNumber',
        label: 'Loan Contract Number',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'city',
        label: 'City',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'dateOfLoanAgreement',
        label: 'Date Of Loan Agreement',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
               {moment(value).format('MM-DD-YYYY')}
              </React.Fragment>
            );
          },
        },
      },
// myFunction
      {
        name: 'lender',
        label: 'Lender',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
              {myFunction(value)}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: 'dateOfLoanApplication',
        label: 'Date Of Loan Application',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
               {moment(value).format('MM-DD-YYYY')}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: 'signedAndDeliveredBy',
        label: 'Signed and Delivered By',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
              {myFunction(value)}
              </React.Fragment>
            );
          },
        },
      },

      {
        name: 'typeOfLoan',
        label: 'Type Of Loan',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'defaultRating',
        label: 'Rating',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "loanId",
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {/*  */}
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        {...bindTrigger(popupState)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={() => { this.onOpenModal1(value); popupState.close(); }}  ><img alt="" src={require('../../images/Viewpoolsummary.png')} style={{ marginRight: "7px" }} ></img>View Loan Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal2(value); popupState.close(); }}><img alt="" src={require('../../images/ViewLoans.png')} style={{ marginRight: "7px" }} ></img>View Customer Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal3(value); popupState.close(); }}><img alt="" src={require('../../images/Pooldataanalytics.png')} style={{ marginRight: "7px" }} ></img>View Asset Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal4(value); popupState.close(); }}><img alt="" src={require('../../images/Upload.png')} style={{ marginRight: "7px" }} ></img>View Emi Schedule</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal5(value); popupState.close(); }}><img alt="" src={require('../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View collection history</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal6(tableMeta.rowData[1]); popupState.close(); }}><img alt="" src={require('../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View documents</MenuItem>
                        {tableMeta.rowData[8] !== null ? <MenuItem onClick={() => { this.onOpenModal7(value); popupState.close(); }}><img alt="" src={require('../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>Rating Details</MenuItem> :
                          <MenuItem disabled onClick={() => { popupState.close(); }}><img alt="" src={require('../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>Rating Details</MenuItem>

                        }
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </React.Fragment>



            );

          },

        }
      }
    ];



    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header">
              <Header pageTitle={'View LMS Loans'}></Header>
            </div>
            <div className="tableSearch">
              <div className="row">
                <div className="justify-content-left " id="assignpoolbutton">
                  <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange" onClick={this.onOpenModal8.bind(this)} color="primary" type="submit" > Update Rating</Button>
                </div>
                <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}

                </div>
                <div className="col-md-6 col-sm-12">
                </div>
              </div>
            </div>
            <div className="page-content">
              {/* {this.state.loading === true ? */}
              <React.Fragment>
                <MUIDataTable
                  data={this.state.tableData}
                  columns={columns}
                  options={options}
                />
              </React.Fragment>


              <div className="container-fluid">
              <div className="row">
                
                <div className="col-sm-12 text-right">
                  <div className="nextviewloan">

                {this.state.offset <= 99 ? <Button disabled type="button" variant="contained" color="primary" onClick={this.previous}> Prev </Button> :
                    <Button disabled={this.state.isLoading} type="button" variant="contained" color="primary" onClick={this.previous}> Prev
              {this.state.isLoading !== false ? <CircularProgress size={20} style={{ position: 'absolute' }} color="secondary" /> : ''} </Button>}

                  <Button disabled={this.state.isLoading}
                    type="button" variant="contained" color="primary" onClick={this.next}>
                    Next {this.state.isLoading !== false ? <CircularProgress size={20} style={{ position: 'absolute' }} color="secondary" /> : ''} </Button>



                    </div>
                </div>
              </div>
            </div>


            </div>



          </div>
        </div>
        <ReactModal
          isOpen={this.state.open1}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal1}

        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Loan Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>

              <div>
                <PreProcessingLoanDetails dataFromParent={this.state.value} />

              </div>



            </div>
          </React.Fragment>
        </ReactModal>



        <ReactModal
          isOpen={this.state.open2}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal2}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Customer Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal2}> <CloseIcon></CloseIcon> </Button>
              <div>
                <PreProcessingCustomerDetails dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>


        <ReactModal
          isOpen={this.state.open3}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal3}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Asset Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal3}> <CloseIcon></CloseIcon> </Button>
              <div>
                <PreProcessingAssetDetails dataFromParent={this.state.value} />
              </div>
            </div>
          </React.Fragment>
        </ReactModal>


        <ReactModal
          isOpen={this.state.open4}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal4}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>EMI Schedule</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal4}> <CloseIcon></CloseIcon> </Button>

              <div>
                <PreProcessingEmiScheduleTable dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.open5}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal5}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Collection History</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal5}> <CloseIcon></CloseIcon> </Button>

              <div>
                <PreProcessingCollectionHistoryTable dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>


        <ReactModal
          isOpen={this.state.open6}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal6}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>View Documents</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal6}> <CloseIcon></CloseIcon> </Button>

              <div>
                <PreProcessingViewDocumentsTable dataFromParent={this.state.LoanID} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>


        <ReactModal
          isOpen={this.state.open7}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal7}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>View Rating</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal7}> <CloseIcon></CloseIcon> </Button>

              <div>
                <PreProcessingRatingDetails dataFromParent={this.state.LoanID} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>


        <ReactModal
          isOpen={this.state.open8}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal8}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Update Rating</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal8}> <CloseIcon></CloseIcon> </Button>

              {this.state.loading === true ?
                <div>
                  {/* <PreProcessingRatingDetails dataFromParent={this.state.LoanID} /> */}

                </div>
                : <Loader msg={"Please wait, processing"} />}
            </div>
          </React.Fragment>
        </ReactModal>
      </React.Fragment>
    );
  }
}

export default withSnackbar(preProcessingViewLoans);