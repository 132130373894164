/* eslint-disable require-jsdoc */
import React, { Component } from 'react';

import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { withSnackbar } from 'notistack';



class EmiScheduleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
     
    };
   
;

  }
  
 


  
  // async componentDidMount() {
  //   var value=this.props.dataFromParent;
  //   this.setState({ getLoansLoader: true, assignbutton: true });
  //   try {
  //     const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/loanemischedule?loanId='+value);
  //     const resp = res.data;
  //     console.log("loanemischedule",res.data );
  //     if (res.status === 200) {
  //       console.log("loanemischedule", resp);
  //       // const columns = resp;


  //       this.setState({ getLoansLoader: false, open: true, EMIdetails: resp , loading: true});
  //     } else {
  //       alert("Failed");
  //     }
  //   } catch (error) {
  //     alert("Failed");
  //     console.log("getLoans API error", error);
  //   }
  // };

  async componentDidMount() {
    var loan_id = this.props.dataFromParent;
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    this.loanDetails(loan_id, peer, token)
}

  async loanDetails(loan_id, peer, token) {
    try {
        const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/loanemischedule?loanId=' + loan_id + "&peer=" + peer + "&token=" + token);
        const resp = res.data;
        console.log("loandetails", res.data);
        if (res.status === 200) {
            console.log("loandetails", resp);
            var result = resp;


            this.setState({ getLoansLoader: false, open: true, EMIdetails: resp , loading: true });
            const message = "Successfully fetched the data";
            this.props.enqueueSnackbar(message, {
                variant: 'info',
                autoHideDuration: 2000,
            });
        } else {
            alert("Failed");
        }
    } catch (error) {
        alert("Failed");
        console.log("getLoans API error", error);
    }
}




  render() {
 

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("valuee" + selected);
        console.log(allRows);
        this.setState({
          rowsSelected: selected,


        });

      },


      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'InstallmentNumber',
        label: 'Installment Number',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'ScheduleDate',
        label: 'Schedule Date',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'Currency',
        label: 'Currency',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'EmiAmount',
        label: 'EMI Amount',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OutstandingPrincipalBalance',
        label: 'Outstanding Principle Balance',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'Installmentprincipalamount',
        label: 'Installment Principle Amount',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'Installmentinterestamount',
        label: 'Installment Interest Amount',
        options: {
          filter: true,
          sort: false,
        },
      },


    ];



    return (
     
      <React.Fragment>
       
            
            <div className="page-content">

              {this.state.loading === true ?
                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

                : <Loader msg={"Please wait, Loading EMI data"} />}

            </div>
       
       
      </React.Fragment>
    );
  }
}


export default withSnackbar(EmiScheduleTable);