/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from "axios";
import Modal from 'react-responsive-modal';
import { Form } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
import EmiScheduleTable from '../../viewLoans/emiScheduleTable';
import CollectionHistoryTable from '../../viewLoans/collectionHistoryTable';
import ViewDocumentsTable from '../../viewLoans/viewDocuments';
import LoanDetails from '../../viewLoans/loanDetails';
import CustomerDetails from '../../viewLoans/customerDetails';
import AssetDetails from '../../viewLoans/assetDetails';
import CloseIcon from '@material-ui/icons/Close';
// import FormLoader from '../../../../components/loader/formLoader';

// import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactModal from 'react-modal';
import { withSnackbar } from 'notistack';
import * as moment from 'moment';

import { customStyles, customStylesauto } from '../../../components/customscripts/customscript';

function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
      var res = str.substring(2, str.length);
      var rep = res.replace(/[a-z]/gi, 'x')
      return str.replace(res, rep);
  }

  return str;
}

class poolDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poolinfo: {},
      tableData: [],
      loading: false,
      getLoansLoader: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      open6: false,
      Loandetails: null,
      Customerdetails: null,
      Collateraldetails: null,
      Docdetails: null,
      searchText: '',
      viewLoans: false,
    }

    this.options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: true,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      // rowsSelected: this.state.rowsSelected,
      // onRowsSelect: (rowsSelected, allRows) => {
      //   const selected = allRows.map((item) => item.index);
      //   console.log(selected);
      //   this.setState({
      //     rowsSelected: selected,
      //   });
      // },


      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };

    // console.log("Sampling Events: ", this.props.samplingEvents);
  }
  state = {
    // open: false,
    open1: false,
    open2: false,
    open3: false,
    open4: false,
    open5: false,
    open6: false,
  }


  async onOpenModal1(value) {
    console.log("MODAL " + value);
    this.setState({ open1: true, value: value });

  };
  onCloseModal1 = () => {
    this.setState({ open1: false, loadingmodal: false });
  };

  async onOpenModal2(value) {
    this.setState({ open2: true, value: value });

  };
  onCloseModal2 = () => {
    this.setState({ open2: false, loadingmodal: false });
  };

  async onOpenModal3(value) {
    this.setState({ open3: true, value: value });

  };


  onCloseModal3 = () => {
    this.setState({ open3: false, loadingmodal: false });
  };

  async onOpenModal4(value) {
    this.setState({ open4: true, value: value });

  };

  onCloseModal4 = () => {
    this.setState({ open4: false, loadingmodal: false });
  };

  async onOpenModal5(value) {
    this.setState({ open5: true, value: value });

  };
  onCloseModal5 = () => {
    this.setState({ open5: false, loadingmodal: false });
  };
  async onOpenModal6(value) {
    this.setState({ open6: true, LoanID: value });

  };
  onCloseModal6 = () => {
    this.setState({ open6: false, loadingmodal: false });
  };
  async componentDidMount() {

    const poolId = this.props.match.params.id;
    const peer = localStorage.getItem("peer");
    const token = localStorage.getItem("token");


    this.setState({ getLoansLoader: true });
    // var poolId = "85a7f113cb364520b4895a0f67559e5f";
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/poolinfo?poolId=' + poolId + '&peer=' + peer + '&token=' + token);
      // &peer= &token=

      const resp2 = res.data;
      if (res.status === 200) {
        console.log("poolinfo", resp2);
        this.setState({ poolinfo: resp2 });

        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 3000,
        });

      } else {
        alert("Failed");
      }
    } catch (error) {
      alert("Failed")
      console.log("getLoans API error", error);
    }


  };


  FetchDoc = async () => {

    this.setState({ viewLoans: true })

    const poolId = this.props.match.params.id;
    const peer = localStorage.getItem("peer");
    const token = localStorage.getItem("token");

    try {
      // const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/poolloan?poolId=' + poolId);
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/poolloan?poolId=' + poolId + '&peer=' + peer + '&token=' + token);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        const columns = resp;
        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });

        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 3000,
        });

      } else {
        alert("Failed");
      }
    } catch (error) {
      alert("Failed")
      console.log("getLoans API error", error);
    }

  }

  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);


  };

  render() {
    const { poolinfo } = this.state;
    const { open1 } = this.state;
    const { open2 } = this.state;
    const { open3 } = this.state;
    const { open4 } = this.state;
    const { open5 } = this.state;
    const { open6 } = this.state;
    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      // onRowsSelect: ( allRows) => {
      //   const selected = allRows.map((item) => item.index);
      //   console.log("valuee" + selected);
      //   console.log(allRows);
      //   this.setState({
      //     rowsSelected: selected,


      //   });

      //   if (allRows.length < 1) {

      //     var assignbutton = true
      //     this.setState({
      //       assignbutton: assignbutton
      //     })
      //   }
      //   else {
      //     this.setState({
      //       assignbutton: false
      //     })
      //   }




      // },


      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading: true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'LoanContractNumber',
        label: 'Loan Contract Number',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'DateOfLoanAgreement',
        label: 'Date Of Loan Agreement',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {moment(value).format('MM-DD-YYYY')}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: 'Lender',
        label: 'Lender',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
              {myFunction(value)}
              </React.Fragment>
            );
          },
        },
      },

      {
        name: 'DateOfLoanApplication',
        label: 'Date Of Loan Application',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {moment(value).format('MM-DD-YYYY')}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: 'SignedAndDeliveredBy',
        label: 'Signed And Delivered By',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
              {myFunction(value)}
              </React.Fragment>
            );
          },
        },
      },
      {
        name: 'TypeOfLoan',
        label: 'Type Of Loan',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'LoanID',
        label: 'Loan ID',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: "LoanID",
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (

              <React.Fragment>
                {/*  */}
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        {...bindTrigger(popupState)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        {/* <MenuItem onClick={popupState.close}> {value}</MenuItem> */}
                        <MenuItem onClick={() => { this.onOpenModal1(value); popupState.close(); }}><img alt="" src={require('../../../images/Viewpoolsummary.png')} style={{ marginRight: "7px" }} ></img>View Loan Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal2(value); popupState.close(); }}><img alt="" src={require('../../../images/ViewLoans.png')} style={{ marginRight: "7px" }} ></img>View Customer Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal3(value); popupState.close(); }}><img alt="" src={require('../../../images/Pooldataanalytics.png')} style={{ marginRight: "7px" }} ></img>View Asset Details</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal4(value); popupState.close(); }}><img alt="" src={require('../../../images/Upload.png')} style={{ marginRight: "7px" }} ></img>View Emi Schedule</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal5(value); popupState.close(); }}><img alt="" src={require('../../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View collection history</MenuItem>
                        <MenuItem onClick={() => { this.onOpenModal6(value); popupState.close(); }}><img alt="" src={require('../../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View documents</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </React.Fragment>



            );

          },

        }
      },
    ];
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Portfolio Setup Wizard'} activeStep={'stepActive4'} stepNav={true}></Header></div>
            <div className="tableSearch">

              <div className="row">
              <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}

                </div>

                <div className="col-md-6 col-sm-12">

                </div>

              </div>
            </div>
            <hr />
            <div className="tableSearch ">
              <div className="poolinfo row">
                <div className="poolinfo col-3">
                  <p id="poolinfohead">Pool ID</p>

                </div>
                <div className="col-1">
                  <p id="poolinfohead">Pool Name</p>
                </div>
                <div className=" col-2">
                  <p id="poolinfohead">Pool Description</p>
                </div>
                <div className="col-1">
                  <p id="poolinfohead">Pool Owner</p>
                </div>
                <div className=" col-1">
                  <p id="poolinfohead">Start Date</p>
                </div>
                <div className=" col-1">
                  <p id="poolinfohead">Expiry Date</p>
                </div>
                <div className="col-2">
                  <p id="poolinfohead">No Of Loans</p>
                </div>
              </div>

              <div className="poolinfo row">
                <div className="poolinfo col-3">
                  <p id="poolinfovalue">{poolinfo.PoolID}</p>
                </div>
                <div className="col-1">
                  <p id="poolinfovalue">{poolinfo.PoolName}</p>
                </div>
                <div className=" col-2">
                  <p id="poolinfovalue">{poolinfo.PoolDesc}</p>
                </div>
                <div className="col-1">
                  <p id="poolinfovalue">{poolinfo.Poolowner}</p>
                </div>
                <div className=" col-1">
                  <p id="poolinfovalue">{poolinfo.PoolStartedDate}</p>
                </div>
                <div className=" col-1">
                  <p id="poolinfovalue">{poolinfo['PoolExpiryDate ']}</p>
                </div>
                <div className="col-2">
                  <p id="poolinfovalue">{poolinfo.NoOfAssets}</p>
                </div>
              </div>
            </div>


            <div className="page-content">

              <Button onClick={this.FetchDoc} variant="contained" color="primary" type="submit">Click here to view loans </Button>
              <p>&nbsp;</p>

              {this.state.viewLoans === true ?
                <React.Fragment>
                  <div className="tableWrapper">
                    <MUIDataTable
                      data={this.state.tableData}
                      columns={columns}
                      options={options}

                    />
                  </div>
                  {/* <div className="navbarSteps navbarStepsBtm">
                  <div className="row justify-content-end">
                    <Button variant="contained" color="primary" type="submit" disabled={this.state.getLoansLoader === true ? true : false} >
                      {this.state.getLoansLoader === true ? <CircularProgress size="30px" color="secondary" /> : ''} Ok </Button>
                  </div>
                </div> */}
                </React.Fragment>
                : ''}

            </div>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.open1}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal1}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Loan Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>
              <div>
                <LoanDetails dataFromParent={this.state.value} />
              </div>
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.open2}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal2}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Customer Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal2}> <CloseIcon></CloseIcon> </Button>
              <div>
                <CustomerDetails dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>



        <ReactModal
          isOpen={this.state.open3}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal3}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Asset Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal3}> <CloseIcon></CloseIcon> </Button>
              <div>
                <AssetDetails dataFromParent={this.state.value} />
              </div>
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.open4}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
          onRequestClose={this.onCloseModal3}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>EMI Schedule</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal4}> <CloseIcon></CloseIcon> </Button>

              <div>
                <EmiScheduleTable dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>

        <ReactModal
          isOpen={this.state.open5}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal5}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>Collection History</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal5}> <CloseIcon></CloseIcon> </Button>

              <div>
                <CollectionHistoryTable dataFromParent={this.state.value} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>



        <ReactModal
          isOpen={this.state.open6}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal6}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>View Documents</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal6}> <CloseIcon></CloseIcon> </Button>

              <div>
                <ViewDocumentsTable dataFromParent={this.state.LoanID} />

              </div>
            </div>
          </React.Fragment>
        </ReactModal>

      </React.Fragment>
    );
  }
}

export default withSnackbar(poolDetails);