/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from 'axios';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {widgets,CustomFieldTemplate} from '../../../components/customscripts/customscript'

const Form = withTheme(MuiTheme);
const schema = require('./schema.json');



export default class poolFormation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open1: false,
            schema: schema,
            loading: false,
            getLoansLoader: false,
            open: false,
            openPopup: false,
            message: null,
            popupLoader: false,
            msgData: null,
            open2: false,
        };
    }
    onCloseModal1 =()=>{
        console.log("hii")
        // this.setState({ showModal: false })
      
            this.setState({ showModal: false })
            this.props.action('done')
      

      
    }

    onSubmit = (value) => {
        this.setState({getLoansLoader:true})
        console.log('onSubmit:', value.formData)
        let data = value.formData
        console.log('onSubmit data:', data)
        
        const peer = JSON.parse(localStorage.getItem('peers'));
        const token = localStorage.getItem('token');

        
        var input = {
          "accountNumber": data.accountNumber,
          "bankName": data.bankName,
          "bankIFSCorIBankCode": data.bankIFSCorIBankCode,
          "userCurrency": data.userCurrency,
          "peers":peer,
          "token":token

        }
        var result = JSON.stringify(input);
        console.log(result);
        axios(process.env.react_app_base_url + 'api/V1/payment/savebankdetails', {
          method: 'POST',
          data: result,
          dataType: 'json',
          processData: true,
          headers: {
            "Content-Type": "application/json"
          }
    
        }).then(res => {
          console.log(res.data);
    
          if (res.data.isSuccess === true) {
            this.setState({ getLoansLoader: false, open: true })
            

            // setTimeout(() => {
            //     this.setState({ showModal: false })
            //     this.props.action('done')
            // }, 1500);
            setTimeout(() => {
                this.setState({ showModal: false })
                this.props.action('done')
            }, 1500);

        }
        else {
            alert("Failed");
            // setTimeout(() => {
            //     this.setState({ showModal: false })
            //     this.props.action('done')
            // }, 100);
            setTimeout(() => {
                this.setState({ showModal: false })
                this.props.action('done')
            }, 100);
        }
    
    
        }).catch(error => { console.log(error); })
      }


    render() {
        return (
            <React.Fragment>
                <div className="twoColunm">
                    <Form
                        schema={this.state.schema}
                        onSubmit={this.onSubmit}
                        formData={this.state.formData}
                        onChange={this.onFormChanged}
                        widgets={widgets}
                        FieldTemplate={CustomFieldTemplate}
                    >
                        <div className="modalsubmit">
                        <div className="submitbuttonbg">
                            <hr className="hrbottom"/>
                        <div className="row justify-content-end">
                          
                        <Button variant="contained" id="optionalbutton" onClick={this.onCloseModal1} > Cancel  </Button>
                                <Button variant="contained" color="primary" type="submit" >Submit</Button>
                            </div>
                        </div>
                        </div>
                    </Form>


                </div>

                {this.state.getLoansLoader === false ? '' : <Loader msg={"Please wait.."} />}

                {this.state.open === true ? <React.Fragment><Snackbar msg={"Data Saved Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '}

            </React.Fragment>
        );
    }
}