import React, {Component} from 'react';
import './documentDetails.scss'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as documentDetailsActions from "../../store/documentDetails/actions";
export default class documentDetails extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return <div className="component-document-details">Hello! component documentDetails</div>;
    }
  }
// export default connect(
//     ({ documentDetails }) => ({ ...documentDetails }),
//     dispatch => bindActionCreators({ ...documentDetailsActions }, dispatch)
//   )( documentDetails );