/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar';
import TextField from '@material-ui/core/TextField';
// import { Dropdown } from 'react-bootstrap';
// import { Form as form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import Loader from '../../../../components/loader';
import Snackbar from '../../../../components/snackbar';
import axios from "axios";
import Modal from 'react-responsive-modal';
import { withSnackbar } from 'notistack';
// import { withTheme } from 'react-jsonschema-form';
// import { Theme as MuiTheme } from 'rjsf-material-ui';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
// import IconButton from '@material-ui/core/IconButton';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { Select } from '@material-ui/core';
import { Form } from 'react-bootstrap';
// import {

//   Redirect,
//   NavLink
// } from "react-router-dom";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import TrancheDetails from "./trancheDetails";
import PoolSlicingParameter from "./poolslicingparameter";
import CloseIcon from '@material-ui/icons/Close';
// import $ from 'jquery';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import DropdownToggle from 'react-bootstrap/DropdownToggle';
// const Form = withTheme(MuiTheme);
// const schema = require('./schema.json');
// const docattributes = require('./docattributes.json');


// function CustomFieldTemplate(props) {
//   const { id, classNames, label, help, required, description, errors, children } = props;
//   return (
//     <div className={classNames + ' customWrapper'}>
//       <label htmlFor={id} className={classNames + ' customLabel'}>{label}{required ? "*" : null}</label>
//       {description}
//       {children}
//       {errors}
//       {help}
//     </div>
//   );
// }


// const select = props => {
//   const { value, onChange, options } = props

//   const handleChange = val => evt => {
//     evt.preventDefault()
//     onChange(val)
//   }

//   return (

//     <FormControl variant="outlined" size="small" >
//       {/* <InputLabel id={props.id}> {props.label} {props.required ? "*" : null} </InputLabel> */}
//       <Select
//         labelId={props.id}
//         id={props.id}
//         value={props.value}
//         required={props.required}
//         onChange={(event) => props.onChange(event.target.value)}
//       >
//         {props.options.enumOptions.map((item) =>
//           <MenuItem value={item.value}>{item.label}</MenuItem>
//         )}
//       </Select>
//     </FormControl>

//   )
// }

// const widgets = {
//   select: select,
// }
import ReactModal from 'react-modal';


import { customStyles, customStylesauto } from '../../../../components/customscripts/customscript'

class AssignedPool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waterfall: null,
      loadingmodal: false,
      selected: [],
      loading: false,
      loader: false,
      getLoansLoader: false,
      open1: false,
      open2: false,
      open3: false,
      disabled: true,
      Redirect: false,
      // schema: schema,

      assignbuttonbtn: sessionStorage.getItem("assignbutton"),
      assignbutton: true,
      searchText: '',
      rowsSelected: null
    };
    this.childHandler = this.childHandler.bind(this)
  }
  state = {
    // waterfall:[],
    open1: false,
    open2: false,
    open3: false,
    Redirect: false,
    poolId: null,
    hashValue: null,
    fileName: null,
    filePath: null
  }

  childHandler(dataFromChild) {
    console.log(dataFromChild);
    this.setState({ open1: false });
  }


  onOpenModal1 = () => {
    console.log("inside modal");
    var value = sessionStorage.getItem("PoolID")
    this.setState({ open1: true, value: value });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false });
  };

  async onOpenModal2(value) {

    console.log("inside modal");
    this.setState({ open2: true, poolid: value });

  };

  onCloseModal2 = () => {
    this.setState({ open2: false });
  };


  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);


  };


  async componentDidMount() {

    const token = localStorage.getItem('token');

    const peer = localStorage.getItem('peer')
    sessionStorage.setItem("assignbutton", true)

    this.setState({ getLoansLoader: true, assignbutton: true });
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/pool/pools?peer=' + peer + "&token=" + token);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        // const columns = resp;
        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 2000,
        });

        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
      } else {
        alert("Failed");
      }
    } catch (error) {
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
        });

      } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
        });


      }
    }



  };


  async selectedpoolid(selected) {
    const arr = []


    for (var i = 0; i < selected.length; i++) {
      // console.log('loanID = i', i)
      var j = selected[i];
      let PoolID = this.state.tableData[j].PoolID;
      arr.push(PoolID);
    }

    console.log(arr);
    sessionStorage.setItem("PoolID", arr);
    // this.setState({
    //   PoolID:arr
    // })


  }


  render() {
    const { open1 } = this.state;
    const { open2 } = this.state;
    const { open3 } = this.state;
    // const {waterfall}=this.state;

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: true,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },


      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading: true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'PoolID',
        label: 'Pool ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'NoOfAssets',
        label: 'No. of Assets',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'Poolowner',
        label: 'Originator',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'PoolStartedDate',
        label: 'Approval Date',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'ApproverID',
        label: 'Approver ID',
        options: {
          filter: true,
          sort: false,
        },
      },



      {
        name: "PoolID",
        label: 'View',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (

              <React.Fragment>



                <Button variant="outlined" id="viewlink" onClick={() => this.onOpenModal2(value)}>View Tranche</Button>

              </React.Fragment>





            );

          },

        }
      },
    ];


    // const btn = sessionStorage.getItem("assignbutton");
    const btn = JSON.stringify(this.state.assignbuttonbtn)

    return (

      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Tranche'}></Header></div>
            <div className="tableSearch">

              <div className="row">
                <div className="justify-content-left " id="assignpoolbutton">





                  {this.state.rowsSelected !== null && this.state.rowsSelected.length !== 0 ?
                    <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                      onClick={this.onOpenModal1.bind(this)} color="primary" type="submit" >Pool Slicing Parameter</Button> : <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                        onClick={this.onOpenModal1.bind(this)} disabled color="primary" type="submit" >Pool Slicing Parameter</Button>}
                </div>

                <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ? <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })} /> : ''}

                </div>

                <div className="col-md-6 col-sm-12">

                </div>

              </div>
            </div>
            <div className="page-content">


              <React.Fragment>
                <MUIDataTable
                  data={this.state.tableData}
                  columns={columns}
                  options={options}

                />

              </React.Fragment>


              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
            </div>
          </div>
        </div>
        <div >
          <ReactModal
            isOpen={this.state.open1}
            contentLabel="Minimal Modal Example"
            style={customStyles}
            onRequestClose={this.onCloseModal1}

          >
            <React.Fragment>
              <div className="modalPopup">
                <h2>Pool Slicing Parameter</h2>
                <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>
                <div>
                  <PoolSlicingParameter dataFromParent={this.state.value} action={this.childHandler} />
                </div>
              </div>
            </React.Fragment>
          </ReactModal>


          <ReactModal
            isOpen={this.state.open2}
            contentLabel="Minimal Modal Example"
            style={customStyles}
            onRequestClose={this.onCloseModal2}

          >
            <React.Fragment>
              <div className="modalPopup">
                <h2>Tranche Details</h2>
                <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal2}> <CloseIcon></CloseIcon> </Button>
                <div>
                  <TrancheDetails dataFromParent={this.state.poolid} />
                </div>
              </div>
            </React.Fragment>
          </ReactModal>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(AssignedPool);
