import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Button from '@material-ui/core/Button';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { widgets, CustomFieldTemplate, customStyles, ObjectFieldTemplate } from '../../../components/customscripts/customscript'
import { withSnackbar } from 'notistack';
import LinearLoader from '../../../components/loader/LinearLoader';
import Loader from '../../../components/loader';
import { InvestorList, GetAllDeals, generateYears, months, AdjustmentMonth, EnableDisable, Prompt, GenerateReport } from '../../../servies/services';
const Form = withTheme(MuiTheme);
const LimaSchema = require('./LimaSchema.json');
const BawagSchema = require('./BawagSchema.json');
const SaludaSchema = require('./SaludaSchema.json');
const SaludagradeSchema = require('./SaludagradeSchema.json');

const uiSchemaLima = {
  dealId: {
    classNames: "task-title foo-bar"
  },
  servicingexpenses: {
    "ui:readonly": true
  },
  backupexpenses: {
    "ui:readonly": true
  },
  documentexpenses: {
    "ui:readonly": true
  },
  payingagentexpenses: {
    "ui:readonly": true
  }

};
const uiSchema2 = {
  dealId: {
    classNames: "task-title foo-bar"
  },
  servicingexpenses: {
    "ui:readonly": false
  },
  backupexpenses: {
    "ui:readonly": false
  },
  documentexpenses: {
    "ui:readonly": false
  },
  payingagentexpenses: {
    "ui:readonly": false
  }

};

class generateInvestorReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: SaludagradeSchema,
      years: generateYears(),
      months: months,
      getLoansLoader: false,
      open: false,
      // formData: formDataLima,
      pageTitle: "Generate Trustee Report",
      investorLoader: false,
      DealType: localStorage.getItem('DealType'),
      formData: {
        dealId: null,
        month: null,
        year: null,
      },
      generatebutton: false,
      all_deals: JSON.parse(localStorage.getItem("all_deals")),
      channelname: localStorage.getItem('ChannelName'),
    };
  }

  handleChange = (event) => {

    const { formData1 } = this.state;
    formData1[event.target.name] = event.target.value;
    this.setState({ formData1 });
    console.log(formData1);

  }


  async componentDidMount() {



    if (this.state.DealType == "lima") {

      this.setState({
        schema: LimaSchema, uiSchema: uiSchemaLima,
        // formData: formDataLima 
      });

    } else if (this.state.DealType == "trusteedeal") {

      this.setState({
        schema: SaludagradeSchema, uiSchema: uiSchemaLima,
        // formData: formDataLima
      });

    }

    const DealId = this.props.match.params.DealId;
    const DealMonth = this.props.match.params.DealMonth;
    const DealYear = this.props.match.params.DealYear;
    // console.log("dealid", dealid)
    const DealType = this.state.DealType
    this.callMethod(DealId, DealMonth, DealYear);

    // this.AdjustmentMonth();
    this.InvestorList(DealType);
    // this.InvestorList(DealType);





  }



  async componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps)

    const DealId = this.props.match.params.DealId;
    const DealMonth = this.props.match.params.DealMonth;
    const DealYear = this.props.match.params.DealYear;
    this.callMethod(DealId, DealMonth, DealYear);

  }
  callMethod(DealId, DealMonth, DealYear) {
    const formData = {
      dealId: DealId,
      month: DealMonth,
      year: DealYear,

    }

    this.setState({ formData: formData })
    const DealType = this.state.DealType
    this.GetAllDeals(DealType)
  }

  AdjustmentMonth = async (DealType) => {

    const selectedMonth = this.state.formData.month;
    if (selectedMonth == "5") {
      this.setState({ uiSchema: uiSchema2 })
    }
    else {
      this.setState({ uiSchema: LimaSchema })
    }

  }


  onChange = async (value) => {
    const selectedMonth = value.formData.month;
    if (selectedMonth == "5") {
      this.setState({ uiSchema: uiSchema2 })
    }
    else {
      this.setState({ uiSchema: LimaSchema })
    }


    const newlogic = value.formData.newlogic;
    if (newlogic == "No") {
      this.setState({ generatebutton: true });
    }
    else {
      this.setState({ generatebutton: false });

      this.newlogic(value);

    }

  }


  newlogic = async (value) => {
    const DealType = this.state.DealType
    const APIResponse = await EnableDisable(DealType, value);
    console.log("EnableDisable", APIResponse.data)
    this.setState({ EnableDisable: APIResponse.data })
    if (APIResponse.status == 204) {
      alert("No change in logic - Investor Report cannot be Generated!")
      this.setState({ generatebutton: true });

    }
    else if (APIResponse.status == 200) {
      if (APIResponse.data.token == -1) {
        alert("Session Expired!!");
      }
      else {
        const Successmsg = APIResponse.data['isSuccess']
        if (Successmsg == false) {
        }
        else {
          this.setState({ generatebutton: false });

        }
      }

    }


  }
  InvestorList = async (DealType) => {
    const APIResponse = await InvestorList(DealType)
    if (APIResponse !== null) {
      console.log("InvestorList", APIResponse.data);
      this.setState({ InvestorList: APIResponse.data });
      let UserID = []
      let UserName = []
      if (APIResponse.data.length !== 0) {
        APIResponse.data.forEach(function (key, value) {
          console.log("key", key.UserID)
          console.log("value", value)
          // var obj = { name: key, label: key }
          UserID.push(key.UserID);
          UserName.push(key.UserName);
        });
      }

      console.log("APIResponse.data", APIResponse.data);
      let oldSchema = this.state.schema;
      console.log("oldstagedata", oldSchema);
      oldSchema.properties.investorid.enum = UserID;
      oldSchema.properties.investorid.enumNames = UserName;
      const newSchema = Object.assign({}, oldSchema);
      console.log("WRITE oldSchema", newSchema);
      this.setState({ schema: newSchema, investorLoader: true });
    }
  };

  GetAllDeals = async (DealType) => {

    let all_deals = this.state.all_deals;
    let deal_name = [];
    if (all_deals.length !== 0) {
      all_deals.map((item) => {
        console.log("all_deals item", item.deal_id);
        deal_name.push(item.deal_id);
      })
    }

    let month_name = []
    let month_value = []
    if (this.state.months.length !== 0) {
      this.state.months.map((item) => {
        console.log("item", item);
        month_name.push(item.label);
        month_value.push(item.value);
      })
    }

    let year_name = []
    let year_value = []
    if (this.state.years.length !== 0) {
      this.state.years.map((item) => {
        console.log("item", item);
        year_name.push(item.key);
        year_value.push(item.value);
      })
    }

    // console.log("currency_list_static", currency_list_static);
    let oldSchema = this.state.schema;
    console.log("oldstagedata", oldSchema);
    oldSchema.properties.dealId.enum = deal_name;
    oldSchema.properties.dealId.enumNames = deal_name;

    oldSchema.properties.month.enum = month_value;
    oldSchema.properties.month.enumNames = month_name;

    oldSchema.properties.year.enum = year_value;
    oldSchema.properties.year.enumNames = year_name;

    const newSchema = Object.assign({}, oldSchema);
    console.log("WRITE oldSchema", newSchema);
    // this.setState({ schema: newSchema});
    this.setState({ schema: newSchema, loadingForm: true });
  };
  onSubmit = async (value) => {

    this.setState({ getLoansLoader: true, formData: value.formData });

    console.log("generate-investor-report", value.formData)
    let data = value.formData;
    // data.userId = localStorage.getItem("user_id");
    data.peers = JSON.parse(localStorage.getItem("peers"));
    data.token = localStorage.getItem("token");
    data.userId = localStorage.getItem("user_id");
    data.channelname = this.state.channelname
    console.log("coming from schema" + JSON.stringify(data));

    let dealId = value.formData.dealId;
    let month = value.formData.month;
    let year = value.formData.year;
    let DealType = this.state.DealType;



    // data.additionallast = value.formData.additionalDetailsTemplate.additionallast;
    // data.address = value.formData.additionalDetailsTemplate.address;
    // data.addtionalfirst = value.formData.additionalDetailsTemplate.addtionalfirst;
    // data.email = value.formData.additionalDetailsTemplate.email;
    // data.relationshipmanager = value.formData.additionalDetailsTemplate.relationshipmanager;
    // data.websitereporting = value.formData.additionalDetailsTemplate.websitereporting;
    // data.Delawaretrustee = value.formData.Fees.Delawaretrustee;
    // data.indenturetrustee = value.formData.Fees.indenturetrustee;
    // data.channelname = this.state.channelname
    // delete value.formData.additionalDetailsTemplate;
    // delete value.formData.Fees;
    console.log("data onSubmit onSubmit", data)

    let channelname = this.state.channelname
    const APIResponse = await Prompt(DealType, dealId, month, year, channelname)
    console.log("Prompt", APIResponse.data)
    if (APIResponse.status == 204) {


    }
    else if (APIResponse.status == 200) {
      if (APIResponse.data.token == -1) {
        alert("Session Expired!!");
      }
      else {
        var status = APIResponse.data.status;
        var length = APIResponse.data.length;
        value.formData.version = length;
        if (status == "1") {

          if (window.confirm("Already generated report for this dealName , month , year. Would you still like to update?")) {
            this.GenerateReport(data)
          }
          else {
            window.location.reload(true)
          }

        }
        else {
          this.GenerateReport(data)
        }

      }
    }

  }


  GenerateReport = async (value) => {
    let DealType = this.state.DealType;


    console.log("GenerateReport", value);



    // formData
    const APIResponse = await GenerateReport(value, DealType)
    if (APIResponse !== null) {
      console.log("GenerateReport", APIResponse.data)
      if (APIResponse.status == 204) {


      }
      else if (APIResponse.status == 200) {
        if (APIResponse.data.token == -1) {
          alert("Session Expired!!");
        }
        else {
          const Successmsg = APIResponse.data['isSuccess'];
          const SuccessmsgText = APIResponse.data['message'];

          if (Successmsg == false) {
          }

          else {
            this.setState({ getLoansLoader: false });
            const message = "Data Saved Successfully!";
            this.props.enqueueSnackbar(message, {
              variant: 'info',
              autoHideDuration: 4000,
            });

            setTimeout(() => {
              this.props.history.push("/report/" + this.state.DealType + "/customize-investor-report/" + this.state.formData.dealId + "/" + this.state.formData.month + "/" + this.state.formData.year);
            }, 2000);


          }
        }
      }
    }
  }
  render() {
    const { formData1 } = this.state;
    return (

      <React.Fragment>
        <div className="page">
          <div className="content">
            <div className="header"><Header pageTitle={this.state.pageTitle}></Header>
              {/* {this.state.getLoader == false ? '' : <LinearLoader></LinearLoader>} */}
            </div>
            <div className="page-content text-center">
              <div className="row">
                <div className="col-md-12 text-left">
                  <h3 className="title-page">{!this.state.pageTitle ? '' : this.state.pageTitle}</h3>
                </div>
                <div className="col-md-12">
                  {/* {JSON.stringify(this.state.formData)} */}
                  <div class="threecolform">
                    {this.state.investorLoader == true ?
                      <Form
                        schema={this.state.schema}
                        onSubmit={this.onSubmit}
                        onChange={this.onFormChanged}
                        widgets={widgets}
                        omitExtraData={true}
                        liveOmit={true}
                        FieldTemplate={CustomFieldTemplate}
                        formData={this.state.formData}
                        uiSchema={this.state.uiSchema}
                        ObjectFieldTemplate={ObjectFieldTemplate}
                      >
                        <div className="row justify-content-center">
                          <Button variant="contained" color="primary" disabled={this.state.generatebutton === true ? true : false} type="submit">
                            Generate Trustee Report {this.state.getLoansLoader === true ? (
                              <CircularProgress size='25px' color='primary' />
                            ) : (
                              ''
                            )} </Button>

                        </div>
                      </Form>
                      : <Loader></Loader>}
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(generateInvestorReport)
