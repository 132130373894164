/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormLoader from '../../../components/formLoader';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import LinkItem from '../../../components/linkItem';
import { CustomFieldTemplate, widgets } from '../../../components/customscripts/customscript';
import { withSnackbar } from 'notistack';

import {addUser, authenticate, getAllUserRoles } from '../../../servies/services';


const Form = withTheme(MuiTheme);
const schema = require('./schema.json');
const uiSchema = {
  'email': {
    'ui:widget': 'email',
  },
  'password': {
    'ui:widget': 'password',
  }
};


class register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: schema,
      uiSchema: uiSchema,
      token: localStorage.getItem('token'),
      peers: JSON.parse(localStorage.getItem('peers')),
      userid: localStorage.getItem("userid"),
      // OrgLoading: true,
      formData:{}
    };
  }

  validate(formData, errors) {
    
    if (!formData.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/)) {
      errors.password.addError("Password should contain 1 uppercase, 1 numeric, 1 special character & atleast 8 character");
    }
    return errors;
  }

  // onSubmit = (value) => {
  //   console.log('onSubmit:', value.formData)
  //   let data = value.formData;
  //   this.register(data);
  // };

  async componentDidMount() {
    localStorage.clear();
    // this.getAllUserRoles();
  }


  // getAllUserRoles = async () => {
  //   this.setState({ OrgLoading: false })
  //   const APIResponse = await getAllUserRoles()
  //   console.log("getAllUserRoles", APIResponse.data)

  //   let orgname_id = []
  //   let orgname_name = []
  //   if (APIResponse.data.length !== 0) {
  //     APIResponse.data.forEach(function (key, value) {
  //       console.log("key", key.UserRoleID)
  //       console.log("value", value)
  //       // var obj = { name: key, label: key }
  //       orgname_id.push(key.UserRoleID);
  //       orgname_name.push(key.UserRoleName);
  //     });
  //   }
  //   console.log("APIResponse.data", APIResponse.data);
  //   let oldSchema = this.state.schema;
  //   console.log("oldstagedata", oldSchema);
  //   oldSchema.properties.OrgName.enum = orgname_name;
  //   oldSchema.properties.OrgName.enumNames = orgname_name;
  //   const newSchema = Object.assign({}, oldSchema);
  //   console.log("WRITE oldSchema", newSchema);
  //   this.setState({ schema: newSchema });
  //   this.setState({ OrgLoading: true, invoiceDetails: APIResponse.data })
  //   // sessionStorage.setItem("OrgName", JSON.stringify(newSchema))
  // }

  onSubmit = async (value) => {

    console.log('onSubmit:', value.formData)
    let data = value.formData;
    // data.peers = this.state.peers
    // data.token = this.state.token;
    // data.userid = this.state.userid;
    // const UserRoleID = this.state.invoiceDetails.filter(item => item.UserRoleName == value.formData.OrgName)
    // console.log("++++++++++" + JSON.stringify(UserRoleID[0].UserRoleID))
    // data.UserRoleID = UserRoleID[0].UserRoleID
    const APIResponse = await addUser(data)
    console.log("addUser", APIResponse)

    if (APIResponse.status !== 200) {
      const message = "Something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
      });

    }
    else {

      if (APIResponse.data.isSuccess == false) {

        this.setState({ formLoader: false })
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000,
        });

      } else {
        this.setState({ formLoader: false })
        const message = "Registered successfully!";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 2000,
        });

        this.props.history.push("/")

      }

    }

  };

  render() {
    return (
      <React.Fragment>
        <h4>Sign up to your account to access Intain ABS</h4>
        <div className="form_row_single">
          {/* {this.state.OrgLoading === false ?
            <React.Fragment>
              <FormLoader></FormLoader>
              <p className="loading_text">Loading, Please wait...</p>
            </React.Fragment>
            : */}
            <Form
              schema={this.state.schema}
              onChange={this.onFormChanged}
              onSubmit={this.onSubmit}
              widgets={widgets}
              FieldTemplate={CustomFieldTemplate}
            uiSchema={this.state.uiSchema}
            validate={this.validate}
            >

              <div id="form-btn">
                <div className="container-fluid text-center">
                  <div className="row">
                    <Button className="col-md-12" variant="contained" size="large" color="primary" id="signinbutton" type="submit"
                      disabled={this.state.loading === true ? true : false} > Register </Button>
                  </div>
                </div>
              </div>
            </Form>
          {/* } */}
          <div className="loginCheckbox">
            <p className="lineDivider">
              <span>Have Account?</span>
            </p>
            <LinkItem to={'/'} variant="contained" className="loginBtn" title={'Login'}> </LinkItem>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withSnackbar(register);