/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import { TrusteeMenu, InvestorMenu, Preprocessing, Originator, IntainABSTrustee, Issuer, IntainABSInvestor, Servicer, adminUser, AdminUser, Processor} from './menu';
import LinkItem from '../linkItem';
import Logo from '../../images/logo-ind.png';
import Button from '@material-ui/core/Button';
import axios from "axios";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';
import { history, theme, setUpAxios ,logout} from '../../servies/services';
import { withSnackbar } from 'notistack';
import Snackbar from '../../components/snackbar';




 class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: localStorage.getItem('username'),
      // currentUser: localStorage.getItem('userrole'),
      OrgName: localStorage.getItem('OrgName'),
      userType: localStorage.getItem('userrole'),
      ChannelName: localStorage.getItem('ChannelName'),
      token: localStorage.getItem('token'),
      loading: false,
      getLoansLoader: false,
      open: false,
      message: '',
      currentmenu: [],
      menu_loader: false
    };
  }

  async componentDidMount() {
    history.go(1)

    // currentUser: sessionStorage.getItem('name').charAt(0).toUpperCase(),

    console.log("this.state.currentUser", this.state.currentUser)


    // alert(this.state.OrgName);

    if (this.state.userType == "Admin") {

      this.setState({ currentmenu: AdminUser, menu_loader: true })
    } else {
      this.setState({ currentmenu: Processor, menu_loader: true })
      // if (this.state.OrgName == "investor") {
      //   if (this.state.ChannelName == "IntainABS") {
      //     this.setState({ currentmenu: IntainABSInvestor, menu_loader: true })
      //   } else if (this.state.ChannelName == "WSFS") {
      //     this.setState({ currentmenu: InvestorMenu, menu_loader: true })
      //   } else if (this.state.ChannelName == "UMB") {
      //     this.setState({ currentmenu: InvestorMenu, menu_loader: true })
      //   }
      // } else if (this.state.OrgName == "originator") {
      //   this.setState({ currentmenu: Originator, menu_loader: true })
      // } else if (this.state.OrgName == "issuer") {
      //   this.setState({ currentmenu: Issuer, menu_loader: true })
      // } else if (this.state.OrgName == "servicer") {
      //   this.setState({ currentmenu: Servicer, menu_loader: true })
      // } else if (this.state.OrgName == "whlender") {
      //   this.setState({ currentmenu: TrusteeMenu, menu_loader: true })
      // } else if (this.state.OrgName == "trustee") {
      //   if (this.state.ChannelName == "IntainABS") {
      //     this.setState({ currentmenu: IntainABSTrustee, menu_loader: true })
      //   } else if (this.state.ChannelName == "WSFS") {
      //     this.setState({ currentmenu: TrusteeMenu, menu_loader: true })
      //   } else if (this.state.ChannelName == "UMB") {
      //     this.setState({ currentmenu: TrusteeMenu, menu_loader: true })
      //   }
      // }
    }

    // Originator, Preprocessing, Issuer, Investor, Servicer




    if (this.state.currentUser !== null) {
      const firstname = this.state.currentUser;
      const namefirstletter = firstname.charAt(0).toUpperCase();
      this.setState({ currentUser: namefirstletter });
    }

  };

  logoutBtn =async ()=> {
    let data ={}
    data.token=this.state.token
    console.log(this.state.token,"token")
    const APIResponse= await logout(data)
    console.log(APIResponse,"APIResponse")
    console.log("aaaa")
    if (APIResponse.status == 200) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      localStorage.clear();

          window.location.replace('/')

    }
    else {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }

  }

  render() {
    return (
      <React.Fragment>
        {this.state.menu_loader == false ? '' :
          <div className="sidebar">
            <ul>
              <li className="logoPlaceholder">
                {/* <LinkItem to={'#nogo'} title={''} icon={Logo} > </LinkItem> */}
              </li>
              {this.state.currentmenu.map((item) => {
                return (
                  <li key={item.title}>
                    {/* {item.icon} */}
                    <LinkItem to={item.linkto} title={item.title} icon={item.icon}></LinkItem>
                    {item.subitems != null ? (
                      <React.Fragment>
                        <ul>
                          <div className="arrow-left"></div>
                          <div className="arrow_box">
                            {item.subitems.map((item1) => {
                              return (
                                <li key={item1.title}>
                                  <LinkItem to={item1.linkto} title={item1.title}></LinkItem>
                                </li>
                              );
                            })
                            }
                          </div>
                        </ul>
                      </React.Fragment>
                    ) : (
                      <React.Fragment> </React.Fragment>
                    )
                    }
                  </li>
                );
              })
              }
            </ul>
            <Tooltip title="LogOut">
            <ul className="bottomMenu">
              {/* <li className="userInfo"><LinkItem to={'#nogo'} title={this.state.currentUser} > </LinkItem></li> */}
              <li className="showMenu">  
                <Button className="logout" variant="contained" color="primary" type="submit" onClick={this.logoutBtn} >
                  <ExitToAppIcon></ExitToAppIcon> 
                </Button>
              </li>
              
            </ul>
            </Tooltip>
          </div>
        }
      </React.Fragment>
    );
  }
}
export default withSnackbar(sidebar);