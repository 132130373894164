/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import Modal from 'react-responsive-modal';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Match from '../../../images/match.png';
import Mismatch from '../../../images/mismatch.png';
import { withSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import FormLoader from '../../../components/loader/formLoader';
import ReactModal from 'react-modal';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import * as moment from 'moment';
import { widgets, CustomFieldTemplate, customStyles, customStylesauto,customStylesauto1 } from '../../../components/customscripts/customscript';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {getAllProcessors,addProcessor} from '../../../servies/services';

const Form = withTheme(MuiTheme);
const Processorschema = require('./processor-schemas.json');

const token = localStorage.getItem('token')

class processorDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecords: [],
      eligibleloans: {},
      tableData: [],
      loading: false,
      getLoansLoader: false,
      open: false,
      selectedLoanId: [],
      searchText: '',
      open1: false,
      openPopup: false,
      message: null,
      popupLoader: false,
      msgData: null,
      rowsSelected: null,
      // selectedrows :null,
      Processorschema:Processorschema,
      exportdata:[],
      noofprocessor:[]
      // searchText: ''
    };

  }
  onOpenModal = () => {
    this.setState({formData:[]})
    console.log("inside modal1");
    this.setState({ open1: true });
  };

  onCloseModal = () => {
    this.setState({ open1: false });
  };

  onchange = e => {
    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  };

  handleClick = (rowId) =>{
    const arrayCopy = this.state.tableData.filter((row) => row.processorId !== rowId);
    this.setState({tableData: arrayCopy});
    this.HIt(rowId)
  }
  HIt = (rowId) => {
    setTimeout(() => {
        console.log("setTimeout", Date().toLocaleString())
        console.log('tabledata: ',this.state.tableData)
    }, 1000)
}

  async componentDidMount() {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined) {
      window.location.assign("/")
    }
    this.getAllProcessors(token)
    // const APIResponse =  {
    //   "message": "successfull",
    //   "isSuccess": true,
    //   "data": [{
    //       "processorId": "Processor 1",
    //       "emailId": "user1@gamil.com",
    //       "role": "Processor",
    //       "dealId": "2 Deals"
    //     },
    //     {
    //       "processorId": "Processor 2",
    //       "emailId": "user2@gamil.com",
    //       "role": "Reviewer",
    //       "dealId": "4 Deals"
    //     },
    //     {
    //       "processorId": "Processor 3",
    //       "emailId": "user3@gamil.com",
    //       "role": "Processor",
    //       "dealId": "1 Deals"
    //     },
    
    //     {
    //       "processorId": "Processor 4",
    //       "emailId": "user4@gamil.com",
    //       "role": "Processor",
    //       "dealId": "3 Deals"
    //     },
    //     {
    //       "processorId": "Processor 5",
    //       "emailId": "user5@gamil.com",
    //       "role": "Processor",
    //       "dealId": "2 Deals"
    //     }
    //   ],
    //   "noofprocessors": "5"
    // }
    // this.setState({tableData:APIResponse.data, noofprocessors:APIResponse.noofprocessors})
  }

  getAllProcessors = async (token) =>{
    this.setState({getLoansLoader: true,tableData:[]});
    const APIResponse = await getAllProcessors(token)
  
    if (APIResponse.status === 200) {
      console.log('Allpools--', APIResponse);
      this.setState({getLoansLoader: false, open: true, tableData: APIResponse.data, loading: true,noofprocessor:APIResponse.data.length});
      const message = "Record Fetched Successfully";
      this.props.enqueueSnackbar(message, {
        variant: 'info',
        autoHideDuration: 3000,
      });
    } else {
      alert('Failed');
    }
  }

  addProcessor = async (value) =>{
    // this.setState({ formLoader: true })
    // let data = value.formData
    // console.log('data',data)
    // let oldSchema = this.state.Processorschema;
    // console.log("oldstagedata inside addprocessor", oldSchema);
    // oldSchema.properties.name.default = data.name;
    // oldSchema.properties.userLastName.default = data.userLastName;
    // oldSchema.properties.emailid.default = data.emailid;
    // const newSchema = Object.assign({}, oldSchema);
    // console.log("WRITE oldSchema", newSchema);
  
    // this.setState({formLoader: true, Processorschema:newSchema})
    
    // const newdata = new FormData();
    // newdata.append("name", data.name);
    // newdata.append('emailid', data.emailid);
    // newdata.append("userrole",'Processor');
    // newdata.forEach(element => {
    //   console.log("Asdasdas2", element);
    //   });
    // console.log("addprocessor---"+JSON.parse(newdata))
    var data = value.formData
  
    console.log("addprocessor1---"+JSON.stringify(value))
  
    
  const APIResponse = await addProcessor(data,token)
  console.log(APIResponse);
    if (APIResponse.status !== 200) {
      const message = "Something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      this.setState({ formLoader: false })
  
    }
    else {
  
      this.setState({ getLoansLoader: false, openPopup: true });
  
      if (APIResponse.data.isSuccess == false) {
  
        this.setState({ formLoader: false })
        const message = "Processor with emailid already exists";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 1000,
        });
  
      } else {
        this.setState({ formLoader: false })
        const message = "Processor Added successfully!";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 1000,
        });
           this.onCloseModal()
           this.getAllProcessors(token)
          //  this.props.history.push("/admin/dashboard")
          // window.location.reload()
      }
    }
  
  }

  async selectedpoolid(selected) {
    const arr = []

    this.setState({selectedrows:selected})

    for (var i = 0; i < selected.length; i++) {

      var j = selected[i];

      let loanId = this.state.tableData[j];
      arr.push(loanId);
    }
    console.log(arr);
    localStorage.setItem('DDReport', arr)
  }

  onSubmit = () => {
    if (localStorage.getItem("dueDiligence") !== null) {
      const data = localStorage.getItem('dueDiligence');
      this.props.history.push({
        pathname: '/portfolio-setup-wizard/pool-formation',
        search: '',
        state: { eligiblePortfolioImportParameter: data }
      });
    }
  }


  render() {

    const options = {
      customToolbar: ()=>{},
      filterType: 'dropdown',
      filter:true,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      // searchOpen: true, 
      // searchPlaceholder:'Search',
      selectableRowsOnClick: true,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },
      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      loading:false,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, Loading Loan Data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };




    const columns = [
      {
        name:'username',
        label: 'Processor',
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "emailid",
        label: 'Email ID',
        options: {
          filter: true,
          sort: true,
        }
      },
      
      {
        name: "userrole",
        label: 'Role',
        options: {
          filter: true,
          sort: true,
          
        }
      },

      {
        name: "noofpool",
        label: 'Deals',
        options: {
          filter: true,
          sort: true,
          
        }
      },

    //   {
    //     name: 'processorId',
    //     label: 'Action',
    //     options: {
    //       filter: true,
    //       sort: false,
    //       customBodyRender: (value, tableMeta, updateValue) => {

    //           return(
    //           <React.Fragment>
    //             <div className="">
    //                {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value)} > */}
    //                   {/* <img alt="" src={require('../../../images/ViewLoans.png')} onClick={() => this.handleClick(value)}></img> */}
    //                  <DeleteOutlinedIcon style={{cursor: "pointer"}} onClick={() => {this.handleClick(value)}}> </DeleteOutlinedIcon> 
    //                 {/* </Button> */}


    //             </div>
    //           </React.Fragment>
    //         );
    //     }
    //   }
    // },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'PROCESSORS'} ></Header></div>


            <div className="page-content">
              <React.Fragment>

              <div className="row11">
              
              <div className="col-md-6 col-sm-12" id="searchBox">
              <div className="tableSearch1">
                <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}
                </div>
              </div>
           

          
    
        <div className="col-md-3 col-sm-12 heyy">
            <div className="page-content1 text-center">
              <div className="row align-items-center">
              <div className="col text-secondary shifts1">
                  <h5 className="font-weight-bold">NO. OF PROCESSORS &nbsp;&nbsp; {this.state.noofprocessor}</h5>
              </div>
              </div>
            </div>
           </div>


           <div className="col-md-3 col-sm-12 hey">
            <div className="page-content12 text-center">
              <div className="row align-items-center">
              <div className="col shifts12">
              <Button className="buttonchanges" id="optionalbutton" onClick={this.onOpenModal.bind(this)} type="submit" startIcon={<AddIcon />}>&nbsp;&nbsp;&nbsp;ADD PROCESSOR</Button>
              </div>
              </div> 
            </div>
           </div>


           </div>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                  />
                  
                </React.Fragment>
                </div>


                
          </div>

          <div id="modal">
                  {/* <Modal open={open1} onClose={this.onCloseModal1} center>
                    <div id="modalheader">
                      <h5>Create a Pool</h5>

                    </div> */}
                  <ReactModal
                    isOpen={this.state.open1}
                    onRequestClose={this.onCloseModal}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >

                    <React.Fragment>
                      <div className="modalPopup">
                        <h2> Add Processor </h2>
                        <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal}> <CloseIcon></CloseIcon> </Button>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}


                        <div>
                          <Form
                            schema={this.state.Processorschema}
                            // uiSchema={uiSchema}
                            onSubmit={this.addProcessor}
                            widgets={widgets}
                            FieldTemplate={CustomFieldTemplate}
                          >
                           
                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <hr className="hrbottom" />
                                <div className="row justify-content-end">

                                  <Button variant="contained" id="optionalbutton" onClick={this.onCloseModal} > Cancel  </Button>
                                  <Button variant="contained" color="primary" type="submit" > Add Processor  
                                  {this.state.formLoader === true ? (
                                  <CircularProgress size='25px' color='primary' />
                                   ) : (
                                   ''
                                  )}</Button>
                                </div>
                              </div>
                            </div>
                          </Form>


                          {this.state.getLoansLoader === false ? '' : <FormLoader></FormLoader>}

                          {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}


                        </div>
                      </div>
                    </React.Fragment>



                  </ReactModal>
                </div>
        </div>
        {/* <div className="navbarSteps navbarStepsBtm">
        <div className="row justify-content-end">
        <Button variant="contained" color="primary" id="optionalbutton" type="submit">MAP PROCESSOR</Button>
        </div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default withSnackbar(processorDashboard);