/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { withSnackbar } from 'notistack';



class PreProcessingEmiScheduleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
    };
  }





  async componentDidMount() {

    const loanId = this.props.dataFromParent;   
    this.loanDetails(loanId)

  };

  async loanDetails(loanId){
    this.setState({ getLoansLoader: true, assignbutton: true });
    try {
        const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/emi?loanId=' + loanId);
        const resp = res.data;
        if (res.status === 200) {
            if (resp.token == '-1') {
                const message = "Invalid Token!";
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000
                });
                this.props.history.push({
                    pathname: '/'
                })
            } else {
                console.log("poolloans", resp);
                this.setState({loading: true, tableData: resp, getLoansLoader: true,  });
                const message = "Successfully fetched the data";
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 2000,
                });
            }

        } else if (res.status === 204) {
            const message = "No content available";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        } else {
            const message = "something went wrong, please try again";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }



    } catch (error) {

        if (error.response.status == 400) {

            console.log("getLoans API error", error);
            console.log("error.response", error.response.data.message);

            const message = error.response.data.message;
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });

        } else {

            const message = "Went something wrong, please try again";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 3000,
            });


        }

    }
}

  render() {


    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("valuee" + selected);
        console.log(allRows);
        this.setState({
          rowsSelected: selected,
        });
      },
      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'installmentNumber',
        label: 'Installment Number',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'scheduleDate',
        label: 'Schedule Date',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'currency',
        label: 'Currency',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'emiAmount',
        label: 'EMI Amount',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'outstandingPrincipalBalance',
        label: 'Outstanding Principle Balance',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'installmentPrincipalAmount',
        label: 'Installment Principle Amount',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'installmentInterestAmount',
        label: 'Installment Interest Amount',
        options: {
          filter: true,
          sort: false,
        },
      },
    ];



    return (

      <React.Fragment>


        <div className="page-content">

          {this.state.loading === true ?
            <React.Fragment>
              <MUIDataTable
                data={this.state.tableData}
                columns={columns}
                options={options}

              />

            </React.Fragment>

            : <Loader msg={"Please wait, Loading EMI data"} />}

          
        </div>


      </React.Fragment>
    );
  }
}


export default withSnackbar(PreProcessingEmiScheduleTable);