/* eslint-disable require-jsdoc */
import React, { Component } from 'react';

import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { Form } from 'react-bootstrap';

// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppIcon from '@material-ui/icons/GetApp';


// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
// import { NavLink } from 'react-bootstrap';
// import {
  
   
//    NavLink
//   } from "react-router-dom";

// import $ from 'jquery';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import DropdownToggle from 'react-bootstrap/DropdownToggle';
// const Form = withTheme(MuiTheme);
// const schema = require('./schema.json');
// class CustomToggle extends React.Component {
//   constructor(props, context) {
//     super(props, context);

//     this.handleClick = this.handleClick.bind(this);


//   }

//   handleClick(e) {
//     e.preventDefault();

//     this.props.onClick(e);
//   }
//   render() {
//     return (
//       <a href="" onClick={this.handleClick}>
//         {this.props.children}
//       </a>
//     );
//   }
// }

function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
      var res = str.substring(2, str.length);
      var rep = res.replace(/[a-z]/gi, 'x')
      return str.replace(res, rep);
  }

  return str;
}


export default class PreProcessingCustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
     
    };
   
    // this.viewloansredirect = this.viewloansredirect(value);

  }
  
 
// async downloadDoc(filePath) {
//     console.log("inside");
//     console.log(filePath);
//     try {
//         const res = await axios.get(process.env.react_app_base_url + 'root_folder/'+filePath);
//         const resp = res.url;
//         console.log("res", resp)

//         // 35.208.133.41:8082/root_folder/loandocuments/PHTWRBL00228_asset.pdf
     
//         setTimeout(() => {
//             window.open(resp);
//         }, 100);
    


//       } catch (error) {
//         alert("Failed");
//         console.log("getLoans API error", error);
//       }
// }

  
  async componentDidMount() {
    var value=this.props.dataFromParent;
// console.log("LOANID"+LoanID)

   
try {
    const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/customers?loanId='+value);
    const resp = res.data;
    console.log("customerdetails",res.data );
    if (res.status === 200) {
      console.log("customerdetails", resp);
      // const columns = resp;


      this.setState({  Customerdetails: resp , loadingmodal: true});
    } else {
      alert("Failed");
    }
  } catch (error) {
    alert("Failed");
    console.log("getLoans API error", error);
  }
  }
  render() {


    return (
     
      <React.Fragment>
       
            
       <div>
                        {this.state.Customerdetails!=null && this.state.loadingmodal === true ?  
                            <Form id="labelform">
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Group controlId="formGroupName">
                                            <Form.Label>Borrower Name</Form.Label>
                                            <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].borrowerName)}/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-4">
                                        <Form.Group controlId="formImage">
                                            <Form.Label>S/o D/o W/o</Form.Label>
                                            <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].borrowerSOrDOrWOf)} />
                                        </Form.Group>
                                    </div>

                                    <div className="col-4">
                                        <Form.Group controlId="formGroupDescription">
                                            <Form.Label>Age</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Customerdetails[0].age} />
                                        </Form.Group>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-4">
                                        <Form.Group controlId="formGroupName">
                                            <Form.Label>Co-Borrower(s)/Guarantor(s) Name</Form.Label>
                                            <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].coBorrowerOrGuarantorNames)} />
                                        </Form.Group>
                                    </div>
                                    <div className="col-4">
                                        <Form.Group controlId="formImage">
                                            <Form.Label>S/o D/o W/o</Form.Label>
                                            <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].coBorrowerSOrDOrWOf)}/>
                                        </Form.Group>
                                    </div>

                                    <div className="col-4">
                                        <Form.Group controlId="formGroupDescription">
                                            <Form.Label>Age of co Borrower</Form.Label>
                                            <Form.Control required disabled placeholder="" value={this.state.Customerdetails[0].ageOfCoBorrower}/>
                                        </Form.Group>
                                    </div>
                                </div>





                            </Form>
                           :  <Loader msg={"Please wait, Loading Customer details"} /> }
                        </div>
       
      </React.Fragment>
    );
  }
}

