/* eslint-disable require-jsdoc */
//ddreport final
import React, { Component } from "react";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import MUIDataTable from "mui-datatables";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../../../components/loader";
import Snackbar from "../../../components/snackbar";
import Modal from "react-responsive-modal";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import CompareData from "./compareData";
import Match from "../../../images/match.png";
import Mismatch from "../../../images/mismatch.png";
import { withSnackbar } from "notistack";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import {
  attributeDetails,
  OldResponse,
  testHighlights,
} from "../../../Response";

import { Checkbox } from "@material-ui/core";
import { TableVirtuoso ,Virtuoso} from "react-virtuoso";
import Tooltip from "@material-ui/core/Tooltip";

import ReactModal from "react-modal";
import * as moment from "moment";
import {
  widgets,
  CustomFieldTemplate,
  customStyles,
  customStylesauto,
  customStylesauto1,
} from "../../../components/customscripts/customscript";

import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import {
  compareData,
  GetDealId,
  ReviewStatus,
  ExportExcel,
  DownloadFile,
  GetAttributeDetail,
  editLoans,
  DownloadLMSData,
} from "../../../servies/services";
import { Height } from "@material-ui/icons";
import credibly from "../../../images/credibly.pdf";
import $ from "jquery";
// import { Document, Page, Image } from "@react-pdf/renderer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Search, SearchOffOutlined } from "@mui/icons-material";

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const token = localStorage.getItem("token");
const Userrole = localStorage.getItem("userrole");

function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
    var res = str.substring(2, str.length);
    var rep = res.replace(/[a-z]/gi, "x");
    return str.replace(res, rep);
  }

  return str;
}

class DDReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open1: false,
      loading: true,
      getLoansLoader: false,
      open: false,
      openPopup: false,
      message: null,
      popupLoader: false,
      msgData: null,
      open2: false,
      open4: false,
      searchText: "",
      rowsSelected: null,
      selectedrows: null,
      tableData: [],
      exportdata: [],
      duplicateobject: [],
      loanData: [],
      formLoader: false,
      file1: "",
      filename1: "",
      open3: false,
      certifyStatus: false,
      CertiFyData: [],
      Sort: [],
      exportdataxl: [],
      SortAddress: [],
      SortSignatory: [],
      edited_loanid: "",
      isMatched: [],
      match: null,
      duplicateobject1: [],
      columns: [],
      tableHeight: 600,
      selectAllChecked: false,
      selectedrows: [],
      poolid: "",
      poolname: "",
      lmsfile: "",
      contractfile: "",
      showSearchBox: false,
      filteredData: [], //to store filtered data
      scrollTop: 0
    };

    this.childHandler = this.childHandler.bind(this);
  }

  // {loanId: "Frankie Long", poolname: "Industrial Analyst", matched: 1, status: "Digitised"},
  //     {loanId: "Brynn Robbins", poolname: "Business Analyst", matched: 0, status: "Reviewd"},
  //     {loanId: "Justice Mann", poolname: "Business Consultant", matched: 1, status: "Uploaded"},



  childHandler(dataFromChild) {
    console.log(
      "dataFromChilddataFromChilddataFromChilddataFromChilddataFromChild",
      dataFromChild
    );
    if (dataFromChild.close == true) {
      this.setState({ open2: false });
    }

    if (dataFromChild.reload == true) {
      this.editLoans();
      // window.location.reload();
    }

    // const idToRemove = dataFromChild.loanContractNumber;
    // const filteredPeople = this.state.tableData.filter((item) => item.lmsLoan.agreementid === idToRemove);
    // filteredPeople[0].matched = 1;
    // console.log('filteredPeople', filteredPeople);
  }
  searchBar = () => (
    <div className="search-bar-main-container" id="searchBox">
      <div className="tableSearch1">
        <TextField
        autoFocus
          value={this.state.searchText}
          onChange={this.onchange}
          placeholder="Search"
          color="#212121"
          variant="standard"
          size="small"
          
        />
        {/* {this.state.searchTextDashboard.length !== 0 ? <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchTextDashboard: '' })} /> : ''} */}
      </div>
      <button
        type="button"
        onClick={this.onClickCloseSearchBar}
        className="close-mui-icon"
      >
        <CloseIcon />
      </button>
    </div>
  );

  onClickCloseSearchBar = () => {
    const { tableData } = this.state;

    this.setState({
      showSearchBox: false,
      searchText: "", // Clear the search term when closing the search field
      tableData: tableData, // Display the whole tableData
      tableHeight: 600,
    });
  };

  onClickSearchButton = () => {
    this.setState({ showSearchBox: true });
  };

  onOpenModal3 = () => {
    console.log("inside modal");
    this.setState({ open3: true });

    this.GetAllMessagesByDealName();
  };
  onCloseModal3 = () => {
    this.setState({ open3: false });
  };
  onOpenModal1 = () => {
    console.log("inside modal");
    this.setState({ open1: true });
  };
  onCloseModal1 = () => {
    this.setState({ msgData: null });
    this.setState({ open1: false });
    this.setState({ open3: false });
  };

  onYes = () => {
    this.setState({ open1: false });
    // this.setState({ popupLoader: true, open2: true });
    this.finalsave();
  };

  onOpenModal2 = () => {
    console.log("inside modal");
    this.setState({ open2: true });
  };
  onCloseModal2 = () => {
    this.setState({ open2: false });
  };

  onSubmit = () => {
    console.log("Saving", this.state.selectedrows);
    let flag = 0;
    for (var i = 0; i < this.state.selectedrows.length; i++) {
      var j = this.state.selectedrows[i];
      let loanId = this.state.tableData[j];
      console.log("loanid---> ", loanId.matched);
      if (loanId.matched == 0) {
        flag = 1;
        break;
      }
    }
    if (flag == 1) {
      this.onOpenModal1();
    } else {
      this.finalsave();
    }
    this.onOpenModal3();
  };

  finalsave = () => {
    console.log("the final save");
  };

  rowcancel = () => {
    this.setState({ rowsSelected: null });
  };

  onCancel = () => {
    const { data } = this.props;
    this.setState({
      ...data,
    });
  };

  Export = async () => {
    console.log("this.state.searchtest", this.state.searchText);

    if (this.state.searchText === "") {
      let exportdata = [];
      console.log("tabledata", this.state.tableData);

      this.state.tableData.map((data, index) => {
        let dupjson = {};
        // 'Deal Id': data.lmsloan["Deal Id"],
        // 'Agreement Id': data.lmsloan["Agreement/Account #"],
        // 'Merchant Legal Name': data.lmsloan["Merchant Legal Name"],
        // 'Funded Amount': data.lmsloan["Principal Loan Amount/Purchase Price"],
        // 'Receivables File Received': data.attributewise_matched[0] === 1 ? 'YES' : 'NO',
        // 'Merchant Agreement Number': data.attributewise_matched[1] === 1 ? 'YES' : 'NO',
        // 'Name of the related Merchant': data.attributewise_matched[2] === 1 ? 'YES' : 'NO',
        // 'Funded Amount ': data.attributewise_matched[3] === 1 ? 'YES' : 'NO',
        // 'Exceptions': data.matched === 1 ? 'NO' : 'YES'

        Object.keys(data.lmsloan).map((value) => {
          dupjson[value] = data.lmsloan[value];
          // dupjson[value+' ']=data.attributewise_matched[value] === 1 ? 'YES' : 'NO'
        });
        console.log("datanumber of pages", typeof data.NumberofPagesProcessed);
        let ababa1 = "Number of pages processed";
        dupjson[ababa1] = data.NumberofPagesProcessed;

        Object.keys(data.lmsloan).map((value) => {
          // dupjson[value]=data.lmsloan[value]
          dupjson[value + " "] =
            data.attributewise_matched[value] === 1 ? "YES" : "NO";
        });
        // dupjson.lmsloan=data.lmsloan
        // dupjson.attributewise_matched=Object.keys(data.attributewise_matched).map((value) =>{return {[value]:data.attributewise_matched[value] === 1 ? 'YES' : 'NO'}})
        dupjson["Exceptions"] = data.matched === 1 ? "NO" : "YES";
        exportdata.push(dupjson);
      });

      console.log("exportdata", exportdata);

      // console.log("abcdef",exportdata[0].lmsloan)

      // let newArr = [{}]

      this.setState({ exportdata: exportdata });

      console.log("exportdata1234", exportdata);

      const message = "Record Fetched Successfully";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });

      let poolname = localStorage.getItem("runddpoolname");
      let poolid = localStorage.getItem("runddpoolid");
      let data = {
        data: exportdata,
        poolname: poolname,
        poolid: poolid,
      };
      console.log("exportdata", data);

      const res = await ExportExcel(data, token);
      console.log("ExportExcel response", res);

      if (res.status === 200) {
        console.log("ExportExcel Success");

        const file_name = poolname + poolid + ".xlsx";
        startDownload(res.data, file_name);
        function startDownload(file, fileName) {
          const url = window.URL.createObjectURL(new Blob([file]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }

        // if(res.data.isSuccess == true) {
        // let filepath = process.env.react_app_base_url + 'backendapiumb/downloadfile?filename='+res.data.filename
        // // // let filepath = "http://localhost:3000/" + res.data.filename
        // downloadFile(filepath);
        // const resp = await DownloadFile(res.data.filename ,token)
        // console.log("downloadfile response",resp)
      } else {
        if (res.status === 204) {
          console.log("ExportExcel 204");
        } else {
          console.log("ExportExcel notSuccess");
        }
      }
    } else {
      let exportdata = [];
      console.log(this.state.datefilter);
      this.state.datefilter.map((data, index) => {
        console.log("datatattat", data.lmsloan["Deal Id"]);
        console.log("datefilter", this.state.datefilter);
        let dupjson = {};

        Object.keys(data.lmsloan).map((value) => {
          dupjson[value] = data.lmsloan[value];
          // dupjson[value+' ']=data.attributewise_matched[value] === 1 ? 'YES' : 'NO'
        });

        Object.keys(data.lmsloan).map((value) => {
          // dupjson[value]=data.lmsloan[value]
          dupjson[value + " "] =
            data.attributewise_matched[value] === 1 ? "YES" : "NO";
        });
        // dupjson.lmsloan=data.lmsloan
        // dupjson.attributewise_matched=Object.keys(data.attributewise_matched).map((value) =>{return {[value]:data.attributewise_matched[value] === 1 ? 'YES' : 'NO'}})
        dupjson["Exceptions"] = data.matched === 1 ? "NO" : "YES";
        exportdata.push(dupjson);
      });

      this.setState({ exportdata: exportdata });

      console.log("exportdata1234", exportdata);

      const message = "Record Fetched Successfully";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });

      let poolname = localStorage.getItem("runddpoolname");
      let poolid = localStorage.getItem("runddpoolid");
      let data = {
        data: exportdata,
        poolname: poolname,
        poolid: poolid,
      };
      console.log("exportdata", data);

      const res = await ExportExcel(data, token);
      console.log("ExportExcel response", res);

      if (res.status === 200) {
        console.log("ExportExcel Success");

        const file_name = poolname + poolid + ".xlsx";
        startDownload(res.data, file_name);
        function startDownload(file, fileName) {
          const url = window.URL.createObjectURL(new Blob([file]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }

        // if(res.data.isSuccess == true) {
        // let filepath = process.env.react_app_base_url + 'backendapiumb/downloadfile?filename='+res.data.filename
        // // // let filepath = "http://localhost:3000/" + res.data.filename
        // downloadFile(filepath);
        // const resp = await DownloadFile(res.data.filename ,token)
        // console.log("downloadfile response",resp)
      } else {
        if (res.status === 204) {
          console.log("ExportExcel 204");
        } else {
          console.log("ExportExcel notSuccess");
        }
      }
    }

    // function downloadFile(urlToSend) {
    // var req = new XMLHttpRequest();
    // req.open("GET", urlToSend, true);
    // req.setRequestHeader("authorization",`Bearer ${token}`)
    // req.responseType = "blob";
    // req.onload = function (event) {
    // var blob = req.response;
    // var fileName = res.data.filename //if you have the fileName header available
    // var link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = fileName;
    // link.click();
    // };

    // req.send();
    // }
  };

  // Export = async () => {
  //   console.log("this.state.searchtest", this.state.searchText);

  //   if (this.state.searchText === "") {
  //     let exportdata = [];
  //     console.log("tabledata", this.state.tableData);

  //     this.state.tableData.map((data, index) => {
  //       let dupjson = {};
  //       // 'Deal Id': data.lmsloan["Deal Id"],
  //       // 'Agreement Id': data.lmsloan["Agreement/Account #"],
  //       // 'Merchant Legal Name': data.lmsloan["Merchant Legal Name"],
  //       // 'Funded Amount': data.lmsloan["Principal Loan Amount/Purchase Price"],
  //       // 'Receivables File Received': data.attributewise_matched[0] === 1 ? 'YES' : 'NO',
  //       // 'Merchant Agreement Number': data.attributewise_matched[1] === 1 ? 'YES' : 'NO',
  //       // 'Name of the related Merchant': data.attributewise_matched[2] === 1 ? 'YES' : 'NO',
  //       // 'Funded Amount ': data.attributewise_matched[3] === 1 ? 'YES' : 'NO',
  //       // 'Exceptions': data.matched === 1 ? 'NO' : 'YES'

  //       Object.keys(data.lmsloan).map((value) => {
  //         dupjson[value] = data.lmsloan[value];
  //         // dupjson[value+' ']=data.attributewise_matched[value] === 1 ? 'YES' : 'NO'
  //       });
  //       console.log("datanumber of pages", typeof data.NumberofPagesProcessed);
  //       let ababa1 = "Number of pages processed";
  //       dupjson[ababa1] = data.NumberofPagesProcessed;

  //       Object.keys(data.lmsloan).map((value) => {
  //         // dupjson[value]=data.lmsloan[value]
  //         dupjson[value + " "] =
  //           data.attributewise_matched[value] === 1 ? "YES" : "NO";
  //       });
  //       // dupjson.lmsloan=data.lmsloan
  //       // dupjson.attributewise_matched=Object.keys(data.attributewise_matched).map((value) =>{return {[value]:data.attributewise_matched[value] === 1 ? 'YES' : 'NO'}})
  //       dupjson["Exceptions"] = data.matched === 1 ? "NO" : "YES";
  //       exportdata.push(dupjson);
  //     });

  //     console.log("exportdata", exportdata);

  //     // console.log("abcdef",exportdata[0].lmsloan)

  //     // let newArr = [{}]

  //     this.setState({ exportdata: exportdata });

  //     console.log("exportdata1234", exportdata);

  //     // const message = "Record Fetched Successfully";
  //     // this.props.enqueueSnackbar(message, {
  //     //   variant: "info",
  //     //   autoHideDuration: 3000,
  //     // });
  //     var arr = JSON.parse(localStorage.getItem("dealids"));

  //     let poolname = localStorage.getItem("runddpoolname");
  //     let poolid = localStorage.getItem("runddpoolid");
  //     let data = {
  //       dealid: arr,
  //       // data: exportdata,
  //       poolname: poolname,
  //       poolid: poolid,
  //     };
  //     console.log("exportdata", data);

  //     const res = await ExportExcel(data, token);
  //     console.log("ExportExcel response", res);

  //     if (res.status === 200) {
  //       console.log("ExportExcel Success");
  //       const message = "Record Fetched Successfully";
  //       this.props.enqueueSnackbar(message, {
  //         variant: "info",
  //         autoHideDuration: 3000,
  //       });

  //       const file_name = poolname + poolid + ".xlsx";
  //       startDownload(res.data, file_name);
  //       function startDownload(file, fileName) {
  //         const url = window.URL.createObjectURL(new Blob([file]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", fileName);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.parentNode.removeChild(link);
  //       }

  //       // if(res.data.isSuccess == true) {
  //       // let filepath = process.env.react_app_base_url + 'backendapiumb/downloadfile?filename='+res.data.filename
  //       // // // let filepath = "http://localhost:3000/" + res.data.filename
  //       // downloadFile(filepath);
  //       // const resp = await DownloadFile(res.data.filename ,token)
  //       // console.log("downloadfile response",resp)
  //     } else {
  //       if (res.status === 204) {
  //         console.log("ExportExcel 204");
  //       } else {
  //         console.log("ExportExcel notSuccess");
  //       }
  //     }
  //   } else {
  //     let exportdata = [];
  //     console.log(this.state.datefilter);
  //     this.state.datefilter.map((data, index) => {
  //       console.log("datatattat", data.lmsloan["Deal Id"]);
  //       console.log("datefilter", this.state.datefilter);
  //       let dupjson = {};

  //       Object.keys(data.lmsloan).map((value) => {
  //         dupjson[value] = data.lmsloan[value];
  //         // dupjson[value+' ']=data.attributewise_matched[value] === 1 ? 'YES' : 'NO'
  //       });

  //       Object.keys(data.lmsloan).map((value) => {
  //         // dupjson[value]=data.lmsloan[value]
  //         dupjson[value + " "] =
  //           data.attributewise_matched[value] === 1 ? "YES" : "NO";
  //       });
  //       // dupjson.lmsloan=data.lmsloan
  //       // dupjson.attributewise_matched=Object.keys(data.attributewise_matched).map((value) =>{return {[value]:data.attributewise_matched[value] === 1 ? 'YES' : 'NO'}})
  //       dupjson["Exceptions"] = data.matched === 1 ? "NO" : "YES";
  //       exportdata.push(dupjson);
  //     });

  //     this.setState({ exportdata: exportdata });

  //     console.log("exportdata1234", exportdata);

  //     const message = "Record Fetched Successfully";
  //     this.props.enqueueSnackbar(message, {
  //       variant: "info",
  //       autoHideDuration: 3000,
  //     });
  //     var arr = JSON.parse(localStorage.getItem("rundd"));

  //     let poolname = localStorage.getItem("runddpoolname");
  //     let poolid = localStorage.getItem("poolid");
  //     let data = {
  //       dealid: arr,

  //       // data: exportdata,
  //       poolname: poolname,
  //       poolid: poolid,
  //     };
  //     console.log("exportdata", data);

  //     const res = await ExportExcel(data, token);
  //     console.log("ExportExcel response", res);

  //     if (res.status === 200) {
  //       console.log("ExportExcel Success");
  //       const message = "Record Fetched Successfully";
  //       this.props.enqueueSnackbar(message, {
  //         variant: "info",
  //         autoHideDuration: 3000,
  //       });
  //       const file_name = poolname + poolid + ".xlsx";
  //       startDownload(res.data, file_name);
  //       function startDownload(file, fileName) {
  //         const url = window.URL.createObjectURL(new Blob([file]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", fileName);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.parentNode.removeChild(link);
  //       }

  //       // if(res.data.isSuccess == true) {
  //       // let filepath = process.env.react_app_base_url + 'backendapiumb/downloadfile?filename='+res.data.filename
  //       // // // let filepath = "http://localhost:3000/" + res.data.filename
  //       // downloadFile(filepath);
  //       // const resp = await DownloadFile(res.data.filename ,token)
  //       // console.log("downloadfile response",resp)
  //     } else {
  //       if (res.status === 204) {
  //         console.log("ExportExcel 204");
  //       } else {
  //         console.log("ExportExcel notSuccess");
  //       }
  //     }
  //   }

  //   // function downloadFile(urlToSend) {
  //   // var req = new XMLHttpRequest();
  //   // req.open("GET", urlToSend, true);
  //   // req.setRequestHeader("authorization",`Bearer ${token}`)
  //   // req.responseType = "blob";
  //   // req.onload = function (event) {
  //   // var blob = req.response;
  //   // var fileName = res.data.filename //if you have the fileName header available
  //   // var link = document.createElement('a');
  //   // link.href = window.URL.createObjectURL(blob);
  //   // link.download = fileName;
  //   // link.click();
  //   // };

  //   // req.send();
  //   // }
  // };

  // async createPool(data) {
  //   this.setState({ getLoansLoader: true });

  //   const dataString = data;
  //   dataString.peers = JSON.parse(localStorage.getItem("peers"))
  //   dataString.token = localStorage.getItem("token")
  //   console.log("createPool", dataString);

  //   try {
  //     const res = await axios.post(process.env.react_app_base_url + 'api/V1/portfolio/poolcreation', dataString);
  //     const resp = res.data;
  //     if (res.status === 200) {

  //       this.setState({ getLoansLoader: false, openPopup: true, message: resp.message, loading: true });
  //       if (resp.isSuccess === true) {
  //         // alert("success");
  //         const poolID = resp.poolId;
  //         this.props.history.push({
  //           pathname: '/portfolio-setup-wizard/pool-details/' + poolID
  //         })

  //         const message = "Pool Created Succefully";
  //         this.props.enqueueSnackbar(message, {
  //           variant: 'info',
  //           autoHideDuration: 3000,
  //         });

  //       }

  //       else {

  //       }

  //     } else {
  //       alert("Failed");
  //     }
  //   } catch (error) {
  //     // alert("Failed");
  //     this.setState({ getLoansLoader: false });
  //     if (error.response.status == 400) {

  //       console.log("getLoans API error", error);
  //       console.log("error.response", error.response.data.message);

  //       const message = error.response.data.message;
  //       this.props.enqueueSnackbar(message, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       });

  //     } else {

  //       const message = "something went wrong, please try again";
  //       this.props.enqueueSnackbar(message, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       });

  //     }
  //   }

  // };

  // GetAttributeDetail = async (token,xyz) =>{
  //   const data = localStorage.getItem('runddpoolid');
  //   const APIResponse = await GetAttributeDetail(data,token)

  //   if (APIResponse.status === 200) {
  //     console.log('Allpools--', APIResponse);
  //     this.setState({getLoansLoader: false, open: true, resss1: APIResponse.data});

  //   let display = []
  //   console.log('xyzzzz',xyz)
  //   APIResponse.data.map((value) =>{
  //     if(value.attributeDisplay){
  //       Object.keys(xyz[0].res.data[0].lmsloan).map((val) =>{
  //         if(value.attributeName.toLocaleLowerCase().replace(/ /g, "") === val.toLocaleLowerCase()){
  //           console.log('valll',val)
  //           console.log('ress2',xyz[0].res.data[0].lmsloan[val])
  //           // display.push(value.attributeName)
  //           var column = {
  //           name: 'lmsloan',
  //           label: value.attributeName,
  //           options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //               // console.log('values',value[val])
  //               return (
  //                 <React.Fragment>
  //                   {value[val]}
  //                 </React.Fragment>
  //               );
  //             },
  //           }
  //         }
  //         display.push(column)
  //      }
  //   })
  // }})

  //   const restdata=[

  //     {

  //               name: "date",
  //               label: 'date',
  //               options: {
  //                 filter: true,
  //                 sort: true,
  //                 display: "exclude"
  //                 // customBodyRender: (value, tableMeta, updateValue) => {
  //                 // return (
  //                 // <React.Fragment>
  //                 // {value.merchantlegalname}
  //                 // </React.Fragment>
  //                 // );
  //                 // },

  //               }
  //             },
  //     {
  //               // 11
  //               name: "matched",
  //               label: 'Match / Mismatch',
  //               options: {
  //                 filter: false,
  //                 sort: false,
  //                 empty: true,
  //                 customHeadRender: (columnMeta, updateDirection) => (
  //                   <th style={{textAlign:'center',fontSize:'0.875rem'}}>
  //                       {columnMeta.label}
  //                   </th>
  //                 ),
  //                 customBodyRender: (value, tableMeta, updateValue) => {
  //                   return (
  //                     <React.Fragment>
  //                       {/* {JSON.stringify(value)} */}
  //                       <div className="mismatchbox text-center">  {value === 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
  //                     </React.Fragment>
  //                   );
  //                 },
  //               }
  //             },

  //             {
  //               // 12
  //               name: "lmsloan",
  //               label: 'Action',
  //               options: {
  //                 filter: false,
  //                 sort: false,
  //                 display: Userrole == "Processor"? true:'exclude',
  //                 empty: true,
  //                 customBodyRender: (value, tableMeta, updateValue) => {
  //                   return (
  //                     <React.Fragment>
  //                       <div className="">

  //                           <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value.dealid,tableMeta.rowData[4])} >
  //                            REVIEW   </Button>

  //                       </div>
  //                     </React.Fragment>
  //                   );
  //                 },
  //               }
  //             },
  //   ]
  //   restdata.map((json)=>{
  //     display.push(json)
  //   })
  //   console.log("display",display)
  //   this.setState({columns:display})

  //   const message = "Record Fetched Successfully";
  //   this.props.enqueueSnackbar(message, {
  //     variant: 'info',
  //     autoHideDuration: 3000,
  //   });
  //   } else {
  //     alert('Failed');
  //   }
  // }

  async componentDidMount() {
    if (
      localStorage.getItem("token") == "" ||
      localStorage.getItem("token") == undefined
    ) {
      window.location.assign("/");
    }
    const pooldataname = localStorage.getItem("runddpoolname");
    const pooldataid = localStorage.getItem("poolid");

    this.setState({ poolName: pooldataname, poolId: pooldataid });
    // if (localStorage.getItem("rundd") !== null) {

    //   const data = localStorage.getItem('rundd');
    //   // this.getLoans(data);

    // } else {
    //   alert('Please select the parameters');
    //   window.location.assign('/processor/dashboard');

    // }
    // let arr=[]
    var userrole = localStorage.getItem("userrole");

    if (userrole == "Processor") {
      var arr = JSON.parse(localStorage.getItem("rundd"));
      var arr1 = localStorage.getItem("runddpoolid");
      var arr2 = localStorage.getItem("runddpoolname");
      console.log(arr);
      console.log(arr1);

      // var data = { dealidlist: arr, poolid: arr1, poolname: arr2 };
      var data = { poolid: arr1 };

      // var formData = new FormData();
      // formData.append("dealidlist", arr);
      console.log("data::::::", data);
      this.getLoans(data);
      // this.GetAttributeDetail(token)
    } else {
      var arr = JSON.parse(localStorage.getItem("rundd"));
      var arr1 = localStorage.getItem("runddpoolid");
      var arr2 = localStorage.getItem("runddpoolname");
      console.log(arr);
      console.log(arr1);
      // var data = { dealidlist: arr, poolid: arr1, poolname: arr2 };
      var data = { poolid: arr1 };

      console.log("data::::::", data);
      this.getLoans(data);
      // this.GetAttributeDetail(token)
    }

    // console.log('resss1',getAPIData)
    //  let resss1=this.state.resss1
  }

  async archiveclick(value, match) {
    console.log("archive::" + value + "----" + match);
  }
  // async handleClick(value, loandocpath) {
  //   console.log("helloo")
  //   // const staticDealId = 1
  //   // const staticLoanDocPath = credibly

  //   this.setState({ msgData: value });
  //   console.log("handle::", {
  //     value,
  //     loandocpath,
  //     loanData: this.state.loanData,
  //   });
  //   let result = this.state.loanData.res.data.filter(
  //     (e) => e["Deal Id"] === value
  //   )[0];
  //   console.log(result,"resulltttt")
  //   sessionStorage.setItem(
  //     "activeLoanData",
  //     JSON.stringify({
  //       // ...result, loandocpath: staticLoanDocPath
  //       ...result,
  //       loandocpath,
  //     })
  //   );
  //   // if(match == 0 ){
  //   //   this.onOpenModal1()
  //   // }
  //   // else{
  //   this.setState({ popupLoader: true, open2: true, edited_loanid: value });
  //   console.log("111", value);
  //   // }
  // }

  async archiveclick(value, match) {
    console.log("archive::" + value + "----" + match);
  }
  async handleClick(value, loandocpath) {
    this.setState({ msgData: value,showSearchBox:false,searchText:'' });
    console.log("handle::", {
      value,
      loandocpath,
      loanData: this.state.loanData,
    });

    // Ensure this.state.loanData is an array, or initialize it as an empty array
    const loanDataArray = Array.isArray(this.state.loanData)
      ? this.state.loanData
      : [];

    let result = loanDataArray.filter((e) => e["Deal Id"] === value)[0];
    console.log(result, "resulltttt");
    sessionStorage.setItem(
      "activeLoanData",
      JSON.stringify({
        ...result,
        loandocpath,
      })
    );

    this.setState({ popupLoader: true, open2: true, edited_loanid: value });
    console.log("111", value);
  }
  handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    const allSelectedIndices = Array.from(
      { length: this.state.tableData.length },
      (_, i) => i
    );

    this.setState(
      {
        selectAllChecked: isChecked,
        selectedrows: isChecked ? allSelectedIndices : [],
      },
      () => {
        if (isChecked) {
          const allDealIds = this.state.tableData.map((row) => row["Deal Id"]);
          this.selectedpoolid(allSelectedIndices);
          this.setState({ selectedRecords: allDealIds }, () => {
            localStorage.setItem("rundd", JSON.stringify(allDealIds));
          });
        } else {
          this.selectedpoolid([]);
          this.setState({ selectedRecords: [] }, () => {
            localStorage.removeItem("rundd");
          });
        }
      }
    );
  };

  handleSelectRowChange = (event, rowIndex) => {
    const isChecked = event.target.checked;
    const selectedRows = [...this.state.selectedrows];

    if (isChecked) {
      selectedRows.push(rowIndex);
    } else {
      const indexToRemove = selectedRows.indexOf(rowIndex);
      if (indexToRemove !== -1) {
        selectedRows.splice(indexToRemove, 1);
      }
    }

    const selectedDealIds = selectedRows.map(
      (rowIndex) => this.state.tableData[rowIndex]["Deal Id"]
    );

    this.setState({
      selectedrows: selectedRows,
      selectedRecords: selectedDealIds,
    });

    localStorage.setItem("rundd", JSON.stringify(selectedDealIds));
  };
  async getLoans(data) {
    this.setState({ getLoansLoader: true });

    // const dataString = {
    //   "input": data
    // }

    console.log("getLoans dataString", data);

    // try {
    const response = await compareData(data);
    // let response = { status: 200 }
    console.log("responses", response);

    if (response.status === 200) {
      const arr = localStorage.getItem("runddpoolid");

      const resp = response.data;
      // const resp = testHighlights
      // this.setState({ loanData: response.data });
      this.setState({
        poolid: resp.res.display_values.poolid,
        poolname: resp.res.display_values.poolname,
        lmsfile: resp.res.display_values.lms_filename,
        contractfile: resp.res.display_values.contract_filename,
      });

      this.setState({ loanData: resp.res.data[0][arr].data });

      console.log("eligibleloans", resp);
      console.log("eligibleloanslength", resp.res);
      if (resp.res.data.length > 0) {
        let arr = localStorage.getItem("runddpoolid");
        let xyz = resp.res.data[0][arr].data.sort(
          (a, b) => parseFloat(a.matched) - parseFloat(b.matched)
        );
        this.setState({
          getLoansLoader: false,
          open: true,
          tableData: resp.res.data[0][arr].data.sort(
            (a, b) => parseFloat(a.matched) - parseFloat(b.matched)
          ),
          loading: true,
        });

        const data = localStorage.getItem("runddpoolid");
        const APIResponse = await GetAttributeDetail(data, token);
        // const APIResponse = attributeDetails
        console.log("ssssssssss", APIResponse);
        if (APIResponse.status === 200) {
          if (APIResponse.data.length > 0) {
            this.setState({
              getLoansLoader: false,
              open: true,
              resss1: APIResponse.data,
              loading: true,
            });
            let display = [];
            APIResponse.data.map((value, h) => {
              Object.keys(xyz && xyz[0].lmsloan).map((val) => {
                if (
                  value.attributeName.toLocaleLowerCase().replace(/ /g, "") ===
                  val.toLocaleLowerCase().replace(/ /g, "")
                ) {
                  // console.log('ress2',xyz[0].res.data[0].lmsloan[val])
                  // display.push(value.attributeName)
                  console.log("valll", { val, value, xyz, APIResponse });
                  var column = {
                    name: "lmsloan",
                    label: value.attributeName,
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value, tableMeta, updateValue) => {
                        console.log("values", value[val]);
                        return <React.Fragment>{value[val]}</React.Fragment>;
                      },
                    },
                  };
                  display.push(column);
                }
              });
            });

            const restdata = [
              // {
              //   name: "date",
              //   label: "date",
              //   options: {
              //     filter: true,
              //     sort: true,
              //     display: "exclude",
              //     // customBodyRender: (value, tableMeta, updateValue) => {
              //     // return (
              //     // <React.Fragment>
              //     // {value.merchantlegalname}
              //     // </React.Fragment>
              //     // );
              //     // },
              //   },
              // },
              {
                name: "NumberofPagesProcessed",
                label: "Pages Processed",
                // width:20,
                options: {
                  filter: true,
                  sort: true,
                  // customHeadRender: (columnMeta, updateDirection) => (
                  //   <th style={{ textAlign: 'center', fontSize: '0.875rem' }}>
                  //     {columnMeta.label}
                  //   </th>
                  // ),
                  // customBodyRender: (value, tableMeta, updateValue) => {
                  //   return (
                  //     <React.Fragment>
                  //       {/* {JSON.stringify(value)} */}
                  //       <div className="mismatchbox text-center">  {value}</div>
                  //     </React.Fragment>
                  //   );
                  // },
                },
              },
              {
                // 11
                name: "matched",
                label: "Match / Mismatch",
                //  width:20,
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customHeadRender: (columnMeta, updateDirection) => (
                    <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
                      {columnMeta.label}
                    </th>
                  ),
                  customBodyRender: (value, tableMeta, updateValue) => {
                    console.log("Match / Mismatch", value);

                    return (
                      <React.Fragment>
                        {/* {JSON.stringify(value)} */}
                        <div className="mismatchbox text-center">
                          {" "}
                          {value === 1 ? (
                            <img alt="" src={Match} />
                          ) : (
                            <img alt="" src={Mismatch} />
                          )}
                        </div>
                      </React.Fragment>
                    );
                  },
                },
              },

              {
                // 12
                name: "agreementloan",
                label: "Action",

                // width:20,
                options: {
                  filter: false,
                  sort: false,
                  display: Userrole == "Processor" ? true : "exclude",
                  empty: true,
                  customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                      // <React.Fragment>
                      //   <div className="">
                      //     <Button
                      //       variant="outlined"
                      //       id="optionalbutton"
                      //       type="submit"
                      //       onClick={() =>
                      //         this.handleClick(
                      //           value["Deal Id"] ||
                      //             value["APP_ID"] ||
                      //             value["Unique Identifier / Filename"] ||
                      //             value["Contract #"] ||
                      //             value["Unique Identifier"] ||
                      //             value["DOCUMENT_ID"] ||
                      //             value[
                      //               "Identifier (9 digit unique identifier)"
                      //             ] ||
                      //             value["Document ID"],
                      //           tableMeta.rowData.filter(
                      //             (e) =>
                      //               typeof e === "string" && e.includes(".pdf")
                      //           )[0]
                      //         )
                      //       }
                      //     >
                      //       REVIEW{" "}
                      //     </Button>
                      //   </div>
                      // </React.Fragment>
                      <React.Fragment>
                        <div className="">
                          <Button
                            variant="outlined"
                            id="optionalbutton"
                            type="submit"
                            onClick={() =>
                              this.handleClick(
                                value["Deal Id"] ||
                                  value["APP_ID"] ||
                                  value["Unique Identifier / Filename"] ||
                                  value["Contract #"] ||
                                  value["Unique Identifier"] ||
                                  value["DOCUMENT_ID"] ||
                                  value[
                                    "Identifier (9 digit unique identifier)"
                                  ] ||
                                  value["Document ID"],
                                tableMeta.rowData.filter(
                                  (e) =>
                                    (typeof e === "string" &&
                                      e.includes(".pdf")) ||
                                    (typeof e === "string" &&
                                      e.toLowerCase().endsWith(".pdf"))
                                )[0]
                              )
                            }
                          >
                            REVIEW
                          </Button>
                        </div>
                      </React.Fragment>
                    );
                  },
                },
              },

              // {
              //   // 12
              //   name: "loandocpath",
              //   label: "loandocpath",
              //   options: {
              //     display: false,
              //     customBodyRender: (value, tableMeta, updateValue) => {
              //       return (
              //         <React.Fragment>
              //           <div className="">{value}</div>
              //         </React.Fragment>
              //       );
              //     },
              //   },
              // },
            ];

            restdata.map((json) => {
              display.push(json);
            });
            console.log("display", display);
            this.setState({ columns: display });

            const message = "Record Fetched Successfully";
            this.props.enqueueSnackbar(message, {
              variant: "info",
              autoHideDuration: 3000,
            });
          }
        } else if (APIResponse.data.isSuccess === false) {
          this.setState({ getLoansLoader: false, open: true, loading: false });
          const message = APIResponse.data.message;
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 3000,
          });
          this.props.history.push({
            pathname: "/processor/dashboard",
          });
        }

        const obj =  resp.res.data[0][arr].data.filter(
          (tag, index, array) =>
            array.findIndex((t) => t.date == tag.date) == index
        );
        console.log("uniquenames", obj);
        this.setState({ duplicateobject: obj });
        console.log(
          "this.state.tableData.lmsLoan",
          this.state.tableData.lmsLoan
        );
        console.log("this.state.tableData", this.state.tableData);
        console.log("this.state.tableData", this.state.tableData[0]?.lmsLoan);
      } else if (response.status === 201) {
        this.setState({ getLoansLoader: false, open: true, loading: false });
        const message = resp.res.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } else if (response.status === 201) {
      this.setState({ getLoansLoader: false, open: true, loading: false });
      const message = response.data.res.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ getLoansLoader: false, open: true, loading: true });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    // } catch (error) {
    //   alert('Failed');
    //   console.log('getLoans API error', error);
    // }
  }


  // async getLoans(data) {
  //   this.setState({ getLoansLoader: true });

  //   // const dataString = {
  //   //   "input": data
  //   // }

  //   console.log("getLoans dataString", data);

  //   const response = await compareData(data);

  //   if (response.status === 200) {
  //     const arr = localStorage.getItem("runddpoolid");

  //     const resp = response.data;

  //     this.setState({
  //       poolid: resp.res.display_values.poolid,
  //       poolname: resp.res.display_values.poolname,
  //       lmsfile: resp.res.display_values.lms_filename,
  //       contractfile: resp.res.display_values.contract_filename,
  //     });

  //     // const resp = testHighlights
  //     this.setState({ loanData: resp.res.data[0][arr].data });

  //     localStorage.setItem(
  //       "dealids",
  //       JSON.stringify(resp.res.data[0][arr].dealids)
  //     );

  //     if (resp.res.data.length > 0) {
  //       let arr = localStorage.getItem("runddpoolid");

  //       let xyz = resp.res.data[0][arr].data.sort(
  //         (a, b) => parseFloat(a.matched) - parseFloat(b.matched)
  //       );
  //       console.log("xyz", Object.keys(xyz[0]));
  //       this.setState({
  //         getLoansLoader: false,
  //         open: true,
  //         tableData: resp.res.data[0][arr].data.sort(
  //           (a, b) => parseFloat(a.matched) - parseFloat(b.matched)
  //         ),
  //         loading: true,
  //       });

  //       const data = localStorage.getItem("runddpoolid");
  //       const APIResponse = await GetAttributeDetail(data, token);
  //       // const APIResponse = attributeDetails
  //       console.log("ssssssssss", APIResponse);
  //       console.log("aaaa", APIResponse.data);
  //       if (APIResponse.status === 200) {
  //         if (APIResponse.data.length > 0) {
  //           this.setState({
  //             getLoansLoader: false,
  //             open: true,
  //             resss1: APIResponse.data,
  //             loading: true,
  //           });
  //           let display = [];
  //           // if (
  //           //   APIResponse &&
  //           //   APIResponse.data &&
  //           //   APIResponse.data.result &&
  //           //   xyz &&
  //           //   xyz[0] &&
  //           //   xyz[0].lmsloan
  //           // )

  //           APIResponse.data.map((value, h) => {
  //             Object.keys(xyz[0].lmsloan).map((val) => {
  //               if (
  //                 value.attributeName.toLocaleLowerCase().replace(/ /g, "") ===
  //                 val.toLocaleLowerCase().replace(/ /g, "")
  //               ) {
  //                 console.log("valll", { val, value, xyz, APIResponse });
  //                 var column = {
  //                   name: "lmsloan",
  //                   label: value.attributeName,
  //                   width: 18,
  //                   options: {
  //                     filter: true,
  //                     sort: true,
  //                     customBodyRender: (value, tableMeta, updateValue) => {
  //                       console.log("values", value[val]);
  //                       return <React.Fragment>{value[val]}</React.Fragment>;
  //                     },
  //                   },
  //                 };
  //                 display.push(column);
  //               }
  //             });
  //           });

  //           // APIResponse.data.result.map((value, h) => {
  //           //   Object.keys(xyz && xyz[0].lmsloan).map((val) => {
  //           //     if (
  //           //       value.attributeName.toLocaleLowerCase().replace(/ /g, "") ===
  //           //       val.toLocaleLowerCase().replace(/ /g, "")
  //           //     ) {
  //           //       // display.push(value.attributeName)
  //           //       var column = {
  //           //         name: "lmsloan",
  //           //         label: value.attributeName,
  //           //         options: {
  //           //           filter: true,
  //           //           sort: true,
  //           //           customBodyRender: (value, tableMeta, updateValue) => {
  //           //             return <React.Fragment>{value[val]}</React.Fragment>;
  //           //           },
  //           //         },
  //           //       };
  //           //       display.push(column);
  //           //     }
  //           //   });
  //           // });

  //           const restdata = [
  //             // {
  //             //   name: "date",
  //             //   label: "date",
  //             //   options: {
  //             //     filter: true,
  //             //     sort: true,
  //             //     display: "exclude",
  //             //     // customBodyRender: (value, tableMeta, updateValue) => {
  //             //     // return (
  //             //     // <React.Fragment>
  //             //     // {value.merchantlegalname}
  //             //     // </React.Fragment>
  //             //     // );
  //             //     // },
  //             //   },
  //             // },
  //             {
  //               name: "NumberofPagesProcessed",
  //               label: "Pages Processed",
  //               // width:20,
  //               options: {
  //                 filter: true,
  //                 sort: true,
  //                 // customHeadRender: (columnMeta, updateDirection) => (
  //                 //   <th style={{ textAlign: 'center', fontSize: '0.875rem' }}>
  //                 //     {columnMeta.label}
  //                 //   </th>
  //                 // ),
  //                 // customBodyRender: (value, tableMeta, updateValue) => {
  //                 //   return (
  //                 //     <React.Fragment>
  //                 //       {/* {JSON.stringify(value)} */}
  //                 //       <div className="mismatchbox text-center">  {value}</div>
  //                 //     </React.Fragment>
  //                 //   );
  //                 // },
  //               },
  //             },
  //             {
  //               // 11
  //               name: "matched",
  //               label: "Match / Mismatch",
  //               //  width:20,
  //               options: {
  //                 filter: false,
  //                 sort: false,
  //                 empty: true,
  //                 customHeadRender: (columnMeta, updateDirection) => (
  //                   <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
  //                     {columnMeta.label}
  //                   </th>
  //                 ),
  //                 customBodyRender: (value, tableMeta, updateValue) => {
  //                   console.log("Match / Mismatch", value);

  //                   return (
  //                     <React.Fragment>
  //                       {/* {JSON.stringify(value)} */}
  //                       <div className="mismatchbox text-center">
  //                         {" "}
  //                         {value === 1 ? (
  //                           <img alt="" src={Match} />
  //                         ) : (
  //                           <img alt="" src={Mismatch} />
  //                         )}
  //                       </div>
  //                     </React.Fragment>
  //                   );
  //                 },
  //               },
  //             },

  //             {
  //               // 12
  //               name: "agreementloan",
  //               label: "Action",

  //               // width:20,
  //               options: {
  //                 filter: false,
  //                 sort: false,
  //                 display: Userrole == "Processor" ? true : "exclude",
  //                 empty: true,
  //                 customBodyRender: (value, tableMeta, updateValue) => {

  //                   return (
  //                     // <React.Fragment>
  //                     //   <div className="">
  //                     //     <Button
  //                     //       variant="outlined"
  //                     //       id="optionalbutton"
  //                     //       type="submit"
  //                     //       onClick={() =>
  //                     //         this.handleClick(
  //                     //           value["Deal Id"] ||
  //                     //             value["APP_ID"] ||
  //                     //             value["Unique Identifier / Filename"] ||
  //                     //             value["Contract #"] ||
  //                     //             value["Unique Identifier"] ||
  //                     //             value["DOCUMENT_ID"] ||
  //                     //             value[
  //                     //               "Identifier (9 digit unique identifier)"
  //                     //             ] ||
  //                     //             value["Document ID"],
  //                     //           tableMeta.rowData.filter(
  //                     //             (e) =>
  //                     //               typeof e === "string" && e.includes(".pdf")
  //                     //           )[0]
  //                     //         )
  //                     //       }
  //                     //     >
  //                     //       REVIEW{" "}
  //                     //     </Button>
  //                     //   </div>
  //                     // </React.Fragment>
  //                     <React.Fragment>
  //                       <div className="">
  //                         <Button
  //                           variant="outlined"
  //                           id="optionalbutton"
  //                           type="submit"
  //                           onClick={() =>
  //                             this.handleClick(
  //                               value["Deal Id"] ||
  //                                 value["APP_ID"] ||
  //                                 value["Unique Identifier / Filename"] ||
  //                                 value["Contract #"] ||
  //                                 value["Unique Identifier"] ||
  //                                 value["DOCUMENT_ID"] ||
  //                                 value[
  //                                   "Identifier (9 digit unique identifier)"
  //                                 ] ||
  //                                 value["Document ID"],
  //                               tableMeta.rowData.filter(
  //                                 (e) =>
  //                                   (typeof e === "string" &&
  //                                     e.includes(".pdf")) ||
  //                                   (typeof e === "string" &&
  //                                     e.toLowerCase().endsWith(".pdf"))
  //                               )[0]
  //                             )
  //                           }
  //                         >
  //                           REVIEW
  //                         </Button>
  //                       </div>
  //                     </React.Fragment>
  //                   );
  //                 },
  //               },
  //             },

  //             // {
  //             //   // 12
  //             //   name: "loandocpath",
  //             //   label: "loandocpath",
  //             //   options: {
  //             //     display: false,
  //             //     customBodyRender: (value, tableMeta, updateValue) => {
  //             //       return (
  //             //         <React.Fragment>
  //             //           <div className="">{value}</div>
  //             //         </React.Fragment>
  //             //       );
  //             //     },
  //             //   },
  //             // },
  //           ];
  //           restdata.map((json) => {
  //             display.push(json);
  //           });
  //           this.setState({ columns: display });

  //           const message = "Record Fetched Successfully";
  //           this.props.enqueueSnackbar(message, {
  //             variant: "info",
  //             autoHideDuration: 3000,
  //           });
  //         }
  //       } else if (APIResponse.data.isSuccess === false) {
  //         this.setState({ getLoansLoader: false, open: true, loading: false });
  //         const message = APIResponse.data.message;
  //         this.props.enqueueSnackbar(message, {
  //           variant: "error",
  //           autoHideDuration: 3000,
  //         });
  //         this.props.history.push({
  //           pathname: "/processor/dashboard",
  //         });
  //       }

  //       const obj = resp.res.data[0][arr].data.filter(
  //         (tag, index, array) =>
  //           array.findIndex((t) => t.date == tag.date) == index
  //       );
  //       this.setState({ duplicateobject: obj });
  //     } else if (response.status === 201) {
  //       this.setState({ getLoansLoader: false, open: true, loading: false });
  //       const message = resp.res.message;
  //       this.props.enqueueSnackbar(message, {
  //         variant: "error",
  //         autoHideDuration: 3000,
  //       });
  //     }
  //   } else if (response.status === 201) {
  //     this.setState({ getLoansLoader: false, open: true, loading: false });
  //     const message = response.data.res.message;
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   } else {
  //     this.setState({ getLoansLoader: false, open: true, loading: true });
  //     const message = "Something went wrong";
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  //   // } catch (error) {
  //   //   alert('Failed');
  //   // }
  // }
  async selectedpoolid(selected) {
    const arr = [];
    const arr1 = [];

    this.setState({ selectedrows: selected });

    for (var i = 0; i < selected.length; i++) {
      var j = selected[i];

      let loanData = this.state.tableData[j];
      arr.push(loanData);
      arr1.push(this.state.tableData[j]);
    }
    console.log("arr", arr);
    localStorage.setItem("DDReport", JSON.stringify(arr));
  }

  backBtn = () => {
    const pooldatanames = localStorage.getItem("runddpoolname");
    const pooldataids = localStorage.getItem("runddpoolid");
    this.props.history.push({
      // pathname: "/processor/rundd/" + pooldataids + "/" + pooldatanames,
      pathname: "/processor/dashboard",
    });
  };
  // onchange = (e) => {
  //   const searchText = e.target.value.toLowerCase();
  //   this.setState({ searchText }, () => {
  //     // Filter data based on searchText
  //     const filteredData = this.state.tableData.filter((row) =>
  //       Object.values(row).some((value) =>
  //         typeof value === "object"
  //           ? Object.values(value).some(
  //               (nestedValue) =>
  //                 typeof nestedValue === "string" &&
  //                 nestedValue.toLowerCase().includes(searchText)
  //             )
  //           : typeof value === "string" &&
  //             value.toLowerCase().includes(searchText)
  //       )
  //     );
  //     this.setState({ filteredData });

  //     const tableHeight = searchText ? 50 * filteredData.length + 100 : 600;
  //     this.setState({ tableHeight: tableHeight });
  //   });
  // };

  onchange = (e) => {
    const searchText = e.target.value.trim().toLowerCase();
    this.setState({ searchText }, () => {
      // Combine data from dynamic and static columns
      const combinedData = this.state.tableData.map((dynamicRow, index) => {
        const staticRow = this.state.resss1[index]; // Assuming both arrays have the same length
        return { ...dynamicRow, ...staticRow };
      });

      // Filter combined data based on searchText
      const filteredData = combinedData.filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText)
        )
      );

      console.log(filteredData, "filter");
      this.setState({ filteredData });

      const tableHeight = searchText ? 50 * filteredData.length + 100 : 600;
      console.log(tableHeight, "tableheight");
      this.setState({ tableHeight: tableHeight });
    });
  };

  handleOnChange1 = (e) => {
    this.setState({
      file1: e.target.files[0],
      filename1: e.target.files[0].name,
    });
    console.log("eeee", e.target.files[0].name, this.state.file1);
  };

  onSubmit1 = (e) => {
    e.preventDefault();
    // this.addSignature();
    console.log("hello we have clicked the button");
  };

  //Upload Signature Api
//   addSignature = async () => {
//     const newdata = new FormData();
//     newdata.append("filename", this.state.file1);

//     console.log("newdata", JSON.stringify(newdata));

//     this.setState({ formLoader: true });
//     const APIResponse = await AddSignature(newdata, token);

//     if (APIResponse.status === 200) {
//       if (APIResponse.data.isSuccess === true) {
//         this.setState({ formLoader: false, certifyStatus: true });
//         const message = "Document uploaded successfully!";
//         this.props.enqueueSnackbar(message, {
//           variant: "info",
//           autoHideDuration: 3000,
//         });
//         this.setState({ filePath: APIResponse.data.filePath });
//       } else {
//         this.setState({ formLoader: false, certifyStatus: false });
//         const message = "Upload Update Success";
//         this.props.enqueueSnackbar(message, {
//           variant: "info",
//           autoHideDuration: 3000,
//         });
//       }
//     } else {
//       this.setState({ formLoader: false, certifyStatus: false });
//       const message = "Something went wrong";
//       this.props.enqueueSnackbar(message, {
//         variant: "error",
//         autoHideDuration: 3000,
//       });
//     }
//   };

//   //getAllMessageByDealName
//   GetAllMessagesByDealName = async () => {
//     var data = {
//       dealName: localStorage.getItem("runddpoolname"),
//     };
//     console.log("GetMessage");
//     const APIResponse = await GetAllMessagesByDealName(data, token);
// console.log(APIResponse,'GetAll')
//     let exportdata1 = [];
//     console.log("tabledata", this.state.tableData);

//     this.state.tableData.map((data, index) => {
//       let dupjson = {};

//       Object.keys(data.lmsloan).map((value) => {
//         dupjson[value] = data.lmsloan[value];
//         // dupjson[value+' ']=data.attributewise_matched[value] === 1 ? 'YES' : 'NO'
//       });
//       console.log("datanumber of pages", typeof data.NumberofPagesProcessed);
//       let ababa1 = "Number of pages processed";
//       dupjson[ababa1] = data.NumberofPagesProcessed;

//       Object.keys(data.lmsloan).map((value) => {
//         // dupjson[value]=data.lmsloan[value]
//         dupjson[value + " "] =
//           data.attributewise_matched[value] === 1 ? "YES" : "NO";
//       });
//       // dupjson.lmsloan=data.lmsloan
//       // dupjson.attributewise_matched=Object.keys(data.attributewise_matched).map((value) =>{return {[value]:data.attributewise_matched[value] === 1 ? 'YES' : 'NO'}})
//       dupjson["Exceptions"] = data.matched === 1 ? "NO" : "YES";
//       exportdata1.push(dupjson);
//     });

//     console.log("exportdataa", exportdata1);
//     this.setState({ exportdataxl: exportdata1 });



//     if (APIResponse.status === 200) {
//       console.log("Allpools--", APIResponse);

//       if (Array.isArray(APIResponse.data.result)) {
//         let filterdata2 = APIResponse.data.result.filter((value) => {
//           return value.messageType === "Signatory";
//         });
//         let filterdata1 = APIResponse.data.result.filter((value) => {
//           return value.messageType === "Address";
//         });
//         let filterdata = APIResponse.data.result.filter((value) => {
//           return value.messageType === "Message";
//         });

//         const Sorting = [...filterdata].sort(
//           (a, b) => a.messageNumber - b.messageNumber
//         );

//         this.setState({
//           Sort: Sorting,
//           getLoansLoader: false,
//           CertiFyData: APIResponse.data,
//           SortAddress: filterdata1,
//           SortSignatory: filterdata2,
//           loading: true,
//         });

//         console.log("SortCer");
//       } else {
//         console.error("APIResponse.data is not an array.");
//         // Handle the error or provide a default behavior.
//       }
//     } else {
//       const message = APIResponse.data.message;
//       this.props.enqueueSnackbar(message, {
//         variant: "info",
//         autoHideDuration: 3000,
//       });
//     }
//   };

//   fistTableData = (tableData1) => {
//     if (tableData1 == undefined) {
//       return <div></div>;
//     } else {
//       console.log("tableData", tableData1);
//       const headingArr = Object.keys(tableData1[0]);
//       console.log(tableData1);
//       return (
//         <div className="span-class-scheduled">
//           <table className="table-servicer">
//             <tr>
//               {headingArr.map((heading) => (
//                 <th className="servicer-data-table-heading">{heading}</th>
//               ))}
//             </tr>
//             {tableData1.map((data) => (
//               <tr>
//                 {headingArr.map((element) => (
//                   <td>
//                     {element == "Balance" ? data[element] : data[element]}
//                   </td>
//                 ))}
//               </tr>
//             ))}
//           </table>
//         </div>
//       );
//     }
//   };

//   Certify = () => {
//     const data = $("#pdfdata2").html();
//     console.log("dataaaaa", JSON.stringify(data));
//     this.IssueCertificate(data);
//   };

//   IssueCertificate = async (value) => {
//     console.log(value,"issuevalue")
//     this.setState({ formLoader1: true });
//     var issue = {
//       poolID: localStorage.getItem("poolid"),
//       data: JSON.stringify(value),
//     };
//     console.log("issue", issue);
//     const APIResponse = await IssueCertificate(issue, token);

//     console.log("APIResponse-issue", APIResponse);

//     if (APIResponse.status === 201) {
//       this.setState({ formLoader1: true });
//       this.SaveCertificate(APIResponse.data.filename);
//       //     const message = "Upload Update Success";
//       //     this.props.enqueueSnackbar(message, {
//       //       variant: "info",
//       //       autoHideDuration: 3000,
//       //     });
//       console.log("hellooall", APIResponse);
//     } else {
//       this.setState({ formLoader1: false });
//       const message = "Something went wrong";
//       this.props.enqueueSnackbar(message, {
//         variant: "error",
//         autoHideDuration: 3000,
//       });
//       console.log("Nooooo");
//     }
//   };

//   //Save Certificate
//   SaveCertificate = async (value) => {
//     this.setState({ formLoader1: true });
//     var data = {
//       dealId: localStorage.getItem("poolid"),
//       filePath: value,
//     };

//     const APIResponse = await SaveCertificate(data, token);
//     console.log("APIResponse,save", APIResponse);

//     if (APIResponse.status === 201) {
//       this.setState({ formLoader1: true });
//       // this.DeleteLMS();
//       window.location.assign("/processor/dashboard");

//       console.log("hellooall", APIResponse);
//     } else {
//       this.setState({ formLoader1: false });
//       const message = "Something went wrong";
//       this.props.enqueueSnackbar(message, {
//         variant: "error",
//         autoHideDuration: 3000,
//       });
//       console.log("Nooooo");
//     }
//   };

//   //Delete Certificate
//   DeleteLMS = async () => {
//     this.setState({ formLoader1: true });
//     var data = {
//       dealId: localStorage.getItem("poolid"),
//       dealname: localStorage.getItem("runddpoolname"),
//       signPath: this.state.filePath,
//     };

//     console.log(data, "data123");
//     const APIResponse = await DeleteLMS(data, token);
//     console.log("APIResponse123", APIResponse);

//     if (APIResponse.status === 200) {
//       this.setState({ formLoader1: false });
//       const message = APIResponse.data.message;
//       this.props.enqueueSnackbar(message, {
//         variant: "info",
//         autoHideDuration: 3000,
//       });
//       window.location.assign("/processor/dashboard");
//       console.log("hellooall", APIResponse);
//     } else {
//       this.setState({ formLoader1: false });
//       const message = "Something went wrong";
//       this.props.enqueueSnackbar(message, {
//         variant: "error",
//         autoHideDuration: 3000,
//       });
//       console.log("Nooooo");
//     }
//   };

  // GetLoans Ai Api
  // editLoans = async () => {
  //   var data = {};
  //   data.edited_loanid = this.state.edited_loanid;
  //   data.dealidlist = JSON.parse(localStorage.getItem("rundd"));
  //   data.poolid = localStorage.getItem("runddpoolid");
  //   data.poolname = localStorage.getItem("runddpoolname");

  //   const APIResponse = await editLoans(data, token);

  //   if (APIResponse.status === 200) {
  //     const resp = APIResponse.data;
  //     var getUsers = this.state.isMatched;
  //     var editUser = resp.res.data.map((item) => item.matched);
  //     for (var getUser in getUsers) {
  //       if (editUser.indexOf(getUsers[getUser]) > -1) {
  //         this.state.match = 1;
  //       }
  //     }
  //     const message = "Data Saved Successfully";
  //     this.props.enqueueSnackbar(message, {
  //       variant: "info",
  //       autoHideDuration: 3000,
  //     });
  //     // window.location.reload();
  //   } else {
  //     this.setState({ formLoader1: false });
  //     const message = "Something went wrong";
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  // };

  // editLoans = async () => {
  //   this.setState({ getLoansLoader: true });
  //   var data = {};
  //   data.edited_loanid = this.state.edited_loanid;
  //   // data.dealidlist = JSON.parse(localStorage.getItem("rundd"));
  //   data.poolid = localStorage.getItem("runddpoolid");
  //   data.poolname = localStorage.getItem("runddpoolname");

  //   const response = await editLoans(data, token);
  //   console.log("responses", response);
     
  //   if (response.status === 200) {
  //     const resp = response.data;
  //     console.log(resp, "hellooo");
  //     console.log(resp.res.data, "helo1111");
  //     const message = resp.res.message;
  //     this.setState({ loanData: resp.res.data });
  //     console.log(this.state.loanData, "save button");
  //     this.props.enqueueSnackbar(message, {
  //       variant: "info",
  //       autoHideDuration: 3000,
  //     });
  //     // this.setState({ isMatched: resp.res.data.map((item) => item.matched) });
  //     if (resp.res.data.length > 0) {
     
  //       const updatedData = resp.res.data
  //       const existingData = this.state.loanData;

  //       const updatedTableData = [...existingData];

  //       updatedData.forEach(updatedItem => {
  //           const index = updatedTableData.findIndex(existingItem => existingItem['Deal Id'] === updatedItem['Deal Id']);
  //           if (index !== -1) {
  //               updatedTableData[index] = updatedItem;
  //           }
  //       });
      
  //     this.setState({
  //         getLoansLoader: false,
  //         tableData: updatedTableData.sort((a, b) => parseFloat(a.matched) - parseFloat(b.matched)),
  //         // loading: true,
  //     });
            
  //       // const obj1 = resp.res.data.filter(
  //       //   (tag, index, array) =>
  //       //     array.findIndex((t) => t.date == tag.date) == index
  //       // );
  //       // this.setState({ duplicateobject1: obj1 });
  //     } else if (response.status === 201) {
  //       this.setState({ getLoansLoader: false, open: true, loading: false });
  //       const message = resp.res.message;
  //       this.props.enqueueSnackbar(message, {
  //         variant: "error",
  //         autoHideDuration: 3000,
  //       });
  //     }
  //   } else if (response.status === 201) {
  //     this.setState({ getLoansLoader: false, open: true, loading: false });
  //     const message = response.data.res.message;
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   } else {
  //     this.setState({ getLoansLoader: false, open: true, loading: true });
  //     const message = "Something went wrong";
  //     this.props.enqueueSnackbar(message, {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   }
  //   // } catch (error) {
  //   //   alert('Failed');
  //   // }
  // };
  editLoans = async () => {
    var data = {};
    data.edited_loanid = this.state.edited_loanid;
    // data.dealidlist = JSON.parse(localStorage.getItem("rundd"));
    data.poolid = localStorage.getItem("runddpoolid");
    data.poolname = localStorage.getItem("runddpoolname");

    const response = await editLoans(data, token);
    console.log("responses", response);

    if (response.status === 200) {
      const resp = response.data;
      const message = resp.res.message;
      this.setState({ loanData: resp.res.data });
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      // this.setState({ isMatched: resp.res.data.map((item) => item.matched) });
      if (resp.res.data.length > 0) {
        // let xyz = resp.res.data.sort(
        //   (a, b) => parseFloat(a.matched) - parseFloat(b.matched)
        // );
        this.setState({
          getLoansLoader: false,
          tableData: resp.res.data.sort(
            (a, b) => parseFloat(a.matched) - parseFloat(b.matched)
          ),
          loading: true,
        });
        // console.log("tabledatasort", this.state.tableData);
        // const obj1 = resp.res.data.filter(
        //   (tag, index, array) =>
        //     array.findIndex((t) => t.date == tag.date) == index
        // );
        // console.log("uniquenames", obj1);
        // this.setState({ duplicateobject1: obj1 });
      } else if (response.status === 201) {
        this.setState({ getLoansLoader: false, open: true, loading: false });
        const message = resp.res.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } else if (response.status === 201) {
      this.setState({ getLoansLoader: false, open: true, loading: false });
      const message = response.data.res.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ getLoansLoader: false, open: true, loading: true });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    // } catch (error) {
    //   alert('Failed');
    //   console.log('getLoans API error', error);
    // }
  };
  
  Downloadexcel = async () => {
    let poolname = localStorage.getItem("runddpoolname");
    let poolid = localStorage.getItem("runddpoolid");

    let data = {
      poolname: poolname,
      poolid: poolid,
    };
    const APIResponse = await DownloadLMSData(data, token);
    console.log("DownloadLMS", APIResponse);
    if (APIResponse.status === 200) {
      console.log("Suceessfully downloaded");
      const message = "Suceessfully Downloaded";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      const file_name = poolname + "_" + poolid + ".xlsx";
      startDownload(APIResponse.data, file_name);
      function startDownload(file, fileName) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } else {
      if (APIResponse.status === 201) {
        const message = "No Data Found";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      } else {
        console.log("Download not Success");
      }
    }
  };

  render() {
    const dataToDisplay = this.state.searchText
      ? this.state.filteredData
      : this.state.tableData;

    const tableHeight = 50 * dataToDisplay.length + 100;
    const maxHeight = 600; // Maximum height allowed

    // Limit the table height to the maximum height
    const limitedTableHeight = Math.min(tableHeight, maxHeight);

    console.log(tableHeight, "112233");
    // Assuming itemHeight is 50 pixels
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header">
              <Header pageTitle={"DD REPORT SCREEN"}></Header>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
                marginLeft: "1rem",
                maxWidth: "85%",
                fontWeight: "600",
              }}
            >
              <div style={{ marginLeft: "1rem" }}>
                <div>Deal Name: {this.state.poolname} </div>
                <div>Deal ID: {this.state.poolid}</div>
              </div>
              <div>
                {" "}
                <div>LMS File: {this.state.lmsfile}</div>
                <div>Zip File: {this.state.contractfile}</div>
              </div>
            </div>
            <div className="page-content">
              <React.Fragment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.showSearchBox == true ? (
                    this.searchBar()
                  ) : (
                    <h6 style={{ fontSize: "20px" }}>{this.state.poolName}</h6>
                  )}
                  {/* <h6 style={{ fontSize: "20px" }}>{this.state.poolName}</h6> */}

                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        paddingRight: "10px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          {this.state.showSearchBox == false && (
                            <Search
                              style={{
                                marginRight: "5px",
                                color: "#018e82",
                                cursor: "pointer",
                              }}
                              onClick={this.onClickSearchButton}
                            />
                          )}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.Downloadexcel}
                            style={{ marginRight: "1rem" }}
                          >
                            Export LMS Excel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.Export}
                          >
                            EXPORT RECON
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="poolFormationTableWrapper"> */}

                {this.state.getLoansLoader === false ? (
                  this.state.tableData.length > 0 ? (
                    // Check if searchText is not empty and filteredData is empty
                    this.state.searchText &&
                    this.state.filteredData.length === 0 ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No Data Found</p>
                      </div>
                    ) : (
                      <div>
                        <TableVirtuoso
                      
                          data={dataToDisplay}
                          columns={this.state.columns}
                          components={TableComponents} 
                          
                         
                          // onScroll={this.handleScroll}
                          style={{ height: `${limitedTableHeight}px`, overflowAnchor: "none"  }}
                          itemHeight={50}
                          fixedHeaderContent={() => (
                            <TableRow>
                              <>
                                <TableCell>
                                  <Checkbox
                                    style={{
                                      transform: "scale(0.9)",
                                      color: "#048c88",
                                    }}
                                    checked={this.state.selectAllChecked}
                                    onChange={this.handleSelectAllChange}
                                  />
                                </TableCell>
                                {this.state.columns.map((column , i) => (
                                  <TableCell
                                    key={i}
                                    // style={{
                                    //   width: `${column.width * 10}px`,
                                    //   // flex: `${column.width}px`,
                                    //   textAlign: "center",
                                    //   cursor: "pointer",
                                    //   justifyContent: "space-between",
                                    //   alignItems: "center",
                                    // }}
                                    style={{
                                      background: "white",
                                      cursor: "pointer",
                                      width: `${column.length * 10}px`,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Tooltip
                                      title={`${column.label}`}
                                      placement="bottom-start"
                                    >
                                      <span>{column.label}</span>
                                    </Tooltip>
                                  </TableCell>
                                ))}
                              </>
                            </TableRow>
                          )}
                          itemContent={(index) => {
                            const rowData = dataToDisplay[index]["lmsloan"];
                            const isOddRow = index % 2 !== 0;
                            console.log('rowwww',rowData)
                            return (
                              <>
                                <TableCell
                                  style={{
                                    backgroundColor: isOddRow
                                      ? "rgb(229,229,229,0.3)"
                                      : "",
                                  }}
                                >
                                  <Checkbox
                                    style={{
                                      transform: "scale(0.9)",
                                      color: "#048c88",
                                    }}
                                    checked={this.state.selectedrows.includes(
                                      index
                                    )}
                                    onChange={(event) =>
                                      this.handleSelectRowChange(event, index)
                                    }
                                  />
                                </TableCell>

                                {this.state.columns.map((column , i) => (
                                  <TableCell
                                  
                                    key={i}
                                    style={{
                                      width: `${column.length * 10}px`,
                                      background: "white",
                                      whiteSpace: "nowrap",
                                      backgroundColor: isOddRow
                                        ? "rgb(229,229,229,0.3)"
                                        : "",
                                      // flex: `${column.width * 1.5}px`,
                                      // display: "flex",
                                      // alignItems: "center",
                                      // alignContent: "center",
                                    }}
                                  >
                                    {console.log(column, "column")}
                                    {column.name ===
                                    "NumberofPagesProcessed" ? (
                                      dataToDisplay[index][column.name]
                                    ) : column.name === "matched" ? (
                                     dataToDisplay[index][
                                        column.name
                                      ] === 1 ? (
                                        <img alt="" src={Match} />
                                      ) : (
                                        <img alt="" src={Mismatch} />
                                      )
                                    ) : column.name === "agreementloan" ? (
                                      <Button
                                        variant="outlined"
                                        id="optionalbutton"
                                        type="submit"
                                        onClick={(event) => {
                                          const dealId =
                                            dataToDisplay[index][
                                              "Deal Id"
                                            ] ||
                                            dataToDisplay[index][
                                              "APP_ID"
                                            ] ||
                                            dataToDisplay[index][
                                              "Unique Identifier / Filename"
                                            ] ||
                                            dataToDisplay[index][
                                              "Contract #"
                                            ] ||
                                            dataToDisplay[index][
                                              "Unique Identifier"
                                            ] ||
                                            dataToDisplay[index][
                                              "DOCUMENT_ID"
                                            ] ||
                                            dataToDisplay[index][
                                              "Identifier (9 digit unique identifier)"
                                            ] ||
                                            dataToDisplay[index][
                                              "Document ID"
                                            ];

                                          this.handleClick(
                                            dealId,
                                            dataToDisplay[index]
                                              .loandocpath,
                                            event.currentTarget
                                          );
                                        }}
                                      >
                                        REVIEW
                                      </Button>
                                    ) : (
                                      rowData[column.label]
                                    )}
                                  </TableCell>
                                ))}
                                {/* </TableCell> */}
                              </>
                            );
                          }}
                        />
                      </div>
                    )
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <p>No Data Found</p>
                    </div>
                  )
                ) : this.state.getLoansLoader === true ? (
                  <div
                    style={{
                      textAlign: "center",
                      margin: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <CircularProgress size="25px" color="primary" /> Please
                    wait, Loading Loan Data...
                  </div>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>No Data Found</p>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "1rem",
                  }}
                >
                  Total no. of Loans{" "}
                  <div style={{ marginLeft: "5px", fontWeight: "bold" }}>
                    {dataToDisplay.length}
                  </div>{" "}
                  {/* Update here */}
                </div>
                <></>

                {/* </div> */}

                <div className="navbarSteps navbarStepsBtm">
                  <div className="row justify-content-end">
                    <Button
                      variant="contained"
                      id="optionalbutton"
                      onClick={this.backBtn}
                    >
                      {" "}
                      BACK{" "}
                    </Button>
                    {/* <Button variant="contained" id="optionalbutton" onClick={this.rowcancel} > CANCEL  </Button> */}

                    <Button
                      onClick={this.onSubmit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={this.state.selectedrows.length === 0}
                    >
                      
                      CERTIFY
                    </Button>
                    {/* <Button
                      variant="contained"
                      id="optionalbutton"
                      color="primary"
                      onClick={this.onOpenModal3}
                    >
                      {" "}
                      UPLOAD SIGNATURE{" "}
                    </Button> */}
                  </div>
                </div>

                <ReactModal
                  isOpen={this.state.open3}
                  onRequestClose={this.onCloseModal3}
                  style={customStylesauto}
                >
                  <div className="modalPopup">
                    <h2>Upload Signature</h2>
                    <Button
                      className="closePopup"
                      style={{ minWidth: "30px" }}
                      variant="text"
                      color="primary"
                      onClick={this.onCloseModal3}
                    >
                      {" "}
                      <CloseIcon></CloseIcon>{" "}
                    </Button>
                    <div className="form-container">
                      <div>
                        {/* <h5 className="borderclassh5">Loan Tape</h5> */}

                        <div className="kyc-card__button-container1">
                          <div style={{ padding: "2rem" }}>
                            <h6 className="e1class">Upload Signature</h6>
                            <button
                              className="card__button"
                              style={{
                                position: "relative",
                              }}
                            >
                              <label
                                htmlFor="icon-button-file-id2"
                                className="upload-button-label"
                              >
                                Select File
                              </label>
                              <input
                                required
                                id="icon-button-file-id2"
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                style={{
                                  position: "absolute",
                                  width: "60%",
                                  height: "100%",
                                  cursor: "pointer",
                                  top: "0",
                                  right: "0px",
                                  opacity: "0",
                                  border: "1.2px solid #212121",
                                }}
                                onChange={this.handleOnChange1}
                              />
                            </button>
                          </div>
                          {this.state.file1 !== "" && (
                            <div className="kyc-card__select_name-container">
                              <p>{this.state.filename1}</p>
                            </div>
                          )}
                        </div>
                        <div className="modalsubmit">
                          <div className="submitbuttonbg">
                            <div className="">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  id="optionalbutton"
                                  onClick={this.onCloseModal3}
                                >
                                  {" "}
                                  CANCEL{" "}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  onClick={this.onSubmit1}
                                >
                                  Upload
                                  {this.state.formLoader === true ? (
                                    <CircularProgress
                                      size="22px"
                                      color="primary"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Button>

                                {this.state.certifyStatus === true ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.Certify}
                                  >
                                    {" "}
                                    CERTIFY{" "}
                                    {this.state.formLoader1 === true ? (
                                      <CircularProgress
                                        size="22px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled
                                    // onClick={this.Certify}
                                  >
                                    CERTIFY
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactModal>

                <ReactModal
                  isOpen={this.state.open2}
                  contentLabel="Minimal Modal Example"
                  onRequestClose={this.onCloseModal2}
                  style={customStyles}
                >
                  <React.Fragment>
                    <div className="modalPopup">
                      <h2>Match Unmatch Data </h2>
                      <Button
                        className="closePopup"
                        style={{ minWidth: "30px" }}
                        variant="text"
                        color="primary"
                        onClick={this.onCloseModal2}
                      >
                        {" "}
                        <CloseIcon></CloseIcon>{" "}
                      </Button>

                      <CompareData
                        action={this.childHandler}
                        msgData={this.state.msgData}
                      ></CompareData>
                    </div>
                  </React.Fragment>
                </ReactModal>

                <div id="modal">
                  {/* <Modal open={open1} onClose={this.onCloseModal1} center>
                    <div id="modalheader">
                      <h5>Create a Pool</h5>

                    </div> */}
                  <ReactModal
                    isOpen={this.state.open1}
                    onRequestClose={this.onCloseModal1}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto1}
                  >
                    <React.Fragment>
                      <div className="text-center p-3">
                        <ReportProblemOutlinedIcon
                          style={{ fontSize: "xxx-large" }}
                        ></ReportProblemOutlinedIcon>
                      </div>
                      <div className="text-center p-3">
                        <h5>
                          Contracts have not been viewed for mismatch. Do you
                          still want to proceed?
                        </h5>
                      </div>
                      <div class="row text-center p-3">
                        <div className="col-md-6">
                          <Button
                            variant="outlined"
                            size="large"
                            onClick={this.onCloseModal1}
                          >
                            No
                          </Button>
                        </div>
                        <div className="col-md-6">
                          <Button
                            variant="contained"
                            size="large"
                            onClick={this.onYes}
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
              </React.Fragment>
              <React.Fragment>
                {this.state.CertiFyData.length !== 0 ? (
                  <div id="pdfdata2" hidden>
                    <h4 className="certicate-heading">Certificate</h4>

                    {this.state.SortAddress.map((value) => {
                      var str_array = value.messageBody
                        .split(",")
                        .map((add) => (
                          <div>
                            {add},<br />
                          </div>
                        ));
                      console.log("str_array", str_array);
                      return (
                        <div className="certificate-para1">
                          <address>{str_array}</address>
                        </div>
                      );
                    })}
                    <div className="certificate-para1">
                      {this.state.Sort.map((value) => {
                        console.log("sortt", value.messageBody);
                        return (
                          <div>
                            <div className="certificate-para1">
                              {value.messageBody}
                            </div>
                            <br />
                          </div>
                        );
                      })}

                      {this.state.SortSignatory.map((value) => {
                        return (
                          <div className="certificate-para1-right">
                            <h6>{value.messageBody}</h6>
                            <img
                              src={
                                process.env.react_app_base_url +
                                "root_folder/" +
                                this.state.filePath
                              }
                              alt="logo"
                              className="wellslogodeal"
                            />
                          </div>
                        );
                      })}

                      <h4 className="certicate-heading1">Exception Report</h4>

                      <div className="certificate-para1-center">
                        {this.state.exportdataxl == undefined ? (
                          <div></div>
                        ) : (
                          this.fistTableData(this.state.exportdataxl)
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(DDReport);
