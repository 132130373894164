/* eslint-disable require-jsdoc */
import React, {Component} from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Loader from '../../../components/loader';
import axios from 'axios';
import {Doughnut} from 'react-chartjs-2';
import { withSnackbar } from 'notistack';

class poolSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      myChart: {},
      myChart2: {},
      myChart3: {},
      myChart4: {},
    };
  }

  async componentDidMount() {
    let poolId=this.props.match.params.id;
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
  
    
    this.getSummery(poolId, peer, token);

  };

  async getSummery(poolId, peer, token){
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/pool/poolsummary?pool_id=' + poolId + "&peer="+peer+"&token="+token,);
      const result = res.data;
      
      if (res.status === 200) {

        if(result.token == '-1'){

          const message = "Invalid Token!";
          this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 2000
        });
        this.props.history.push({
          pathname: '/'
        })

        } else {

          
          const message = "Successfully fetched the data";
          this.props.enqueueSnackbar(message, { 
            variant: 'info',
            autoHideDuration: 2000
        });
       
        this.setState({loading: true});
        var first = result.loanAmtCat1;
        var second = result.loanAmtCat2;
        var third = result.loanAmtCat3;
        var fourth = result.loanAmtCat4;
        /* var first = "10";
              var second = "5";
              var third = "2";
              var fourth = "3"; */
        console.log(first + second + third + fourth);

        const first_label = 'Loan Amount 100000+';
        const second_label = 'Loan Amount 50000-100000';
        const third_label = 'Loan Amount 25000-50000';
        const fourth_label = 'Loan Amount <25000';

        var first = parseInt(first);
        var second = parseInt(second);
        var third = parseInt(third);
        var fourth = parseInt(fourth);

        /* var total = first + second + third + fourth;
              var firstpercentage = Math.floor((first / total) *  100 );
              var secondpercentage = Math.floor((second / total) *  100 );
              var thirdpercentage = Math.floor((third / total) *  100 );
              var fourthpercentage = Math.floor((fourth / total) *  100 );  */

        const total = first + second + third + fourth;
        const firstpercentage2 = parseFloat(first * 100 / total);
        const secondpercentage2 = parseFloat(second * 100 / total);
        const thirdpercentage2 = parseFloat(third * 100 / total);
        const fourthpercentage2 = parseFloat(fourth * 100 / total);


        const firstpercentage = firstpercentage2.toFixed(2);
        const secondpercentage = secondpercentage2.toFixed(2);
        const thirdpercentage = thirdpercentage2.toFixed(2);
        const fourthpercentage = fourthpercentage2.toFixed(2);

        console.log(fourthpercentage2);

        const data = [
          first,
          second,
          third,
          fourth,
        ];
        const labels = [first_label + ' : ' + firstpercentage + '%', second_label + ' : ' + secondpercentage + '%', third_label + ' : ' + thirdpercentage + '%',
          fourth_label + ' : ' + fourthpercentage + '%'];
        const bgColor = ['#878BB6', '#FFEA88', '#FF8153', '#ff0000'];

        // Loan amount
        // Categories are: <25000, 25000-50000, 50000-100000, 100000+

        const myChart = {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: bgColor,
            }],
        };
        this.setState({myChart: myChart});



        var first2 = result.agecat1;
        var second2 = result.agecat2;
        var third2 = result.agecat3;
        var fourth2 = result.agecat4;
        /* var first = "10";
			var second = "5";
			var third = "2";
			var fourth = "3"; */
        console.log('second' + first2 + second2 + third2 + fourth2);

        const first_label2 = '24 months+';
        const second_label2 = '12 months - 24 months';
        const third_label2 = '6 months';
        const fourth_label2 = '<6 months';

        var first2 = parseInt(first2);
        var second2 = parseInt(second2);
        var third2 = parseInt(third2);
        var fourth2 = parseInt(fourth2);

        /* var total = first + second + third + fourth;
			var firstpercentage = Math.floor((first / total) *  100 );
			var secondpercentage = Math.floor((second / total) *  100 );
			var thirdpercentage = Math.floor((third / total) *  100 );
			var fourthpercentage = Math.floor((fourth / total) *  100 );  */

        const total2 = first2 + second2 + third2 + fourth2;
        const firstpercentage2x = parseFloat(first2 * 100 / total2);
        const secondpercentage2x = parseFloat(second2 * 100 / total2);
        const thirdpercentage2x = parseFloat(third2 * 100 / total2);
        const fourthpercentage2x = parseFloat(fourth2 * 100 / total2);


        const firstpercentagex = firstpercentage2x.toFixed(2);
        const secondpercentagex = secondpercentage2x.toFixed(2);
        const thirdpercentagex = thirdpercentage2x.toFixed(2);
        const fourthpercentagex = fourthpercentage2x.toFixed(2);

        console.log(fourthpercentage2x);

        const data2 = [
          first2,
          second2,
          third2,
          fourth2,
        ];
        const labels2 = [first_label2 + ' : ' + firstpercentagex + '%', second_label2 + ' : ' + secondpercentagex + '%', third_label2 + ' : ' + thirdpercentagex + '%',
          fourth_label2 + ' : ' + fourthpercentagex + '%'];
        const bgColor2 = ['#878BB6', '#FFEA88', '#FF8153', '#ff0000'];


        const myChart2= {
          labels: labels2,
          datasets: [
            {
              data: data2,
              backgroundColor: bgColor2,
            }],
        };
        this.setState({myChart2: myChart2});

        var first3 = result.cCEastI;
        var second3 = result.cCWestI;
        var third3 = result.cCNorthI;
        var fourth3 = result.cCSouthI;
        var fifth3 = result.others;
        /* var first = "10";
            var second = "5";
            var third = "2";
            var fourth = "3"; */
        console.log('thiird' + first3 + second3 + third3 + fourth3 + fifth3);

        const first_label3 = 'East';
        const second_label3 = 'West';
        const third_label3 = 'North';
        const fourth_label3 = 'South';
        const fifth_label3 = 'Others';

        var first3 = parseInt(first3);
        var second3 = parseInt(second3);
        var third3 = parseInt(third3);
        var fourth3 = parseInt(fourth3);
        var fifth3 = parseInt(fifth3);

        /* var total = first + second + third + fourth;
            var firstpercentage = Math.floor((first / total) *  100 );
            var secondpercentage = Math.floor((second / total) *  100 );
            var thirdpercentage = Math.floor((third / total) *  100 );
            var fourthpercentage = Math.floor((fourth / total) *  100 );  */

        const total3 = first3 + second3 + third3 + fourth3 + fifth3;
        const firstpercentage2x2 = parseFloat(first3 * 100 / total3);
        const secondpercentage2x2 = parseFloat(second3 * 100 / total3);
        const thirdpercentage2x2 = parseFloat(third3 * 100 / total3);
        const fourthpercentage2x2 = parseFloat(fourth3 * 100 / total3);
        const fifthpercentage2x2 = parseFloat(fifth3 * 100 / total3);


        const firstpercentagex2 = firstpercentage2x2.toFixed(2);
        const secondpercentagex2 = secondpercentage2x2.toFixed(2);
        const thirdpercentagex2 = thirdpercentage2x2.toFixed(2);
        const fourthpercentagex2 = fourthpercentage2x2.toFixed(2);
        const fifthpercentagex2 = fifthpercentage2x2.toFixed(2);

        console.log(fourthpercentage2x2);

        const data3 = [
          first3,
          second3,
          third3,
          fourth3,
          fifth3,
        ];
        const labels3 = [first_label3 + ' : ' + firstpercentagex2 + '%', second_label3 + ' : ' + secondpercentagex2 + '%', third_label3 + ' : ' + thirdpercentagex2 + '%',
          fourth_label3 + ' : ' + fourthpercentagex2 + '%',
          fifth_label3 + ' : ' + fifthpercentagex2 + '%'];
        const bgColor3 = ['#878BB6', '#FFEA88', '#FF8153', '#ff0000', '#333333'];

        const myChart3 = {
          labels: labels3,
          datasets: [
            {
              data: data3,
              backgroundColor: bgColor3,
            }],
        };
        this.setState({myChart3: myChart3});



        var first4 = result.vcatYamaha;
        var second4 = result.vcatHonda;
        var third4 = result.vcatTVS;
        var fourth4 = result.vcatOthers;
        /* var first = "10";
			var second = "5";
			var third = "2";
			var fourth = "3"; */
        console.log('fourth' + first4 + second4 + third4 + fourth4);

        const first_label4 = 'Yamaha';
        const second_label4 = 'Honda';
        const third_label4 = 'TVS';
        const fourth_label4 = 'Others';

        var first4 = parseInt(first4);
        var second4 = parseInt(second4);
        var third4 = parseInt(third4);
        var fourth4 = parseInt(fourth4);

        /* var total = first + second + third + fourth;
			var firstpercentage = Math.floor((first / total) *  100 );
			var secondpercentage = Math.floor((second / total) *  100 );
			var thirdpercentage = Math.floor((third / total) *  100 );
			var fourthpercentage = Math.floor((fourth / total) *  100 );  */

        const total4 = first4 + second4 + third4 + fourth4;
        const firstpercentage2x3 = parseFloat(first4 * 100 / total4);
        const secondpercentage2x3 = parseFloat(second4 * 100 / total4);
        const thirdpercentage2x3 = parseFloat(third4 * 100 / total4);
        const fourthpercentage2x3 = parseFloat(fourth4 * 100 / total4);


        const firstpercentagex3 = firstpercentage2x3.toFixed(2);
        const secondpercentagex3 = secondpercentage2x3.toFixed(2);
        const thirdpercentagex3 = thirdpercentage2x3.toFixed(2);
        const fourthpercentagex3 = fourthpercentage2x3.toFixed(2);

        console.log(fourthpercentage2x3);

        const data4 = [
          first4,
          second4,
          third4,
          fourth4,
        ];
        const labels4 = [first_label4 + ' : ' + firstpercentagex3 + '%', second_label4 + ' : ' + secondpercentagex3 + '%', third_label4 + ' : ' + thirdpercentagex3 + '%',
          fourth_label4 + ' : ' + fourthpercentagex3 + '%'];
        const bgColor4 = ['#878BB6', '#FFEA88', '#FF8153', '#ff0000'];


        const myChart4 = {
          labels: labels4,
          datasets: [
            {
              data: data4,
              backgroundColor: bgColor4,
            }],
        };
        this.setState({myChart4: myChart4});
      }

      } else {
        alert('Failed');
      }
    } catch (error) {
      alert('Failed');
      console.log('getLoans API error', error);
    }
  }
  render() {

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header backTitle={'Pool Details'} backButton={'/pool-details'} pageTitle={'Pool Summary'}></Header></div>

            

            <div className="page-content">


              {this.state.loading === true ?
            <div>
              <div className="row">
                <div className="col-6">
                  <div className="card">
                    <div className="card-header">
                   Asset Wise Distribution

                    </div>
                    <div className="card-body">
                      <Doughnut data={this.state.myChart} />
                    </div>

                  </div>


                </div>

                <div className="col-6">
                  <div className="card">
                    <div className="card-header">
                   Overdue Ageing

                    </div>
                    <div className="card-body">
                      <Doughnut data={this.state.myChart2} />

                    </div>

                  </div>


                </div>
              </div>



              <div className="row">
                <div className="col-6">
                  <div className="card">
                    <div className="card-header">
                   Geographical Distribution

                    </div>
                    <div className="card-body">
                      <Doughnut data={this.state.myChart3} />
                    </div>

                  </div>


                </div>

                <div className="col-6">
                  <div className="card">
                    <div className="card-header">
                   Vehicle Class Distribution

                    </div>
                    <div className="card-body">
                      <Doughnut data={this.state.myChart4} />
                    </div>

                  </div>


                </div>
              </div>
            </div> :
<Loader msg={'Please wait, loading data'} /> }

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(poolSummary);