/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { Form } from 'react-bootstrap';
import { withSnackbar } from 'notistack';


function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
      var res = str.substring(2, str.length);
      var rep = res.replace(/[a-z]/gi, 'x')
      return str.replace(res, rep);
  }

  return str;
}


class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
    };

  }



  async componentDidMount() {
    var loan_id = this.props.dataFromParent;
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    this.loanDetails(loan_id, peer, token)
}

  async loanDetails(loan_id, peer, token) {
    try {
        const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/loancustomer?loanId=' + loan_id + "&peer=" + peer + "&token=" + token);
        const resp = res.data;
        console.log("loandetails", res.data);
        if (res.status === 200) {
            console.log("loandetails", resp);
            // const columns = resp;

            var result = resp;


            this.setState({ Customerdetails: result, loadingmodal: true });
            const message = "Successfully fetched the data";
            this.props.enqueueSnackbar(message, {
                variant: 'info',
                autoHideDuration: 2000,
            });
        } else {
            alert("Failed");
        }
    } catch (error) {
        alert("Failed");
        console.log("getLoans API error", error);
    }
}


  render() {


    return (

      <React.Fragment>


        <div>
          {this.state.Customerdetails != null && this.state.loadingmodal === true ?
            <Form id="labelform">
              <div className="row">
                <div className="col-4">
                  <Form.Group controlId="formGroupName">
                    <Form.Label>Borrower Name</Form.Label>
                    <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].BorrowerName)} />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <Form.Group controlId="formImage">
                    <Form.Label>S/o D/o W/o</Form.Label>
                    <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].BorrowerSODOWO)} />
                  </Form.Group>
                </div>

                <div className="col-4">
                  <Form.Group controlId="formGroupDescription">
                    <Form.Label>Age</Form.Label>
                    <Form.Control required disabled placeholder="" value={this.state.Customerdetails[0].AgeOfBorrower} />
                  </Form.Group>
                </div>
              </div>


              <div className="row">
                <div className="col-4">
                  <Form.Group controlId="formGroupName">
                    <Form.Label>Co-Borrower(s)/Guarantor(s) Name</Form.Label>
                    <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].CoBorrowerName)} />
                  </Form.Group>
                </div>
                <div className="col-4">
                  <Form.Group controlId="formImage">
                    <Form.Label>S/o D/o W/o</Form.Label>
                    <Form.Control required disabled placeholder="" value={myFunction(this.state.Customerdetails[0].CoBorrowerSODOWO)} />
                  </Form.Group>
                </div>

                <div className="col-4">
                  <Form.Group controlId="formGroupDescription">
                    <Form.Label>Age of co Borrower</Form.Label>
                    <Form.Control required disabled placeholder="" value={this.state.Customerdetails[0].AgeOfCoBorrower} />
                  </Form.Group>
                </div>
              </div>

            </Form>
            : <Loader msg={"Please wait, Loading Customer details"} />}
        </div>

      </React.Fragment>
    );
  }
}


export default withSnackbar(CustomerDetails);