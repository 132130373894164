import React, { Component } from 'react';
import UserHeader from '../../../components/header/header';
import Button from '@material-ui/core/Button';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import LinearLoader from '../../../components/loader/LinearLoader';
import Loader from '../../../components/loader';
import { widgets, CustomFieldTemplate, ObjectFieldTemplate } from '../../../components/customscripts/customscript';
import { GetAllDeals, generateYears, months, GroupByLima, InvestorVersion, InvestorReportStatus, ViewInvestorReport, PublishReport, invreporthistoryLima, CrateTable, InvestorDummyData, LimaInvestorIndexData, GeneratePDF } from '../../../servies/services';
import Table from '../../../components/Table';
import FirstTable from '../../../components/FirstTable';
import IndexTable from '../../../components/IndexTable';
import CreateAdditonalTable from '../../../components/CreateAdditonalTable';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ViewDragNDrop from './ViewDragNDrop';
import MUIDataTable from 'mui-datatables';
import CustomFooter from '../customize-footer/CustomFooter';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import $ from 'jquery';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PublishIcon from '@material-ui/icons/Publish';

const Form = withTheme(MuiTheme);
const schema = require('./schema.json');

class ViewInvestorReportComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: schema,
      getLoansLoader: false,
      years: generateYears(),
      months: months,
      groupby: GroupByLima,
      getLoader: false,
      loadingForm: false,
      DealType: localStorage.getItem('DealType'),
      OrgName: localStorage.getItem('OrgName'),
      user_id: localStorage.getItem('user_id'),
      pageTitle: "View Trustee Report",
      report_data: null,
      report_loader: false,
      version_name: null,
      version_list: [],
      version_loader: false,
      version_display: false,
      InvestorData: null,
      tableData: [],
      formData: {
        dealId: "",
        month: "",
        year: ""
      },
      getReportLoader: false,
      all_deals: JSON.parse(localStorage.getItem("all_deals")),
      report_box: false,
      getReportLoaderTable: null,
      ChannelName: localStorage.getItem('ChannelName'),
      report_status: 0,
      dashboard_bdb: false,
      loan_strat_report: false,
      loading: false,

    };
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(e) {
    this.setState({
      version_name: e.target.value
    });
  }

  async componentDidMount() {

    console.log("GroupByLima", this.state.groupby)
    console.log("componentDidMount DealId", this.props.match.params.DealId, this.props.match.params.DealMonth, this.props.match.params.DealYear);

    const DealId = this.props.match.params.DealId;
    const DealMonth = this.props.match.params.DealMonth;
    const DealYear = this.props.match.params.DealYear;
    this.callMethod(DealId, DealMonth, DealYear);

  }

  async componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps)
    let DealId = nextProps.match.params.DealId;
    let DealMonth = nextProps.match.params.DealMonth;
    let DealYear = nextProps.match.params.DealYear;
    this.callMethod(DealId, DealMonth, DealYear);
  }


  callMethod(DealId, DealMonth, DealYear) {
    const formData = {
      dealId: DealId,
      month: DealMonth,
      year: DealYear
    }

    this.setState({ formData: formData })
    const DealType = this.state.DealType
    this.GetAllDeals(DealType)

    if (DealId != "null" && DealId != undefined && DealMonth != "null" && DealMonth != undefined && DealYear != "null" && DealYear != undefined) {
      let value = {
        "formData": {
          "dealId": DealId,
          "month": DealMonth,
          "year": DealYear,
        }
      }
      // this.onSubmit(value);

      if (this.state.OrgName == "investor") {
        this.onSubmitInvestor(value)
      } else {
        this.onSubmit(value)
      }

    } else {
      this.setState({ version_display: false })
    }


  }



  getReport = async (dealId, month, year, version) => {

    console.log("getReport getReport getReport getReport getReport ", dealId, month, year, version)
    this.setState({ getReportLoaderTable: version })

    const DealType = this.state.DealType;
    // const version = this.state.version_name;
    const userid = this.state.user_id;
    const role = this.state.OrgName;
    this.setState({ version_name: version })

    const data = this.state.version_list.toString();
    const APIResponse = await InvestorReportStatus(DealType, dealId, month, year, version, data)
    if (APIResponse != null) {
      console.log("InvestorVersion", APIResponse.data)
      this.setState({ report_status: APIResponse.data })

      const InvestorData = await ViewInvestorReport(DealType, dealId, month, year, version, role, userid)
      if (InvestorData != null) {
        console.log("InvestorData", InvestorData.data)
        this.setState({ report_data: InvestorData.data, getReportLoader: true, report_box: true, getReportLoaderTable: null })

      }
      if (APIResponse.data == "1") {


      } else {

      }

    }

    

  }

  dashboard_bdb = async (dealId, month, year, version) => {
    this.setState({ dashboard_bdb: true, loan_strat_report: false })
  }

  loan_strat_report = async (dealId, month, year, version) => {
    this.setState({ dashboard_bdb: false, loan_strat_report: true })
  }



  publish_report = async (event) => {

    var Status = "1";
    const peers = JSON.parse(localStorage.getItem("peers"));
    const token = localStorage.getItem('token');
    const DealType = this.state.DealType;
    var input = {
      "DealID": this.state.formData.dealId,
      "month": this.state.formData.month,
      "year": this.state.formData.year,
      "version": this.state.version_name,
      "Status": Status,
      "peers": peers,
      "token": token,
      "channelname": this.state.ChannelName
    }

    const ConstGeneratePDF = await PublishReport(input, DealType)
    console.log("ConstGeneratePDF", ConstGeneratePDF)
    if (ConstGeneratePDF != null) {
      this.setState({ report_status: ConstGeneratePDF.data })

      const message = "Report published";
      this.props.enqueueSnackbar(message, {
        variant: 'info',
        autoHideDuration: 5000,
      });
    }
  }

  export_excel = async (event) => {

  }

  export_pdf = async (event) => {
    let DealType = this.state.DealType
    var dealId = this.state.dealId
    var month = this.state.month;
    var year = this.state.year;
    const data = $("#pdfdata").html();
    console.log("data", JSON.stringify(data))
    var input = {
      "dealId": dealId,
      "month": month,
      "year": year,
      "data": JSON.stringify(data),
      "channelname": localStorage.getItem('ChannelName'),
    }
    // var result = JSON.stringify(input);
    console.log("input input", input);

    const ConstGeneratePDF = await GeneratePDF(input, DealType)
    console.log("ConstGeneratePDF", ConstGeneratePDF)
    if (ConstGeneratePDF != null) {
      // alert(ConstGeneratePDF.data.filename)
      const file_name = ConstGeneratePDF.data.filename
      const filepath = process.env.react_app_base_url + "api/v1/wsfs/" + file_name
      downloadFile(filepath);

      function downloadFile(urlToSend) {
        var req = new XMLHttpRequest();
        req.open("GET", urlToSend, true);
        req.responseType = "blob";
        req.onload = function (event) {
          var blob = req.response;
          var fileName = file_name //if you have the fileName header available
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        };

        req.send();
      }
    }
  }


  onSubmit = async (value) => {

    this.setState({ version_loader: true, formData: value.formData })
    let data = value.formData;
    let dealId = value.formData.dealId;
    let month = value.formData.month;
    let year = value.formData.year;
    let groupby = value.formData.groupby;
    let OrgName = this.state.OrgName;
    const DealType = this.state.DealType;

    this.setState({ dealId: dealId, month: month, year: year })

    const APIResponse = await InvestorVersion(DealType, dealId, month, year, groupby, OrgName)
    if (APIResponse != null) {
      console.log("InvestorVersion", APIResponse.data)
      this.setState({ version_data: APIResponse.data, version_loader: false, version_display: true, loading: true })

      let version_list = []
      if (APIResponse.data.length !== 0) {
        APIResponse.data.reverse().map((item) => {
          console.log("item irVersion", item.irVersion);
          version_list.push(item.irVersion);
        })
      }

      console.log("version_list", version_list)
      this.setState({ version_list: version_list })
    }
  }


  onSubmitInvestor = async (value) => {

    this.setState({ version_loader: true, formData: value.formData })
    let dealId = value.formData.dealId;
    let month = value.formData.month;
    let year = value.formData.year;
    let groupby = value.formData.groupby;
    let OrgName = this.state.OrgName;
    const DealType = this.state.DealType;
    const version = null
    const data = null
    const userid = this.state.user_id;
    const role = this.state.OrgName;
    this.setState({ dealId: dealId, month: month, year: year })

    const APIResponse = await InvestorReportStatus(DealType, dealId, month, year, version, data)
    if (APIResponse != null) {
      console.log("InvestorReportStatus", APIResponse)

      if (APIResponse.data == "1") {
        this.setState({ report_status: APIResponse.data })
        const InvestorData = await ViewInvestorReport(DealType, dealId, month, year, version, role, userid)
        if (InvestorData != null) {
          console.log("InvestorData", InvestorData.data)
          this.setState({ report_data: InvestorData.data, getReportLoader: true, report_box: true, getReportLoaderTable: null })

        }
      } else {

        const message = "Report not published";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 5000,
        });

        this.setState({ version_loader: false })

      }




    }

    // this.setState({ dealId: dealId, month: month, year: year })

    // const APIResponse = await InvestorVersion(DealType, dealId, month, year, groupby, OrgName)
    // if (APIResponse != null) {
    //   console.log("InvestorVersion", APIResponse.data)
    //   this.setState({ version_data: APIResponse.data, version_loader: false, version_display: true })

    //   let version_list = []
    //   if (APIResponse.data.length !== 0) {
    //     APIResponse.data.reverse().map((item) => {
    //       console.log("item irVersion", item.irVersion);
    //       version_list.push(item.irVersion);
    //     })
    //   }

    //   console.log("version_list", version_list)
    //   this.setState({ version_list: version_list })
    // }
  }




  report_box = async (DealType) => {

    this.setState({ report_box: false, version_loader: false })

  }

  GetAllDeals = async (DealType) => {

    let all_deals = this.state.all_deals;
    let deal_name = []
    if (all_deals.length !== 0) {
      all_deals.map((item) => {
        console.log("all_deals item", item.deal_id);
        deal_name.push(item.deal_id);
      })
    }

    let month_name = []
    let month_value = []
    if (this.state.months.length !== 0) {
      this.state.months.map((item) => {
        console.log("item", item);
        month_name.push(item.label);
        month_value.push(item.value);
      })
    }

    let year_name = []
    let year_value = []
    if (this.state.years.length !== 0) {
      this.state.years.map((item) => {
        console.log("item", item);
        year_name.push(item.key);
        year_value.push(item.value);
      })
    }

    let oldSchema = this.state.schema;
    console.log("oldstagedata", oldSchema);
    oldSchema.properties.dealId.enum = deal_name;
    oldSchema.properties.dealId.enumNames = deal_name;

    oldSchema.properties.month.enum = month_value;
    oldSchema.properties.month.enumNames = month_name;

    oldSchema.properties.year.enum = year_value;
    oldSchema.properties.year.enumNames = year_name;

    const newSchema = Object.assign({}, oldSchema);
    console.log("WRITE oldSchema", newSchema);
    this.setState({ schema: newSchema, loadingForm: true });
  };





  render() {

    const columns = [
      {
        name: 'irDealId',
        label: 'Deal Name',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>
              {value}
            </div>
          ),
        },
      },
      {
        name: 'irVersion',
        label: 'Version',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>
              {value}
            </div>
          ),
        },
      },
      {
        name: 'irMonth',
        label: 'Month',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>
              {parseInt(value) + 1}
            </div>
          ),
        },
      },
      {
        name: 'irYear',
        label: 'Year',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>
              {value}
            </div>
          ),
        },
      },
      {
        name: 'irCreatedDate',
        label: 'Created Date',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>
              {value}
            </div>
          ),
        },
      },
      {
        name: 'irCreatedBy',
        label: 'Created By',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>
              {value}
            </div>
          ),
        },
      },
      {
        name: 'irVersion',
        label: 'Action',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>

              <Button variant='text' onClick={() => this.getReport(tableMeta.rowData[0], parseInt(tableMeta.rowData[2]) + 1, tableMeta.rowData[3], value)} color='primary' type='submit'>

                {this.state.getReportLoaderTable == value ? (
                  <React.Fragment>
                    Getting Report <CircularProgress size='20px' color='primary' />
                  </React.Fragment>

                ) : (
                    'View Report'
                  )}

              </Button>
            </div>
          ),
        },
      },
    ];

    const options = {
      responsive: 'stacked',
      filterType: 'dropdown',
      search: true,
      print: true,
      viewColumns: true,
      download: true,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
        return (
          <CustomFooter
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            textLabels={textLabels}
          />
        );
      },

      searchText: this.state.searchText,
      searchPlaceholder: 'Search Platform',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading: true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };

    return (
      <React.Fragment>
        <div className="page">
          <div className="content">
            <div className="header"><UserHeader pageTitle={this.state.pageTitle}></UserHeader>
              {this.state.getLoader == false ? '' : <LinearLoader></LinearLoader>}
            </div>

            {/* {JSON.stringify(this.state)} */}

            <div className="page-content text-center">
              {this.state.report_box == false ?
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <h3 className="title-page">{!this.state.pageTitle ? '' : this.state.pageTitle}</h3>
                    </div>
                    <div className="col-md-12">
                      <div class="threecolform">
                        {this.state.loadingForm == true ?
                          <Form
                            schema={this.state.schema}
                            onSubmit={this.state.OrgName == "investor" ? this.onSubmitInvestor : this.onSubmit}
                            onChange={this.onFormChanged}
                            widgets={widgets}
                            omitExtraData={true}
                            liveOmit={true}
                            FieldTemplate={CustomFieldTemplate}
                            formData={this.state.formData}
                            uiSchema={this.state.uiSchema}
                            ObjectFieldTemplate={ObjectFieldTemplate}
                          >
                            <div className='row justify-content-center'>
                              <Button variant='contained' color='primary' type='submit'>

                                {this.state.OrgName == "investor" ? 'View Report' : 'Display Versions'}

                                {this.state.version_loader == true ? <CircularProgress size='25px' color='primary' /> : ''}
                              </Button>
                              {this.state.getLoansLoader === true ? (
                                <CircularProgress size='30px' color='primary' />
                              ) : (
                                  ''
                                )}
                            </div>
                          </Form>
                          : <Loader></Loader>}
                      </div>
                    </div>
                  </div>

                  <React.Fragment>
                    {this.state.version_display == true ?
                      <div className="row custom-input justify-content-center">
                        <div className="col-md-12 mb-4" id="version_loader">
                          <MUIDataTable
                            title={''}
                            data={this.state.version_data}
                            columns={columns}
                            options={options}
                          />
                        </div>
                      </div>
                      : ' '}
                  </React.Fragment>

                </React.Fragment>
                :
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <p className=" float-left view_report_heading"> <strong> Viewing Report for {this.state.dealId} - {this.state.month} -  {this.state.year}

                        {this.state.OrgName == "investor" ? '' :
                          <React.Fragment>
                            -  {this.state.version_name}
                          </React.Fragment>
                        }



                      </strong> </p>
                      <Button className="float-right" variant='outlined' onClick={this.report_box} color='primary' type='submit'>
                        <ArrowBackIosIcon></ArrowBackIosIcon>

                        {this.state.OrgName == "investor" ? 'Back to deal list' : 'Back to version list'}
                      </Button>
                    </div>

                  </div>
                </React.Fragment>

              }

            </div>

            {this.state.report_box == false ? '' :
              <React.Fragment>
                {this.state.getReportLoader !== false ?

                  <React.Fragment>
                    {/* <ul>
                      <li> <Button variant='outlined' onClick={this.dashboard_bdb} color='primary' type='submit'> Dashboard (BDB)</Button> </li>
                      <li> <Button variant='outlined' onClick={this.loan_strat_report} color='primary' type='submit'> Loan Strat Report </Button> </li>
                  </ul> */}

                    <div className="page-content">
                      <div className="wrapper-pdf-container"></div>
                      <div className="view-report-header">
                        {/* {JSON.stringify(this.state.report_status)} */}

                        {this.state.OrgName == "investor" ? '' : <Button variant="outlined" color="primary" disabled={this.state.report_status == 0 ? false : true} onClick={this.publish_report}> <PublishIcon></PublishIcon> Publish Report</Button>}

                        <Button variant="outlined" color="primary" onClick={this.export_pdf}> <PictureAsPdfIcon></PictureAsPdfIcon> Export PDF</Button>
                        <Button variant="outlined" color="primary" onClick={this.export_excel}> <DescriptionIcon></DescriptionIcon> Export Excel</Button>
                      </div>
                      <div className="clearfix"></div>
                      <ViewDragNDrop
                        customize_data={this.state.report_data}
                        dealname={this.state.formData.dealId}
                        month={this.state.formData.month}
                        year={this.state.formData.year}
                        peers={this.state.peers}
                        token={this.state.token}
                        dealType={this.state.DealType}
                      >
                      </ViewDragNDrop>



                    </div>
                  </React.Fragment>
                  : ''}
              </React.Fragment>
            }

            {/* {this.state.report_loader == true ? <Loader></Loader> :
                  <React.Fragment>
                    {this.state.report_data == null ? '' :
                      <React.Fragment>
                        <FirstTable section_id={'first_page'} data={this.state.report_data.DealContactInformation} section_title={''} ></FirstTable>
                        <IndexTable section_id={'index_page'} data={LimaInvestorIndexData} section_title={''} ></IndexTable>
                        <Table section_id={'Date'} data={this.state.report_data.Date} section_title={'1 Date'} ></Table>
                        <Table section_id={'PaymentSummary'} data={this.state.report_data.PaymentSummary} section_title={'1 Payment Summary'} ></Table>
                        <Table section_id={'PrincipalPayments'} data={this.state.report_data.PrincipalPayments} section_title={'2 Principal Payments'} ></Table>
                        <Table section_id={'InterestPayments'} data={this.state.report_data.InterestPayments} section_title={'3 Interest Payments'} ></Table>
                        <Table section_id={'ClassFactors'} data={this.state.report_data.ClassFactors} section_title={'4 Class Factors'} ></Table>                        
                        <CreateAdditonalTable section_id={'AccountStatements'} data={this.state.report_data.AccountStatements} section_title={'5 AccountStatements'} ></CreateAdditonalTable>
                        <Table section_id={'CollateralSummary'} data={this.state.report_data.CollateralSummary} section_title={'5 Collateral Summary'} ></Table>
                        <Table section_id={'AcquisitionCriteria'} data={this.state.report_data.AcquisitionCriteria} section_title={'6 Acquisition Criteria'} ></Table>
                        <Table section_id={'AcquisitionCriteria1'} data={this.state.report_data.AcquisitionCriteria1} section_title={'7 Acquisition Criteria1'} ></Table>
                        <Table section_id={'PrePaymentsAndDefaultRates'} data={this.state.report_data.PrePaymentsAndDefaultRates} section_title={'8 PrePayments And Default Rates'} ></Table>
                        <Table section_id={'DealFeesAndExpenses'} data={this.state.report_data.DealFeesAndExpenses} section_title={'9 Deal Fees And Expenses'} ></Table>
                        <Table section_id={'PriorityOfPayments'} data={this.state.report_data.PriorityOfPayments} section_title={'10 Priority Of Payments'} ></Table>
                      </React.Fragment>
                    }
                  </React.Fragment>
                } */}






          </div>
        </div>


      </React.Fragment>


    );
  }
}

export default withSnackbar(ViewInvestorReportComp)