/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import MUIDataTable from 'mui-datatables';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { NavLink } from 'react-router-dom';

import Header from '../../../components/header/header';
import { withSnackbar } from 'notistack';
// import { PlatformList } from '../../../components/StaticData';
import TextField from '@material-ui/core/TextField';

import Loader from '../../../components/loader';
import Tooltip from '@material-ui/core/Tooltip';
import CustomFooter from '../customize-footer/CustomFooter';

import AddNewReport from '../../../images/icons/add-new-report.svg';

import GenerateInvestorReport from '../../../images/icons/generate-investor-report.svg';

import IconReport from '../../../images/icons/icon-report.svg';

import ViewBlockchain from '../../../images/icons/view-blockchain.svg';
import ViewCustomizeInvestorReport from '../../../images/icons/view-customize-investor-report.svg';
import ViewInvestorReport from '../../../images/icons/view-investor-report.svg';

import ViewMongdb from '../../../images/icons/view-mongdb.svg';
import SidebarDrawer from './SidebarDrawer';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import AssessmentIcon from '@material-ui/icons/Assessment';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
      userid: localStorage.getItem("userid"),
      OrgName: localStorage.getItem('OrgName'),
      DealType: localStorage.getItem('DealType'),
      loader: false,
      getLoansLoader: false,
      open: false,
      message: '',
      table_data: [],
      all_deals: JSON.parse(localStorage.getItem("all_deals")),
      channelname: localStorage.getItem('ChannelName'),
      drawer: false,
      total_deals:null
    };
    this.childHandler = this.childHandler.bind(this)

  }

  async componentDidMount() {
    const userid = this.state.userid;
    const DealType = this.state.DealType
    this.GetAllDeals(DealType);
  }

  childHandler(dataFromChild) {
    // log our state before and after we updated it
    console.log('%cPrevious Parent State: ' + JSON.stringify(this.state), "color:orange");
    this.setState({ drawer: false })
  }




  GetAllDeals = async (DealType) => {
    console.log("this.state.all_deals", this.state.all_deals);
    let all_deals = this.state.all_deals;
    this.setState({ loading: true, table_data: all_deals, total_deals:all_deals.length });
  };

  OpenDrawer = (pageUrl, deal_id, pagetitle) => {
    console.log("data OpenDrawer", pageUrl, deal_id)
    this.setState({ drawer: true, pagetitle: pagetitle, pageUrl: pageUrl, deal_id: deal_id })

    {/* <NavLink to={"/report/" + this.state.DealType + "/customize-investor-report/" + tableMeta.rowData[0] + "/" + null + "/" + null + ""}>asdas</NavLink> */ }
  }



  render() {

    const columnsInvestor = [
      {
        name: 'deal_id',
        label: 'Deal Name',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>

              {value}

            </div>
          ),
        },
      },
      {
        name: 'deal_id',
        label: 'Trustee Report',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div style={{ position: 'relative' }}>
              <React.Fragment>

                <Tooltip title="Trustee Report">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-loan-data-tape/monthly-trustee-report', tableMeta.rowData[0], "Trustee Report")}>
                    <VisibilityIcon></VisibilityIcon>
                  </Button>
                </Tooltip>

              </React.Fragment>
            </div>
          ),
        }
      },

      {
        name: 'deal_id',
        label: 'Loan Strats',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div style={{ position: 'relative' }}>
              <React.Fragment>

              <Tooltip title="Loan Strats">
                  <NavLink to={"/report/" + this.state.DealType + "/view-loan-data-tape/loan-strat-analytics/" + tableMeta.rowData[0] + "/" + null + "/" + null + "/BorrowerState"}>
                      <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-loan-data-tape/loan-strat-analytics', tableMeta.rowData[0], "Loan Strats")}> 
                        {/* <img src={IconReport} alt="Loan Strats" /> */}
                        <AssessmentIcon></AssessmentIcon>
                      </Button>
                  </NavLink>
                </Tooltip>

              </React.Fragment>
            </div>
          ),
        }
      },
      {
        name: 'deal_id',
        label: 'Servicer Summary',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div style={{ position: 'relative' }}>
              <React.Fragment>

                <Tooltip title="Servicer Summary">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('download-servicer-data', tableMeta.rowData[0], "Servicer Summary")}>
                    <GetAppIcon></GetAppIcon>
                  </Button>
                </Tooltip>

              </React.Fragment>
            </div>
          ),
        }
      },
      // {
      //   name: 'deal_id',
      //   label: 'Loan Strat Report',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta) => (
      //       <div style={{ position: 'relative' }}>
      //         <React.Fragment>

      //           <Tooltip title="Loan Strat Report">
      //             <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-loan-data-tape/loan-strat-report', tableMeta.rowData[0], "Loan Strat Report")}>
      //               <VerticalSplitIcon></VerticalSplitIcon>
      //             </Button>
      //           </Tooltip>

      //         </React.Fragment>
      //       </div>
      //     ),
      //   }
      // },

      
    ];

    const columns = [
      {
        name: 'deal_id',
        label: 'Deal Name',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div>

              {value}

            </div>
          ),
        },
      },

      {
        name: 'deal_id',
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div className="action_col" style={{ position: 'relative' }}>
              <React.Fragment>

                <Tooltip title="Add New Report">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('add-new', tableMeta.rowData[0], "Add New Report")}>
                    <img src={AddNewReport} alt="Add New Report" />
                  </Button>
                </Tooltip>

                <Tooltip title="View Servicer Data From Database ">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-servicer-mongodb-data', tableMeta.rowData[0], "View Servicer Data From Database")}>
                    <img src={ViewMongdb} alt="View Servicer Data From Database" />
                  </Button>
                </Tooltip>

                <Tooltip title="Validate Monthly Inputs">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('validate-monthly-inputs', tableMeta.rowData[0], "Validate Monthly Inputs")}>
                    <img src={AddNewReport} alt="Validate Monthly Inputs" />
                  </Button>
                </Tooltip>

                <Tooltip title="View Servicer Data From Network">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-servicer-blockchain-data', tableMeta.rowData[0], "View Servicer Data From Network")}>
                    <img src={ViewBlockchain} alt="View Servicer Data From Network" />
                  </Button>
                </Tooltip>

                <Tooltip title="Generate Trustee Report">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('generate-investor-report', tableMeta.rowData[0], "Generate Trustee Report")}>
                    <img src={GenerateInvestorReport} alt="Generate Trustee Report" />
                  </Button>
                </Tooltip>

                <Tooltip title="Customize Trustee Report">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('customize-investor-report', tableMeta.rowData[0], "Customize Trustee Report")}>
                    <img src={ViewCustomizeInvestorReport} alt="Customize Trustee Report" />
                  </Button>
                </Tooltip>

              </React.Fragment>
            </div>
          ),
        }
      },
      {
        name: 'deal_id',
        label: 'Trustee Report',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div style={{ position: 'relative' }}>
              <React.Fragment>

                <Tooltip title="Trustee Report">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-loan-data-tape/monthly-trustee-report', tableMeta.rowData[0], "Trustee Report")}>
                    <VisibilityIcon></VisibilityIcon>
                  </Button>
                </Tooltip>

              </React.Fragment>
            </div>
          ),
        }
      },
      

      {
        name: 'deal_id',
        label: 'Loan Strats',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div style={{ position: 'relative' }}>
              <React.Fragment>
                <Tooltip title="Loan Strats">
                  <NavLink to={"/report/" + this.state.DealType + "/view-loan-data-tape/loan-strat-analytics/" + tableMeta.rowData[0] + "/" + null + "/" + null + "/BorrowerState"}>
                      <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-loan-data-tape/loan-strat-analytics', tableMeta.rowData[0], "Loan Strats")}>
                        {/* <img src={IconReport} alt="Loan Strats" /> */}
                        <AssessmentIcon></AssessmentIcon>
                      </Button>
                  </NavLink>
                </Tooltip>
              </React.Fragment>
            </div>
          ),
        }
      },

      {
        name: 'deal_id',
        label: 'Servicer Summary',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div style={{ position: 'relative' }}>
              <React.Fragment>

                <Tooltip title="Servicer Summary">
                  <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('download-servicer-data', tableMeta.rowData[0], "Servicer Summary")}>
                    <GetAppIcon></GetAppIcon>
                  </Button>
                </Tooltip>

              </React.Fragment>
            </div>
          ),
        }
      },

      // {
      //   name: 'deal_id',
      //   label: 'Loan Strat Report',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta) => (
      //       <div style={{ position: 'relative' }}>
      //         <React.Fragment>

      //           <Tooltip title="Loan Strat Report">
      //             <Button variant="outlined" color="primary" onClick={() => this.OpenDrawer('view-loan-data-tape/loan-strat-report', tableMeta.rowData[0], "Loan Strat Report")}>
      //               <VerticalSplitIcon></VerticalSplitIcon>
      //             </Button>
      //           </Tooltip>

      //         </React.Fragment>
      //       </div>
      //     ),
      //   }
      // },

      
    ];

    const options = {
      responsive: 'stacked',
      filterType: 'dropdown',
      search: true,
      print: true,
      viewColumns: true,
      download: true,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      draggableColumns: {
        enabled: true
      },

      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
        return (
          <CustomFooter
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            textLabels={textLabels}
          />
        );
      },

      searchText: this.state.searchText,
      searchPlaceholder: 'Search Deal',
      loading: true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };

    return (
      <React.Fragment>
        <div className="page">
          <div className="content">
            <div className="header"><Header pageTitle={'Dashboard'} total_deals={this.state.total_deals}></Header></div>

            {/* <div className="tableSearch">
              <div className="row justify-content-left">
                <div className="col-md-4 col-sm-12">
                  <div className="" id="searchBox">
                    <TextField id="outlined-basic" label="Search for Deal Name" variant="outlined" size="small" /></div>
                </div>
                <div className="col-md-6 col-sm-12">
                </div>
              </div>
            </div> */}

            <div className="page-content">
              <MUIDataTable
                title={''}
                data={this.state.table_data}
                columns={this.state.OrgName != "investor" ? columns : columnsInvestor}
                options={options}
              />


              {this.state.drawer == false ? '' :
                <SidebarDrawer
                  drawer={this.state.drawer}
                  pagetitle={this.state.pagetitle}
                  pageUrl={this.state.pageUrl}
                  deal_id={this.state.deal_id}
                  action={this.childHandler}
                >
                </SidebarDrawer>
              }


            </div>

          </div>
        </div>



      </React.Fragment>
    );
  }
}

export default withSnackbar(Dashboard);