/* eslint-disable require-jsdoc */
import React, { Component } from 'react';

import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { withSnackbar } from 'notistack';

class CollectionHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
    };

  }
  
 

  async componentDidMount() {
    var loan_id = this.props.dataFromParent;
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    this.loanDetails(loan_id, peer, token)
}

  async loanDetails(loan_id, peer, token) {
    try {
        const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/loancollection?loanId=' + loan_id + "&peer=" + peer + "&token=" + token);
        const resp = res.data;
        console.log("loandetails", res.data);
        if (res.status === 200) {
            console.log("loandetails", resp);
            var result = resp;


            this.setState({ getLoansLoader: false, open: true, loading: true,Collectiondetails: resp });
            const message = "Successfully fetched the data";
            this.props.enqueueSnackbar(message, {
                variant: 'info',
                autoHideDuration: 2000,
            });
        } else {
            alert("Failed");
        }
    } catch (error) {
        alert("Failed");
        console.log("getLoans API error", error);
    }
}

  render() {
 

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("valuee" + selected);
        console.log(allRows);
        this.setState({
          rowsSelected: selected,


        });
      },


      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'InstallmentNumber',
        label: 'Installment Number',
        options: {
          filter: true,
          sort: true,
        },
      },
    
      {
        name: 'Currency',
        label: 'Currency',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'EmiPaid',
        label: 'EMI Paid',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OutstandingPrincipalBalance',
        label: 'Outstanding Principle Balance',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'InterestAmountRepaid',
        label: 'Interest Amount Repaid',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'PrincipalAmountRepaid',
        label: 'Principle Amount Repaid',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'OverdueEMINumbers1To30Days',
        label: 'Overdue EMI Numbers 1 to 30 Days',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OverdueEMINumbers31To60Days',
        label: 'Overdue EMI Numbers 31 to 60 Days',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OverdueEMINumbers61To90Days',
        label: 'Overdue EMI Numbers 61 to 90 Days',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OverdueEMINumbers91To120Days',
        label: 'Overdue EMI Numbers 91 to 120 Days',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OverdueEMINumbers121To180Days',
        label: 'Overdue EMI Numbers 121 to 180 Days',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OverdueEMINumbers180PlusDays',
        label: 'Overdue EMI Numbers 180 Plus Days',
        options: {
          filter: true,
          sort: false,
        },
      },

    ];



    return (
     
      <React.Fragment>
       
            
            <div className="page-content">

              {this.state.loading === true ?
                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

                : <Loader msg={"Please wait, Loading collection history data"} />}

              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}

            </div>
       
       
      </React.Fragment>
    );
  }
}


export default withSnackbar(CollectionHistoryTable);