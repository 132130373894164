/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar';
import TextField from '@material-ui/core/TextField';
// import { Dropdown } from 'react-bootstrap';
// import { Form as form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import Loader from '../../../../components/loader';
import Snackbar from '../../../../components/snackbar';
import axios from "axios";
import Modal from 'react-responsive-modal';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import Menu from '@material-ui/core/Menu';
import FormLoader from '../../../../components/loader/formLoader';
import MenuItem from '@material-ui/core/MenuItem';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
// import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import ReactModal from 'react-modal';
import { widgets, CustomFieldTemplate, customStyles, customStylesauto } from '../../../../components/customscripts/customscript'

import { withSnackbar } from 'notistack';


// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { Select } from '@material-ui/core';
// import {

//   Redirect,
//   NavLink
// } from "react-router-dom";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import DocumentDetailsTable from "./documentDetails";
// import $ from 'jquery';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import DropdownToggle from 'react-bootstrap/DropdownToggle';
const Form = withTheme(MuiTheme);
const schema = require('./schema.json');
// const docattributes = require('./docattributes.json');



class PaymentWaterfall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waterfall: null,
      loadingmodal: false,
      selected: [],
      loading: false,
      loader: false,
      getLoansLoader: false,
      openx:false,
      message:null,
      open1: false,
      open2: false,
      open3: false,
      disabled: true,
      Redirect: false,
      schema: schema,

      assignbuttonbtn: sessionStorage.getItem("assignbutton"),
      assignbutton: true,
      searchText: '',
      rowsSelected: null,
    };

  }
  state = {
    // waterfall:[],
    open1: false,
    open2: false,
    open3: false,
    Redirect: false,
    poolId: null,
    hashValue: null,
    fileName: null,
    filePath: null
  }




  onOpenModal1 = () => {
    console.log("inside modal");
    this.setState({ open1: true ,openx:false,
      message:null,});
  };

  onCloseModal1 = () => {
    this.setState({ open1: false });
  };

  async onOpenModal2(value) {
    const peer = localStorage.getItem("peer");
		const token = localStorage.getItem('token');
    console.log("inside modal");
    this.setState({ open2: true, loadingmodal: false ,msg:""});

    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var date = new Date();

    var month = months[date.getMonth()];
    console.log(month);
    try {

      const res = await axios.get(process.env.react_app_base_url + 'api/V1/paymentwaterfall?poolId='+value+"&month="+month +"&peer="+peer+ "&token="+token);
      const resp = res.data;
      console.log(resp)
      if (res.status === 204) {
        this.setState({
          loadingmodal: true,msg:true,open2:false
        })
        const message = "Data not available";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
    }
    else if(res.status== 200){
      if(res.data.token == '-1'){
    
        const message = "Invalid Token!";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
      this.props.history.push({
        pathname: '/'
      })
    
      } else {
        
      if (resp[0] !== undefined && resp[0].length !==0) {
        console.log("poolloans", resp.length);
        // const columns = resp;
        var Currency = resp[resp.length-1].Currency;
        var TotalCollections = resp[resp.length-1].TotalCollections;
        var TaxPayment = resp[resp.length-1].TaxPayment;
        var PaymentOfReimbursableExpenses = resp[resp.length-1].PaymentOfReimbursableExpenses;
        var PaymentOfServicingFeesToServicer = resp[resp.length-1].PaymentOfServicingFeesToServicer;
        var InterestPayableToClassA = resp[resp.length-1].InterestPayableToClassA;
        var PrincipalPayableToClassA = resp[resp.length-1].PrincipalPayableToClassA;
        var InterestPayableToClassB = resp[resp.length-1].InterestPayableToClassB;
        var PrincipalPayableToClassB = resp[resp.length-1].PrincipalPayableToClassB;
        var InterestPayableToClassC = resp[resp.length-1].InterestPayableToClassC;
        var PrincipalPayableToClassC = resp[resp.length-1].PrincipalPayableToClassC;
        var BalanceAsIncomeOfClassC = resp[resp.length-1].BalanceAsIncomeOfClassC;
        const message = "Data fetched successfully!";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 2000
      });
        this.setState({
          loadingmodal: true,msg:false, Currency: Currency, TotalCollections: TotalCollections, TaxPayment: TaxPayment, PaymentOfReimbursableExpenses: PaymentOfReimbursableExpenses, PaymentOfServicingFeesToServicer: PaymentOfServicingFeesToServicer, InterestPayableToClassA: InterestPayableToClassA, PrincipalPayableToClassA: PrincipalPayableToClassA
          , InterestPayableToClassB: InterestPayableToClassB, PrincipalPayableToClassB: PrincipalPayableToClassB, PrincipalPayableToClassB: PrincipalPayableToClassB, InterestPayableToClassC: InterestPayableToClassC, PrincipalPayableToClassC: PrincipalPayableToClassC, BalanceAsIncomeOfClassC: BalanceAsIncomeOfClassC
        });
      } else {
        this.setState({
          loadingmodal: true,msg:true, Currency: "", TotalCollections: "", TaxPayment: "", PaymentOfReimbursableExpenses: "", PaymentOfServicingFeesToServicer: "", InterestPayableToClassA: "", PrincipalPayableToClassA: ""
          , InterestPayableToClassB: "", PrincipalPayableToClassB: "", PrincipalPayableToClassB: "", InterestPayableToClassC: "", PrincipalPayableToClassC: "", BalanceAsIncomeOfClassC: ""
        });
      }
    }
  }
    } catch (error) {
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }
    }


  };

  onCloseModal2 = () => {
    this.setState({ open2: false });
  };



   onSubmit= async (value) =>{
    this.setState({ getLoansLoader: true })
    console.log('onSubmit:', value.formData)
    let data = value.formData
    console.log('onSubmit data:', data)
    var PoolID = sessionStorage.getItem("PoolID")
    console.log(PoolID);
    const peers = JSON.parse(localStorage.getItem("peers"));
		const token = localStorage.getItem('token');
    var input = {
      "poolId": PoolID,
      "totalCollections": data.TotalMonthCollection,
      "taxPayment": data.TaxPayment,
      "reimbursableExpenses": data.PaymentOfReimbursableExpenses,
      "servicingFees": data.PaymentOfservicingFeestoservicer,
      "currency": data.currency,
      "peers":peers,
      "token":token
    }
    var result = JSON.stringify(input);
    // const result = JSON.stringify(x);

    axios(process.env.react_app_base_url + 'api/V1/paymentwaterfall', {
      method: 'POST',
      data: result,
      dataType: 'json',
      processData: true,
      headers: {
        // Accept:"application/json",
        "Content-Type": "application/json"

      }

    }).then(res => {
      console.log(res.data);
      if (res.status === 204) {
        this.setState({ getLoansLoader: false })
        const message = "Investor has to approve the tranche!";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
    }
    else if(res.status== 200){
      if(res.data.token == '-1'){
        this.setState({ getLoansLoader: false })

        const message = "Invalid Token!";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
      this.props.history.push({
        pathname: '/'
      })
    
      } else {
      if (res.data.isSuccess === "true") {
        //alert("hii");
        this.setState({ getLoansLoader: false })

        const message = "Data saved successfully!";
        this.props.enqueueSnackbar(message, { 
          variant: 'info',
          autoHideDuration: 2000
      });
        this.setState({  openx: true , message:res.data.message });

      }
      else {
        this.setState({ getLoansLoader: false })

        const message = "Data not saved";
        this.props.enqueueSnackbar(message, { 
          variant: 'error',
          autoHideDuration: 2000
      });
        //alert("Failed");
        this.setState({ openx: true, message:res.data.message});

      }
    }
  }

    }).catch(error => { 
      if (error.response.status == 400) {
        this.setState({ getLoansLoader: false })

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {
      this.setState({ getLoansLoader: false })

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }
    })
  }

  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);


  };
  async componentDidMount() {
    const token = localStorage.getItem('token');
  
    const peer = localStorage.getItem('peer')
    sessionStorage.setItem("assignbutton", true)

    this.setState({ getLoansLoader: true, assignbutton: true });
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/pool/pools?peer='+peer+"&token="+token);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        // const columns = resp;
        const message = "Successfully fetched the data";     
        this.props.enqueueSnackbar(message, { 
          variant: 'info',
          autoHideDuration: 2000,
      });


        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
      } else {
        alert("Failed");
      }
    } catch (error) {
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }
    }



  };


  async selectedpoolid(selected) {
    const arr = []


    for (var i = 0; i < selected.length; i++) {
      // console.log('loanID = i', i)
      var j = selected[i];
      let PoolID = this.state.tableData[j].PoolID;
      arr.push(PoolID);
    }

    console.log(arr);
    sessionStorage.setItem("PoolID", arr);
    // this.setState({
    //   PoolID:arr
    // })


  }


  render() {
    const { open1 } = this.state;
    const { open2 } = this.state;
    const { open3 } = this.state;
    // const {waterfall}=this.state;

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: true,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },
      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading: true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'PoolID',
        label: 'Pool ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'Poolowner',
        label: 'Pool Owner Name',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'PoolName',
        label: 'Pool Name',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'PoolDesc',
        label: 'Pool Description',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'PoolStartedDate',
        label: 'Pool Start Date',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'NoOfAssets',
        label: 'No. of Assets',
        options: {
          filter: true,
          sort: false,
        },
      },



      {
        name: "PoolID",
        label: 'View',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (

              <React.Fragment>


                {/* <MenuItem onClick={popupState.close}> {value}</MenuItem> */}
                {/* <p id="viewlink" onClick={() => this.onOpenModal2(value)}>Payment waterfall Detail</p> */}
                <Button variant="outlined" id="viewlink" onClick={() => this.onOpenModal2(value)}>Payment waterfall Detail</Button>
                {/* <MenuItem onClick={() => this.onOpenModal2(value)}><img src={require('../../../images/Upload.png')} style={{ marginRight: "7px" }} ></img>Upload Documents</MenuItem> */}
                {/* <MenuItem onClick={() => this.onOpenModal3(value)}><img src={require('../../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View Documents</MenuItem> */}

              </React.Fragment>





            );

          },

        }
      },
    ];


    // const btn = sessionStorage.getItem("assignbutton");
    const btn = JSON.stringify(this.state.assignbuttonbtn)

    return (

      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Payment Waterfall'}></Header></div>
            <div className="tableSearch">

              <div className="row">
                <div className="justify-content-left " id="assignpoolbutton">

                  {this.state.rowsSelected !== null && this.state.rowsSelected.length !== 0 ?
                    <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                      onClick={this.onOpenModal1.bind(this)} color="primary" type="submit" >Expenses Payment</Button> : <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                        onClick={this.onOpenModal1.bind(this)} disabled color="primary" type="submit" >Expenses Payment</Button>}
                </div>

                <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}

                </div>

                <div className="col-md-6 col-sm-12">

                </div>

              </div>
            </div>
            <div className="page-content">


              <React.Fragment>
                <MUIDataTable
                  data={this.state.tableData}
                  columns={columns}
                  options={options}

                />

              </React.Fragment>


              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
            </div>
          </div>
        </div>
        <div >
          <ReactModal
            isOpen={this.state.open1}
            contentLabel="Minimal Modal Example"
            style={customStylesauto}
            onRequestClose={this.onCloseModal1}

          >
            <React.Fragment>
              <div className="modalPopup">
                <h2>Expenses Payment</h2>
                <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>



                <div>
                  <Form
                    schema={this.state.schema}
                    // uiSchema={uiSchema}
                    // formData={formData}
                    onSubmit={this.onSubmit}
                    onChange={this.onFormChanged}
                    widgets={widgets}
                    FieldTemplate={CustomFieldTemplate}
                  >

                    <div className="modalsubmit">
                      <div className="submitbuttonbg">
                        <hr className="hrbottom" />
                        <div className="row justify-content-end">

                          <Button variant="contained" id="optionalbutton" onClick={this.onCloseModal1} > Cancel  </Button>

                          <Button variant="contained" color="primary" type="submit" >Submit</Button>
                        </div>
                      </div>
                    </div>
                    {this.state.getLoansLoader === false ? '' : <FormLoader  />}

                  </Form>


                </div>
                {/* {this.state.openx === true ? <React.Fragment><Snackbar msg={this.state.message} open="true" /> </React.Fragment> : ' '} */}
                {/* {this.state.openx === false ? <React.Fragment><Snackbar msg={this} open="true" /> </React.Fragment> : ' '} */}



              </div>
            </React.Fragment>
          </ReactModal>


          <ReactModal
            isOpen={this.state.open2}
            contentLabel="Minimal Modal Example"
            style={customStyles}
            onRequestClose={this.onCloseModal2}

          >
            <React.Fragment>
              <div className="modalPopup">
                <h2>Payment Waterfall Details</h2>
                <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal2}> <CloseIcon></CloseIcon> </Button>

                {this.state.loadingmodal === true ?
                  <div >
                    <table id="table" className=" table table-striped table-bordered">

                      <tbody>
                        <tr>
                          <td id="waterfalldetails">Currency</td>
                          <td>{this.state.Currency}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Total Monthly Collection</td>
                          <td>{this.state.TotalCollections}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Tax Payment</td>
                          <td>{this.state.TaxPayment}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails" >Payment of Reimbursable Expenses</td>
                          <td>{this.state.PaymentOfReimbursableExpenses}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Payment of Servicing Fees to Servicer</td>
                          <td>{this.state.PaymentOfServicingFeesToServicer}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Payment of interest payable under Class A Notes</td>
                          <td>{this.state.InterestPayableToClassA}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Payment of principle of Class A </td>
                          <td>{this.state.PrincipalPayableToClassA}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Payment of interest payable under Class B Note</td>
                          <td>{this.state.InterestPayableToClassB}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Payment of principle of Class B</td>
                          <td>{this.state.PrincipalPayableToClassB}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Payment of interest payable under Class C Note</td>
                          <td>{this.state.InterestPayableToClassC}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Payment of principle of Class C</td>
                          <td>{this.state.PrincipalPayableToClassC}</td>

                        </tr>
                        <tr>
                          <td id="waterfalldetails">Balance as income of Class C Notes</td>
                          <td>{this.state.BalanceAsIncomeOfClassC}</td>

                        </tr>
                      </tbody>
                    </table>

                  </div>

                  : <Loader msg={"Please wait, Loading data"} />}
              {/* {this.state.open === true && this.state.msg === true? <React.Fragment><Snackbar msg={"Record not found"} open="true" /> </React.Fragment> : ' '} */}
              {/* {this.state.open === true && this.state.msg === false? <React.Fragment><Snackbar msg={"Record fetched successfully"} open="true" /> </React.Fragment> : ' '} */}
             


              </div>
            </React.Fragment>
          </ReactModal>


        </div>

      </React.Fragment>
    );
  }
}

export default withSnackbar(PaymentWaterfall);
