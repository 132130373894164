import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Button from '@material-ui/core/Button';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form as form } from 'react-bootstrap';
import { withSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import LinearLoader from '../../../components/loader/LinearLoader';
import Loader from '../../../components/loader';
import { GetAllDeals, generateYears, months, UploadServicerReport, ServicerData, ServicerDataAddNewReport1, ServicerDataAddNewReport2, ServicerDataAddNewReport3, ServicerDataAddNewReport4 } from '../../../servies/services';
import Select from '@material-ui/core/Select';

class addNewReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getLoansLoader: false,
      open: false,
      years: generateYears(),
      fields: {
        dealId: null,
        month: null,
        year: null,
      },
      file: null,
      pageTitle: "Add New Report",
      getLoader: false,
      token: localStorage.getItem("token"),
      user_id: localStorage.getItem("user_id"),
      OrgName: localStorage.getItem('OrgName'),
      DealType: localStorage.getItem('DealType'),
      peers: JSON.parse(localStorage.getItem('peers')),
      deal_name: [],
      all_deals: JSON.parse(localStorage.getItem("all_deals")),
      form_loader: false,
      channelname: localStorage.getItem('ChannelName'),
    };

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e) {
    const fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });

    console.log('fields', this.state.fields);

  }


  handleDoc = (e) => {

    console.log("{JSON.stringify(e.target.files[0])}", JSON.stringify(e.target.files[0]))
    this.setState({ file: e.target.files[0] });

  }

  componentDidMount() {

    const dealId = this.props.match.params.DealId;
    const month = this.props.match.params.DealMonth;
    const year = this.props.match.params.DealYear;
    console.log("dealId dealMonth dealyear componentDidMount", dealId, month, year);
    const fields = {
      dealId: dealId,
      month: month,
      year: year,
    }

    this.setState({ fields: fields });
    const DealType = this.state.DealType;
    console.log("setTimeout", Date().toLocaleString())
    this.setTimeout(DealType)
  }

  setTimeout = (DealType) => (
    
    this.setState({ getLoader: true }),

    setTimeout(() => {
      console.log("setTimeout", Date().toLocaleString())
      this.GetAllDeals(DealType)
    }, 3000)

);



  componentWillReceiveProps(nextProps) {
    const dealId = nextProps.match.params.DealId;
    const month = nextProps.match.params.DealMonth;
    const year = nextProps.match.params.DealYear;
    console.log("dealId dealMonth dealyear componentWillReceiveProps", dealId, month, year);
    const fields = {
      dealId: dealId,
      month: month,
      year: year,
    }

    this.setState({ fields: fields });

  }


  onSubmit = () => {
    console.log("fields", this.state.fields);
    const fields = this.state.fields
    if (this.state.fields.dealId == "null" ||  this.state.fields.month == "null"  ||  this.state.fields.year == "null" || this.state.file == null) {

      const message = "Please fill the required fields";
      this.props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 4000,
      });

    } else {

      this.props.history.push("/report/" + this.state.DealType + "/add-new/" + this.state.fields.dealId + "/" + this.state.fields.month + "/" + this.state.fields.year);
      this.setState({ form_loader: true })
      const data = {
        "dealId": this.state.fields.dealId,
        "month": this.state.fields.month,
        "year": this.state.fields.year,
        "userId": this.state.userId,
        "token": this.state.token,
        "peers": this.state.peers,
        "filetype": ".xls",
        "updatedBy": this.state.user_id,
        "channelname":this.state.channelname,
      }

      this.UploadServicerReportMethod(data);

    }

  }


  GetAllDeals = async (DealType) => {


    this.setState({ getLoader: true })
    const APIResponse = await GetAllDeals(DealType)
    console.log("APIResponse GetAllDeals GetAllDeals", APIResponse, this.state.years, this.state.months)
    if (APIResponse != null) {

      let deal_name = []
      if (APIResponse.data.length !== 0) {
        APIResponse.data.map((item) => {
          console.log("item", item);
          deal_name.push({ "deal_id": item });
        })
      }

      localStorage.setItem('all_deals', JSON.stringify(deal_name));
      this.setState({ getLoader: false })
      this.setState({ deal_name: deal_name })
    }

  };



  async UploadServicerReportMethod(data) {
    const DealType = this.state.DealType;
    const newdata = new FormData();
    newdata.append('filename', this.state.file);
    const constUploadServicerReport = await UploadServicerReport(newdata, DealType);
    if (constUploadServicerReport != null) {
      console.log("UploadServicerReport", constUploadServicerReport.data);
      if (constUploadServicerReport.data.isSuccess == true) {
        this.ServicerDataAddNewReportMethod(data, DealType);
      } else {
        const message = "Data not saved successfully";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  }

  async ServicerDataAddNewReportMethod(data, DealType) {
    const ConstServicerDataAddNewReport1 = await ServicerData(data, DealType);
    if (ConstServicerDataAddNewReport1 != null) {
      console.log("ServicerData", ConstServicerDataAddNewReport1.data);
      if (ConstServicerDataAddNewReport1.data.success == true) {
        console.log("Servicer Data DONE")
        const message = "Data saved successfully";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 3000,
        });
              this.props.history.push("/report/" + this.state.DealType + "/view-servicer-mongodb-data/" + this.state.fields.dealId + "/" + this.state.fields.month + "/" + this.state.fields.year);

      } else {
        const message = "Data not saved successfully";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }

    }
  }


  // async ServicerDataAddNewReportMethod3(data, res, DealType) {
  //   let newdata = data
  //   newdata.result2 = res.result1;
  //   const ConstServicerDataAddNewReport2 = await ServicerDataAddNewReport3(newdata, DealType);
  //   if (ConstServicerDataAddNewReport2 != null) {
  //     console.log("ServicerDataAddNewReportMethod3", ConstServicerDataAddNewReport2.data);
  //     if (ConstServicerDataAddNewReport2.data.isSuccess == true) {
  //       let res3 = ConstServicerDataAddNewReport2.data;
  //       this.ServicerDataAddNewReportMethod4(data, res3, DealType);
  //     } else {
  //       const message = "Data not saved successfully";
  //       this.props.enqueueSnackbar(message, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       });
  //     }
  //   }
  // }


  // async ServicerDataAddNewReportMethod4(data, res3, DealType) {
  //   let newdata = data
  //   newdata.result3 = res3.result3;
  //   const ConstServicerDataAddNewReport4 = await ServicerDataAddNewReport4(newdata, DealType);
  //   if (ConstServicerDataAddNewReport4 != null) {
  //     console.log("ServicerDataAddNewReportMethod4", ConstServicerDataAddNewReport4.data);
  //     if (ConstServicerDataAddNewReport4.data.success == true) {
  //       const message = "Data saved successfully";
  //       this.props.enqueueSnackbar(message, {
  //         variant: 'info',
  //         autoHideDuration: 3000,
  //       });
  //       this.props.history.push("/report/" + this.state.DealType + "/view-servicer-mongodb-data/" + this.state.fields.dealId + "/" + this.state.fields.month + "/" + this.state.fields.year);
  //     } else {
  //       const message = "Data not saved successfully";
  //       this.props.enqueueSnackbar(message, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       });
  //     }
  //   }
  // }

  // async servicerdata(data) {
  //   this.setState({ getLoansLoader: true });
  //   const DealType = this.state.DealType;
  //   const APIResponse = await ServicerData(data, DealType);
  //   console.log("APIResponse.data.Success", APIResponse.data);
  //   if (this.state.file !== null) {
  //     const newdata = new FormData();
  //     newdata.append('filename', this.state.file);
  //     const UploadServicerReport = await UploadServicerReport(newdata, DealType);
  //     console.log("UploadServicerReport", UploadServicerReport.data);
  //     if (UploadServicerReport.data.isSuccess == true) {
  //       this.props.history.push("/report/" + this.state.DealType + "/view-servicer-mongodb-data/" + data.dealId + "/" + data.DealMonth + "/" + data.DealYear);
  //       const message = "Data saved successfully";
  //       this.props.enqueueSnackbar(message, {
  //         variant: 'info',
  //         autoHideDuration: 2000,
  //       });
  //     } else {
  //       const message = "Data not saved successfully";
  //       this.props.enqueueSnackbar(message, {
  //         variant: 'error',
  //         autoHideDuration: 3000,
  //       });
  //     }
  //   }
  // }

  render() {
    return (

      <React.Fragment>
        <div className="page">
          <div className="content">
            <div className="header"><Header pageTitle={this.state.pageTitle}></Header>
              {this.state.getLoader == false ? '' : <LinearLoader></LinearLoader>}
            </div>
            <div className="page-content text-center">
              <div className="row">
                <div className="col-md-12 text-left">
                  <h3 className="title-page">{!this.state.pageTitle ? '' : this.state.pageTitle}</h3>
                </div>


                <div className="col-md-12">
                  {this.state.loading == false ? <Loader msg={"Loading Modules..."} /> :
                    <React.Fragment>
                      <div className="fourColunm">
                        <div className="rjsf">
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <Select
                                variant="outlined"
                                size="medium"
                                name="dealId"
                                labelId="demo-controlled-open-select-label"
                                id="demo-simple-select-helper"
                                value={this.state.fields.dealId}
                                onChange={this.handleChange}
                              >
                                {this.state.deal_name.map((item) => {
                                  return (
                                    <MenuItem value={item.deal_id}> {item.deal_id} </MenuItem>
                                  );
                                })
                                }
                              </Select>

                            </div>

                            <div className="col-md-4">

                              <TextField
                                label={'Month*'}
                                variant="filled"
                                name="month"
                                id="standard-select-currency"
                                select
                                value={this.state.fields.month}
                                onChange={this.handleChange}
                              >
                                {months.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div className="col-md-4">

                              <TextField
                                label={'Year*'}
                                variant="filled"
                                name="year"
                                id="standard-select-currency"
                                select
                                value={this.year}
                                value={this.state.fields.year}
                                onChange={this.handleChange}
                              >
                                {this.state.years.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>

                            </div>
                          </div>

                          <div className="row text-left">
                            <div className="col-md-4">
                              <div id="browseexcel">
                                <form.Group controlId="formGrid">
                                  <form.Label>Browse Excel Sheet:</form.Label>
                                  <form.Control type="file"
                                    onChange={this.handleDoc}
                                    placeholder="" />
                                </form.Group>
                              </div>
                            </div>

                          </div>


                          <div className='row justify-content-center'>
                            <Button onClick={this.onSubmit} variant='contained' color='primary' type='submit'>
                              Data Save to DB {this.state.form_loader === true ? (
                                <CircularProgress size='25px' color='primary' />
                              ) : (
                                  ''
                                )}
                            </Button>
                          </div>
                        </div>
                     </div>
                    </React.Fragment>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>







      </React.Fragment>
    );
  }
}

export default withSnackbar(addNewReport);