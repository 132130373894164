/* eslint-disable require-jsdoc */
import React, {Component} from 'react';
// import { connect } from 'react-redux';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

function myFunction(str) {
  //var str = "Hello world!";
  if (str.length > 2) {
      var res = str.substring(2, str.length);
      var rep = res.replace(/[a-z]/gi, 'x')
      return str.replace(res, rep);
  }

  return str;
}

class dueDiligence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecords: [],
      eligibleloans: {},
      tableData: [],
      loading: false,
      getLoansLoader: false,
      open: false,
      selectedLoanId: [],
      searchText: '',
      rowsSelected:null

    };


 
  }
  
  
   async selectedpoolid(selected) {
    const arr = []


    for (var i = 0; i < selected.length; i++) {
     
      var j = selected[i];
      let PoolID = this.state.tableData[j].loanContractNumber;
      arr.push(PoolID);
    }
    console.log("selected Loans",arr);
    localStorage.setItem("dueDiligence", arr);
  }

  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  
  
  };

  async componentDidMount() {
    localStorage.removeItem('dueDiligence');
    const stateStatus = this.props.location.state;
    console.log('stateStatus', stateStatus);

    if (stateStatus === null) {
      alert('Please select the parameters');
      window.location.assign('/portfolio-setup-wizard/eligible-portfolio-import');
    } else {
      const parameters = JSON.parse(localStorage.getItem('eligiblePortfolioImportParameter'));
      console.log('data', parameters);
      this.getLoans(parameters);
    }
  };

  async getLoans(data) {
    this.setState({getLoansLoader: true});
    // console.log("getLoans Data", data);
    const dataString = 'assetType=' + data.assetType + '&assetCategory=' + data.assetCategory + '&hypothecatedWithLender=' + data.hypothecatedWithLender + '&rcCopyAvailable=' + data.rcCopyAvailable +
      '&insuranceCopyAvailable=' + data.insuranceCopyAvailable + '&lenderHypothecationOnInsuranceCopyAvailable=' + data.lenderHypothecationOnInsuranceCopyAvailable +
      '&loanAgreementAvailable=' + data.loanAgreementAvailable + '&anyEMIsRestructured=' + data.anyEMIsRestructured +
      '&cutoffDate=' + data.cutoffDate;
    console.log('getLoans dataString', dataString);
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/eligibleloans?' + dataString);

      const resp = res.data;
      if (res.status === 200) {
        console.log('eligibleloans', resp);
        this.setState({getLoansLoader: false, open: true, tableData: resp, loading: true});
        const message = "Record Fetched Successfully";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 3000,
        });
      } else {
        alert('Failed');
      }
    } catch (error) {
      alert('Failed');
      console.log('getLoans API error', error);
    }
  };

  onSubmit = () => {
    if (localStorage.getItem("dueDiligence") !== null) {
      const data = localStorage.getItem('dueDiligence');
      this.props.history.push({
        pathname: '/portfolio-setup-wizard/pool-formation',
        search: '',
        state: { eligiblePortfolioImportParameter: data }
      });
    }
  }

  backBtn = () => {

    this.props.history.push({
      pathname: '/portfolio-setup-wizard/eligible-portfolio-import'
    });
   
  }

  render() {

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: true,
      selectableRows: true,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,

      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
    },

      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading:true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, Loading Loan Data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };



    const columns = [
     
      {
        name: 'loanContractNumber',
        label: 'Loan Contract Number',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'dateOfLoanAgreement',
        label: 'Date Of Loan Agreement',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
               {moment(value).format('MM-DD-YYYY')}
              </React.Fragment>
            );
          },
        },
        
      },
      {
        name: 'city',
        label: 'City',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'lender',
        label: 'Lender',
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
              {myFunction(value)}
              </React.Fragment>
            );
          },
        },
      },

      {
        name: 'typeOfLoan',
        label: 'Type Of Loan',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'loanPurpose',
        label: 'Loan Purpose',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'loanOrFacilityAmount',
        label: 'Loan Or Facility Amount',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'currency',
        label: 'Currency',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'emiPayable',
        label: 'EMI Payable',
        options: {
          filter: true,
          sort: false,
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Portfolio Setup Wizard'} activeStep={'stepActive2'}  stepNav={true}></Header></div>
            <div className="tableSearch">

              <div className="row">
              <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}

                </div>

                <div className="col-md-6 col-sm-12">

                </div>

              </div>

            </div>
            <div className="page-content">


                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                  <div className="navbarSteps navbarStepsBtm">
                    <div className="row justify-content-end">


                      <Button variant="contained" id="optionalbutton" onClick={this.backBtn} > Back  </Button>

                    {  this.state.rowsSelected !== null && this.state.rowsSelected.length !== 0 ? 
                      <Button onClick={this.onSubmit} variant="contained" color="primary" type="submit" disabled={this.state.getLoansLoader === true ? true : false} >
                        {/* {this.state.getLoansLoader === true ? <CircularProgress size="30px" color="secondary" /> : ''} */}

                        Run DD  </Button>:
                         <Button onClick={this.onSubmit} variant="contained"  disabled color="primary" type="submit"  >
                    
                         Run DD  </Button>
                    }
                    </div>
                  </div>
                </React.Fragment> :
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default withSnackbar(dueDiligence);
