/* eslint-disable require-jsdoc */
import React, {Component} from 'react';
// import { connect } from 'react-redux';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import ReactModal from 'react-modal';
import { widgets, CustomFieldTemplate, customStyles, customStylesauto } from '../../../components/customscripts/customscript';
import FormLoader from '../../../components/loader/formLoader';
import {viewLoans,uploadlms,savelms,DownloadLMSData} from '../../../servies/services';

const token = localStorage.getItem('token')



class rundd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecords: [],
      eligibleloans: {},
      tableData: [],
      loading: false,
      getLoansLoader: false,
      open: false,
      openPopup: false,
      selectedLoanId: [],
      searchText: '',
      rowsSelected:null,
      open1: false,
      open2: false,

    };

  }

  // {loanId: "Frankie Long", loanNumber: "Industrial Analyst", constatus: "Uploaded", lmsstatus: "Digitised"},
  //     {loanId: "Brynn Robbins", loanNumber: "Business Analyst", constatus: "Digitised", lmsstatus: "Reviewd"},
  //     {loanId: "Justice Mann", loanNumber: "Business Consultant", constatus: "Reviewd", lmsstatus: "Uploaded"},
  
  
   async selectedpoolid(selected) {
    const arr = []

    for (var i = 0; i < selected.length; i++) {
     
      var j = selected[i];
      let PoolID = this.state.tableData[j].dealid;
      arr.push(PoolID);
    }
    console.log("selected Loans",arr);
    localStorage.setItem("rundd", JSON.stringify(arr));
  }

  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  
  };


  async componentDidMount() {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined) {
      window.location.assign("/")
    }

    localStorage.removeItem('runddpoolname');

    const poolId = this.props.match.params.id;
    const poolName = this.props.match.params.name;

    this.setState({poolId:poolId})
    this.setState({poolName:poolName})

    localStorage.setItem('runddpoolname',poolName);
    localStorage.setItem('runddpoolid',poolId);

    localStorage.removeItem('rundd');
    // const stateStatus = this.props.location.state;
    // console.log('stateStatus', stateStatus);

    // if (stateStatus === null) {
    //   alert('Please select the parameters');
    //   window.location.assign('/portfolio-setup-wizard/eligible-portfolio-import');
    // } else {
      // const parameters = localStorage.getItem('pdashboard');
    //   console.log('data', parameters);
      this.getLoans(poolId);
    // }
  };

  async getLoans(data) {
//     this.setState({getLoansLoader: true});
//     // console.log("getLoans Data", data);
//     const dataString = 'assetType=' + data.assetType + '&assetCategory=' + data.assetCategory + '&hypothecatedWithLender=' + data.hypothecatedWithLender + '&rcCopyAvailable=' + data.rcCopyAvailable +
//       '&insuranceCopyAvailable=' + data.insuranceCopyAvailable + '&lenderHypothecationOnInsuranceCopyAvailable=' + data.lenderHypothecationOnInsuranceCopyAvailable +
//       '&loanAgreementAvailable=' + data.loanAgreementAvailable + '&anyEMIsRestructured=' + data.anyEMIsRestructured +
//       '&cutoffDate=' + data.cutoffDate;
//     console.log('getLoans dataString', dataString);
//     try {
//       const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/eligibleloans?' + dataString);
        const res = await viewLoans(data,token)
      const resp = res.data;
      if (res.status === 200) {
        console.log('eligibleloans', resp);
        this.setState({getLoansLoader: false, open: true, tableData: resp, loading: true});
        const message = "Record Fetched Successfully";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 3000,
        });
       
        // let x=localStorage.getItem('selectedrows')
        // if(x!=null){
        //   x=x.split(",")
        // //  for(var i=0 ; i<arr.length ; i++){
        // //    arr[i]=arr[i]
        // //  }
        // console.log(x,"gotselect")
        // // this.selectedpoolid(arr)
        // x.map((v)=>this.selectedpoolid(v))
        // this.setState({rowsSelected:x})
        // }
} 
              else {
        alert('Failed');
      }
//     } catch (error) {
//       alert('Failed');
//       console.log('getLoans API error', error);
//     }
  };

  onSubmit = () => {
    if (localStorage.getItem("rundd") !== null) {
      const data = localStorage.getItem('rundd');
      // localStorage.setItem("selectedrows",this.state.rowsSelected)
      this.props.history.push({
        pathname: '/ddreport',
        search: '',
        state: { pdashboard: data }
      });
    }
  }

  backBtn = () => {

    this.props.history.push({
      pathname: '/processor/dashboard'
    });
   
  }
  Downloadexcel = async () => {
    let poolname = localStorage.getItem("runddpoolname");
    let poolid = localStorage.getItem("runddpoolid");

    let data = {
      poolname: poolname,
      poolid: poolid,
    };
     const APIResponse = await DownloadLMSData(data,token);
     console.log("DownloadLMS",APIResponse);
     if(APIResponse.status===200){
      console.log("Suceessfully downloaded");
      const message ="Suceessfully Downloaded"
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      const file_name = poolname +"_"+ poolid + ".xlsx";
      startDownload(APIResponse.data, file_name);
        function startDownload(file, fileName) {
          const url = window.URL.createObjectURL(new Blob([file]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }    
       }
       else {
        if (APIResponse.status === 201) {
          const message ="No Data Found"
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 3000,
          });        } else {
          console.log("Download not Success");
        }
      }

  }

  render() {

    const options = {
      filterType: 'dropdown',
      filter:true,
      search: true,
      customToolbar: () => {
        return (
          <Button variant="contained" color="primary" onClick={this.Downloadexcel}>
            Export LMS Excel
          </Button>
        );
      },
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: true,
      selectToolbarPlacement:'none',
      selectableRows: true,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,

      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
    },

      // searchText: this.state.searchText,
      // searchPlaceholder: 'Search',
      // customSearch: (searchQuery, currentRow, columns) => {
      //   let isFound = false;
      //   currentRow.forEach(col => {
      //     if (col.toString().indexOf(searchQuery) >= 0) {
      //       isFound = true;
      //     }
      //   });
      //   return isFound;
      // },

//
      loading:false,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, Loading Loan Data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };



    const columns = [
     
      {
        name: 'dealid',
        label: 'Loan Id',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'agreementid',
        label: 'Agreement Id',
        options: {

          filter: true,
          sort: true,
        },
        
      },
      {
        name: 'contractstatus',
        label: 'Contract Status',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'lmsstatus',
        label: 'LMS Data Status',
        options: {
          filter: true,
          sort: true,
        //   customBodyRender: (value, tableMeta, updateValue) => {
        //     return (
        //       <React.Fragment>
        //       {/* {myFunction(value)} */}
        //       </React.Fragment>
        //     );
        //   },
        },
      },

      // {
      //   name: 'lastactivity',
      //   label: 'Last Activity',
      //   options: {
      //     filter: true,
      //     sort: false,
      //   },
      // },

    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'RUN DD SCREEN'} ></Header></div>


            <div className="page-content">

                <React.Fragment>
                {/* <h3 className="alignment text-secondary">{this.state.poolName}</h3> */}
                  <MUIDataTable
                  title={this.state.poolName}
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                  />

                  <div className="navbarSteps navbarStepsBtm">
                    <div className="row justify-content-end">


                      <Button variant="contained" id="optionalbutton" onClick={this.backBtn} > BACK  </Button>

                    {  this.state.rowsSelected !== null && this.state.rowsSelected.length !== 0 ? 
                      <Button onClick={this.onSubmit} variant="contained" color="primary" type="submit" disabled={this.state.getLoansLoader === true ? true : false} >
                        {this.state.getLoansLoader === true ? <CircularProgress size="30px" color="secondary" /> : ''}

                        RECONCILE  </Button>:
                         <Button onClick={this.onSubmit} variant="contained"  disabled color="primary" type="submit"  >
                    
                         RECONCILE  </Button>
                    }
                    </div>
                  </div>

                </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default withSnackbar(rundd);
