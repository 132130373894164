import React, { Component } from 'react';
import UserHeader from '../../../components/header/header';
import Button from '@material-ui/core/Button';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import LinearLoader from '../../../components/loader/LinearLoader';
import Loader from '../../../components/loader';
import { widgets, CustomFieldTemplate, ObjectFieldTemplate } from '../../../components/customscripts/customscript';
import { GetAllDeals, generateYears, months, GroupByLima, GroupBySaludaGrade, InvestorVersion, GenerateLoanStratPDF, PublishReport, InvestorReportStatus, ViewInvestorReport, ReportStatusStrat, ViewLoanTapeData, SaludaLoanStratReport, GeneratePDF, dummydata, CrateTable, loanstraitdata, SaludaIndexData, LimaIndexData } from '../../../servies/services';
import Table from '../../../components/Table';
import FirstTable from '../../../components/FirstTable';
import IndexTable from '../../../components/IndexTable';

import ViewDragNDrop from './ViewDragNDrop';
import MUIDataTable from 'mui-datatables';

import $ from 'jquery';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PublishIcon from '@material-ui/icons/Publish';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import axios from "axios";
import CustomFooter from '../customize-footer/CustomFooter';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from 'moment';

// import 'table2excel';
// const Table2Excel = window.Table2Excel;
// const table2excel = new Table2Excel();
// import 'crestapps-tableexport';



const Form = withTheme(MuiTheme);
const schema = require('./schema.json');
const LoanStratSchema = require('./LoanStratSchema.json');
const TrusteeReportSchema = require('./TrusteeReportSchema.json');
const report_data_static = {
	"29a8e6a8-7b1d-454e-a3cd-b4b113be766f": {
		"name": "Requested",
		"class": "static_table",
		"id": "requested_table",
		"sid": "c67282db-e445-41ba-b537-acd755360421",
		"items": [],
		"show": "true"
	},
	"897b6d3f-52c6-4182-ba1b-6f38994cc1f4": {
		"name": "Page 1",
		"class": "dynamic_table",
		"id": "page_layout_1",
		"sid": "8fe7e980-8669-4905-a2af-1651c40cae7e",
		"items": [{
			"id": "51ca2ef2-c39e-4332-b2a8-dacbd8e1838b",
			"content": "DealContactInformation",
			"data": {
				"dealid": "Test Deal 52",
				"distributiondate": "8/25/2020",
				"reporttype": "Test Report",
				"relationshipmanager": "John Smith",
				"address": "My Street, Kingston, New York 12401",
				"email": "john.smith@mydomain.com",
				"websitereporting": "https://www.mydomain.com",
				"addtionalfirst": "adasd",
				"additionallast": "ada adasd"
			}
		}],
		"show": "false"
	},
	"6a340099-9bd0-40e4-af66-942c1b3b45c6": {
		"name": "Page 2",
		"class": "dynamic_table",
		"id": "page_layout_2",
		"sid": "8979c04e-7b77-43cc-9bff-a76ca7efd55b",
		"items": [{
			"id": "8a95d95b-5e0a-40a1-8afc-61374f0d137c",
			"content": "Date",
			"data": [{
				"ClosingDate": "8/31/2021",
				"1stPaymentDate": "9/25/2020",
				"MaturityDate": "9/25/2050",
				"PreviousPaymentDate": "",
				"CurrentPaymentDate": "9/25/2020",
				"Number of Days in Accrual Period": "30"
			}]
		}, {
			"id": "c911b079-240a-4c48-9045-f052729d3287",
			"content": "PaymentSummary",
			"data": [{
				"Class": "A1 Notes",
				"CUSIP": "795811 AA6",
				"BeginningBalance": "215887000.00",
				"InterestPaid": "641904.01",
				"PrincipalPaid": "6001136.04",
				"TotalPaid": "6643040.06",
				"EndingBalance": "210503114.22"
			}, {
				"Class": "A2 Notes",
				"CUSIP": "795811 AB4",
				"BeginningBalance": "30841000.00",
				"InterestPaid": "104730.90",
				"PrincipalPaid": "870335.47",
				"TotalPaid": "975066.37",
				"EndingBalance": "30071873.46"
			}, {
				"Class": "A3 Notes",
				"CUSIP": "795811 AC2",
				"BeginningBalance": "15421000.00",
				"InterestPaid": "65359.34",
				"PrincipalPaid": "448174.04",
				"TotalPaid": "513533.38",
				"EndingBalance": "15036424.26"
			}, {
				"Class": "M1 Notes",
				"CUSIP": "795811 AD0",
				"BeginningBalance": "15420000.00",
				"InterestPaid": "89950.00",
				"PrincipalPaid": "90804.33",
				"TotalPaid": "180754.33",
				"EndingBalance": "15417384.74"
			}, {
				"Class": "C Notes",
				"CUSIP": "795811 AE8",
				"BeginningBalance": "30841926.00",
				"InterestPaid": "3522.07",
				"PrincipalPaid": "0.00",
				"TotalPaid": "3522.07",
				"EndingBalance": "30843707.92"
			}, {
				"Class": "Retained Certificates",
				"CUSIP": "795811 AF5",
				"BeginningBalance": "0.00",
				"InterestPaid": "",
				"PrincipalPaid": "",
				"TotalPaid": "1203932.24",
				"EndingBalance": ""
			}, {
				"Class": "Total:",
				"CUSIP": "",
				"BeginningBalance": "308410926.00",
				"InterestPaid": "905466.32",
				"PrincipalPaid": "7410449.89",
				"TotalPaid": "9519848.44",
				"EndingBalance": "301872504.60"
			}]
		}, {
			"id": "ccbc7d54-9ca5-44f9-b910-295ca5ee494f",
			"content": "PrincipalPayments",
			"data": [{
				"Class": "A1 Notes",
				"OriginalBalance": "215887000.00",
				"BeginningBalance": "215887000.00",
				"PrincipalPaid": "5383885.78",
				"(Write-Down) / Write-Up": "0.00",
				"EndingBalance": "210503114.22"
			}, {
				"Class": "A2 Notes",
				"OriginalBalance": "30841000.00",
				"BeginningBalance": "30841000.00",
				"PrincipalPaid": "769126.54",
				"(Write-Down) / Write-Up": "0.00",
				"EndingBalance": "30071873.46"
			}, {
				"Class": "A3 Notes",
				"OriginalBalance": "15421000.00",
				"BeginningBalance": "15421000.00",
				"PrincipalPaid": "384575.74",
				"(Write-Down) / Write-Up": "0.00",
				"EndingBalance": "15036424.26"
			}, {
				"Class": "M1 Notes",
				"OriginalBalance": "15420000.00",
				"BeginningBalance": "15420000.00",
				"PrincipalPaid": "2615.26",
				"(Write-Down) / Write-Up": "0.00",
				"EndingBalance": "15417384.74"
			}, {
				"Class": "C Notes",
				"OriginalBalance": "30841926.00",
				"BeginningBalance": "30841926.00",
				"PrincipalPaid": "0.00",
				"(Write-Down) / Write-Up": "1781.92",
				"EndingBalance": "30843707.92"
			}, {
				"Class": "Total:*",
				"OriginalBalance": "308410926.00",
				"BeginningBalance": "308410926.00",
				"PrincipalPaid": "6540203.32",
				"(Write-Down) / Write-Up": "1781.92",
				"EndingBalance": "301872504.60"
			}]
		}],
		"show": "false"
	},
	"771fb94d-bb4f-400b-a55a-2530beca1744": {
		"name": "Page 3",
		"class": "dynamic_table",
		"id": "page_layout_3",
		"sid": "d136a7a2-d3b3-4d1e-aefc-7c7a7e4d63c9",
		"items": [{
			"id": "da3401b9-3a9c-4d3b-b181-4e958b481e1f",
			"content": "InterestPayments",
			"data": [{
				"Class": "A1 Notes",
				"InterestRate": "3.568",
				"BeginningBalance": "215887000.00",
				"InterestOwed": "24653.75",
				"InterestShortfall": "0.00",
				"InterestPaid": "24653.75",
				"InterestUnpaid": "0.00"
			}, {
				"Class": "A2 Notes",
				"InterestRate": "4.075",
				"BeginningBalance": "30841000.00",
				"InterestOwed": "3521.96",
				"InterestShortfall": "0.00",
				"InterestPaid": "3521.96",
				"InterestUnpaid": "0.00"
			}, {
				"Class": "A3 Notes",
				"InterestRate": "5.086",
				"BeginningBalance": "15421000.00",
				"InterestOwed": "1761.04",
				"InterestShortfall": "0.00",
				"InterestPaid": "1761.04",
				"InterestUnpaid": "0.00"
			}, {
				"Class": "M1 Notes",
				"InterestRate": "7.000",
				"BeginningBalance": "15420000.00",
				"InterestOwed": "1760.93",
				"InterestShortfall": "0.00",
				"InterestPaid": "1760.93",
				"InterestUnpaid": "0.00"
			}, {
				"Class": "C Notes",
				"InterestRate": "4.814",
				"BeginningBalance": "30841926.00",
				"InterestOwed": "3522.07",
				"InterestShortfall": "0.00",
				"InterestPaid": "3522.07",
				"InterestUnpaid": "0.00"
			}, {
				"Class": "Total:",
				"InterestRate": "",
				"BeginningBalance": "308410926.00",
				"InterestOwed": "35219.75",
				"InterestShortfall": "0.00",
				"InterestPaid": "35219.75",
				"InterestUnpaid": "0.00"
			}]
		}, {
			"id": "ea5654a8-0e3c-43e8-9a0f-450a95a7f33b",
			"content": "SpecialAdjustments",
			"data": [{
				"Class": "A1 Notes",
				"BeginningCumulativeWrite-Down": "0.00",
				"CumulativeWrite-DownPaid": "0.00",
				"EndingCumulativeWrite-Down": "0.00",
				"TotalCumulativeWAC Shortfall": "617250.26",
				"CumulativeWAC ShortfallPaid": "617250.26",
				"EndingCumulativeWAC Shortfall": "0.00"
			}, {
				"Class": "A2 Notes",
				"BeginningCumulativeWrite-Down": "0.00",
				"CumulativeWrite-DownPaid": "0.00",
				"EndingCumulativeWrite-Down": "0.00",
				"TotalCumulativeWAC Shortfall": "101208.93",
				"CumulativeWAC ShortfallPaid": "101208.93",
				"EndingCumulativeWAC Shortfall": "0.00"
			}, {
				"Class": "A3 Notes",
				"BeginningCumulativeWrite-Down": "0.00",
				"CumulativeWrite-DownPaid": "0.00",
				"EndingCumulativeWrite-Down": "0.00",
				"TotalCumulativeWAC Shortfall": "63598.30",
				"CumulativeWAC ShortfallPaid": "63598.30",
				"EndingCumulativeWAC Shortfall": "0.00"
			}, {
				"Class": "M1 Notes",
				"BeginningCumulativeWrite-Down": "0.00",
				"CumulativeWrite-DownPaid": "0.00",
				"EndingCumulativeWrite-Down": "0.00",
				"TotalCumulativeWAC Shortfall": "88189.07",
				"CumulativeWAC ShortfallPaid": "88189.07",
				"EndingCumulativeWAC Shortfall": "0.00"
			}, {
				"Class": "C Notes",
				"BeginningCumulativeWrite-Down": "0.00",
				"CumulativeWrite-DownPaid": "",
				"EndingCumulativeWrite-Down": "0.00",
				"TotalCumulativeWAC Shortfall": "120205.46",
				"CumulativeWAC ShortfallPaid": "0.00",
				"EndingCumulativeWAC Shortfall": "120205.46"
			}, {
				"Class": "Total:",
				"BeginningCumulativeWrite-Down": "0.00",
				"CumulativeWrite-DownPaid": "0.00",
				"EndingCumulativeWrite-Down": "0.00",
				"TotalCumulativeWAC Shortfall": "990452.02",
				"CumulativeWAC ShortfallPaid": "870246.57",
				"EndingCumulativeWAC Shortfall": "120205.46"
			}]
		}],
		"show": "false"
	},
	"ffeaddb4-f654-469f-ab62-014ce6031cb1": {
		"name": "Page 4",
		"class": "dynamic_table",
		"id": "page_layout_4",
		"sid": "3afc3d2c-cc10-46a5-97e0-99c817418802",
		"items": [{
			"id": "3acac29f-100c-4365-9d5a-983ecac04ac4",
			"content": "AccountStatement",
			"data": [
				[{
					"key": "COLLATERAL BALANCE",
					"value1": "",
					"value2": ""
				}, {
					"key": "",
					"value1": "Number of Loans",
					"value2": "Unpaid Principal Balance"
				}, {
					"key": "Beginning Balance",
					"value1": "6335",
					"value2": "308410926.60"
				}, {
					"key": "Additions",
					"value1": "",
					"value2": ""
				}, {
					"key": "New Loan Principal",
					"value1": "0",
					"value2": "0.00"
				}, {
					"key": "Draws",
					"value1": "29",
					"value2": "71415.00"
				}, {
					"key": "Principal Adjustments",
					"value1": "10",
					"value2": "1781.32"
				}, {
					"key": "Total:",
					"value1": "39",
					"value2": "73196.32"
				}, {
					"key": "Subtractions",
					"value1": "",
					"value2": ""
				}, {
					"key": "Principal Remittance",
					"value1": "6285",
					"value2": "6611618.32"
				}, {
					"key": "Transferred Third Party Principal",
					"value1": "0",
					"value2": "0.00"
				}, {
					"key": "Repurchased/Transferred Principal",
					"value1": "0",
					"value2": "0.00"
				}, {
					"key": "Net Realized Losses",
					"value1": "0",
					"value2": "0.00"
				}, {
					"key": "Total:",
					"value1": "6285",
					"value2": "6611618.32"
				}, {
					"key": "Ending Balance",
					"value1": "6228",
					"value2": "301872504.60"
				}], {
					"PRINCIPAL REMITTANCE": "",
					"ScheduledPrincipal": "1340597.10",
					"Deposits": "",
					"Prepayments & Curtailments": "5271021.22",
					"NetLiquidationProceeds": "0.00",
					"RepurchasedPrincipal": "0.00",
					"Total:": "5271021.22",
					"Withdrawals": "",
					"DrawPrincipal": "71415.00",
					"Total: ": "71415.00",
					"EndingBalance": "6540203.32"
				}, {
					"INTEREST REMITTANCE": "",
					"InterestReceived": "2242972.39",
					"Deposits": "",
					"RepurchasedInterest": "0.00",
					"Total:": "0.00",
					"Withdrawals": "",
					"RetainedFees": "89952.38",
					"DeferredInterest": "0.00",
					"ReliefActInterestShortfall": "0.00",
					"NetNonrecoverableAdvances": "0.00",
					"InterestLoss": "0.00",
					"Total: ": "89952.38",
					"EndingBalance": "2153020.01"
				}
			]
		}, {
			"id": "19407fec-d984-4473-88d5-2dc674b584dd",
			"content": "CollateralSummary",
			"data": [{
				"Activity": "New Loan Principal",
				"CountRelatedPeriod": "0",
				"AmountRelatedPeriod": "0.00",
				"CountSinceCutoff": "0",
				"AmountSinceCutoff": "0.00"
			}, {
				"Activity": "Draws",
				"CountRelatedPeriod": "29",
				"AmountRelatedPeriod": "71415.00",
				"CountSinceCutoff": "29",
				"AmountSinceCutoff": "0.00"
			}, {
				"Activity": "Principal Adjustments",
				"CountRelatedPeriod": "10",
				"AmountRelatedPeriod": "1781.32",
				"CountSinceCutoff": "10",
				"AmountSinceCutoff": "1781.32"
			}, {
				"Activity": "Principal Remittance",
				"CountRelatedPeriod": "6285",
				"AmountRelatedPeriod": "6611618.32",
				"CountSinceCutoff": "6285",
				"AmountSinceCutoff": "6611618.32"
			}, {
				"Activity": "Transferred Third Party Principal",
				"CountRelatedPeriod": "0",
				"AmountRelatedPeriod": "0.00",
				"CountSinceCutoff": "0",
				"AmountSinceCutoff": "0.00"
			}, {
				"Activity": "Repurchased/Transferred Principal",
				"CountRelatedPeriod": "1",
				"AmountRelatedPeriod": "32405.61",
				"CountSinceCutoff": "1",
				"AmountSinceCutoff": "32405.61"
			}, {
				"Activity": "Net Realized Losses",
				"CountRelatedPeriod": "0",
				"AmountRelatedPeriod": "0.00",
				"CountSinceCutoff": "0",
				"AmountSinceCutoff": "0.00"
			}]
		}, {
			"id": "74fad493-d51e-4daf-af0f-2cbb9f4e9b73",
			"content": "PrePaymentsAndDefaultRates",
			"data": [{
				"key": "",
				"value1": "Current",
				"value2": "Last 3-Month",
				"value3": "Since Cut-Off"
			}, {
				"key": "Single Monthly Mortality (SMM)",
				"value1": "1.66%",
				"value2": "",
				"value3": "1.66%"
			}, {
				"key": "Constant Prepayments Rates (CPR)",
				"value1": "18.16%",
				"value2": "",
				"value3": "18.16%"
			}, {
				"key": "Constant Default Rates (CDR)*",
				"value1": "0.00%",
				"value2": "",
				"value3": "0.00%"
			}]
		}],
		"show": "false"
	},
	"448e78e6-4d82-4dee-ba87-d0aba9fd2c77": {
		"name": "Page 5",
		"class": "dynamic_table",
		"id": "page_layout_5",
		"sid": "c5a65c47-6a9f-4730-bf2a-4133b9054e84",
		"items": [{
			"id": "c2569a52-ecc2-4f9a-8fc6-815af3ffa074",
			"content": "DealFeesAndExpenses",
			"data": [{
				"Fees": "Servicer Fee",
				"Type": "Fee",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "0.00",
				"TotalDue": "0.00",
				"TotalPaid": "0.00",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Indenture Trustee Annual Fee",
				"Type": "Fee",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "0.00",
				"TotalDue": "0.00",
				"TotalPaid": "0.00",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Indenture Trustee Monthly Fee",
				"Type": "Fee",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "2500.00",
				"TotalDue": "2500.00",
				"TotalPaid": "2500.00",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Administrator Fee",
				"Type": "Fee",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "35981.27",
				"TotalDue": "35981.27",
				"TotalPaid": "35981.27",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Provenance Platform Fee",
				"Type": "Fee",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "5140.18",
				"TotalDue": "5140.18",
				"TotalPaid": "5140.18",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Delaware Trustee Fee",
				"Type": "Fee",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "0.00",
				"TotalDue": "0.00",
				"TotalPaid": "0.00",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Indenture Trustee",
				"Type": "Expenses",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "0.00",
				"TotalDue": "0.00",
				"TotalPaid": "0.00",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Delaware Trustee",
				"Type": "Expenses",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "0.00",
				"TotalDue": "0.00",
				"TotalPaid": "0.00",
				"EndingUnpaid": "0.00"
			}, {
				"Fees": "Total:",
				"Type": "",
				"BeginningUnpaid": "0.00",
				"CurrentDue": "43621.46",
				"TotalDue": "43621.46",
				"TotalPaid": "43621.46",
				"EndingUnpaid": "0.00"
			}]
		}, {
			"id": "5e487ce8-b9c9-4e0d-a1c6-95be404c1d02",
			"content": "DealEvents",
			"data": [{
				"Key": "",
				"Value1": "",
				"Value3": "Current",
				"Value4": "Limit",
				"Value5": "Limit Type",
				"Value6": "Status"
			}, {
				"Key": "Cumulative Loss Trigger Event",
				"Value1": "",
				"Value3": "0.00%",
				"Value4": "5.00%",
				"Value5": "MAX",
				"Value6": "FALSE"
			}, {
				"Key": "Delinquency Trigger Event",
				"Value1": "",
				"Value3": "0.00%",
				"Value4": "30.00%",
				"Value5": "MAX",
				"Value6": "FALSE"
			}, {
				"Key": "the aggregate Class Principal Balance of the Class A Notes and Class M-1 Notes is reduced to zero",
				"Value1": "",
				"Value3": "277569000.00",
				"Value4": "0.00",
				"Value5": "MIN",
				"Value6": "FALSE"
			}, {
				"Key": "Is Indenture Default?",
				"Value1": "NO",
				"Value3": "",
				"Value4": "",
				"Value5": "",
				"Value6": ""
			}, {
				"Key": "Is Early Redemption?",
				"Value1": "NO",
				"Value3": "",
				"Value4": "",
				"Value5": "",
				"Value6": ""
			}, {
				"Key": "Is Trigger Event?",
				"Value1": "FALSE",
				"Value3": "",
				"Value4": "",
				"Value5": "",
				"Value6": ""
			}, {
				"Key": "Net WAC Rate",
				"Value1": "0.14%",
				"Value3": "",
				"Value4": "",
				"Value5": "",
				"Value6": ""
			}, {
				"Key": "Realized Losses",
				"Value1": "0.00",
				"Value3": "",
				"Value4": "",
				"Value5": "",
				"Value6": ""
			}, {
				"Key": "Overcollateralization Amount",
				"Value1": "24303504.60",
				"Value3": "",
				"Value4": "",
				"Value5": "",
				"Value6": ""
			}, {
				"Key": "Class A Principal Distributable Amount",
				"Value1": "6537588.06",
				"Value3": "",
				"Value4": "",
				"Value5": "",
				"Value6": ""
			}]
		}],
		"show": "false"
	},
	"a16c1669-37d9-42dc-ae78-e6c3326640ac": {
		"name": "Page 6",
		"class": "dynamic_table",
		"id": "page_layout_6",
		"sid": "ca1be89a-2d8a-4a69-a6e4-37ca703f85cf",
		"items": [{
			"id": "54d7b8cd-3cd8-4d86-9608-11f3b2b6ca4f",
			"content": "PerformanceDetails",
			"data": [
				[{
					"EndingPrincipalBalance": "Current",
					"InRepayment": "22052421.45",
					"InBankruptcy": "0.00",
					"InForebearance": "37620.86",
					"Total": "22090042.31"
				}, {
					"EndingPrincipalBalance": "30-59",
					"InRepayment": "0.00",
					"InBankruptcy": "0.00",
					"InForebearance": "0.00",
					"Total": "0.00"
				}, {
					"EndingPrincipalBalance": "60-89",
					"InRepayment": "0.00",
					"InBankruptcy": "0.00",
					"InForebearance": "0.00",
					"Total": "0.00"
				}, {
					"EndingPrincipalBalance": "90-119",
					"InRepayment": "0.00",
					"InBankruptcy": "0.00",
					"InForebearance": "0.00",
					"Total": "0.00"
				}, {
					"EndingPrincipalBalance": "120+",
					"InRepayment": "0.00",
					"InBankruptcy": "0.00",
					"InForebearance": "0.00",
					"Total": "0.00"
				}, {
					"EndingPrincipalBalance": "Foreclosure",
					"InRepayment": "0.00",
					"InBankruptcy": "0.00",
					"InForebearance": "0.00",
					"Total": "0.00"
				}, {
					"EndingPrincipalBalance": "REO",
					"InRepayment": "0.00",
					"InBankruptcy": "0.00",
					"InForebearance": "0.00",
					"Total": "0.00"
				}, {
					"EndingPrincipalBalance": "Total:",
					"InRepayment": "22052421.45",
					"InBankruptcy": "0.00",
					"InForebearance": "37620.86",
					"Total": "22090042.31"
				}],
				[{
					"EndingPrincipalBalance": "Current",
					"InRepayment": "99.83%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.17%",
					"Total": "100.00%"
				}, {
					"EndingPrincipalBalance": "30-59",
					"InRepayment": "0.00%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.00%",
					"Total": "0.00%"
				}, {
					"EndingPrincipalBalance": "60-89",
					"InRepayment": "0.00%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.00%",
					"Total": "0.00%"
				}, {
					"EndingPrincipalBalance": "90-119",
					"InRepayment": "0.00%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.00%",
					"Total": "0.00%"
				}, {
					"EndingPrincipalBalance": "120+",
					"InRepayment": "0.00%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.00%",
					"Total": "0.00%"
				}, {
					"EndingPrincipalBalance": "Foreclosure",
					"InRepayment": "0.00%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.00%",
					"Total": "0.00%"
				}, {
					"EndingPrincipalBalance": "REO",
					"InRepayment": "0.00%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.00%",
					"Total": "0.00%"
				}, {
					"EndingPrincipalBalance": "Total:",
					"InRepayment": "99.83%",
					"InBankruptcy": "0.00%",
					"InForebearance": "0.17%",
					"Total": "100.00%"
				}]
			]
		}, {
			"id": "155040b7-39ce-4050-8df0-b34a6c6747a2",
			"content": "PerformanceDetails2",
			"data": [{
				"LoanNumber": "",
				"TradeDate": "",
				"DelinquencyStatus": "",
				"EndingPeriodPrincipalBalance": "",
				"NetInterestRate": "",
				"FICO": "",
				"LTV": ""
			}]
		}, {
			"id": "cd6a9a1e-d85f-4e21-aa36-eb635f034c9d",
			"content": "PerformanceDetails3",
			"data": [{
				"LoanNumber": "",
				"TradeDate": "",
				"ModificationType": "",
				"EndingPeriodPrincipalBalance": "",
				"NetInterestRate": "",
				"FICO": "",
				"LTV": ""
			}]
		}, {
			"id": "e3c373cb-26fd-4a83-9cb2-276c4bc7db46",
			"content": "PerformanceDetails4",
			"data": [{
				"LoanNumber": "",
				"TradeDate": "",
				"Servicer": "",
				"EndingPeriodPrincipalBalance": "",
				"NetInterestRate": "",
				"FICO": "",
				"LTV": ""
			}]
		}, {
			"id": "cf1c73e8-fed7-4a88-a9d4-a488877a4f72",
			"content": "PerformanceDetails5",
			"data": []
		}],
		"show": "false"
	},
	"d03f18c7-b6e3-4045-821d-2d6c03608d7e": {
		"name": "Page 7",
		"class": "dynamic_table",
		"id": "page_layout_7",
		"sid": "431df210-911e-45fd-9e99-bfe2e3ac2977",
		"items": [{
			"id": "3e8b13d6-ea67-425e-9412-399528597f1f",
			"content": "ClassFactorsPer1000",
			"data": [{
				"Class": "A1 Notes",
				"BeginningBalance": "1000.00",
				"InterestPaid": "2.9733",
				"PrincipalPaid": "27.7976",
				"TotalPaid": "30.7709",
				"EndingBalance": "975.06"
			}, {
				"Class": "A2 Notes",
				"BeginningBalance": "1000.00",
				"InterestPaid": "3.3958",
				"PrincipalPaid": "28.2201",
				"TotalPaid": "31.6159",
				"EndingBalance": "975.06"
			}, {
				"Class": "A3 Notes",
				"BeginningBalance": "1000.00",
				"InterestPaid": "4.2383",
				"PrincipalPaid": "29.0626",
				"TotalPaid": "33.3009",
				"EndingBalance": "975.06"
			}, {
				"Class": "M1 Notes",
				"BeginningBalance": "1000.00",
				"InterestPaid": "5.8333",
				"PrincipalPaid": "5.8887",
				"TotalPaid": "11.7221",
				"EndingBalance": "999.83"
			}, {
				"Class": "C Notes",
				"BeginningBalance": "1000.00",
				"InterestPaid": "0.0114",
				"PrincipalPaid": "0.0000",
				"TotalPaid": "0.0114",
				"EndingBalance": "978.80"
			}]
		}],
		"show": "false"
	}
}

class ViewLoanDataTape extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DealType: localStorage.getItem('DealType'),
            OrgName: localStorage.getItem('OrgName'),
            pageTitle: "Deals",
            all_deals: JSON.parse(localStorage.getItem("all_deals")),
            channelname: localStorage.getItem('ChannelName'),
            user_id: localStorage.getItem('user_id'),

            schema: schema,
            LoanStratSchema: LoanStratSchema,
            TrusteeReportSchema: TrusteeReportSchema,
            years: generateYears(),
            months: months,
            groupby: GroupBySaludaGrade,

            formDataTrusteeReport: {
                dealId: null,
                month: null,
                year: null
            },
            formDataBDB: {
                dealId: null,
                groupby: null
            },

            formDataLoanStrat: {
                dealId: null,
                month: null,
                year: null,
                groupby: null
            },

            dashboard_bdb: false,
            loan_strat_report: false,
            trustee_report: false,
            report_data: null,
            report_data_LoanTapeData: null,
            getReportLoader: false,

            loadingFormschema: false,
            loadingFormLoanStratSchema: false,
            loadingFormTrusteeReportSchema: false,
            switchType: "loan-strat-analytics",

            bdb_loader: false,
            report_loader: false,
            version_display: false,
            report_box: false,
            verison_displayed: false,
            total_deals: null,
            header_dealId: null,
            bdbUrl: null,
            formDataLoanStratmonth: null

        };
    }

    async componentDidMount() {
        const DealId = this.props.match.params.DealId;
        const switchType = this.props.match.params.type;
        const DealMonth = this.props.match.params.DealMonth;
        const DealYear = this.props.match.params.DealYear;
        const groupby = this.props.match.params.groupBy;

        let formData = {
            dealId: DealId,
            month: DealMonth,
            year: DealYear,
            groupby: groupby,
        }

        this.setState({ groupby: GroupBySaludaGrade, switchType: switchType, header_dealId: DealId })
        this.switchType(switchType, formData)
        this.GetAllDeals();



    }

    async componentWillReceiveProps(nextProps) {
        let DealId = nextProps.match.params.DealId;
        let DealMonth = nextProps.match.params.DealMonth;
        let DealYear = nextProps.match.params.DealYear;
        let switchType = nextProps.match.params.type;
        let groupby = nextProps.match.params.groupBy;
        this.setState({
            report_data: null,
            dashboard_bdb: false,
            loan_strat_report: false,
            trustee_report: false,
            report_data_LoanTapeData: null,
            getReportLoader: false,
            loadingFormschema: false,
            loadingFormLoanStratSchema: false,
            loadingFormTrusteeReportSchema: false,
            bdb_loader: false,
            report_loader: false,
            version_display: false,
            report_box: false,
            verison_displayed: false,
            bdbUrl: null,
        })

        let formData = {
            dealId: DealId,
            month: DealMonth,
            year: DealYear,
            groupby: groupby,
        }
        this.setState({ groupby: GroupBySaludaGrade, switchType: switchType, header_dealId: DealId })
        this.switchType(switchType, formData)
        this.GetAllDeals();
    }

    switchType = async (switchType, formData) => {

        if (switchType == "loan-strat-analytics") {

            this.dashboard_bdb();
            this.setState({ formDataBDB: formData, header_dealId: formData.dealId });


            if (formData.dealId != null && formData.dealId != undefined && formData.month != null && formData.month != undefined && formData.year != null && formData.year != undefined) {
                this.BDBReport(formData)
            }

        } else if (switchType == "monthly-trustee-report") {

            this.trustee_report();
            this.setState({ formDataTrusteeReport: formData, header_dealId: formData.dealId });

            if (formData.dealId != null && formData.dealId != undefined && formData.month != null && formData.month != undefined && formData.year != null && formData.year != undefined) {
                let value = {
                    formData
                }

                if (this.state.OrgName == "investor") {
                    this.onSubmitInvestor(value)
                } else {
                    this.onSubmitTrustee(value)
                }
            }

        } else {

            this.loan_strat_report();
            this.setState({ formDataLoanStrat: formData, header_dealId: formData.dealId, formDataLoanStratmonth: formData.month });

            let value = {
                formData
            }

            if (formData.dealId != null && formData.dealId != undefined && formData.month != null && formData.month != undefined && formData.year != null && formData.year != undefined) {
                this.onSubmit(value)
            }

        }
    }

    GetAllDeals = async () => {
        let all_deals = this.state.all_deals;
        this.setState({ total_deals: all_deals.length })
    }

    dashboard_bdb = async () => {

        this.setState({
            dashboard_bdb: true,
            loan_strat_report: false,
            trustee_report: false,
        })
        let all_deals = this.state.all_deals;
        let deal_name = []
        if (all_deals.length !== 0) {
            all_deals.map((item) => {
                console.log("all_deals item", item.deal_id);
                deal_name.push(item.deal_id);
            })
        }
        console.log("GroupByLima", this.state.groupby)
        let groupby_name = []
        let groupby_value = []
        if (this.state.groupby.length !== 0) {
            this.state.groupby.map((item) => {
                console.log("GroupByLima item", item);
                groupby_name.push(item.label);
                groupby_value.push(item.value);
            })
        }

        let oldSchema = this.state.schema;
        console.log("oldstagedata", oldSchema);
        oldSchema.properties.dealId.enum = deal_name;
        oldSchema.properties.dealId.enumNames = deal_name;

        oldSchema.properties.groupby.enum = groupby_value;
        oldSchema.properties.groupby.enumNames = groupby_name;

        const newSchema = Object.assign({}, oldSchema);
        console.log("insight oldSchema", newSchema);
        this.setState({ schema: newSchema, loadingFormschema: true });

    }

    trustee_report = async () => {
        this.setState({
            dashboard_bdb: false,
            loan_strat_report: false,
            trustee_report: true,
        })
        let all_deals = this.state.all_deals;

        let deal_name = []

        if (all_deals.length !== 0) {
            all_deals.map((item) => {
                console.log("all_deals item", item.deal_id);
                deal_name.push(item.deal_id);
            })
        }

        let month_name = []
        let month_value = []
        if (this.state.months.length !== 0) {
            this.state.months.map((item) => {
                console.log("item", item);
                month_name.push(item.label);
                month_value.push(item.value);
            })
        }

        let year_name = []
        let year_value = []
        if (this.state.years.length !== 0) {
            this.state.years.map((item) => {
                console.log("item", item);
                year_name.push(item.key);
                year_value.push(item.value);
            })
        }

        // console.log("currency_list_static", currency_list_static);
        let oldSchema = this.state.TrusteeReportSchema;
        console.log("TrusteeReportSchema", oldSchema);
        oldSchema.properties.dealId.enum = deal_name;
        oldSchema.properties.dealId.enumNames = deal_name;

        oldSchema.properties.month.enum = month_value;
        oldSchema.properties.month.enumNames = month_name;

        oldSchema.properties.year.enum = year_value;
        oldSchema.properties.year.enumNames = year_name;

        const newSchema = Object.assign({}, oldSchema);
        console.log("TrusteeReportSchema oldSchema", newSchema);
        // this.setState({ schema: newSchema});
        this.setState({ TrusteeReportSchema: newSchema, loadingFormTrusteeReportSchema: true });

    }

    loan_strat_report = async () => {
        this.setState({
            dashboard_bdb: false,
            loan_strat_report: true,
            trustee_report: false,
        })

        let all_deals = this.state.all_deals;
        let deal_name = []

        if (all_deals.length !== 0) {
            all_deals.map((item) => {
                console.log("all_deals item", item.deal_id);
                deal_name.push(item.deal_id);
            })
        }

        let month_name = []
        let month_value = []
        if (this.state.months.length !== 0) {
            this.state.months.map((item) => {
                console.log("item", item);
                month_name.push(item.label);
                month_value.push(item.value);
            })
        }

        let year_name = []
        let year_value = []
        if (this.state.years.length !== 0) {
            this.state.years.map((item) => {
                console.log("item", item);
                year_name.push(item.key);
                year_value.push(item.value);
            })
        }

        console.log("GroupByLima", this.state.groupby)
        let groupby_name = []
        let groupby_value = []
        if (this.state.groupby.length !== 0) {
            this.state.groupby.map((item) => {
                console.log("GroupByLima item", item);
                groupby_name.push(item.label);
                groupby_value.push(item.value);
            })
        }

        // console.log("currency_list_static", currency_list_static);
        let oldSchema = this.state.LoanStratSchema;
        console.log("loan_strat_report", oldSchema);
        oldSchema.properties.dealId.enum = deal_name;
        oldSchema.properties.dealId.enumNames = deal_name;

        oldSchema.properties.month.enum = month_value;
        oldSchema.properties.month.enumNames = month_name;

        oldSchema.properties.year.enum = year_value;
        oldSchema.properties.year.enumNames = year_name;

        // oldSchema.properties.groupby.enum = groupby_value;
        // oldSchema.properties.groupby.enumNames = groupby_name;

        const newSchema = Object.assign({}, oldSchema);
        console.log("loan_strat_report oldSchema", newSchema);
        // this.setState({ schema: newSchema});
        this.setState({ LoanStratSchema: newSchema, loadingFormLoanStratSchema: true });

    }


    GenerateBDBReport = async (value) => {
        console.log("GenerateBDBReport", value.formData.dealId)
        this.setState({ formDataBDB: value.formData, header_dealId: value.formData.dealId })
        this.BDBReport(value.formData);
    }

    BDBReport = async (formData) => {

        this.setState({ bdb_loader: true })
        console.log("getSummery", formData.dealId)
        let poolidold = JSON.stringify({
            'DealId': {
                'type': 'in',
                'value': [formData.dealId.toLowerCase()],
            },
            // 'Month': {
            //     'type': 'in',
            //     'value': value.month,
            // },
            // 'Year': {
            //     'type': 'in',
            //     'value': value.year,
            // },
            'ChannelName': {
                'type': 'in',
                'value': [this.state.channelname],
            },
        });



        try {
            const res = await axios.get(process.env.react_app_base_url_relative + "backendapi_wsfs_bdb/api/v1/saluda2/link?groupby=" + formData.groupby);
            let UrlBdbNew = 'https://bdb.intainavb.com/home/#/opendocument?data=' + res.data + '&customGlobalFilter=' + encodeURIComponent(poolidold);
            console.log("+++++",UrlBdbNew)
            this.setState({ bdbUrl: UrlBdbNew, bdb_loader: false })

        } catch (error) {
            alert("Failed");
            console.log("getLoans API error", error);
        }
    }

    version_history = () => {
        if (this.state.verison_displayed == true) {
            this.setState({ verison_displayed: false })
        } else {
            this.setState({ verison_displayed: true })
        }
    }


    onSubmit = async (value) => {
        this.setState({ report_loader: true, formDataLoanStrat: value.formData, header_dealId: value.formData.dealId })
        let data = value.formData;

        let dealId = value.formData.dealId;
        let month = value.formData.month - 1;
        let year = value.formData.year;
        let groupby = value.formData.groupby;
        let OrgName = this.state.OrgName;

        this.setState({ formDataLoanStratmonth: month + 1 })

        const DealType = this.state.DealType;
        const APIResponse = await ReportStatusStrat(DealType, dealId, month, year, groupby, OrgName)
        if (APIResponse != null) {

            if (parseInt(APIResponse.data) === 0) {

                alert("Report not published")


            } else {

                console.log("ReportStatusStrat", APIResponse.data)
                const LoanTapeData = await ViewLoanTapeData(DealType, dealId, month, year, groupby, OrgName);
                if (LoanTapeData != null) {
                    console.log("LoanTapeData", LoanTapeData)
                    this.setState({ report_data_LoanTapeData: LoanTapeData.data, report_loader: false })
                    this.setState({ dashboard_bdb: false, loan_strat_report: true })



                }


            }


        }

    }


    onSubmitTrustee = async (value) => {



        this.setState({ version_loader: true, formDataTrusteeReport: value.formData, header_dealId: value.formData.dealId })
        let data = value.formData;
        let dealId = value.formData.dealId;
        let month = value.formData.month;
        let year = value.formData.year;
        let groupby = value.formData.groupby;
        let OrgName = this.state.OrgName;
        let DealType = this.state.DealType;
        // alert("before")
        const APIResponse = await InvestorVersion(DealType, dealId, month, year, groupby, OrgName)
        // alert(APIResponse)
        // alert("after")
        if (APIResponse != null) {
            console.log("InvestorVersion", APIResponse.data)
            this.setState({ version_data: APIResponse.data, version_loader: false, version_display: true, loading: true, verison_displayed: true })

            let version_list = []
            if (APIResponse.data.length !== 0) {
                APIResponse.data.reverse().map((item) => {
                    console.log("item irVersion", item.irVersion);
                    version_list.push(item.irVersion);
                })
            }

            console.log("version_list", version_list)
            this.setState({ version_list: version_list })
        }
    }


    onSubmitInvestor = async (value) => {

        this.setState({ version_loader: true, formDataTrusteeReport: value.formData, header_dealId: value.formData.dealId })
        let dealId = value.formData.dealId;
        let month = value.formData.month;
        let year = value.formData.year;
        let groupby = value.formData.groupby;
        let OrgName = this.state.OrgName;
        const DealType = this.state.DealType;
        const version = null
        const data = null
        const userid = this.state.user_id;
        const role = this.state.OrgName;

        const APIResponse = await InvestorReportStatus(DealType, dealId, month, year, version, data)
        if (APIResponse != null) {
            console.log("InvestorReportStatus", APIResponse)

            if (APIResponse.data == "1") {
                this.setState({ report_status: APIResponse.data })
                const InvestorData = await ViewInvestorReport(DealType, dealId, month, year, version, role, userid)
                if (InvestorData != null) {
                    console.log("InvestorData", InvestorData.data.status1)
                    if (InvestorData.data.status1 == 2) {
                        alert("Something went wrong please try again")
                        this.setState({ report_data: [], getReportLoader: true, report_box: true, getReportLoaderTable: null })
                        this.setState({ version_loader: false })

                    } else {

                        this.setState({ report_data: InvestorData.data, getReportLoader: true, report_box: true, getReportLoaderTable: null })
                        this.setState({ version_loader: false })
                    }
                    this.setState({ report_data: InvestorData.data, getReportLoader: true, report_box: true, getReportLoaderTable: null })
                    this.setState({ version_loader: false })

                }
            } else {

                const message = "Report not published";
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 5000,
                });

                this.setState({ version_loader: false })

            }
        }
    }


    getReport = async (dealId, month, year, version) => {

        console.log("getReport getReport getReport getReport getReport ", dealId, month, year, version)
        this.setState({ getReportLoaderTable: version })

        const DealType = this.state.DealType;
        // const version = this.state.version_name;
        const userid = this.state.user_id;
        const role = this.state.OrgName;
        this.setState({ version_name: version })

        const data = this.state.version_list.toString();
        const APIResponse = await InvestorReportStatus(DealType, dealId, month, year, version, data)
        if (APIResponse != null) {
            console.log("InvestorVersion", APIResponse.data)
            this.setState({ report_status: APIResponse.data })

            const InvestorData = await ViewInvestorReport(DealType, dealId, month, year, version, role, userid)
            if (InvestorData != null) {
                console.log("InvestorData", InvestorData.data)
                this.setState({ report_data: InvestorData.data, getReportLoader: true, report_box: true, getReportLoaderTable: null, verison_displayed: false })

            }
            if (APIResponse.data == "1") {


            } else {

            }

        }



    }


    publish_report = async (event) => {

        var Status = "1";
        const peers = JSON.parse(localStorage.getItem("peers"));
        const token = localStorage.getItem('token');
        const DealType = this.state.DealType;
        var input = {
            "DealID": this.state.formDataTrusteeReport.dealId,
            "month": this.state.formDataTrusteeReport.month,
            "year": this.state.formDataTrusteeReport.year,
            "version": this.state.version_name,
            "Status": Status,
            "peers": peers,
            "token": token,
            "channelname": this.state.channelname
        }

        const ConstGeneratePDF = await PublishReport(input, DealType)
        console.log("ConstGeneratePDF", ConstGeneratePDF)
        if (ConstGeneratePDF != null) {
            this.setState({ report_status: ConstGeneratePDF.data })

            const message = "Report published";
            this.props.enqueueSnackbar(message, {
                variant: 'info',
                autoHideDuration: 5000,
            });
        }
    }


    export_excel = async (event) => {

        
            // $("#pdfdata").table2excel();
        

            // table2excel.export(document.querySelectorAll("table"));
            $("table").tableExport();
            // new TableExport(document.getElementsByTagName("table"));

    }

    export_pdf = async (event) => {
        let DealType = this.state.DealType
        var dealId = this.state.formDataTrusteeReport.dealId
        var month = this.state.formDataTrusteeReport.month;
        var year = this.state.formDataTrusteeReport.year;
        const data = $("#pdfdata").html();
        console.log("data", JSON.stringify(data))
        var input = {
            "dealId": dealId,
            "month": month,
            "year": year,
            "data": JSON.stringify(data),
            "channelname": localStorage.getItem('ChannelName'),
        }
        // var result = JSON.stringify(input);
        console.log("input input", input);

        const ConstGeneratePDF = await GeneratePDF(input, DealType)
        console.log("ConstGeneratePDF", ConstGeneratePDF)
        if (ConstGeneratePDF != null) {
            // alert(ConstGeneratePDF.data.filename)
            const file_name = ConstGeneratePDF.data.filename
            const filepath = process.env.react_app_base_url + "root_folder/InvestorReportPdf/" + file_name
            downloadFile(filepath);

            function downloadFile(urlToSend) {
                var req = new XMLHttpRequest();
                req.open("GET", urlToSend, true);
                req.responseType = "blob";
                req.onload = function (event) {
                    var blob = req.response;
                    var fileName = file_name //if you have the fileName header available
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                };

                req.send();
            }
        }
    }

    
    export_pdf2 = async (event) => {
        let DealType = this.state.DealType
        var dealId = this.state.formDataLoanStrat.dealId
        var month = this.state.formDataLoanStrat.month;
        var year = this.state.formDataLoanStrat.year;
        const data = $("#pdfdata2").html();
        console.log("data", JSON.stringify(data))
        var input = {
            "dealId": dealId,
            "month": month,
            "year": year,
            "data": JSON.stringify(data),
            "channelname": localStorage.getItem('ChannelName'),
        }
        // var result = JSON.stringify(input);
        console.log("input input", input);

        const ConstGeneratePDF = await GenerateLoanStratPDF(input, DealType)
        console.log("ConstGeneratePDF", ConstGeneratePDF)
        if (ConstGeneratePDF != null) {
            // alert(ConstGeneratePDF.data.filename)
            const file_name = ConstGeneratePDF.data.filename
            const filepath = process.env.react_app_base_url + "root_folder/InvestorReportPdf/" + file_name
            downloadFile(filepath);

            function downloadFile(urlToSend) {
                var req = new XMLHttpRequest();
                req.open("GET", urlToSend, true);
                req.responseType = "blob";
                req.onload = function (event) {
                    var blob = req.response;
                    var fileName = file_name //if you have the fileName header available
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                };

                req.send();
            }
        }
    }


    render() {
        const columns = [
            {
                name: 'irDealId',
                label: 'Deal Name',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta) => (
                        <div>
                            {value}
                        </div>
                    ),
                },
            },
            {
                name: 'irVersion',
                label: 'Version',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta) => (
                        <div>
                            {value}
                        </div>
                    ),
                },
            },
            {
                name: 'irMonth',
                label: 'Month',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta) => (
                        <div>
                            {parseInt(value) + 1}
                        </div>
                    ),
                },
            },
            {
                name: 'irYear',
                label: 'Year',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta) => (
                        <div>
                            {value}
                        </div>
                    ),
                },
            },
            {
                name: 'irCreatedDate',
                label: 'Created Date',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta) => (
                        <div>
                            {value}
                        </div>
                    ),
                },
            },
            {
                name: 'irCreatedBy',
                label: 'Created By',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta) => (
                        <div className="createdByName">
                            {value}
                        </div>
                    ),
                },
            },
            {
                name: 'irVersion',
                label: 'Action',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta) => (
                        <div>

                            <Button variant='text' onClick={() => this.getReport(tableMeta.rowData[0], parseInt(tableMeta.rowData[2]) + 1, tableMeta.rowData[3], value)} color='primary' type='submit'>

                                {this.state.getReportLoaderTable == value ? (
                                    <React.Fragment>
                                        Getting Report <CircularProgress size='20px' color='primary' />
                                    </React.Fragment>

                                ) : (
                                    'View Report'
                                )}

                            </Button>
                        </div>
                    ),
                },
            },
        ];

        const options = {
            responsive: 'stacked',
            filterType: 'dropdown',
            search: true,
            print: true,
            viewColumns: true,
            download: true,
            rowHover: false,
            selectableRowsOnClick: false,
            selectableRows: false,
            // onRowClick: this.onRowClick,
            onRowsSelect: this.onRowsSelect,
            rowsSelected: this.state.rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
                console.log("allRows", allRows);
                console.log("rowsSelected", rowsSelected);
                this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
                const selected = allRows.map(row => row.dataIndex);
                console.log("selected" + selected);
                this.selectedpoolid(selected);
            },

            customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
                return (
                    <CustomFooter
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        changeRowsPerPage={changeRowsPerPage}
                        changePage={changePage}
                        textLabels={textLabels}
                    />
                );
            },

            searchText: this.state.searchText,
            searchPlaceholder: 'Search Platform',
            customSearch: (searchQuery, currentRow, columns) => {
                let isFound = false;
                currentRow.forEach(col => {
                    if (col.toString().indexOf(searchQuery) >= 0) {
                        isFound = true;
                    }
                });
                return isFound;
            },


            loading: true,
            textLabels: {
                body: {
                    noMatch: this.state.loading === true ?
                        'Sorry, there is no matching data to display' :
                        <Loader msg={"Please wait, loading data"} />,
                    toolTip: 'Sort',
                    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },

                selectedRows: {
                    text: 'row(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Rows',
                },
            },
        };


        return (
            <React.Fragment>
                <div className="page">
                    <div className="content">
                        <div className="header"><UserHeader pageTitle={this.state.pageTitle}
                            total_deals={this.state.total_deals}
                            dealId={this.state.header_dealId}
                        ></UserHeader>
                        </div>



                        <div className="page-content" id="formElements">
                            <div className="row">
                                <div className="col-md-12 text-left">
                                    {/* <h3 className="title-page">{!this.state.pageTitle ? '' : this.state.pageTitle}</h3> */}
                                    <ul className="navigation_top">
                                        <li className="float-left"> <Button variant={this.state.dashboard_bdb == true ? 'contained' : 'outlined'} onClick={this.dashboard_bdb} color='primary' type='submit'> Loan Strat Analytics</Button> </li>
                                        <li className="float-left"> <Button variant={this.state.loan_strat_report == true ? 'contained' : 'outlined'} onClick={this.loan_strat_report} color='primary' type='submit'> Loan Strat Summary  </Button> </li>
                                        <li className="float-left"> <Button variant={this.state.trustee_report == true ? 'contained' : 'outlined'} onClick={this.trustee_report} color='primary' type='submit'> Monthly Trustee Report </Button> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {this.state.dashboard_bdb == true ?
                            <div className="page-content" id="formElements2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="deal_form">
                                            {this.state.loadingFormschema == true ?
                                                <Form
                                                    schema={this.state.schema}
                                                    onSubmit={this.GenerateBDBReport}
                                                    onChange={this.onFormChanged}
                                                    widgets={widgets}
                                                    omitExtraData={true}
                                                    liveOmit={true}
                                                    FieldTemplate={CustomFieldTemplate}
                                                    formData={this.state.formDataBDB}
                                                    uiSchema={this.state.uiSchema}
                                                    ObjectFieldTemplate={ObjectFieldTemplate}
                                                >
                                                    <Button className="deal_form_button" variant='contained' color='primary' type='submit'>
                                                        View Report
                                                        {this.state.bdb_loader === true ? (
                                                            <CircularProgress size='25px' color='primary' />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Button>

                                                </Form>
                                                : <Loader></Loader>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}


                        {this.state.loan_strat_report == true ?
                            <div className="page-content" id="formElements2">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        View Report
                                            {this.state.formDataLoanStrat.dealId ?
                                            <React.Fragment>
                                                &nbsp;-&nbsp;<strong>{this.state.formDataLoanStrat.dealId}</strong>,&nbsp;
                                                {moment(this.state.formDataLoanStrat.month).format('MMMM')},&nbsp;
                                                {this.state.formDataLoanStrat.year}
                                                <span className="small_text">{moment(this.state.formDataLoanStrat.month).format('MMMM')} report is based on&nbsp;{moment(this.state.formDataLoanStrat.month).subtract(1).format('MMMM')} &nbsp;month-end data</span>
                                            </React.Fragment>
                                            : ''}
                                    </div>

                                    <div className="col-md-8">
                                        <div class="deal_form_loan_strat_report">
                                            {this.state.loadingFormLoanStratSchema == true ?
                                                <Form
                                                    schema={this.state.LoanStratSchema}
                                                    onSubmit={this.onSubmit}
                                                    onChange={this.onFormChanged}
                                                    widgets={widgets}
                                                    omitExtraData={true}
                                                    liveOmit={true}
                                                    FieldTemplate={CustomFieldTemplate}
                                                    formData={this.state.formDataLoanStrat}
                                                    uiSchema={this.state.uiSchema}
                                                    ObjectFieldTemplate={ObjectFieldTemplate}
                                                >

                                                    <Button variant='contained' className="deal_form_button" color='primary' type='submit'>
                                                        View Report
                                                        {this.state.report_loader === true ? (
                                                            <CircularProgress size='25px' color='primary' />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Button>

                                                </Form>
                                                : <Loader></Loader>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}

                        {this.state.trustee_report == true ?
                            <div className="page-content" id="formElements2">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        View Report
                                            {this.state.formDataTrusteeReport.dealId ?
                                            <React.Fragment>
                                                &nbsp;-&nbsp;<strong>{this.state.formDataTrusteeReport.dealId}</strong>, &nbsp;
                                                {moment(this.state.formDataTrusteeReport.month).format('MMMM')}, &nbsp;
                                                {this.state.formDataTrusteeReport.year}

                                            </React.Fragment>
                                            : ''}
                                    </div>

                                    <div className="col-md-8">
                                        <div class="deal_form_loan_strat_report">
                                            {/* {JSON.stringify(this.state.formDataTrusteeReport)} */}
                                            {this.state.loadingFormTrusteeReportSchema == true ?
                                                <Form
                                                    schema={this.state.TrusteeReportSchema}
                                                    onSubmit={this.state.OrgName == "investor" ? this.onSubmitInvestor : this.onSubmitTrustee}
                                                    onChange={this.onFormChanged}
                                                    widgets={widgets}
                                                    omitExtraData={true}
                                                    liveOmit={true}
                                                    FieldTemplate={CustomFieldTemplate}
                                                    formData={this.state.formDataTrusteeReport}
                                                    uiSchema={this.state.uiSchema}
                                                    ObjectFieldTemplate={ObjectFieldTemplate}
                                                >

                                                    <Button className="deal_form_button" variant='contained' color='primary' type='submit'>
                                                        {this.state.OrgName == "investor" ? 'View Report' : 'Display Versions'}
                                                        {this.state.version_loader == true ? <CircularProgress size='25px' color='primary' /> : ''}
                                                    </Button>
                                                </Form>
                                                : <Loader></Loader>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}

                        {this.state.dashboard_bdb == true ?
                            <React.Fragment>
                                {this.state.bdbUrl !== null ?
                                    <React.Fragment>
                                        {this.state.bdb_loader === false ?
                                            <div className="page-content with_loader" id="bdb_dashboard">
                                                <div className="dealinfo">
                                                    <div className="row">
                                                        <div className="col-md-3"><label>Deal Name</label> {this.state.formDataBDB.dealId} </div>
                                                        <div className="col-md-3"><label>Group By</label>  {this.state.formDataBDB.groupby} </div>
                                                    </div>

                                                </div>
                                                <div className="wrapper-pdf-container">
                                                    <React.Fragment>
                                                        <iframe
                                                            src={this.state.bdbUrl}
                                                            width="100%"
                                                            height="700px"
                                                            id="myId"
                                                            className="iframeOuter"
                                                            display="initial"
                                                            position="relative"
                                                            frameBorder="0"
                                                        >
                                                        </iframe>
                                                    </React.Fragment>
                                                </div>
                                            </div>
                                            : ''}

                                    </React.Fragment>
                                    : ''}
                            </React.Fragment>
                            : ''}

                        {this.state.loan_strat_report == false ? '' :

                            <React.Fragment>

                                {this.state.report_data_LoanTapeData == null ? '' :

                                    <React.Fragment>

                                        <div className="page-content" id="loan_strat_report">

                                            {this.state.loan_strat_report == true ?
                                                <div className="wrapper-pdf-container">
                                                    <React.Fragment>
                                                        <div className="view-report-header">
                                                            {/* <Button variant="outlined" color="primary" disabled onClick={this.publish_report}> <PublishIcon></PublishIcon> Publish Report</Button> */}
                                                            <Button variant="outlined" color="primary" onClick={this.export_pdf2}> <PictureAsPdfIcon></PictureAsPdfIcon> Export PDF</Button>
                                                            {/* <Button variant="outlined" color="primary" onClick={this.export_excel}> <DescriptionIcon></DescriptionIcon> Export Excel</Button> */}
                                                        </div>
                                                        <div className="clearfix"></div>

                                                        <div id="pdfdata2">
                                                            
                                                                    {Object.entries(this.state.report_data_LoanTapeData).map(([key, value], index) => {
                                                                        return (
                                                                            <React.Fragment>
                                                                                {key == "DealContactInformation" ?
                                                                                    <React.Fragment>
                                                                                        <div className="wrapper-pdf-container">
                                                                                        <FirstTable report_type="Loan Strat Summary" section_id={key} data={value} section_title={key} ></FirstTable>
                                                                                                <div class="beforeClass"></div>
                                                                                       </div>
                                                                                       <div className="wrapper-pdf-container">
                                                                                                <IndexTable section_id={'index_page'} data={SaludaIndexData} section_title={''} ></IndexTable>
                                                                                                <div class="beforeClass"></div>
                                                                                                </div>
                                                                                        
                                                                                    </React.Fragment> :
                                                                                    <React.Fragment>
                                                                                        {key == "tableData" ? '' :
                                                                                            <React.Fragment>
                                                                                              <div className="wrapper-pdf-container">
                                                                                                        <Table section_id={key} data={value} section_title={key} ></Table>
                                                                                                        <div class="beforeClass"></div>
                                                                                                        </div>
                                                                                                
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                    )}
                                                              
                                                         
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                                : ''}
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }

                        {this.state.trustee_report == false ? '' :
                            <React.Fragment>
                                {this.state.version_display == true ?
                                    <React.Fragment>
                                        <div className="page-content" id="version_display">
                                            <h2 className="version_btn" onClick={() => { this.version_history(this.state.verison_displayed) }}>
                                                <Button variant="outlined" color="primary" >
                                                    Versions
                                                    {this.state.verison_displayed == false ?
                                                        <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
                                                        :
                                                        <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                                                    }


                                                </Button>
                                            </h2>
                                            <div className="clearfix"></div>
                                            {this.state.verison_displayed == false ? '' :
                                                <div className="row custom-input justify-content-center">
                                                    <div className="col-md-12 mb-4" id="version_loader">
                                                        <MUIDataTable
                                                            title={''}
                                                            data={this.state.version_data}
                                                            columns={columns}
                                                            options={options}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                    : ' '}



                                {this.state.report_box == false ? '' :
                                    <React.Fragment>
                                        {this.state.getReportLoader !== false ?
                                            <React.Fragment>
                                                {this.state.report_data == null ? '' :
                                                    <React.Fragment>
                                                        <div className="page-content" id="ViewDragNDrop">
                                                            <div className="wrapper-pdf-container"></div>
                                                            <div className="view-report-header">
                                                                {this.state.OrgName == "investor" ? '' : <Button variant="outlined" color="primary" disabled={this.state.report_status == 0 ? false : true} onClick={this.publish_report}> <PublishIcon></PublishIcon> Publish Report</Button>}
                                                                <Button variant="outlined" color="primary" onClick={this.export_pdf}> <PictureAsPdfIcon></PictureAsPdfIcon> Export PDF</Button>
                                                                {/* <Button variant="outlined" color="primary" onClick={this.export_excel}> <DescriptionIcon></DescriptionIcon> Export Excel</Button> */}
                                                                
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <ViewDragNDrop
                                                                customize_data={this.state.report_data}
                                                                dealname={this.state.formDataTrusteeReport.dealId}
                                                                month={this.state.formDataTrusteeReport.month}
                                                                year={this.state.formDataTrusteeReport.year}
                                                                peers={this.state.peers}
                                                                token={this.state.token}
                                                                dealType={this.state.DealType}
                                                            >
                                                            </ViewDragNDrop>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                            : ''}
                                    </React.Fragment>
                                }

                            </React.Fragment>
                        }

                    </div>
                </div >
            </React.Fragment >
        );
    }
}

export default withSnackbar(ViewLoanDataTape)