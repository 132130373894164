/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import Button from '@material-ui/core/Button';
import BankAccounts from '../../images/BankAccounts.png';
import axios from "axios";
import Snackbar from '../../components/snackbar';

// import ReactModal from 'react-modal';
// import CloseIcon from '@material-ui/icons/Close';
// // import AddBank from './addBank';
// import Modal from 'react-responsive-modal';
import { customStyles } from '../../components/customscripts/customscript';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class BulkUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loader: false,
      getLoansLoader: false,
      IsOpen: false,
      open2: false,
      open3: false,
      disabled: true,
      Redirect: false,
      modalIsOpen: false,
      showModal: false,
      OrgName: localStorage.getItem('OrgName') || null,
      token: localStorage.getItem('token') || null,
      peer: localStorage.getItem('peer') || null,

    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.childHandler = this.childHandler.bind(this)
    this.onOpenModal2 = this.onOpenModal2.bind(this);
    this.handleDoc = this.handleDoc.bind(this);
  }

  childHandler(dataFromChild) {
    console.log(dataFromChild);
    this.setState({ open2: false });
  }

  handleOpenModal = () => {
    console.log("inside modal");
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  onOpenModal2 = () => {
    console.log("inside modal");
    this.setState({ open2: true });
  };

  onCloseModal2 = () => {
    this.setState({ open2: false });
  };

  handleDoc = (e) => {
    this.setState({ file: e.target.files[0] });
  }

  handleInit() {
    console.log('FilePond instance has initialised', this.pond);
  }

  digitize = () => {

    this.setState({ msg: true, loadingmodal: false })
    console.log("Asdasdas", this.pond.getFiles().length);
    // alert(this.state.doc_type)

    if (this.pond.getFiles().length === 0) {

      alert("Please upload the documents")
    } else {
     

        this.setState({ dig_flag: true });
        var formData = new FormData();

        var cnt = 0;

        this.pond.getFiles()
          .map(fileItem => fileItem.file)
          .forEach(file => {
            formData.append("userFile", file, file.name);
            cnt++;
          });


        formData.append('peers', this.state.peer);
        formData.append('token', this.state.token);



        axios(process.env.react_app_base_url + 'api/V1/datapreprocessing/uploadmultipleFile', {
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          console.log(res.data);


          if (res.status === 200) {
            this.setState({ msg: true, loadingmodal: true })

          }
          else {
            this.setState({ msg2: true, loadingmodal: true })
          }


        }).catch(error => { console.log(error); })

      }
    

  }

  async SaveDoc() {
    this.setState({ msg: false, msg2: false, loadingmodal: false });

    var formData = new FormData();
    formData.append('userFile', this.state.file);
    formData.append('peers', this.state.peer);
    formData.append('token', this.state.token);




    axios(process.env.react_app_base_url + 'api/V1/datapreprocessing/uploadmultipleFile', {
      method: 'POST',
      data: formData,
      // headers: {

      //   'Content-Type': 'multipart/form-data'
      // }
    }).then(res => {
      console.log(res.data);


      if (res.status === 200) {
        this.setState({ msg: true, loadingmodal: true })
        alert("Document uploaded successfully");

      }
      else {
        this.setState({ msg2: true, loadingmodal: true })
        alert("Failed to upload document");
      }


    }).catch(error => { console.log(error); })

  }



  render() {
    const { open2 } = this.state;
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Bulk Document Upload'}></Header></div>

            {/* <FilePond allowMultiple={true}
              ref={ref => this.pond = ref}
              oninit={() => this.handleInit()}
              files={this.state.files}
              onupdatefiles={fileItems => {

                this.setState({
                  files: fileItems.map(fileItem => fileItem.file)
                });
              }}
              name={"file"}
              labelIdle='<b>Drag your documents here or <span class="filepond--label-action">Browse</span></b>'
            />

            <Button className="modal_digitize_button" variant="primary" onClick={this.digitize}> Upload File</Button> */}

            <div className="addBankBox">
              {/* <img src={BankAccounts} alt={''} /> */}

              <h2>Click here to upload files from your computer</h2>
              <p>
                <input type="file" name="userFile" onChange={this.handleDoc} multiple />

              </p>
              <Button variant="contained" color="primary" onClick={this.SaveDoc.bind(this)} type="submit"> Upload  {this.state.loadingmodal === false ? <CircularProgress size="20px" color="primary" /> : ''}  </Button>
            </div>
            {this.state.msg === true ? <React.Fragment><Snackbar msg={"Document uploaded successfully"} open="true" /> </React.Fragment> : ' '}
            {this.state.msg2 === true ? <React.Fragment><Snackbar msg={"Failed to upload document"} open="true" /> </React.Fragment> : ' '}


          </div>
        </div>
      </React.Fragment>
    );
  }
}
