import React, { Component } from 'react'
import Header from '../../../components/header';
import Button from '@material-ui/core/Button';
import axios from 'axios'
import Loader from '../../../components/loader';
import { withSnackbar } from 'notistack';
import * as moment from 'moment'
import { widgets, CustomFieldTemplate, customStyles, customStylesauto, ObjectFieldTemplate } from '../../../components/customscripts/customscript';
import LinearLoader from '../../../components/loader/LinearLoader';
import { InitialSetup, GetAllDealsLogin } from '../../../servies/services';

import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
const Form = withTheme(MuiTheme)
const schemaLima = require('./schema-Lima.json')
const uiSchemaLima = require('./ui-schema-lima.json')
const formDataLima = require('./formdata-lima.json')
const schemaBawag = require('./schema-Bawag.json')
const uiSchemaBawag = require('./ui-schema-bawag.json')
const formDataBawag = require('./formdata-bawag.json')
const schemaSaluda = require('./schema-Saluda.json')
const uiSchemaSaluda = require('./ui-schema-saluda.json')
const formDataSaluda = require('./formdata-saluda.json')
const schemaSaludaGrade = require('./schema-Saludagrade.json')
const uiSchemaSaludaGrade = require('./ui-schema-saludagrade.json')
const formDataSaludaGrade = require('./formdata-saludagrade.json')

class initialSetup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            schema: schemaLima,
            uiSchema: uiSchemaLima,
            formLoader: false,
            open: false,
            formData: formDataLima,
            open2: false,
            pageTitle: "Initial Setup",
            getLoader: false,
            token: localStorage.getItem("token"),
            userid: localStorage.getItem("userid"),
            OrgName: localStorage.getItem('OrgName'),
            DealType: localStorage.getItem('DealType'),
            peers: localStorage.getItem('peers'),
            peer: localStorage.getItem('peer'),
            channelname: localStorage.getItem('ChannelName'),
        }
    }


    async componentDidMount() {

        if (this.state.DealType == "lima") {

            this.setState({ schema: schemaLima, uiSchema: uiSchemaLima, formData: formDataLima });

        } else if (this.state.DealType == "trusteedeal") {

            this.setState({ schema: schemaSaludaGrade, uiSchema: uiSchemaSaludaGrade, formData: formDataSaludaGrade });

        }

    }


    onSubmit = async (value) => {
        this.setState({ formLoader: true, formData: value.formData })
        console.log('onSubmit:', value.formData);
        let data = value.formData;
        data.userId = localStorage.getItem('user_id');
        data.token = localStorage.getItem('token');
        data.peers = JSON.parse(localStorage.getItem('peers'));
        data.channelname = localStorage.getItem('ChannelName');
        console.log('intiinal tabel data', data);
        const DealType = this.state.DealType;
        const APIResponse = await InitialSetup(data, DealType);
        if (APIResponse != null) {
            console.log("APIResponse.data.Success", APIResponse.data);
            if (APIResponse.data.isSuccess == true) {
                let token = this.state.token;
                let peer = this.state.peer;
                let deal_id = value.formData.dealId;
                this.GetAllDeals(DealType, token, peer, deal_id)
            } else {
                const message = "Data not saved successfully";
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            }
        }
    }

    GetAllDeals = async (DealType, token, peer, deal_id) => {

        this.props.history.push("/report/" + DealType + "/add-new/" + deal_id + "/null/null");
        const message = "Data saved successfully";
        this.props.enqueueSnackbar(message, {
            variant: 'info',
            autoHideDuration: 2000,
        });

        // const APIResponse = await GetAllDealsLogin(DealType, token, peer)
        // if (APIResponse.status == "200") {
        //   console.log("GetAllDeals APIResponse", APIResponse, this.state.years, this.state.months)

        //   let deal_name = []

        //   if (APIResponse.data.length !== 0) {
        //     APIResponse.data.map((item) => {
        //       console.log("item", item);
        //       deal_name.push({ "deal_id": item });
        //     })
        //   }

        //   this.setState({ formLoader: false });

        //   localStorage.setItem('all_deals', JSON.stringify(deal_name));

        //   this.props.history.push("/report/" + DealType + "/add-new/" + deal_id + "/null/null");
        //   const message = "Data saved successfully";
        //   this.props.enqueueSnackbar(message, {
        //       variant: 'info',
        //       autoHideDuration: 2000,
        //   });
        // }

    };


    render() {
        return (
            <React.Fragment>
                <div className="page">
                    <div className="content">
                        <div className="header"><Header pageTitle={this.state.pageTitle}></Header>
                            {this.state.getLoader == false ? '' : <LinearLoader></LinearLoader>}
                        </div>
                        <div className="page-content text-center">
                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <h3 className="title-page">{!this.state.pageTitle ? '' : this.state.pageTitle}</h3>
                                </div>


                                <div className="col-md-12">
                                    {this.state.loading == false ? <Loader msg={"Loading Modules..."} /> :
                                        <React.Fragment>
                                            <Form
                                                schema={this.state.schema}
                                                onSubmit={this.onSubmit}
                                                onChange={this.onFormChanged}
                                                widgets={widgets}
                                                omitExtraData={true}
                                                liveOmit={true}
                                                FieldTemplate={CustomFieldTemplate}
                                                formData={this.state.formData}
                                                uiSchema={this.state.uiSchema}
                                                ObjectFieldTemplate={ObjectFieldTemplate}
                                            >
                                                {this.state.formAction == 'add' ?
                                                    <Button variant="contained" color="primary" id="signinbutton" type="submit">
                                                        {this.state.formLoader == false ? '' : <Loader msg={""} />}
                                                        Save to Network
                                                    </Button>
                                                    :
                                                    <Button variant="contained" color="primary" id="signinbutton" type="submit">
                                                        {this.state.formLoader == false ? '' : <Loader msg={""} />}
                                                        Save to Network
                                                    </Button>
                                                }
                                            </Form>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withSnackbar(initialSetup);