import React, {Component} from 'react';
import './documentUpload.scss'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as documentUploadActions from "../../store/documentUpload/actions";
export default class documentUpload extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return <div className="component-document-upload">Hello! component documentUpload</div>;
    }
  }
// export default connect(
//     ({ documentUpload }) => ({ ...documentUpload }),
//     dispatch => bindActionCreators({ ...documentUploadActions }, dispatch)
//   )( documentUpload );