/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar';
import TextField from '@material-ui/core/TextField';
// import { Dropdown } from 'react-bootstrap';
// import { Form as form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import Loader from '../../../../components/loader';
import Snackbar from '../../../../components/snackbar';
import axios from "axios";
import { withSnackbar } from 'notistack';

// import Modal from 'react-responsive-modal';
// import { NavLink } from "react-router-dom";

// import MenuItem from '@material-ui/core/MenuItem';

// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';

import CloseIcon from '@material-ui/icons/Close';
import ReactModal from 'react-modal';
import FormLoader from '../../../../components/loader/formLoader';

import {widgets,CustomFieldTemplate,customStylesauto} from '../../../../components/customscripts/customscript';


const Form = withTheme(MuiTheme);
const schema = require('./schema.json');


const formData={};

class trancheListBuy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waterfall: null,
      loadingmodal: false,
      selected: [],
      loading: false,
      loader: false,
      getLoansLoader: false,
      open1: false,
      formData:formData,
      open2: false,
      open3: false,
      disabled: true,
      Redirect: false,
      schema: schema,

      assignbuttonbtn: sessionStorage.getItem("assignbutton"),
      assignbutton: true,
      searchText: ''
    };

  }




  onOpenModal1 =async (tablevalues) => {
    console.log("inside modal");
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    console.log(tablevalues)
var tranchID=tablevalues[0]
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/purchase/tranchedetails?tranchID='+tranchID+"&peer="+peer+"&token="+token);
      const resp = res.data;
      if (res.status === 200) {

        if (resp.token == '-1') {

          const message = "Invalid Token!";
          this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000
          });
          this.props.history.push({
            pathname: '/'
          })

        } else {

        // const columns = resp;
        this.setState({ open1: true ,TrancheID : tablevalues[0], CouponRate: tablevalues[3], FaceValue:tablevalues[7], NotePeriod:tablevalues[4], TotalUnitsAvailable:tablevalues[2]});


        // this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
     
        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 2000,
        });

      }

    } else {
      const message = "something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });
    }
  } catch (error) {
console.log("error"+error)

    if (error.response.status == 400) {

      console.log("getLoans API error", error);
      console.log("error.response", error.response.data.message);

      const message = error.response.data.message;
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });

  } else {

      const message = "something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });


  }

  }


    
  };

  onCloseModal1 = () => {
    this.setState({ open1: false });
  };



  onFormChanged =(value)=>{
  //   const formData={
  //   "amttopaid":0,

  // }
  //   this.setState({formData:formData});

      console.log(value.formData);
      const FaceValue= this.state.FaceValue;
      console.log("ddddddddddddd", typeof(value.formData.NumberOfUnitsPurchased));
      // const CouponRate=this.state.CouponRate;

      if(value.formData.NumberOfUnitsPurchased !== null || value.formData.NumberOfUnitsPurchased !== undefined || value.formData.NumberOfUnitsPurchased !== ""  )
{
  
    const amttopaid  = parseInt(value.formData.NumberOfUnitsPurchased) * parseInt(FaceValue);

    console.log("amttopaid"+ amttopaid);
    console.log("amttopaid type"+typeof amttopaid);
    console.log("amttopaid"+typeof(value.formData.NumberOfUnitsPurchased));


     if(isNaN(amttopaid) == false ){
      console.log("passed");

      const formData={
    "amttopaid": amttopaid.toString(),
    "noofunits": value.formData.noofunits,
    "currency":value.formData.currency,
    "NumberOfUnitsPurchased":value.formData.NumberOfUnitsPurchased,

  }
     this.setState({formData:formData});
     }
     else{
       console.log("fail");
     }


}
  }

  onSubmit =(value)=>{
    // this.setState({msg6:false})
    console.log(value.formData);
    this.setState({ getLoansLoader: true})

    // console.log("x"+this.state.formData.amttopaid);
    const TrancheId = this.state.TrancheID;
    const AmountPaidByInvestor = value.formData.amttopaid;
    const CouponRate=this.state.CouponRate;
    const MonthlyInterestForInvestor=(value.formData.amttopaid)*CouponRate/12;
    const NumberOfUnitsPurchased = value.formData.NumberOfUnitsPurchased;
    const currency =value.formData.currency;
    const MonthlyPrincipalForInvestorNumber = AmountPaidByInvestor/12;
    const NumberOfInstallments = this.state.NotePeriod;
    const MonthlyPrincipalForInvestor = MonthlyPrincipalForInvestorNumber.toString();
    const peers = JSON.parse(localStorage.getItem("peers"));
    const token = localStorage.getItem('token');


    const input={        
           'TrancheId': TrancheId,
          'InvestorId': localStorage.getItem('user_id'),
          'NumberOfUnitsPurchased': NumberOfUnitsPurchased,
          'AmountPaidByInvestor': AmountPaidByInvestor,
          'MonthlyInterestForInvestor': MonthlyInterestForInvestor.toString(),
          'MonthlyPrincipalForInvestor': MonthlyPrincipalForInvestor,
          'NumberOfInstallments': NumberOfInstallments,
          'Currency': currency,
          "peers": peers,
          "token": token
    
        };

      	const result = JSON.stringify(input);


        axios(process.env.react_app_base_url + 'api/V1/purchase/saveinvestortranche', {
          method: 'POST',
          data: result,
          dataType: 'json',
          processData: true,
          headers: {
            // Accept:"application/json",
            "Content-Type": "application/json"
    
          }
    
        }).then(res => {
          console.log(res.data);
          if (res.status === 204) {
            this.setState({ getLoansLoader: false})

            const message = "Data not available";
            this.props.enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 2000
          });
        }
        else if(res.status== 200){
          if(res.data.token == '-1'){
            this.setState({ getLoansLoader: false})

            const message = "Invalid Token!";
            this.props.enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 2000
          });
          this.props.history.push({
            pathname: '/'
          })
      
          } else {
          if (res.data.isSuccess === true) {
            this.setState({ getLoansLoader: false,open1:false})

            const message = "Tranche Buy successful!";
            this.props.enqueueSnackbar(message, {
              variant: 'info',
              autoHideDuration: 2000
          });
    
            // this.setState({msg6:true});
          }
          else {
            this.setState({ getLoansLoader: false})

            const message = "Data not saved!";
            this.props.enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 2000
          });
          }
    
        }}
    
        }).catch(error => {   if (error.response.status == 400) {
    
          console.log("getLoans API error", error);
          console.log("error.response", error.response.data.message);
    
          const message = error.response.data.message;
          this.props.enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 3000,
          });
    
      } else {
    
          const message = "something went wrong, please try again";
          this.props.enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: 3000,
          });
    
    
      } })
}

onchange = e => {

  this.setState({ searchText: e.target.value, searchType: true });
  console.log("search: e.target.value", e.target.value);


};

  async componentDidMount() {
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    const poolId = this.props.match.params.id;
    this.setState({ getLoansLoader: true, assignbutton: true });
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/purchase/investortranchedetails?poolID=' + poolId +"&peer="+peer+"&token="+token);
      const resp = res.data;
      if (res.status === 200) {

        if (resp.token == '-1') {

          const message = "Invalid Token!";
          this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000
          });
          this.props.history.push({
            pathname: '/'
          })

        } else {

        // const columns = resp;


        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
     
        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 2000,
        });

      }

    } else {
      const message = "something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });
    }
  } catch (error) {
console.log("error"+error)

    if (error.response.status == 400) {

      console.log("getLoans API error", error);
      console.log("error.response", error.response.data.message);

      const message = error.response.data.message;
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });

  } else {

      const message = "something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });


  }

  }


  };


  async selectedpoolid(selected) {
    const arr = []


    for (var i = 0; i < selected.length; i++) {
      // console.log('loanID = i', i)
      var j = selected[i];
      let PoolID = this.state.tableData[j].PoolID;
      arr.push(PoolID);
    }

    console.log(arr);
    sessionStorage.setItem("PoolID", arr);
    // this.setState({
    //   PoolID:arr
    // })


  }


  render() {

    const {open1}=this.state;

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("selected" + selected);
        if (allRows.length < 1) {

          var assignbutton = true

          sessionStorage.setItem("assignbutton", true)
          // this.setState({
          //   assignbutton: assignbutton
          // })
        }
        else {
          sessionStorage.setItem("assignbutton", false)
          // this.setState({
          //   assignbutton: false
          // })
        }

        this.selectedpoolid(selected);


      },

      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading:true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, Loading Tranche Data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'TrancheID',
        label: 'Tranche ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'ClassName',
        label: 'Tranche Name',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'TotalUnitsAvailable',
        label: 'Tranche Units',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'CouponRate',
        label: 'Coupon Rate',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'NotePeriod',
        label: 'Lock In Period',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'Currency',
        label: 'Currency',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'NominalAmount',
        label: 'Total Amount',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'FaceValue',
        label: 'Face Value',
        options: {
          filter: true,
          sort: false,
        },
      },


      {
        name: "TrancheID",
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
// var TrancheID= tableMeta.rowData[0];
              <React.Fragment>
                 
               <Button variant="contained"  onClick={() => this.onOpenModal1(tableMeta.rowData)} id="viewtranche" type="submit" > Buy </Button>


              </React.Fragment>





            );

          },

        }
      },
    ];


    // const btn = sessionStorage.getItem("assignbutton");
    const btn = JSON.stringify(this.state.assignbuttonbtn)

    return (

      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Tranche List'}></Header></div>
            <div className="tableSearch">

              <div className="row">
               

              <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}

                </div>
                <div className="col-md-6 col-sm-12">

                </div>

              </div>
            </div>
            <div className="page-content">

            
                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

    

              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
            </div>


            <div >
            <ReactModal
              isOpen={this.state.open1}
              contentLabel="Minimal Modal Example"
              style={customStylesauto}
            >
              <React.Fragment>
                <div className="modalPopup">
                  <h2>Buy Tranche</h2>
                  <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>

            
            <div className="twoColunm">
              
              <Form
                schema={this.state.schema}
                // uiSchema={uiSchema}
                formData={this.state.formData}
                onSubmit={this.onSubmit}
                onChange={this.onFormChanged}
                widgets={widgets}
                FieldTemplate={CustomFieldTemplate}
              >

                <div className="modalsubmit">
                <div className="submitbuttonbg">
                            <hr className="hrbottom"/>
                        <div className="row justify-content-end">
                          
                        <Button variant="contained" id="optionalbutton" onClick={this.onCloseModal1} > Cancel  </Button>

                    <Button variant="contained" color="primary" type="submit" >Submit</Button>
                  </div>
                </div>
                </div>
                {this.state.getLoansLoader === false ? '' : <FormLoader  />}

              </Form>
{/* // {this.state.msg6=== true ? <React.Fragment><Snackbar msg={"Successfull"} open="true" /> </React.Fragment> : ' '} */}

            </div>



            </div>
              </React.Fragment>
            </ReactModal>
        </div>
          </div>
        </div>


      </React.Fragment>
    );
  }
}

export default withSnackbar(trancheListBuy);