import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Button from '@material-ui/core/Button';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form as form } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';

import { withSnackbar } from 'notistack';
import LinearLoader from '../../../components/loader/LinearLoader';
import Loader from '../../../components/loader';
import { widgets, CustomFieldTemplate, customStyles, customStylesauto, ObjectFieldTemplate } from '../../../components/customscripts/customscript';
import { GetAllDeals, generateYears, months, ServicerDataMongodb, ValidateMonthlyInput, UpdateValidateMonthlyInput } from '../../../servies/services';
import NumberFormat from 'react-number-format';


const Form = withTheme(MuiTheme);
const schema = require('./schema.json');
const validateLimaSchema = require('./validateLimaSchema.json');
const formDataLimaSchema = require('./formdata-lima.json');
const validateSaludafirstmonthSchema = require('./validateSaludafirstmonthSchema.json');
const formDataSaludafirstSchema = require('./formdata-saludafirst.json');
const validateSaludanextmonthSchema = require('./validateSaludanextmonthSchema.json');
const formDataSaludanextSchema = require('./formdata-saludanext.json');

const sampledata = { "value1": "0.00", "value2": "0.00", "value3": "0.00", "value4": "0.00", "value5": "0.00", "value6": "0.00", "value7": "0.00", "value8": "0.00", "value9": "0.00", "value10": "0.00", "value11": "0.00", "value12": "0.00", "value13": "0.00", "value14": "0.00", "value15": "0.00", "value16": "0.00", "value17": "0.00", "value18": "0.00", "value19": "0.00", "value20": "0.00", "value21": "0.00", "value22": "0.00", "value23": "0.00", "value24": "0.00", "value25": "0.00", "value26": "0.00", "value27": "0.00", "value28": "0.00", "value29": "0.00", "value30": "0.00", "value31": "0.00", "value32": "0.00", "value33": "0.00", "value34": "0.00", "value35": "0.00", "value36": "0.00", "value37": "0.00", "value38": "0.00", "value39": "0.00", "value40": "0.00", "value41": "0.00", "value42": "0.00" }

class validateMonthlyInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: schema,
            validateSchema: validateLimaSchema,
            formDataSchema: formDataLimaSchema,
            getLoansLoader: false,
            open: false,
            InvestorReportBox: false,
            onboardservicerdata: null,
            getLoader: false,
            pageTitle: "Validate Monthly Inputs",
            formData: {
                dealId: null,
                month: null,
                year: null
            },
            years: generateYears(),
            months: months,
            DealType: localStorage.getItem('DealType'),
            loadingForm: false,
            loadingForm2: false,
            validate_data: null,
            report_loader: false,
            all_deals: JSON.parse(localStorage.getItem("all_deals")),
            fields: [],
            token: localStorage.getItem('token'),
            peer: localStorage.getItem('peer'),
            ValidateMonthlyInputDealsLoader: false,
            peers: JSON.parse(localStorage.getItem('peers')),
            channelname: localStorage.getItem('ChannelName'),


        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleDoc = (e) => {

        this.setState({ file: e.target.files[0] });

    }


    async componentDidMount() {

        console.log("GroupByLima", this.state.groupby)
        console.log("componentDidMount DealId", this.props.match.params.DealId, this.props.match.params.DealMonth, this.props.match.params.DealYear);
        const DealId = this.props.match.params.DealId;
        const DealMonth = this.props.match.params.DealMonth;
        const DealYear = this.props.match.params.DealYear;
        this.callMethod(DealId, DealMonth, DealYear);
        const DealType = this.state.DealType;


        if (!DealId && !DealMonth && !DealYear) {

            
        } else {
            this.setState({ formData: { dealId: DealId, month: DealMonth.toString(), year: DealYear.toString() } })
            this.servicerdata(DealId, DealMonth, DealYear)
            
        }
    }

    async componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps)
        let DealId = nextProps.match.params.DealId;
        let DealMonth = nextProps.match.params.DealMonth;
        let DealYear = nextProps.match.params.DealYear;
        this.callMethod(DealId, DealMonth, DealYear);

        this.setState({fields: []})

        // fields: [],
        if (!DealId && !DealMonth && !DealYear) {

        } else {
            this.setState({ formData: { dealId: DealId, month: DealMonth.toString(), year: DealYear.toString() } })
            this.servicerdata(DealId, DealMonth, DealYear)
        }
    }


    callMethod(DealId, DealMonth, DealYear) {
        const formData = {
            dealId: DealId,
            month: DealMonth,
            year: DealYear
        }

        this.setState({ formData: formData })
        const DealType = this.state.DealType;
        this.GetAllDeals(DealType)
    }

    GetAllDeals = async (DealType) => {

        // const APIResponse = await GetAllDeals(DealType)
        // console.log("APIResponse", APIResponse, "this.state.years", this.state.years, "this.state.months", this.state.months)

        let all_deals = this.state.all_deals;

        let deal_name = []

        if (all_deals.length !== 0) {
            all_deals.map((item) => {
                console.log("all_deals item", item.deal_id);
                deal_name.push(item.deal_id);
            })
        }

        let month_name = []
        let month_value = []
        if (this.state.months.length !== 0) {
            this.state.months.map((item) => {
                console.log("item", item);
                month_name.push(item.label);
                month_value.push(item.value);
            })
        }

        let year_name = []
        let year_value = []
        if (this.state.years.length !== 0) {
            this.state.years.map((item) => {
                console.log("item", item);
                year_name.push(item.key);
                year_value.push(item.value);
            })
        }

        // console.log("currency_list_static", currency_list_static);
        let oldSchema = this.state.schema;
        console.log("oldstagedata", oldSchema);
        oldSchema.properties.dealId.enum = deal_name;
        oldSchema.properties.dealId.enumNames = deal_name;

        oldSchema.properties.month.enum = month_value;
        oldSchema.properties.month.enumNames = month_name;

        oldSchema.properties.year.enum = year_value;
        oldSchema.properties.year.enumNames = year_name;

        const newSchema = Object.assign({}, oldSchema);
        console.log("WRITE oldSchema", newSchema);
        // this.setState({ schema: newSchema});
        this.setState({ schema: newSchema, loadingForm: true });
    };

    handleChange(e) {
        const fields = this.state.fields;

        fields[e.target.name] = e.target.value;

        this.setState({
            fields,
        });

        console.log('fields', this.state.fields);

    }


    onSubmit = async (value) => {

        this.setState({  formData: value.formData })
        this.props.history.push("/report/" + this.state.DealType + "/validate-monthly-inputs/" + value.formData.dealId + "/" + value.formData.month + "/" + value.formData.year);

        // this.setState({ getLoansLoader: true, open: false })
        // this.setState({ msg:false,loadingmodal:false});
        let data = value.formData;
        console.log(data);
        let dealId = data.dealId;
        let month = data.month;
        let year = data.year;
        await this.servicerdata(dealId, month, year)
        this.setState({ formData: { dealId: dealId, month: month, year: year }, report_loader: false })

    }


    async servicerdata(dealId, month, year) {
        this.setState({ report_loader: true })
        const DealType = this.state.DealType;
        let channelname = this.state.channelname;
        const APIResponse = await ValidateMonthlyInput(DealType, dealId, month, year, channelname)
        this.setState({ report_loader: false })
        if (APIResponse != null) {
            console.log("ValidateMonthlyInput", APIResponse)


            let object = {}

            Object.entries(APIResponse.data).map(([key, value]) => {
                return (
                    // object.push([key]=value.length == 0 ? "0.00": value)
                    object[key] = value.length == 0 ? "0.00" : value
                );
            })


            object['value31'] = "0.00"
            object['value32'] = "0.00"
            object['value33'] = "0.00"
            object['value34'] = "0.00"
            object['value35'] = "0.00"
            object['value36'] = "0.00"
            object['value37'] = "0.00"
            object['value38'] = "0.00"
            object['value39'] = "0.00"
            object['value40'] = "0.00"
            object['value41'] = "0.00"
            object['value42'] = "0.00"

            console.log("object", object)
            this.setState({ fields: object, report_box: true })

            // let validate_data = APIResponse.dtaa;
            // let validateJSON = [];
            // Object.entries(this.state.validateSchema.properties).map(([key, value], index) => {
            //     return (
            //         Object.entries(APIResponse.data).map(([key1, value1, value2], index) => {
            //             // console.log("value2", value2)
            //             if (value2 == undefined) {
            //                 if (key1 == key) {
            //                     validateJSON.push({ "key": value.title, "value": value1 })
            //                 }
            //             } else {
            //                 if (key1 == key) {
            //                     validateJSON.push({ "key": value.title, "value1": value1, "value1": value2 })
            //                 }
            //             }
            //         })
            //     );
            // })
            // console.log("validateJSON", validateJSON)
            // this.setState({ fields: validateJSON })

        }

    }

    ValidateMonthlyInputDeals = async () => {

        this.setState({ ValidateMonthlyInputDealsLoader: true });

        let input = [
            {
                "key": "Collateral Balance - Beginning Balance",
                "value": this.state.fields.value1,
                "value1": this.state.fields.value22
            },
            {
                "key": "Collateral Balance - New Loan Principal",
                "value": this.state.fields.value2,
                "value1": this.state.fields.value23
            }, {
                "key": "Collateral Balance - Draws",
                "value": this.state.fields.value3,
                "value1": this.state.fields.value24
            }, {
                "key": "Collateral Balance - Principal Adjustments",
                "value": this.state.fields.value4,
                "value1": this.state.fields.value25
            }, {
                "key": "Collateral Balance - Principal Remittance",
                "value": this.state.fields.value5,
                "value1": this.state.fields.value26
            }, {
                "key": "Collateral Balance - Transferred Third Party Principal",
                "value": this.state.fields.value6,
                "value1": this.state.fields.value27
            }, {

                "key": "Collateral Balance - Repurchased/Transferred Principal",
                "value": this.state.fields.value7,
                "value1": this.state.fields.value28
            }, {
                "key": "Collateral Balance - Net Realized Losses",
                "value": this.state.fields.value8,
                "value1": this.state.fields.value29
            }, {
                "key": "Collateral Balance - Ending Balance",
                "value": this.state.fields.value9,
                "value1": this.state.fields.value30
            }, {
                "key": "Interest Remittance - Gross Interest",
                "value": this.state.fields.value10,
                "value1": this.state.fields.value31
            },
            {
                "key": "Interest Remittance - Repurchased Interest",
                "value": this.state.fields.value11,
                "value1": this.state.fields.value32
            },
            {
                "key": "Interest Remittance - Retained Fees",
                "value": this.state.fields.value12,
                "value1": this.state.fields.value33
            },
            {
                "key": "Interest Remittance - Deferred Interest",
                "value": this.state.fields.value13,
                "value1": this.state.fields.value34
            },
            {
                "key": "Interest Remittance - Relief Act Interest Shortfall",
                "value": this.state.fields.value14,
                "value1": this.state.fields.value35
            },
            {
                "key": "Interest Remittance - Net Nonrecoverable Advances",
                "value": this.state.fields.value15,
                "value1": this.state.fields.value36
            },
            {
                "key": "Interest Remittance - Interest Loss",
                "value": this.state.fields.value16,
                "value1": this.state.fields.value37
            },
            {
                "key": "Principal Remittance - Scheduled Principal",
                "value": this.state.fields.value17,
                "value1": this.state.fields.value38
            },
            {
                "key": "Principal Remittance - Prepayments & Curtailments",
                "value": this.state.fields.value18,
                "value1": this.state.fields.value39
            },
            {
                "key": "Principal Remittance - Net Liquidation Proceeds",
                "value": this.state.fields.value19,
                "value1": this.state.fields.value40
            },
            {
                "key": "Principal Remittance - Repurchased Principal",
                "value": this.state.fields.value20,
                "value1": this.state.fields.value41
            },
            {
                "key": "Principal Remittance - Draw Principal",
                "value": this.state.fields.value21,
                "value1": this.state.fields.value42
            }
        ]


        let data = {
            "dealId": this.state.formData.dealId,
            "input": input,
            "month": this.state.formData.month,
            "peers": this.state.peers,
            "token": this.state.token,
            "year": this.state.formData.year,
            "channelname":this.state.channelname
        }

        console.log("data", JSON.stringify(data))
        // UpdateValidateMonthlyInput
        let DealType = this.state.DealType
        const APIResponse = await UpdateValidateMonthlyInput(DealType, data)
        console.log("UpdateValidateMonthlyInput", APIResponse)

        console.log("SaveCustomizeReportAPI", APIResponse.data.isSuccess)
        if (APIResponse.data.isSuccess == true) {

            const message = "Data Saved Successfully!";
            this.props.enqueueSnackbar(message, {
                variant: 'info',
                autoHideDuration: 4000,
            });

            let month = this.state.formData.month;
            let year = this.state.formData.year;
            if(month == "12"){
                month = 1;
                year = parseInt(year) + 1
            } else {
                month = parseInt(month) + 1
            }

            
            this.props.history.push("/report/" + this.state.DealType + "/generate-investor-report/" + this.state.formData.dealId + "/" + month + "/" + year);

        } else {

            const message = "Data Saved not Successfully!";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 4000,
            });

        }

        this.setState({ ValidateMonthlyInputDealsLoader: false })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page">
                    <div className="content">
                        <div className="header"><Header pageTitle={this.state.pageTitle}></Header>
                            {this.state.getLoader == false ? '' : <LinearLoader></LinearLoader>}
                        </div>
                        <div className="page-content text-center">
                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <h3 className="title-page">{!this.state.pageTitle ? '' : this.state.pageTitle}</h3>
                                </div>
                                <div className="col-md-12">
                                    <div class="threecolform">
                                        {this.state.loadingForm == true ?
                                            <Form
                                                schema={this.state.schema}
                                                onSubmit={this.onSubmit}
                                                onChange={this.onFormChanged}
                                                widgets={widgets}
                                                omitExtraData={true}
                                                liveOmit={true}
                                                FieldTemplate={CustomFieldTemplate}
                                                formData={this.state.formData}
                                                uiSchema={this.state.uiSchema}
                                                ObjectFieldTemplate={ObjectFieldTemplate}
                                            >
                                                <div className='row justify-content-center'>
                                                    <Button variant='contained' color='primary' type='submit'>

                                                        View Servicer Data From Network

                                                        {this.state.report_loader === true ? (
                                                            <CircularProgress size='25px' color='primary' />
                                                        ) : (
                                                                ''
                                                            )}

                                                    </Button>

                                                </div>
                                            </Form>
                                            : <Loader></Loader>}
                                    </div>
                                </div>
                            </div>

                            {this.state.fields.length !== 0 ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="twocolform">
                                            <table className="table table-bordered text-left">
                                                <tr>
                                                    <td>Collateral Balance - Beginning Balance  </td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value1'} value={this.state.fields.value1} /></td>
                                                    <td>
                                                        <TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value22'} value={this.state.fields.value22} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - New Loan Principal</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value2'} value={this.state.fields.value2} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value23'} value={this.state.fields.value23} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - Draws</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value3'} value={this.state.fields.value3} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value24'} value={this.state.fields.value24} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - Principal Adjustments	</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value4'} value={this.state.fields.value4} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value25'} value={this.state.fields.value25} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - Principal Remittance</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value5'} value={this.state.fields.value5} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value26'} value={this.state.fields.value26} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - Transferred Third Party Principal</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value6'} value={this.state.fields.value6} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value27'} value={this.state.fields.value27} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - Repurchased/Transferred Principal</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value7'} value={this.state.fields.value7} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value28'} value={this.state.fields.value28} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - Net Realized Losses</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value8'} value={this.state.fields.value8} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value29'} value={this.state.fields.value29} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Collateral Balance - Ending Balance</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value9'} value={this.state.fields.value9} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value30'} value={this.state.fields.value30} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Interest Remittance - Gross Interest</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value10'} value={this.state.fields.value10} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value31'} value={this.state.fields.value31} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Interest Remittance - Repurchased Interest</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value11'} value={this.state.fields.value11.length == 0 ? '0.00' : this.state.fields.value11} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value32'} value={this.state.fields.value32} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Interest Remittance - Retained Fees</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value12'} value={this.state.fields.value12} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value33'} value={this.state.fields.value33} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Interest Remittance - Deferred Interest</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value13'} value={this.state.fields.value13} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value34'} value={this.state.fields.value34} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Interest Remittance - Relief Act Interest Shortfall</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value14'} value={this.state.fields.value14} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value35'} value={this.state.fields.value35} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Interest Remittance - Net Nonrecoverable Advances</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value15'} value={this.state.fields.value15} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value36'} value={this.state.fields.value36} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Interest Remittance - Interest Loss</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value16'} value={this.state.fields.value16} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value37'} value={this.state.fields.value37} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Principal Remittance - Scheduled Principal</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value17'} value={this.state.fields.value17} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value38'} value={this.state.fields.value38} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Principal Remittance - Prepayments & Curtailments</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value18'} value={this.state.fields.value18} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value39'} value={this.state.fields.value39} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Principal Remittance - Net Liquidation Proceeds</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value19'} value={this.state.fields.value19} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value40'} value={this.state.fields.value40} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Principal Remittance - Repurchased Principal</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value20'} value={this.state.fields.value20} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value41'} value={this.state.fields.value41} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Principal Remittance - Draw Principal</td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value21'} value={this.state.fields.value21} /></td>
                                                    <td><TextField id={'key'} variant="outlined" size="small" label="" onChange={this.handleChange} name={'value42'} value={this.state.fields.value42} /></td>
                                                </tr>
                                            </table>



                                            <Button variant='contained' color='primary' onClick={this.ValidateMonthlyInputDeals} type='submit'>
                                                {this.state.ValidateMonthlyInputDealsLoader === true ? (
                                                    <CircularProgress size='25px' color='primary' />
                                                ) : (
                                                        ''
                                                    )} Onboard to Network </Button>

                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(validateMonthlyInput)