/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { Form } from 'react-bootstrap';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';
import * as moment from 'moment';

function myFunction(str) {
    //var str = "Hello world!";
    if (str.length > 2) {
        var res = str.substring(2, str.length);
        var rep = res.replace(/[a-z]/gi, 'x')
        return str.replace(res, rep);
    }
  
    return str;
  }

class LoanDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            getLoansLoader: false,
        };

    }
    
    async componentDidMount() {
        var loan_id = this.props.dataFromParent;
        const peer = localStorage.getItem('peer');
        const token = localStorage.getItem('token');
        this.loanDetails(loan_id, peer, token)
    }

    async loanDetails(loan_id, peer, token) {
        try {
            const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/loans?loanId=' + loan_id + "&peer=" + peer + "&token=" + token);
            const resp = res.data;
            console.log("loandetails", res.data);
            if (res.status === 200) {
                console.log("loandetails", resp);
                // const columns = resp;

                var result = resp;


                this.setState({ Loandetails: result, loadingmodal: true });
                const message = "Successfully fetched the data";
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 2000,
                });
            } else {
                alert("Failed");
            }
        } catch (error) {
            alert("Failed");
            console.log("getLoans API error", error);
        }
    }

    render() {


        return (

            <React.Fragment>


                <div>
                    {/* {this.state.loading === true ? */}
                    {this.state.Loandetails != null && this.state.loadingmodal === true ?
                        <Form id="labelform">
                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Loan Contract Number</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.LoanContractNumber} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.City} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Date of loan agreement</Form.Label>
                                        <Form.Control required disabled placeholder=""
                                         value={moment(this.state.Loandetails.DateOfLoanAgreement).format('MM-DD-YYYY')}
                                           />
                                    </Form.Group>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Lender</Form.Label>
                                        <Form.Control required disabled placeholder="" value={myFunction(this.state.Loandetails.Lender)} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Date of loan application</Form.Label>
                                        <Form.Control required disabled placeholder="" value={moment(this.state.Loandetails.DateOfLoanApplication).format('MM-DD-YYYY')} />
                                        
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Signed and Delivered By</Form.Label>
                                        <Form.Control required disabled placeholder="" value={myFunction(this.state.Loandetails.SignedAndDeliveredBy)} />
                                    </Form.Group>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Type of loan</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.TypeOfLoan} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Loan purpose</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.LoanPurpose} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Loan / facility amount</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.LoanOrFacilityAmount} />
                                    </Form.Group>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Currency</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.Currency} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Loan / facility term (in months)</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.LoanOrFacilityTermInMonths} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Interest type</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.InterestType} />
                                    </Form.Group>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Interest Chargeable per annum</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.InterestChargeablePerAnnum} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Default Interest rate per annum</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.DefaultInterestRatePerAnnum} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Mode of communication in case of interest rate change</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.ModeOfCommunicationForInterestRateChange} />
                                    </Form.Group>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Application processing fee(% of loan amount)</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.ApplicationProcessingFee} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Other conditions</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.OtherConditions} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>EMI Payable</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.EmiPayable} />
                                    </Form.Group>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Last EMI Payable</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.LastEMIPayable} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Date of commencement of EMI</Form.Label>
                                        <Form.Control required disabled placeholder="" 
                                        value={moment(this.state.Loandetails.DateOfCommencementOfEMI).format('MM-DD-YYYY')}
                                         />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>EMI Mode of Repayment</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.ModeOfRepayment} />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Warehouse lender Name</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Loandetails.WarehouseLenderName} />
                                    </Form.Group>
                                </div>
                            </div>

                        </Form>
                        : <Loader msg={"Please wait, Loading Loan details"} />}

                </div>

            </React.Fragment>
        );
    }
}


export default withSnackbar(LoanDetails);