/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from 'axios';
import { withSnackbar } from 'notistack';

class poolFormation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open1: false,
            //   schema: schema,
            loading: false,
            getLoansLoader: false,
            open: false,
            openPopup: false,
            message: null,
            popupLoader: false,
            msgData: null,
            open2: false,
        };

    }

    async componentDidMount() {

        const userID = this.props.userID
        const userType = this.props.userType
        this.getLoans(userID, userType);


    }



    async getLoans(userID, userType) {

        this.setState({ getLoansLoader: true });
        const peer = localStorage.getItem('peer');
        const token = localStorage.getItem('token');
        // const dataString = data
        console.log('getLoans dataString', userID, userType);
        try {
            const res = await axios.get(process.env.react_app_base_url + "api/v1/payment/transactions?userID=" + userID + "&userType=" + userType + "&peer=" + peer + "&token=" + token);

            const resp = res.data;
            if (res.status === 200) {
                console.log('eligibleloans', resp);
                const message = "Successfully fetched the data";     
                this.props.enqueueSnackbar(message, {
                  variant: 'info',
                  autoHideDuration: 2000,
              });
        
                this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
            } else {
                alert('Failed');
            }
        } catch (error) {
            alert('Failed');
            console.log('getLoans API error', error);
        }
    };


    render() {

        const options = {
            filterType: 'textField',
            search: false,
            print: false,
            viewColumns: false,
            download: false,
            rowHover: false,
            selectableRowsOnClick: false,
            selectableRows: false,
            onRowClick: this.onRowClick,
            onRowsSelect: this.onRowsSelect,
           
      loading:true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, loading data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },

                selectedRows: {
                    text: 'row(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Rows',
                },
            },
        };
        const columns = [
            {
                name: 'TransactionId',
                label: 'Transaction Id',
            },
            {
                name: 'PaymentDate',
                label: 'Payment Date',
            },

            {
                name: 'ApprovalStatus',
                label: 'Approval Status',
            },

            {
                name: 'PayeeRole',
                label: 'Payee Role',
            },

            {
                name: 'PaymentDate',
                label: 'Payment Date',
            },

            {
                name: 'PaymentStatus',
                label: 'Payment Status',
            },

            {
                name: 'Currency',
                label: 'Currency',
            },
            {
                name: 'AmountPaid',
                label: 'Amount Paid',
            },
            {
                name: 'PayerId',
                label: 'Payer Id',
            }
        ];
        return (
            <React.Fragment>

                <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                />
                {/* {this.state.getLoansLoader === false ? '' : <Loader msg={"Please wait.."} />} */}

                {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}

            </React.Fragment>
        );
    }
}
export default withSnackbar(poolFormation);