/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Button from '@material-ui/core/Button';
import BankAccounts from '../../../images/BankAccounts.png';
import ReactModal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import AddBank from './addBank';
// import Modal from 'react-responsive-modal';
import {customStyles,customStylesauto} from '../../../components/customscripts/customscript';



export default class bankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loader: false,
      getLoansLoader: false,
      IsOpen: false,
      open2: false,
      open3: false,
      disabled: true,
      Redirect: false,
      modalIsOpen: false,
      showModal: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.childHandler = this.childHandler.bind(this)
    this.onOpenModal2 = this.onOpenModal2.bind(this);
  }

  childHandler(dataFromChild) {
    console.log(dataFromChild);
    this.setState({ showModal: false });
  }

  handleOpenModal = () => {
    console.log("inside modal");
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  onOpenModal2 = () => {
    console.log("inside modal");
    this.setState({ open2: true });
  };

  onCloseModal2 = () => {
    this.setState({ open2: false });
  };


  render() {
    const {open2} =this.state;
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Bank Details'}></Header></div>

            <div className="addBankBox">
              <img src={BankAccounts} alt={''} />
              <h1>Bank Account</h1>
              <p>In order to transfer & receive funds, you have need to link your bank details. </p>
              <Button onClick={this.handleOpenModal} variant="contained" color="primary" type="submit"> Add Bank Account  </Button>
            </div>
{/* 
            <Modal id="modal" open={open2} onClose={this.onCloseModal2} center>
            <div id="modalheader">
              <h5>Add bank account details</h5>

            </div>
            <hr id="horline" />
            <div>
            <AddBank action={this.childHandler}></AddBank>
            </div>
            </Modal> */}
            <ReactModal
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              style={customStylesauto}
              onRequestClose={this.handleCloseModal}
            >
              <React.Fragment>
                <div className="modalPopup">
                  <h2> Add Bank Account Details </h2>
                  <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.handleCloseModal}> <CloseIcon></CloseIcon> </Button>
                  <AddBank action={this.childHandler}></AddBank>
                </div>
              </React.Fragment>
            </ReactModal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
