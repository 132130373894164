/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import Modal from 'react-responsive-modal';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Match from '../../../images/match.png';
import Mismatch from '../../../images/mismatch.png';
import { withSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import FormLoader from '../../../components/loader/formLoader';
import ReactModal from 'react-modal';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import * as moment from 'moment';
import { widgets, CustomFieldTemplate, customStyles, customStylesauto,customStylesauto1 } from '../../../components/customscripts/customscript';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import {AddAttribute,GetAllAttributes} from '../../../servies/services';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import LinearLoader from '../../../components/loader/LinearLoader';
const Addfieldschema = require('./Add-Field.json');
const uiSchema = require('./ui-schema.json');
const Form = withTheme(MuiTheme);
const token = localStorage.getItem('token')


class fields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiSchema: uiSchema,
      getLoader: false,  
      formData1:null,  
      isChecked: true,
      selectedRecords: [],
      eligibleloans: {},
      tableData: [],
      loading: false,
      getLoansLoader: false,
      open: false,
      selectedLoanId: [],
      searchText: '',
      open1: false,
      openPopup: false,
      message: null,
      popupLoader: false,
      msgData: null,
      rowsSelected: null,
      // selectedrows :null,
      Addfieldschema:Addfieldschema,
      pageTitle: "FIELDS",
      // searchText: ''
    };

  }
  onOpenModal = () => {
    this.setState({formData1:[]})
      console.log("inside modal1");
      this.setState({ open: true });
      
    };
  
    onCloseModal = () => {
      this.setState({ open: false });
  
    };
    onSubmit = () => {
      // console.log("tabledata1" , this.state.tableData1)
      console.log("tabledata2" , this.state.tableData2)
      // console.log("tabledata3" , this.state.tableData3)
      // let data = this.state.tableData1.concat(this.state.tableData2,this.state.tableData3)
      
      // console.log("tabledata3" , JSON.stringify(data))

  }
//   addfieldbtn = () => {
//     this.onOpenModal()
// }


  onFormChanged = (value) => {
      this.setState({formData1: value.formData })
    }


  onchange = e => {
    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  };

  handleClick = (rowId) =>{
    const arrayCopy = this.state.tableData.filter((row) => row.processorId !== rowId);
    this.setState({tableData: arrayCopy});
    this.HIt(rowId)
  }
  HIt = (rowId) => {
    setTimeout(() => {
        console.log("setTimeout", Date().toLocaleString())
        console.log('tabledata: ',this.state.tableData)
    }, 1000)
}

  async componentDidMount() {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined) {
      window.location.assign("/")
    }
    this.GetAllAttributes(token)
  }

  GetAllAttributes = async (token) =>{
    this.setState({getLoansLoader: true,tableData:[]});
    const APIResponse = await GetAllAttributes(token)
  
    if (APIResponse.status === 200) {
      console.log('Allpools--', APIResponse);
      this.setState({getLoansLoader: false,tableData: APIResponse.data, loading: true,});
      const message = "Record Fetched Successfully";
      this.props.enqueueSnackbar(message, {
        variant: 'info',
        autoHideDuration: 3000,
      });
    } else {
      alert('Failed');
    }
  }

  AddAttribute = async (value) =>{
    let data = value.formData
    console.log("AddAttribute"+JSON.stringify(data))
  
    const APIResponse = await AddAttribute(data,token)
  
    if (APIResponse.status !== 200) {
      const message = "Something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
  
    }
    else {
  
      this.setState({ getLoansLoader: false, openPopup: true });
  
      if (APIResponse.isSuccess == false) {
  
        this.setState({ formLoader: false })
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 1000,
        });
            this.onCloseModal()
  
      } else {
        this.setState({ formLoader: false })
        const message = "Attribute created successfully";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 1000,
        });
           this.onCloseModal()
           this.GetAllAttributes(token)
        //  this.props.history.push("/admin/dashboard")
        //  window.location.reload()
      }
    }
  }


  async selectedpoolid(selected) {
    const arr = []

    this.setState({selectedrows:selected})

    for (var i = 0; i < selected.length; i++) {

      var j = selected[i];

      let loanId = this.state.tableData[j];
      arr.push(loanId);
    }
    console.log(arr);
    localStorage.setItem('DDReport', arr)
  }

  onSubmit = () => {
    if (localStorage.getItem("dueDiligence") !== null) {
      const data = localStorage.getItem('dueDiligence');
      this.props.history.push({
        pathname: '/portfolio-setup-wizard/pool-formation',
        search: '',
        state: { eligiblePortfolioImportParameter: data }
      });
    }
  }


  render() {

    const options = {
      customToolbar: ()=>{},
      filterType: 'dropdown',
      filter:true,
      search: true,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      // searchOpen: true, 
      // searchPlaceholder:'Search',
      selectableRowsOnClick: true,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },
      searchText: this.state.searchText,
      searchPlaceholder: 'Search',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      loading:false,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, Loading Loan Data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };




    const columns = [
      {
        name:'attributePoolName',
        label: 'Deal Name',
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name:'attributeName',
        label: 'Attribute Name',
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "attributeStandardName",
        label: 'Attribute Standard Name',
        options: {
          filter: true,
          sort: true,
        }
      },
      
      {
        name: "attributeCategory",
        label: 'Attribute Category',
        options: {
          filter: true,
          sort: true,
          
        }
      },

      {
        name: "attributeDisplay",
        label: 'Attribute Display',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {/* {JSON.stringify(value)} */}
                {value === true || value === 'true'  ? 'YES' : 'NO'}
              </React.Fragment>
            );
          },
          
        }
      },

    //   {
    //     name: 'processorId',
    //     label: 'Action',
    //     options: {
    //       filter: true,
    //       sort: false,
    //       customBodyRender: (value, tableMeta, updateValue) => {

    //           return(
    //           <React.Fragment>
    //             <div className="">
    //                {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value)} > */}
    //                   {/* <img alt="" src={require('../../../images/ViewLoans.png')} onClick={() => this.handleClick(value)}></img> */}
    //                  <DeleteOutlinedIcon style={{cursor: "pointer"}} onClick={() => {this.handleClick(value)}}> </DeleteOutlinedIcon> 
    //                 {/* </Button> */}


    //             </div>
    //           </React.Fragment>
    //         );
    //     }
    //   }
    // },
    ];
   

    return (
     
      <React.Fragment>
      <div className="page">
          <div className="content">
              <div className="header"><Header pageTitle={this.state.pageTitle}></Header>
                  {this.state.getLoansLoader == false ? '' : <LinearLoader></LinearLoader>}
              </div>
              <div className="page-content text-center">
                  
              <div className="row">
                      <div className="col-md-12 text-left">
                          <h3 className="title-page1"><CenterFocusWeakIcon fontSize="large"></CenterFocusWeakIcon>&nbsp;&nbsp;{"Fields to capture"}</h3>
                      </div>
                      </div>
                      
                      <div className="contentfields">
                          
                       <div className="row">
                          <div className="text-left buttonshifts">
                              <Button className="buttonchangess" variant="outlined" color="primary" style={{margin:"15px"}} onClick={this.onOpenModal.bind(this)}> Add Field</Button>
                          </div>
                          </div>
                        
                  </div>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                  />
                         
          </div>
      </div>
      <div id="modal">
        
        <ReactModal
          isOpen={this.state.open}
          onRequestClose={this.onCloseModal}
          contentLabel="Minimal Modal Example"
          style={customStylesauto}
        >

          <React.Fragment>
            <div className="modalPopup">
              <h2> Add Field </h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal}> <CloseIcon></CloseIcon> </Button>

              <div>
                <Form
                 schema={this.state.Addfieldschema}
                 uiSchema={this.state.uiSchema}
                 formData={this.state.formData1}
                 onSubmit={this.AddAttribute}
                 onChange={this.onFormChanged}
                 widgets={widgets}
                 FieldTemplate={CustomFieldTemplate}

                >
                 
                  <div className="modalsubmit">
                    <div className="submitbuttonbg">
                      <hr className="hrbottom" />
                      <div className="row justify-content-end">

                        <Button variant="contained" id="optionalbutton" onClick={this.onCloseModal} > Cancel  </Button>
                        <Button variant="contained" color="primary" type="submit" > Add Field  
                        {this.state.formLoader === true ? (
                        <CircularProgress size='25px' color='primary' />
                         ) : (
                         ''
                        )}</Button>
                      </div>
                    </div>
                  </div>
                </Form>

              </div>
            </div>
          </React.Fragment>

        </ReactModal>
      </div>

      {/* <div className="navbarSteps navbarStepsBtm">
          <div className="row justify-content-end">


               <Button onClick={this.onSubmit} variant="contained"  color="primary" type="submit"  >
          
               Save  </Button>
          </div>
        </div> */}
      </div>
  </React.Fragment>

    );
  }
}

export default withSnackbar(fields);