/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AmountReceived from './amountReceived';
import Button from '@material-ui/core/Button';
import CreateTransaction from './createTransaction';
import ReactModal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
// import Modal from 'react-responsive-modal';
import {customStyles} from '../../../components/customscripts/customscript';
import { withSnackbar } from 'notistack';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
};

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};



class transactionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      showModal: false,
      userId:null
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.childHandler = this.childHandler.bind(this)
    this.onOpenModal2 = this.onOpenModal2.bind(this);
  }


  childHandler(dataFromChild) {
    console.log(dataFromChild);
    this.setState({ showModal: false });
  }


  handleChange = (event, value) => {
    // alert(value)
    this.setState({ value });
  };

  handleOpenModal = () => {
    console.log("inside modal");
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  onOpenModal2 = () => {
    console.log("inside modal");
    this.setState({ open2: true });
  };

  onCloseModal2 = () => {
    this.setState({ open2: false });
  };

  async componentDidMount() {
    var role = localStorage.getItem("OrgName");
    const userId = localStorage.getItem("user_id");
    this.setState({
      role: role,userId:userId
    })
  }


  render() {
    const { value } = this.state;
    const { open2 } = this.state;
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Transaction Details'}></Header></div>
            <div className="tableSearch" style={{ padding: '0px 15px' }}>
              <div className="row">
                <div className="col-md-6 col-sm-12" id="trantab">
                  {this.state.role === "originator" || this.state.role === "whlender" ? <Tabs value={value} onChange={this.handleChange} className="cards-charts"> <Tab label="Amount Received" icon={''} /> </Tabs> : ''}
                  {this.state.role === "issuer" || this.state.role === "servicer" || this.state.role === "trustee" ? <React.Fragment> <Tabs value={value} onChange={this.handleChange} className="cards-charts"> <Tab label="Amount Received" icon={''} /><Tab label="Amount Transferred" icon={''} /></Tabs></React.Fragment> : ' '}

                  {this.state.role === "investor" ? <Tabs value={value} onChange={this.handleChange} className="cards-charts">  <Tab label="Amount Transferred" icon={''} /> </Tabs> : ''}

                </div>
                <div className="col-md-6 col-sm-12 text-right" style={{ padding: '10px' }}>
                  <Button onClick={this.handleOpenModal} variant="contained" color="primary">Create Transaction </Button>
                </div>
              </div>
            </div>
            {value === 0 && <TabContainer>
              {this.state.role === "investor" ? <AmountReceived userID={this.state.userId} userType={'payer'}></AmountReceived> : ''}
              {this.state.role === "whlender" || this.state.role === "originator" ? <AmountReceived userID={this.state.userId} userType={'payee'}></AmountReceived> : ''}
              {this.state.role === "issuer" || this.state.role === "servicer" || this.state.role === "trustee" ? <AmountReceived userID={this.state.userId} userType={'payee'}></AmountReceived> : ''}
            </TabContainer>}
            {value === 1 && <TabContainer>
              {this.state.role === "issuer" || this.state.role === "servicer" || this.state.role === "trustee" ? <AmountReceived userID={this.state.userId} userType={'payer'}></AmountReceived> : ''}
            </TabContainer>}

            {/* <Modal id="modal" open={open2} onClose={this.onCloseModal2} center>
              <div id="modalheader">
                <h5>Create Transaction</h5>

              </div>
              <hr id="horline" />
              <div>
                <CreateTransaction action={this.childHandler}></CreateTransaction>
              </div>
            </Modal> */}
            <ReactModal
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              style={customStyles}
              onRequestClose={this.handleCloseModal}

            >
              <React.Fragment>
                <div className="modalPopup">
                  <h2> Create Transaction </h2>
                  <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.handleCloseModal}> <CloseIcon></CloseIcon> </Button>
                  <CreateTransaction action={this.childHandler}></CreateTransaction>
           {/* <div id="modalhr">
             <hr/> */}
             {/* <div className="text-right">
                  <Button onClick={this.handleCloseModal} right id="closebutton" variant="contained" color="primary">Close</Button>
                  </div> */}
                  {/* </div> */}
                </div>
              </React.Fragment>
            </ReactModal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(transactionDetails);