/* eslint-disable require-jsdoc */
import React, {Component} from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
export default class createTransaction extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Create Transaction'}></Header></div>
            <div className="page-content">Create Transaction</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


