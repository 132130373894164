/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Iframe from 'react-iframe';
import $ from 'jquery';
import CompareItem from '../../../components/pageTitle/CompareItem';
import * as moment from 'moment';
import { withSnackbar } from 'notistack';

class CompareData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
      msgNewData: null,
    };
    this.saveDate = this.saveDate.bind(this);
  }


  async handleClick(value) {
    this.setState({ popupLoader: true, open2: true });
    const dataString = value;
    console.log('getLoans dataString', dataString);
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/comparedata?loancontractno=' + dataString);

      const resp = res.data;

      console.log('resp', resp);
      if (res.status === 200) {
        console.log('eligibleloans', resp[0]);
        console.log(JSON.stringify(resp[0].loandocpath))

        this.setState({ popupLoader: false, open: true, msgNewData: resp[0], loading: true, loandocpath: resp[0].loandocpath[0], loanpath: resp[0].loandocpath });

      } else {
        alert('Failed');
      }
    } catch (error) {
      alert('Failed');
      console.log('getLoans API error', error);
    }
  }

  async componentDidMount() {
    const value = this.props.msgData;
    this.handleClick(value);

  }



  saveDate() {

    this.props.action({ close: true, loanContractNumber: this.state.msgNewData.lmsLoan.loanContractNumber });

    const message = "Data Saved";
    this.props.enqueueSnackbar(message, {
      variant: 'info',
      autoHideDuration: 4000,
    });

  }


  render() {
    return (

      <React.Fragment>
        <div id="comparesave">
          <Button onClick={this.saveDate} variant="contained" color="primary" type="submit" > Save  </Button>
        </div>
        <div className="matchscroll">
          <div id="content_pdfconent" className="viewmorecolumnpdf">
            {this.state.msgNewData !== null ?

              <Form id="labelform">

                {/* {JSON.stringify(this.state.msgNewData)} */}

                <table className="data_table" id="viewMore" >
                  <thead>
                    <tr>
                      <th>LMS</th>
                      <th>Contract</th>
                      <th style={{ width: '50px' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    <CompareItem id="1" mask={false} title={'Loan Contract Number'} lmsLoan={this.state.msgNewData.lmsLoan.loanContractNumber} agreementLoan={this.state.msgNewData.agreementLoan.loanContractNumber}></CompareItem>
                    <CompareItem id="2" mask={false} title={'City'} lmsLoan={this.state.msgNewData.lmsLoan.city} agreementLoan={this.state.msgNewData.agreementLoan.city}></CompareItem>
                    <CompareItem id="3" mask={false} title={'Date Of Loan Agreement'} lmsLoan={moment(this.state.msgNewData.lmsLoan.dateOfLoanAgreement).format('MM-DD-YYYY')} agreementLoan={this.state.msgNewData.agreementLoan.dateOfLoanAgreement?.length == 0 ? this.state.msgNewData.agreementLoan.dateOfLoanAgreement : moment(this.state.msgNewData.agreementLoan.dateOfLoanAgreement).format('MM-DD-YYYY')}></CompareItem>
                    <CompareItem id="4" mask={true} title={'Lender'} lmsLoan={this.state.msgNewData.lmsLoan.lender} agreementLoan={this.state.msgNewData.agreementLoan.lender}></CompareItem>
                    <CompareItem id="5" mask={false} title={'Date Of Loan Application'} lmsLoan={moment(this.state.msgNewData.lmsLoan.dateOfLoanApplication).format('MM-DD-YYYY')} agreementLoan={this.state.msgNewData.agreementLoan.dateOfLoanApplication?.length == 0 ? this.state.msgNewData.agreementLoan.dateOfLoanApplication : moment(this.state.msgNewData.agreementLoan.dateOfLoanApplication).format('MM-DD-YYYY')} ></CompareItem>
                    <CompareItem id="6" mask={false} title={'Type Of Loan'} lmsLoan={this.state.msgNewData.lmsLoan.typeOfLoan} agreementLoan={this.state.msgNewData.agreementLoan.typeOfLoan}></CompareItem>
                    <CompareItem id="7" mask={false} title={'Loan Purpose'} lmsLoan={this.state.msgNewData.lmsLoan.loanPurpose} agreementLoan={this.state.msgNewData.agreementLoan.loanPurpose}></CompareItem>
                    <CompareItem id="8" mask={false} title={'Loan/Facility Amount'} lmsLoan={this.state.msgNewData.lmsLoan.loanOrFacilityAmount} agreementLoan={this.state.msgNewData.agreementLoan.loanOrFacilityAmount}></CompareItem>
                    <CompareItem id="9" mask={false} title={'Loan/Facility Term'} lmsLoan={this.state.msgNewData.lmsLoan.loanOrFacilityTermInMonths} agreementLoan={this.state.msgNewData.agreementLoan.loanOrFacilityTermInMonths}></CompareItem>
                    <CompareItem id="10" mask={false} title={'Currency'} lmsLoan={this.state.msgNewData.lmsLoan.currency} agreementLoan={this.state.msgNewData.agreementLoan.currency}></CompareItem>
                    <CompareItem id="12" mask={false} title={'Interest Type'} lmsLoan={this.state.msgNewData.lmsLoan.interestType} agreementLoan={this.state.msgNewData.agreementLoan.interestType}></CompareItem>
                    <CompareItem id="13" mask={false} title={'Interest Chargeable'} lmsLoan={this.state.msgNewData.lmsLoan.lmsInterestChargeable} agreementLoan={this.state.msgNewData.agreementLoan.lmsInterestChargeable}></CompareItem>
                    <CompareItem id="14" mask={false} title={'Default IR Per Annum'} lmsLoan={this.state.msgNewData.lmsLoan.defaultInterestRatePerAnnum} agreementLoan={this.state.msgNewData.agreementLoan.defaultInterestRatePerAnnum}></CompareItem>
                    <CompareItem id="15" mask={false} title={'Application Processing Fee'} lmsLoan={this.state.msgNewData.lmsLoan.applicationProcessingFee} agreementLoan={this.state.msgNewData.agreementLoan.applicationProcessingFee}></CompareItem>
                    <CompareItem id="16" mask={false} title={'EMI Payable'} lmsLoan={this.state.msgNewData.lmsLoan.emiPayable} agreementLoan={this.state.msgNewData.agreementLoan.emiPayable}></CompareItem>
                    <CompareItem id="17" mask={false} title={'Date Of Commencement of EMI'} lmsLoan={moment(this.state.msgNewData.lmsLoan.dateOfCommencementOfEMI).format('MM-DD-YYYY')} agreementLoan={this.state.msgNewData.agreementLoan.dateOfCommencementOfEMI?.length == 0 ? this.state.msgNewData.agreementLoan.dateOfCommencementOfEMI : moment(this.state.msgNewData.agreementLoan.dateOfCommencementOfEMI).format('MM-DD-YYYY')} ></CompareItem>
                    <CompareItem id="18" mask={false} title={'Mode of Repayment'} lmsLoan={this.state.msgNewData.lmsLoan.modeOfRepayment} agreementLoan={this.state.msgNewData.agreementLoan.modeOfRepayment}></CompareItem>
                    <CompareItem id="19" mask={true} title={'Borrower Name'} lmsLoan={this.state.msgNewData.lmsCustomer.borrowerName} agreementLoan={this.state.msgNewData.agreementCustomer.borrowerName}></CompareItem>
                    <CompareItem id="20" mask={true} title={'Borrower S/D/W of'} lmsLoan={this.state.msgNewData.lmsCustomer.borrowerSOrDOrWOf} agreementLoan={this.state.msgNewData.agreementCustomer.borrowerSOrDOrWOf}></CompareItem>
                    <CompareItem id="21" mask={false} title={'Age'} lmsLoan={this.state.msgNewData.lmsCustomer.age} agreementLoan={this.state.msgNewData.agreementCustomer.age}></CompareItem>
                    <CompareItem id="22" mask={true} title={'Coborrower/Guaranters Names'} lmsLoan={this.state.msgNewData.lmsCustomer.coBorrowerOrGuarantorNames} agreementLoan={this.state.msgNewData.agreementCustomer.coBorrowerOrGuarantorNames}></CompareItem>
                    <CompareItem id="23" mask={true} title={'Coborrower S/D/W of'} lmsLoan={this.state.msgNewData.lmsCustomer.coBorrowerSOrDOrWOf} agreementLoan={this.state.msgNewData.agreementCustomer.coBorrowerSOrDOrWOf}></CompareItem>
                    <CompareItem id="24" mask={false} title={'Age of Coborrower'} lmsLoan={this.state.msgNewData.lmsCustomer.ageOfCoBorrower} agreementLoan={this.state.msgNewData.agreementCustomer.ageOfCoBorrower}></CompareItem>
                    <CompareItem id="25" mask={false} title={'Class of Vehicle'} lmsLoan={this.state.msgNewData.lmsAsset.classOfVehicle} agreementLoan={this.state.msgNewData.agreementAsset.classOfVehicle}></CompareItem>
                    <CompareItem id="26" mask={false} title={'Makers Name'} lmsLoan={this.state.msgNewData.lmsAsset.makersName} agreementLoan={this.state.msgNewData.agreementAsset.makersName}></CompareItem>
                    <CompareItem id="27" mask={false} title={'Type of Body'} lmsLoan={this.state.msgNewData.lmsAsset.typeOfBody} agreementLoan={this.state.msgNewData.agreementAsset.typeOfBody}></CompareItem>
                    <CompareItem id="28" mask={false} title={'Horsepower'} lmsLoan={this.state.msgNewData.lmsAsset.Horsepower} agreementLoan={this.state.msgNewData.agreementAsset.Horsepower}></CompareItem>
                    <CompareItem id="29" mask={false} title={'Chassis Number'} lmsLoan={this.state.msgNewData.lmsAsset.chassisNumber} agreementLoan={this.state.msgNewData.agreementAsset.chassisNumber}></CompareItem>
                    <CompareItem id="30" mask={false} title={'Number of Cylinders'} lmsLoan={this.state.msgNewData.lmsAsset.numberOfCylinders} agreementLoan={this.state.msgNewData.agreementAsset.numberOfCylinders}></CompareItem>
                    <CompareItem id="31" mask={false} title={'Year Of Manufacture'} lmsLoan={this.state.msgNewData.lmsAsset.yearOfManufacturer} agreementLoan={this.state.msgNewData.agreementAsset.yearOfManufacturer}></CompareItem>
                    <CompareItem id="32" mask={false} title={'Engine Number'} lmsLoan={this.state.msgNewData.lmsAsset.engineNumber} agreementLoan={this.state.msgNewData.agreementAsset.engineNumber}></CompareItem>
                    <CompareItem id="33" mask={false} title={'colour'} lmsLoan={this.state.msgNewData.lmsAsset.colour} agreementLoan={this.state.msgNewData.agreementAsset.colour}></CompareItem>
                  </tbody>
                </table>

              </Form> :
              <CircularProgress size="30px" color="secondary" />}

          </div>
          <div className="viewmorecolumnpdf viewmorecolumn">
            {this.state.msgNewData !== null ?
              <React.Fragment>
                {JSON.stringify(this.state.loanpath[0]) !== "null" ?

                  <Iframe url={process.env.react_app_base_url +"root_folder"+this.state.loandocpath}
                    width="450px"
                    height="450px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative" />

                  :
                  <React.Fragment>

                    <div id="imageshow">
                      No documents available.
  </div>
                  </React.Fragment>
                }
              </React.Fragment>
              :
              <CircularProgress size="30px" color="secondary" />}

          </div>
        </div>





      </React.Fragment>
    );
  }
}


export default withSnackbar(CompareData);
