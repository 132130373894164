// export default {
//   "desc": "Completed!!!",
//   "res": {
//     "message": "Successful!",
//     "data": [
//       {
//         "lmsloan": {
//           "APP_ID": "06A50191-D0B4-4AD2-9258-0AD769F37551",
//           "CONTRACT": "UOWN271558",
//           "PRI NAME": "PROTHRO-JAMYRIO",
//           "E_Signature": "Yes"
//         },
//         "agreementloan": {
//           "APP_ID": "06A50191-D0B4-4AD2-9258-0AD769F37551",
//           "CONTRACT": "UOWN271558",
//           "PRI NAME": "JAMYRIO PROTHRO",
//           "E_Signature": "No"
//         },
//         "matched": 0,
//         "attributewise_matched": {
//           "APP_ID": 1,
//           "CONTRACT": 1,
//           "PRI NAME": 1,
//           "E_Signature": 0
//         },
//         "loandocpath": "data/input/90464b76-3f90-461c-91b6-f69dc234fe2d/UOWN/sample_file/06A50191-D0B4-4AD2-9258-0AD769F37551.pdf",
//         "date": "",
//       },
//     ],
//     "coordinates_details": [
//       {
//         "label": "APP_ID",
//         "value": "06A50191",
//         "varname": "dealid",
//         "coordinates": { "top": 0, "left": 0, "width": 70, "height": 10 },
//         "page_number": 1
//       },
//       {
//         "label": "PRI NAME",
//         "value": "JAMYRIO PROTHRO",
//         "varname": "pri_name",
//         "page_number": 1,
//         "coordinates": { "left": 353, "top": 143, "width": 92, "height": 10 },

//       },
//       {
//         "label": "CONTRACT",
//         "value": "UOWN271558",
//         "varname": "agreementid",
//         "coordinates": { "top": 149, "left": 108, "width": 62, "height": 10 },
//         "page_number": 1
//       },
//       {
//         "label": "E_Signature",
//         "value": "No",
//         "varname": "e_sign",
//         "coordinates": { "left": 0, "top": 0, "width": 0, "height": 0 },
//         "page_number": 0
//       },
//     ],
//   }
// }
import sample from "./images/singlesample.pdf"
import multiple from "./images/multiplesample.pdf"
import credibly from "./images/credibly.pdf"
// data/input/d61b88db-bf47-499a-8f92-e61da23b5389/UOWN/nonread2/7DDAB3B8-A9EA-4B4D-96A0-0321FA67FD54.pdf
// import multiple from "./images/multiplesample1.pdf"
// 'https://arxiv.org/pdf/1604.02480.pdf'
// "data/input/2634d469-a037-4ad4-974e-d424f780d7f7/Highrise/HighRise_Test/0ae22b11-af5f-4f80-963f-f9a7bdf13f6b.pdf"
export const testHighlights =
{
  "desc": "Completed!!!",
  "res": {
    "message": "Successful!",
    "data": [
      {
        "matched": 1,
        "Deal Id": "640394",
        "lmsloan": {
          "Deal Id": "640394",
          "Agreement/Account #": "34C6E9B7",
          "Merchant Legal Name": "Lorain County Homebuyers LLC",
          "Principal Loan Amount/Purchase Price": "30000"
        },
        "agreementloan": {
          "Deal Id": "640394",
          "Agreement/Account #": "34C6E9B7",
          "Merchant Legal Name": "Lorain County Homebuyers LLC",
          "Principal Loan Amount/Purchase Price": "$30,000.00 "
        },
        "attributewise_matched": {
          "Deal Id": 1,
          "Merchant Legal Name": 1,
          "Principal Loan Amount/Purchase Price": 1,
          "Agreement/Account #": 1
        },
        [credibly]: [
          {
            "content": {
              "lmsloan": {
                "Deal Id": "640394"
              },
              "agreementloan": {
                "Deal Id": "640394"
              }
            },
            "position": {
              "rects": [
                {
                  "x1": 0,
                  "y1": 0,
                  "x2": 0,
                  "y2": 0,
                  "width": 0,
                  "height": 0
                }
              ],
              "boundingRect": {
                "x1": 0,
                "y1": 0,
                "x2": 0,
                "y2": 0,
                "width": 0,
                "height": 0
              },
              "pageNumber": 0
            },
            "comment": {
              "text": "",
              "emoji": ""
            },
            "id": 1
          },
          {
            "content": {
              "lmsloan": {
                "Agreement/Account #": "34C6E9B7"
              },
              "agreementloan": {
                "Agreement/Account #": "34C6E9B7"
              }
            },
            "position": {
              "rects": [
                {
                  "x1": 115,
                  "y1": 736,
                  "x2": 186.5,
                  "y2": 746,
                  "width": 612,
                  "height": 792
                }
              ],
              "boundingRect": {
                "x1": 115,
                "y1": 736,
                "x2": 186.5,
                "y2": 746,
                "width": 612,
                "height": 792
              },
              "pageNumber": 1
            },
            "comment": {
              "text": "",
              "emoji": ""
            },
            "id": 2
          },
          {
            "content": {
              "lmsloan": {
                "Merchant Legal Name": "Lorain County Homebuyers LLC"
              },
              "agreementloan": {
                "Merchant Legal Name": "Lorain County Homebuyers LLC"
              }
            },
            "position": {
              "rects": [
                {
                  "x1": 245,
                  "y1": 196,
                  "x2": 462.5,
                  "y2": 207,
                  "width": 612,
                  "height": 792
                }
              ],
              "boundingRect": {
                "x1": 245,
                "y1": 196,
                "x2": 462.5,
                "y2": 207,
                "width": 612,
                "height": 792
              },
              "pageNumber": 4
            },
            "comment": {
              "text": "",
              "emoji": ""
            },
            "id": 3
          },
          {
            "content": {
              "lmsloan": {
                "Principal Loan Amount/Purchase Price": "30000"
              },
              "agreementloan": {
                "Principal Loan Amount/Purchase Price": "$30,000.00 "
              }
            },
            "position": {
              "rects": [
                {
                  "x1": 404,
                  "y1": 214,
                  "x2": 483,
                  "y2": 225,
                  "width": 612,
                  "height": 792
                }
              ],
              "boundingRect": {
                "x1": 404,
                "y1": 214,
                "x2": 483,
                "y2": 225,
                "width": 612,
                "height": 792
              },
              "pageNumber": 2
            },
            "comment": {
              "text": "",
              "emoji": ""
            },
            "id": 4
          }
        ],
        "date": "",
        "loandocpath": "data/input/3b7e97fc-3a6d-43f3-823d-994194cfa609/Credibly/test_file/640394/Final Signed WCL Contract - Lorain County Homebuyers LLC.pdf"
      }
    ]
  }
}
export const OldResponse = {
  "desc": "Completed!!!",
  "res": {
    "message": "Successful!",
    "data": [
      {
        "lmsloan": {
          "Deal Id": "640394",
          "Agreement/Account #": "34C6E9B7",
          "Merchant Legal Name": "Lorain County Homebuyers LLC",
          "Principal Loan Amount/Purchase Price": "30000"
        },
        "agreementloan": {
          "Deal Id": "640394",
          "Agreement/Account #": "34C6E9B7",
          "Merchant Legal Name": "Lorain County Homebuyers LLC",
          "Principal Loan Amount/Purchase Price": "$30,000.00 "
        },
        "matched": 1,
        "attributewise_matched": {
          "Deal Id": 1,
          "Merchant Legal Name": 1,
          "Principal Loan Amount/Purchase Price": 1,
          "Agreement/Account #": 1
        },
        "loandocpath": "data/input/3318a6bc-7c40-49fb-a99c-c759feeddca8/Credibly/05.13.2022 - Credibly Warehouse/675232/Esign Signed Document 2022-05-12.pdf",
        "date": "05-13-2022"
      }
    ]
  }
};
export const attributeDetails = {
  status: 200,
  data: [
    {
      "_id": "62b1caeee118f80009922e8c",
      "attributeId": "HOOvfJugHJ",
      "attributeName": "Deal Id",
      "attributeStandardName": "amount_financed",
      "attributeCategory": "Loan",
      "attributeDisplay": true,
      "attributePoolName": "Stride"
    },
    {
      "_id": "62b1cb11e118f80009922e8d",
      "attributeId": "AXMO1F3uZ9",
      "attributeName": "Merchant Legal Name",
      "attributeStandardName": "program_name",
      "attributeCategory": "Loan",
      "attributeDisplay": true,
      "attributePoolName": "Stride"
    },
    {
      "_id": "62b1cbd9e118f80009922e8e",
      "attributeId": "fxfhNEzhoD",
      "attributeName": "Principal Loan Amount/Purchase Price",
      "attributeStandardName": "bootcamp_name",
      "attributeCategory": "Loan",
      "attributeDisplay": true,
      "attributePoolName": "Stride"
    },
    {
      "_id": "62b1cc42e118f80009922e91",
      "attributeId": "XjZlWLqgNe",
      "attributeName": "Agreement/Account #",
      "attributeStandardName": "dealid",
      "attributeCategory": "Loan",
      "attributeDisplay": true,
      "attributePoolName": "Stride"
    },

  ]
}