/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/loader';
import Snackbar from '../../components/snackbar';
import axios from "axios";
import { Form } from 'react-bootstrap';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';

class PreProcessingAssetDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            getLoansLoader: false,

        };

    }

    async componentDidMount() {
        var loanId = this.props.dataFromParent;
        this.getAssetDetails(loanId)
    }

   async getAssetDetails(loanId){
    
        try {
            const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/asset?loanId=' + loanId);
            const resp = res.data;
            if (res.status === 200) {
                if (resp.token == '-1') {
                    const message = "Invalid Token!";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000
                    });
                    this.props.history.push({
                        pathname: '/'
                    })
                } else {
                    console.log("poolloans", resp);
                    this.setState({ Collateraldetails: resp, loadingmodal: true });
                    const message = "Successfully fetched the data";
                    this.props.enqueueSnackbar(message, {
                        variant: 'info',
                        autoHideDuration: 2000,
                    });
                }

            } else if (res.status === 204) {
                const message = "No content available";
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            } else {
                const message = "something went wrong, please try again";
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            }



        } catch (error) {

            if (error.response.status == 400) {

                console.log("getLoans API error", error);
                console.log("error.response", error.response.data.message);

                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            } else {

                const message = "Went something wrong, please try again";
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });


            }

        }
    }
    render() {


        return (

            <React.Fragment>


                <div>
                    {this.state.Collateraldetails != null && this.state.loadingmodal === true ?
                        <Form id="labelform">
                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Class of vehicle</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].classOfVehicle} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Makers name</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].makersName} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Type of body</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].typeOfBody} />
                                    </Form.Group>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Horsepower</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].horsepower} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Chassis number</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].chassisNumber} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Number of cylinders</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].numberOfCylinders} />
                                    </Form.Group>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col-4">
                                    <Form.Group controlId="formGroupName">
                                        <Form.Label>Year of manufacturer</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].yearOfManufacturer} />
                                    </Form.Group>
                                </div>
                                <div className="col-4">
                                    <Form.Group controlId="formImage">
                                        <Form.Label>Engine number</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].engineNumber} />
                                    </Form.Group>
                                </div>

                                <div className="col-4">
                                    <Form.Group controlId="formGroupDescription">
                                        <Form.Label>Colour</Form.Label>
                                        <Form.Control required disabled placeholder="" value={this.state.Collateraldetails[0].colour} />
                                    </Form.Group>
                                </div>
                            </div>


                        </Form>
                        : <Loader msg={"Please wait, Loading Asset details"} />}
                </div>

            </React.Fragment>
        );
    }
}

export default withSnackbar(PreProcessingAssetDetails);