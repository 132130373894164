/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import TextField from '@material-ui/core/TextField';
// import { Dropdown } from 'react-bootstrap';
import { Form as form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from "axios";
import Modal from 'react-responsive-modal';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withSnackbar } from 'notistack';
import FormLoader from '../../../components/loader/formLoader';

// import { Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {

 
  NavLink
} from "react-router-dom";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import DocumentDetailsTable from "../../issuerSPV/issuerspv/documentDetails";
// import $ from 'jquery';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import DropdownToggle from 'react-bootstrap/DropdownToggle';
import ReactModal from 'react-modal';


import {widgets,CustomFieldTemplate,customStyles,customStylesauto} from '../../../components/customscripts/customscript'

const Form = withTheme(MuiTheme);

const docattributes = require('../../issuerSPV/issuerspv/docattributes.json');





class PoolTransferServicer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      loading: false,
      loadingmodal: false,
      loader: false,
      getLoansLoader: false,
      open1: false,
      open2: false,
      open3: false,
      formData:{},
      disabled: true,
      Redirect: false,
      // schema: schema,
      docattributes: docattributes,
      assignbuttonbtn: sessionStorage.getItem("assignbutton"),
      assignbutton: true,
      
      servicerassignment:false,
      searchText: '',
      rowsSelected:null
    };
    this.handleclick = this.handleclick.bind(this);
    this.onOpenModal2 = this.onOpenModal2.bind(this);
    this.handleDoc = this.handleDoc.bind(this);
    // this.onRowsSelect = this.onRowsSelect.bind(this);
  }
  state = {

    open1: false,
    open2: false,
    open3: false,
    Redirect: false,
    poolId: null,
    hashValue: null,
    fileName: null,
    filePath: null
  }




  onOpenModal1 = () => {
    console.log("inside modal");
    this.setState({ open1: true });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false });
  };

  async onOpenModal2(value) {

    console.log("inside modal");
    this.setState({ open2: true, poolId: value, loadingmodal:true });
  };
  onCloseModal2 = () => {
    this.setState({ open2: false,loadingmodal:false, disabled: true});
  };

  async onOpenModal3(value) {

    console.log("inside modal");
    this.setState({ open3: true, poolId: value });


  };

  onCloseModal3 = () => {
    this.setState({ open3: false });
  };

  handleDoc = (e) => {

    this.setState({ file: e.target.files[0] });
    // console.log(Array.from(e.target.files))


  }

  async SaveDoc() {
    this.setState({ msg:false,loadingmodal:false});

    var formData = new FormData();
    formData.append('filename', this.state.file);


    axios(process.env.react_app_base_url + 'api/V1/pool/uploadFile', {
      method: 'POST',
      data: formData,
      // headers: {

      //   'Content-Type': 'multipart/form-data'
      // }
    }).then(res => {
      console.log(res.data);
      var hashValue = res.data[0];
      console.log(hashValue)
    
      if (res.status === 200) {
        const message = "Uploaded Successfully";
        this.props.enqueueSnackbar(message, { 
          variant: 'info',
          autoHideDuration: 2000
      });
        this.setState({ hashValue: res.data[0], fileName: res.data[2], filePath: res.data[1], disabled: false, msg:true,loadingmodal: true })

      }
      else {
        alert("Failed");
      }


    }).catch(error => { console.log(error); 
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }})

  }




  

  handleChange2 =(e) => {
    this.setState({msg3:false,msg4:false});
    console.log(e.target.value)
this.setState({
  approvalStatus:e.target.value
})
  }


  handleclick =(evt)=> {
    // evt.preventDefault();
    let user_id=localStorage.getItem('user_id');
  
    const peers = JSON.parse(localStorage.getItem("peers"));
    const token = localStorage.getItem('token');
 var approvalStatus=this.state.approvalStatus;

    var input = sessionStorage.getItem("PoolID")

    if(approvalStatus==="Approved"){
      approvalStatus="Approved by Servicer";
    }
    else if(approvalStatus==="Pending"){
      approvalStatus="Pending approval from Servicer";
    }
    else{
      approvalStatus="Rejected by Servicer"
    }
    
var x=
{
   "input":input,
  
   "approvalStatus":approvalStatus,
   "userID": user_id,

   "peers": peers,
   "token": token
   
}
var result = JSON.stringify(x);
console.log(result);
axios(process.env.react_app_base_url + 'api/V1/trustee/approveservicer', {
  method: 'POST',
  data: result,
  dataType: 'json',
  processData: true,
  headers: {
    // Accept:"application/json",
    "Content-Type": "application/json"

  }

}).then(res => {
  console.log(res.data);
  if (res.status === 204) {

    const message = "Data not available";
    this.props.enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 2000
  });
}
else if(res.status== 200){
  if(res.data.token == '-1'){

    const message = "Invalid Token!";
    this.props.enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 2000
  });
  this.props.history.push({
    pathname: '/'
  })

  } else {

  if (res.data.isSuccess === true) {
    const message = "Pool is approved!";
    this.props.enqueueSnackbar(message, { 
      variant: 'info',
      autoHideDuration: 2000
  });

    this.setState({
      // msg4:true,
      servicerassignment:true
    })
   
  }
  else {
 
      const message = "Pool is not approved!";
      this.props.enqueueSnackbar(message, { 
        variant: 'info',
        autoHideDuration: 2000
    });
   this.setState({
    //  msg3:true,
      servicerassignment:false
   })

  }
  // this.Assigneetable()
  }
}
}).catch(error => { console.log(error); 
  if (error.response.status == 400) {

    console.log("getLoans API error", error);
    console.log("error.response", error.response.data.message);

    const message = error.response.data.message;
    this.props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 3000,
    });

} else {

    const message = "something went wrong, please try again";
    this.props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 3000,
    });


}
})



  }

   
  onSubmitofDocAttributes = (value) => {
    this.setState({ getLoansLoader: true})

    this.setState({msg2:false})
    console.log('onSubmit:', value.formData)
    let data = value.formData
    console.log('onSubmit data:', data)
    var docType = data.docType;
    var docTag = data.docTag;
    var docownerId = data.docownerId;
    var docownerTag = data.docownerTag;
    var poolId = this.state.poolId;
    var hashValue = this.state.hashValue;
    var fileName = this.state.fileName;
    var filePath = this.state.filePath;
    const peers = JSON.parse(localStorage.getItem("peers"));
    const token = localStorage.getItem('token');
    // alert(poolId);
    console.log(docTag);
    var input = {
      "docType": docType,
      "docTag": docTag,
      "ownerId": docownerId,
      "ownerTag": docownerTag,
      "poolId": poolId,
      "hashValue": hashValue,
      "fileName": fileName,
      "docPath": filePath,
      "peers":peers,
      "token":token
    }

    // console.log(data);
    var result = JSON.stringify(input);
    console.log(result);
    axios(process.env.react_app_base_url + 'api/V1/pool/savedoc', {
      method: 'POST',
      data: result,
      dataType: 'json',
      processData: true,
      headers: {
        // Accept:"application/json",
        "Content-Type": "application/json"

      }

    }).then(res => {
      console.log(res.data);

     
      if (res.status === 204) {
        this.setState({ getLoansLoader: true})

        const message = "Data not available";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
    }
    else if(res.status== 200){
      if(res.data.token == '-1'){
        this.setState({ getLoansLoader: true})

        const message = "Invalid Token!";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
      this.props.history.push({
        pathname: '/'
      })
  
      } else if(res.data.isSuccess== true) {
  
        this.setState({ getLoansLoader: true})

        const message = "Document attributes saved successfully!";
        this.props.enqueueSnackbar(message, { 
          variant: 'info',
          autoHideDuration: 2000
      });
    }
      else {
        alert("Failed");
      }
    }

    }).catch(error => { console.log(error); 
      if (error.response.status == 400) {
        this.setState({ getLoansLoader: true,open2:false})

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {
      this.setState({ getLoansLoader: true})

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }})
  }


  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  
  
  };
  
async ServicerAssigneetable(user_id,peer,token){

  this.setState({ getLoansLoader: true, assignbutton: true ,servicerassignment:false});
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/trustee/servicerassige?userID=' + user_id + "&peer=" + peer + "&token=" + token);
      const resp = res.data;
      if (res.status === 204) {

        const message = "Data not available";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
    }
    else if(res.status== 200){
      if(resp.token == '-1'){
  
        const message = "Invalid Token!";
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 2000
      });
      this.props.history.push({
        pathname: '/'
      })
  
      } else {
  
        
        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, { 
          variant: 'info',
          autoHideDuration: 2000
      });
        console.log("poolloans", resp);

        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
      } 
    }
    else {
      alert("Failed");
    }
    } catch (error) {
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }
    }
  }


  async componentDidMount() {
  
    const formData={
      "docownerId":localStorage.getItem('user_id'),
      "docownerTag":localStorage.getItem('OrgName')
    }
  console.log("11111111111111111"+formData)
    this.setState({
      formData:formData
    })
    let user_id=localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
  
    const peer = localStorage.getItem('peer');
    sessionStorage.setItem("assignbutton", true)
    this.ServicerAssigneetable(user_id,peer,token)
    


  };


  async selectedpoolid(selected) {
    const arr = []


    for (var i = 0; i < selected.length; i++) {
      // console.log('loanID = i', i)
      var j = selected[i];
      let PoolID = this.state.tableData[j].PoolID;
      arr.push(PoolID);
    }
    console.log(arr);
    sessionStorage.setItem("PoolID", arr);
    // this.setState({
    //   PoolID:arr
    // })


  }


  render() {
    const { open1 } = this.state;
    const { open2 } = this.state;
    const { open3 } = this.state;

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: true,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
    },
      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading:true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, loading data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'PoolID',
        label: 'Pool ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'ServicerID',
        label: 'Servicer ID',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'ApprovedStatus',
        label: 'Approval Status',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'ApprovedDate',
        label: 'Approval Date',
        options: {
          filter: true,
          sort: false,
        },
      },
    

      {
        name: "PoolID",
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (

              <React.Fragment>
                {/*  */}
                <PopupState variant="popover" popupId="demo-popup-menu" >
                  {popupState => (
                    <React.Fragment>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        {...bindTrigger(popupState)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu {...bindMenu(popupState)}>
                        {/* <MenuItem onClick={popupState.close}> {value}</MenuItem> */}
                        <NavLink to={'/viewLoans/' + value} id="viewloanlink"><MenuItem><img alt=""  src={require('../../../images/ViewLoans.png')} style={{ paddingRight: "10px" }} ></img>View Loans</MenuItem></NavLink>
                        <MenuItem onClick={() => {this.onOpenModal2(value); popupState.close();}}><img alt="" src={require('../../../images/Upload.png')} style={{ marginRight: "7px" }} ></img>Upload Documents</MenuItem>
                        <MenuItem onClick={() => {this.onOpenModal3(value); popupState.close();}}><img alt=""  src={require('../../../images/ViewDocuments.png')} style={{ marginRight: "7px" }} ></img>View Documents</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </React.Fragment>



            );

          },

        }
      },
    ];


    // const btn = sessionStorage.getItem("assignbutton");
    const btn = JSON.stringify(this.state.assignbuttonbtn)

    return (

      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Pool Transfer Servicer'}></Header></div>
            <div className="tableSearch">

              <div className="row">
                <div className="justify-content-left " id="assignpoolbutton">

                  <React.Fragment>

                    <PopupState variant="popover" popupId="demo-popup-menu" >
                      {popupState => (
                        <React.Fragment>
                            { this.state.rowsSelected !== null && this.state.rowsSelected.length !== 0 ? 
                                          <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                                          {...bindTrigger(popupState)} color="primary" type="submit" >Approval <img alt=""  src={require('../../../images/inverted-icon2.png')} style={{ marginLeft: "10px", width: "14px", height: "14px" }} ></img></Button> : 
                            <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                              {...bindTrigger(popupState)} disabled color="primary" type="submit" >Approval </Button>}
                          <Menu {...bindMenu(popupState)}>
                            <RadioGroup style={{ margin: "10px" }} >
                              <FormControlLabel value="Approved"  name="Approved"  control={<Radio onChange={this.handleChange2}/> } label="Approved" />
                              <FormControlLabel value="Rejected" name="Rejected" control={<Radio onChange={this.handleChange2}/>} label="Rejected" />
                              <FormControlLabel value="Pending" name="Pending" control={<Radio onChange={this.handleChange2}/>} label="Pending" />
                              <hr />
                              <Button variant="contained" id="submitapproval"
                                color="primary" type="submit" onClick={() => {this.handleclick(); popupState.close();}}>Submit</Button>
                            </RadioGroup>
                          

                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </React.Fragment>



                  {/* {this.state.msg3 === true ? <React.Fragment><Snackbar msg={"Pool is not approved ,document cannot be uploaded"} open="true" /> </React.Fragment> : ' '} */}
                            {/* {this.state.msg4 === true ? <React.Fragment><Snackbar msg={"Pool is approved "} open="true" /> </React.Fragment> : ' '} */}

{/* 
                  {this.state.servicerassignment === false ? <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                    onClick={this.onOpenModal1.bind(this)} disabled color="primary" type="submit" >Servicer Assignment</Button> : <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"

                      onClick={this.onOpenModal1.bind(this)} color="primary" type="submit" >Servicer Assignment</Button>} */}
                </div>

                <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}

                </div>

                <div className="col-md-6 col-sm-12">

                </div>

              </div>
            </div>
            <div className="page-content">

       
                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

        

              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
            </div>
          </div>
        </div>
        <div >
         



        
        <ReactModal
              isOpen={this.state.open2}
              contentLabel="Minimal Modal Example"
              style={customStylesauto}
              onRequestClose={this.onCloseModal2}

            >
              <React.Fragment>
                <div className="modalPopup">
                  <h2>Upload Documents</h2>
                  <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal2}> <CloseIcon></CloseIcon> </Button>
        
        
            {/* <div>             */}
        
            <div id="browse1">
       
              <form.Group controlId="formGrid">

                <form.Control type="file" onChange={this.handleDoc} placeholder="" />
              </form.Group>
              <Button variant="contained" id="docsave" onClick={this.SaveDoc.bind(this)}

                color="primary" type="submit">Save</Button>
                
            </div>
         
            {/* {this.state.msg === true ? <React.Fragment><Snackbar msg={"Document saved Successfully"} open="true" /> </React.Fragment> : ' '} */}

            {this.state.disabled === false  ?
              <div>

                {/* <p>{this.state.formdata}</p> */}
                <Form
                  schema={this.state.docattributes}
                  formData={this.state.formData}
                  onSubmit={this.onSubmitofDocAttributes}
                  onChange={this.onFormChanged}
                  widgets={widgets}
                  FieldTemplate={CustomFieldTemplate}
                >

                  <div className="modalsubmit">
                    <div className="row justify-content-center">



                      <Button variant="contained" color="primary" type="submit" id="docatrrisave" >Save Attributes</Button>
                    </div>
                  </div>
                  {this.state.getLoansLoader === false ? '' : <FormLoader  />}

                </Form>


              </div>
               : ''}
                           {/* {this.state.msg2 === true ? <React.Fragment><Snackbar msg={"Document attributes saved Successfully"} open="true" /> </React.Fragment> : ' '} */}

               {/* : <Loader msg={"Please wait, loading data"} /> */}
              {/* </div> */}
         

              </div>
              </React.Fragment>
            </ReactModal>


            <ReactModal
              isOpen={this.state.open3}
              contentLabel="Minimal Modal Example"
              style={customStyles}
              onRequestClose={this.onCloseModal3}

            >
              <React.Fragment>
                <div className="modalPopup">
                  <h2>Document Details</h2>
                  <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal3}> <CloseIcon></CloseIcon> </Button>




            <div>
              <DocumentDetailsTable dataFromParent={this.state.poolId} />


            </div>

            </div>
              </React.Fragment>
            </ReactModal>
        </div>

      </React.Fragment>
    );
  }
}

export default withSnackbar(PoolTransferServicer);