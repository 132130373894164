import React, { Component } from 'react';
import './investorReport.scss'
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar';
import Button from '@material-ui/core/Button';
import BankAccounts from '../../../../images/BankAccounts.png';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
// import MenuItem from '@material-ui/core/MenuItem';

// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import { widgets, CustomFieldTemplate, customStyles } from '../../../../components/customscripts/customscript'

const Form = withTheme(MuiTheme);
const schema = require('./schema.json');




export default class investorReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: schema
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="page" id="" >
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header stepItem1"><Header pageTitle={'Investor Report'} ></Header></div>
            <div className="reportcontent">
              <div className="fourColunm">
                <Form
                  schema={this.state.schema}

                  onSubmit={this.onSubmit}
                  onChange={this.onFormChanged}
                  widgets={widgets}
                  FieldTemplate={CustomFieldTemplate}
                >
                  <div className="row justify-content-end">
                    <Button variant="contained" color="primary" type="submit" id="viewreport"  >


                      view report  </Button>
                  </div>
                </Form>
              </div>
            </div>

            <div className="InvestorReportBox">
              <img src={BankAccounts} alt={''} />
              <h1>You have to enter values above to view reports</h1>
              <p>In order to view reports, you have to enter deal name along with month and year and click view report button to view detailed report</p>
              {/* <Button variant="contained" color="primary" type="submit"> Add Bank Account  </Button> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
