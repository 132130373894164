/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar';
import TextField from '@material-ui/core/TextField';
// import { Dropdown } from 'react-bootstrap';
// import { Form as form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import Loader from '../../../../components/loader';
import Snackbar from '../../../../components/snackbar';
import axios from "axios";
import { withSnackbar } from 'notistack';
import { NavLink } from "react-router-dom";

import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import CloseIcon from '@material-ui/icons/Close';
import {widgets,CustomFieldTemplate,customStyles} from '../../../../components/customscripts/customscript'

class trancheList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waterfall: null,
      loadingmodal: false,
      selected: [],
      loading: false,
      loader: false,
      getLoansLoader: false,
      open1: false,
      open2: false,
      open3: false,
      disabled: true,
      Redirect: false,


      assignbuttonbtn: sessionStorage.getItem("assignbutton"),
      assignbutton: true,
      searchText: ''
    };

  }




  onOpenModal1 = () => {
    console.log("inside modal");
    this.setState({ open1: true });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false });
  };


  onchange = e => {

    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  
  
  };


  async componentDidMount() {
    
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    this.getPools(peer, token);
   
   } // sessionStorage.setItem("assignbutton", true)



    async getPools(peer, token) {
    this.setState({ getLoansLoader: true, assignbutton: true });
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/pool/pools?peer=' + peer + '&token=' + token);
      const resp = res.data;
   
      if (res.status === 200) {

        if (resp.token == '-1') {

          const message = "Invalid Token!";
          this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000
          });
          this.props.history.push({
            pathname: '/'
          })

        } else {



        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
   
        const message = "Successfully fetched the data";
        this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 2000,
        });

      }

    } else {
      const message = "something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });
    }
  } catch (error) {
console.log("error"+error)

    if (error.response.status == 400) {

      console.log("getLoans API error", error);
      console.log("error.response", error.response.data.message);

      const message = error.response.data.message;
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });

  } else {

      const message = "something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 3000,
      });


  }

  }


  }


  async selectedpoolid(selected) {
    const arr = []


    for (var i = 0; i < selected.length; i++) {
      // console.log('loanID = i', i)
      var j = selected[i];
      let PoolID = this.state.tableData[j].PoolID;
      arr.push(PoolID);
    }

    console.log(arr);
    sessionStorage.setItem("PoolID", arr);
    // this.setState({
    //   PoolID:arr
    // })


  }


  render() {

    // const {waterfall}=this.state;

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("selected" + selected);
        if (allRows.length < 1) {

          var assignbutton = true

          sessionStorage.setItem("assignbutton", true)
          // this.setState({
          //   assignbutton: assignbutton
          // })
        }
        else {
          sessionStorage.setItem("assignbutton", false)
          // this.setState({
          //   assignbutton: false
          // })
        }

        this.selectedpoolid(selected);


      },

      searchText: this.state.searchText,
      searchPlaceholder: 'Your Custom Search Placeholder',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },


      loading:true,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, loading data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'PoolID',
        label: 'Pool ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'Poolowner',
        label: 'Pool Owner Name',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'PoolName',
        label: 'Pool Name',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'PoolDesc',
        label: 'Pool Description',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'PoolStartedDate',
        label: 'Pool Start Date',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'PoolStatus',
        label: 'Pool Status',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'NoOfAssets',
        label: 'No. of Assets',
        options: {
          filter: true,
          sort: false,
        },
      },



      {
        name: "PoolID",
        label: 'View',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (

              <React.Fragment>
                <NavLink to={'/investorBuyer/tranche-list-buy/' + value} id="viewloanlink"><Button variant="contained" id="viewtranche" type="submit" > View Tranche </Button></NavLink>


              </React.Fragment>





            );

          },

        }
      },
    ];


    // const btn = sessionStorage.getItem("assignbutton");
    const btn = JSON.stringify(this.state.assignbuttonbtn)

    return (

      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header"><Header pageTitle={'Pool List'}></Header></div>
            <div className="tableSearch">

              <div className="row">
               


              <div className="col-md-6 col-sm-12" id="searchBox">
                  <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                  {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}

                </div>
                <div className="col-md-6 col-sm-12">

                </div>

              </div>
            </div>
            <div className="page-content">

                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

                

              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
            </div>
          </div>
        </div>


      </React.Fragment>
    );
  }
}
export default withSnackbar(trancheList);
