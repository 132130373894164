import React, {Component} from 'react';
import './documentAttribute.scss'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as documentAttributeActions from "../../store/documentAttribute/actions";
export default class documentAttribute extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return <div className="component-document-attribute">Hello! component documentAttribute</div>;
    }
  }
// export default connect(
//     ({ documentAttribute }) => ({ ...documentAttribute }),
//     dispatch => bindActionCreators({ ...documentAttributeActions }, dispatch)
//   )( documentAttribute );