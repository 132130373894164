/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
// import { connect } from 'react-redux';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import Button from '@material-ui/core/Button';
// import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
// import axios from 'axios';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';

import * as moment from 'moment';
import {widgets, CustomFieldTemplate} from '../../../components/customscripts/customscript'


const Form = withTheme(MuiTheme);
const schema = require('./schema.json');
const uiSchema = require('./ui-schema.json');
const formData = require('./formData.json');


class eligiblePortfolioImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: schema,
      uiSchema: uiSchema,
      formData: JSON.parse(localStorage.getItem('eligiblePortfolioImportParameter')) || null,
      loading: false,
      getLoansLoader: false,
      open: false
    };
  }


  onSubmit = (value) => {
    console.log('onSubmit:', value.formData)
    let data = value.formData;

    console.log('onSubmit data:', data.cutoffDate)
    let newDateSubmit = moment(data.cutoffDate).format('DD-MM-YYYY')
    console.log("newDateSubmit", newDateSubmit)
    data.cutoffDate = newDateSubmit
    localStorage.setItem('eligiblePortfolioImportParameter', JSON.stringify(data));
    console.log("data", data)
    this.props.history.push({
      pathname: '/portfolio-setup-wizard/due-diligence',
      search: '',
      state: { eligiblePortfolioImportParameter: data }
    })
  };

  onCancel = () => {
    const { data } = this.props;
    this.setState({
      ...data,
    });
  }

  async componentDidMount() {

    
    
  };

  render() {
    const { uiSchema, formData } = this.state;
    return (
      <React.Fragment>
        <div className="page" id="eligible-portfolio-import">
          
          <div className="content">
            <div className="header stepItem1"><Header pageTitle={'Portfolio Setup Wizard'} activeStep={'stepActive1'} stepNav={true}></Header></div>
            <div className="page-content">
                <Form
                  schema={this.state.schema}
                  uiSchema={uiSchema}
                  formData={this.state.formData}
                  onSubmit={this.onSubmit}
                  onChange={this.onFormChanged}
                  widgets={widgets}
                  FieldTemplate={CustomFieldTemplate}
                >
                  <div className="navbarSteps navbarStepsBtm">
                    <div className="row justify-content-end">

                      <Button variant="contained" color="primary" type="submit" disabled={this.state.getLoansLoader === true ? true : false} >
                        {this.state.getLoansLoader === true ? <CircularProgress size="30px" color="secondary" /> : ''}

                        Get Loans  </Button>
                    </div>
                  </div>

                </Form>
              

              {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '}


            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default eligiblePortfolioImport;