// @flow

import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import CompareItem from "../../../components/pageTitle/CompareItem";
import './HighlightCoords.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import { testHighlights } from '../../../Response'


function PDFHighlighterSidebar({ highlights: highlightsParent, resetHighlights }) {
  const [highlights, setHighlights] = useState([])
  const activeLoanData = JSON.parse(sessionStorage.getItem('activeLoanData'))

  useEffect(() => {
    setHighlights(highlightsParent)
  }, [highlightsParent])

  console.log("PDFHighlighterSidebar", { highlights })

  const getLMSKeys = () => {
    const newArr = []
    for (let i = 0; i < highlights.length; i++) {
      newArr.push(Object.keys(highlights[i]?.content?.lmsloan))
    }
    // setLMSLoan(newArr.flat())
    return newArr.flat()
  }
  console.log("LMS KEYS", getLMSKeys())
  const getLMSValues = () => {
    const newArr = []
    for (let i = 0; i < highlights.length; i++) {
      newArr.push(Object.values(highlights[i]?.content?.lmsloan))
    }
    // setLMSLoan(newArr.flat())
    return newArr.flat()
  }
  const getAgreementValues = () => {
    const newArr = []
    for (let i = 0; i < highlights.length; i++) {
      newArr.push(Object.values(highlights[i]?.content?.agreementloan))
    }
    // setLMSLoan(newArr.flat())
    return newArr.flat()
  }

  const comparision = (highlight, i) => {
    console.log('comparision', highlights)
    return (
      <CompareItem key={i}
        className="pdfHighlighterSidebar__highlight"
        onClick={() => {
          // updateHash(highlight);
        }}
        id={i + 1} mask={false} title={getLMSKeys()[i]} lmsLoan={Object.values(highlights[i]?.content?.lmsloan)[0]} agreementLoan={Object.values(highlights[i]?.content?.agreementloan)[0]} match={testHighlights.res.data[0].attributewise_matched[i]}></CompareItem>
    )

  }

  return (
    <div className="pdfHighlighterSidebar">

      {/* NEW SIDEBAR */}
      <div id="content_pdfconent" className="viewmorecolumnpdf">
        {getLMSKeys().length !== 0 ?

          <Form id="labelform">
            {/* <table className="data_table" id="viewMore" >
              <thead>
                <tr>
                  <th>LMS</th>
                  <th>Contract</th>
                  <th style={{ width: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {/* {
                  highlights.map((highlight, i) => {
                    console.log('comparision', highlight.content.lmsloan)
                    return <CompareItem key={i}
                      className="pdfHighlighterSidebar__highlight"
                      onClick={() => {
                        updateHash(highlight);
                      }}
                      highlights={highlights}
                      id={i}
                      mask={false}
                      title={getLMSKeys()[i]}
                      lmsLoan={getLMSValues()[i]}
                      agreementLoan={getAgreementValues()[i]}
                      match={Response.res.data[0].attributewise_matched[i]} />
                  }
                    // comparision(highlight, index)
                  )

                } */}
            {/* </tbody>
            </table> */}
            <div className="d-flex justify-content-between">
              <h6>LMS</h6>
              <h6 style={{ width: '50%' }}>Contract</h6>
            </div>
            <CompareItem className="pdfHighlighterSidebar__highlight" highlights={highlights} match={activeLoanData?.attributewise_matched}
              lmsLoan={activeLoanData?.lmsloan} agreementLoan={activeLoanData?.agreementloan}
              isMatched={Object.values(activeLoanData?.attributewise_matched).every(item => item === 1)}
            />

          </Form> :
          <CircularProgress size="30px" color="secondary" />}

      </div>
      {/* <div style={{ padding: "1rem" }}>
        <button onClick={toggleDocument}>Toggle PDF document</button>
      </div> */}
    </div>
  );
}

export default PDFHighlighterSidebar;
{/* <div className="description" style={{ padding: "1rem" }}>
        <h2 style={{ marginBottom: "1rem" }}>react-pdf-highlighter</h2>

        <p style={{ fontSize: "0.7rem" }}>
          <a href="https://github.com/agentcooper/react-pdf-highlighter">
            Open in GitHub
          </a>
        </p>

        <p>
          <small>
            To create area highlight hold ⌥ Option key (Alt), then click and
            drag.
          </small>
        </p>
      </div> */}

{/* <ul className="pdfHighlighterSidebar__highlights">
        {highlights.map((highlight, index) => (
          <li
            key={index}
            className="pdfHighlighterSidebar__highlight"
            onClick={() => {
              updateHash(highlight);
            }}
          >
            <div>
              <strong>{highlight.comment.text}</strong>
              {highlight.content.text ? (
                <input disabled={true} value={`${highlight.content.text.slice(0, 90).trim()}…`} style={{ marginTop: "0.5rem" }} />

              ) : null}
              {highlight.content.image ? (
                <div
                  className="highlight__image"
                  style={{ marginTop: "0.5rem" }}
                >
                  <img src={highlight.content.image} alt={"Screenshot"} />
                </div>
              ) : null}
            </div>
            <div className="highlight__location">
              Page {highlight.position.pageNumber}
            </div>
          </li>
        ))}
      </ul> */}
{/* <div style={{ padding: "1rem" }}>
        <button onClick={toggleDocument}>Toggle PDF document</button>
      </div>
      {highlights.length > 0 ? (
        <div style={{ padding: "1rem" }}>
          <button onClick={resetHighlights}>Reset highlights</button>
        </div>
      ) : null} */}