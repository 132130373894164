/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import TextField from '@material-ui/core/TextField';
// import { Form as form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from "axios";
import { withSnackbar } from 'notistack';
// import Modal from 'react-responsive-modal';
// import { withTheme } from 'react-jsonschema-form';
// import { Theme as MuiTheme } from 'rjsf-material-ui';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
// import IconButton from '@material-ui/core/IconButton';
// import { Redirect, NavLink } from "react-router-dom";

class viewCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      loading: false,
      loader: false,
      getLoansLoader: false,
      open1: false,
      open2: false,
      open3: false,
      disabled: true,
      Redirect: false,
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem('token');  
    const peer = localStorage.getItem('peer')
    // const poolId = 'd3f67064a332ec24e853b19a5e843c83';
    const poolId = this.props.match.params.id;

    this.setState({ getLoansLoader: true, assignbutton: true });
    try {
      // collection/poolcollections?poolID=d3f67064a332ec24e853b19a5e843c83&_=1586773040212
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/collection/poolcollections?poolID='+ poolId+ "&peer="+peer+"&token="+token);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        // const columns = resp;


        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
        this.setState({ getLoansLoader: false, open: true, tableData: resp, loading: true });
        const message = "Successfully fetched the data";     
        this.props.enqueueSnackbar(message, { 
          variant: 'info',
          autoHideDuration: 2000,
      });
      } else {
        alert("Failed");
      }
    } catch (error) {
      alert("Failed")
      console.log("getLoans API error", error);
    }
  }

  render() {
    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      {
        name: 'LoanID',
        label: 'Loan ID',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'InstallmentNumber',
        label: 'Installment Number',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'Currency',
        label: 'Currency',
        options: {
          filter: true,
          sort: false,
        },
      },

      {
        name: 'EmiPaid',
        label: 'Emi Paid',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'InterestAmountRepaid',
        label: 'Interest Amount Repaid',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'PrincipalAmountRepaid',
        label: 'Principal Amount Repaid',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OutstandingPrincipalBalance',
        label: 'Outstanding Principal Balance',
        options: {
          filter: true,
          sort: false,
        },
      },
    ];


    // const btn = sessionStorage.getItem("assignbutton");
    const btn = JSON.stringify(this.state.assignbuttonbtn)

    return (

      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            {/* <div className="header"><Header backButton={'collections'} pageTitle={'View Collections'}></Header></div> */}
            <div className="header"> <Header backTitle={'Pool Details'} backButton={'/pool-details'} pageTitle={'View Collections'}></Header> </div>
            
            <div className="tableSearch">

              <div className="row">
                <div className="justify-content-left " id="assignpoolbutton">

                  {/* {JSON.stringify(this.state.assignbutton)} */}

                  {this.state.userRole === 'Originator' ? 
                    btn === true ? 
                    <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"  onClick={this.onOpenModal1.bind(this)} disabled color="primary" type="submit" >Assign Pool</Button> : 
                    <Button className="assignbtn" variant="contained" id="assignbuttoncolorchange"  onClick={this.onOpenModal1.bind(this)} color="primary" type="submit" >Assign Pool</Button>
                    :''
                  }


                </div>

                <div className="col-md-2 col-sm-3" id="searchBox"><TextField id="outlined-basic" label="Search" variant="outlined" size="small" /></div>

                <div className="col-md-6 col-sm-12">

                </div>

              </div>
            </div>
            <div className="page-content">

              {this.state.loading === true ?
                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

                : <Loader msg={"Please wait, loading data"} />}

              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
            </div>
          </div>
        </div>
        <div >

        </div>

      </React.Fragment>
    );
  }
}


export default withSnackbar(viewCollections);