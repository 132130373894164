/* eslint-disable require-jsdoc */
import React, { Component } from "react";
// import { connect } from 'react-redux';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import MUIDataTable from "mui-datatables";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../../../components/loader";
import Snackbar from "../../../components/snackbar";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Box from "@material-ui/core/Box";
import ReactModal from "react-modal";
import MenuItem from "@material-ui/core/MenuItem";
import {
  widgets,
  CustomFieldTemplate,
  customStyles,
  customStylesauto,
} from "../../../components/customscripts/customscript";
import { FilePond, registerPlugin } from "react-filepond";
import FormLoader from "../../../components/loader/formLoader";
import Tooltip from "@material-ui/core/Tooltip";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { default as ReactSelect } from "react-select";
import "filepond/dist/filepond.min.css";
import { NavLink } from "react-router-dom";
import {
  getallpoolsbyid,
  uploadlms,
  savelms,
  uploadcontract,
  LmsStatus,
  ContractStatus,
  GetFileList,
  GetAttributeDetail,
} from "../../../servies/services";
import { components } from "react-select";
import { Hidden } from "@material-ui/core";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { type } from "jquery";

import LoopIcon from "@material-ui/icons/Loop";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

registerPlugin(FilePondPluginFileValidateType);

const token = localStorage.getItem("token");

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// function myFunction(str) {
//   //var str = "Hello world!";
//   if (str.length > 2) {
//       var res = str.substring(2, str.length);
//       var rep = res.replace(/[a-z]/gi, 'x')
//       return str.replace(res, rep);
//   }

//   return str;
// }
const customStyle = {
  option: (provided, state) => ({
    ...provided,
    // minHeight: 20,
    // height: 20,
    // color: state.isSelected ? 'red' : 'blue',
    padding: 2,
  }),
  control: (base) => ({
    ...base,
    height: 45,
    minHeight: 45,
    // marginLeft: '60px',
    // marginRight: '60px',
    fontWeight: "bold",
    borderStyle: "solid",
    borderColor: "grey",
    borderBottom: "1px solid grey",
  }),
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className="setup">{props.label}</label>
      </components.Option>
    </div>
  );
};
class pdashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecords: [],
      eligibleloans: {},
      tableData: [],
      loading: false,
      getLoansLoader: false,
      open: false,
      selectedLoanId: [],
      searchText: "",
      rowsSelected: null,
      openPopup: false,
      open1: false,
      open2: false,
      poolId: "",
      poolName: "",
      processorarrray: [],
      processoroption: [],
      disable: true,
      array1: [],
      // duplicateobject:[],
      // duplicate:{"data":[
      //       {"date":"02-10-2021"},{"date":"03-10-2021"},{"date":"02-10-2021"},{"date":"04-10-2021"},
      //            {"date":"05-10-2021"}]}
    };
    this.onOpenModal2 = this.onOpenModal2.bind(this);
    this.onOpenModal1 = this.onOpenModal1.bind(this);
  }

  // { poolid: "Gabby George", poolname: "Business Analyst", assetclass: "Minneapolis", noofloans: 30, datecreated: "$100,000", status: "completed" },
  //   { poolid: "Aiden Lloyd", poolname: "Business Consultant", assetclass: "Dallas", noofloans: 55, datecreated: "$200,000", status: "pending" },
  //   { poolid: "Jaden Collins", poolname: "Attorney", assetclass: "Santa Ana", noofloans: 27, datecreated: "$500,000", status: "completed" },
  handleChange = (selected) => {
    this.setState({
      processorarrray: selected,
    });
    console.log("selecteddd", selected.value);
  };

  async selectedpoolid(selected) {
    const arr = [];
    const arr1 = [];

    for (var i = 0; i < selected.length; i++) {
      var j = selected[i];
      let PoolID = this.state.tableData[j].poolid;
      arr.push(PoolID);
      arr1.push(this.state.tableData[j]);
    }
    console.log("selected Loans", arr);
    console.log("selected rows", arr1);

    localStorage.setItem("pdashboard", arr);
  }

  onchange = (e) => {
    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  };

  // async addContracts(value1) {

  //   console.log("Inside ADD Contract --"+value1);
  // }

  // async addlmsData(value2) {

  //   console.log("Inside addlmsData"+value2);
  // }

  onOpenModal1 = (value, poolname) => {
    localStorage.setItem('runddpoolid',value);
    this.setState({ processorarrray: [], formLoader: false });
    this.GetFileList(poolname, token);
    this.GetAttributeDetail(value, token);
    this.setState({ open1: true, poolId: value, poolName: poolname });
  };

  onCloseModal1 = () => {
    // this.pond.getFiles().length !== 0 &&  window.location.reload()
    // this.pond.removeFiles();
    this.setState({ open1: false });
    console.log("poolid===" + this.state.poolId);
    console.log("poolname===" + this.state.poolName);
  };

  onOpenModal2 = () => {
    this.setState({ files1: [], formLoader: false });
    console.log("inside modal2");
    this.setState({ open2: true });
  };
  onCloseModal2 = () => {
    // this.pond1.getFiles().length !== 0 &&  window.location.reload()
    this.setState({ open2: false });
    console.log("poolid===" + this.state.poolId);
    console.log("poolname===" + this.state.poolName);
  };

  async handleClick2(value, poolname) {
    this.onOpenModal2();
    this.setState({ poolId: value });
    this.setState({ poolName: poolname });
    // localStorage.setItem('pdashboard', value);
    // this.props.history.push({
    //   pathname: `/processor/rundd/${this.state.poolId}/${this.state.poolName}`
    // })
    // this.props.history.push({
    //   pathname: '/processor/rundd/' + poolId + poolname
    // })
  }

  async handleClick1(value, poolname) {
    this.onOpenModal1(value, poolname);
    this.setState({ poolId: value });
    this.setState({ poolName: poolname });
  }

  handleInit() {
    console.log("FilePond instance has initialised", this.pond1);
  }

  GetFileList = async (data, token) => {
    const APIResponse = await GetFileList(data, token);
    console.log("Allzipfiles--", APIResponse);
    if (APIResponse.status === 200) {
      console.log("Allzipfiles--", APIResponse);
      this.setState({
        getLoansLoader: false,
        array1: APIResponse.data,
        open: true,
        loading: true,
      });
      let x = APIResponse.data;
      console.log("xxxxxxxx", x);
      let y = [];
      x.forEach(function (element) {
        y.push({ label: element, value: element });
      });
      console.log("yyyyyyyy", y);
      console.log("inside modal1");
      this.setState({ processoroption: y });
    } else {
      alert("Failed");
    }
  };

  GetAttributeDetail = async (data, token) => {
    const APIResponse = await GetAttributeDetail(data, token);

    if (APIResponse.status === 200) {
      console.log("Allpools--", APIResponse);
      this.setState({
        getLoansLoader: false,
        open: true,
        resss1: APIResponse.data,
        loading: true,
      });
      const field_details = this.state.resss1;
      localStorage.setItem("field_details", JSON.stringify(field_details));
      const message = "Record Fetched Successfully";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      alert("Failed");
    }
  };

  addContract = () => {
    console.log("processorarray", this.state.processorarrray);
    var data = {
      poolname: this.state.poolName,
      filename: this.state.processorarrray.value,
      poolid:  localStorage.getItem('runddpoolid'),
      field_details: JSON.parse(localStorage.getItem("field_details")),
    };
    console.log("datattatat", data);
    if (!data.poolid) {
      
      
      const message ='Pool Id Not Found';
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 7000,
        });

        window.location.assign("/processor/dashboard")
  }
    this.uploadContract(data);
  };

  // addContract = () => {

  //   this.setState({ msg: true, loadingmodal: false })
  //   console.log("Asdasdas", this.pond.getFiles().length);
  //   // alert(this.state.doc_type)

  //   if (this.pond.getFiles().length === 0) {

  //     alert("Please upload the documents")
  //   } else {

  //     this.setState({ dig_flag: true });
  //     var formData = new FormData();

  //     var cnt = 0;

  //     this.pond.getFiles()
  //       .map(fileItem => fileItem.file)
  //       .forEach(file => {
  //         formData.append("files", file);
  //         // formData.append("Content-Type", 'multipart/form-data');
  //         cnt++;
  //         console.log("cnt", file);
  //         console.log("Asdasdas1", formData);
  //       });
  //       this.uploadContract(formData)
  //   }
  // }

  uploadContract = async (data) => {
    console.log("mmmmmmmm", data);
    this.setState({ formLoader: true });
    const APIResponse = await uploadcontract(data);
    console.log("apiresponse", APIResponse);
    if (APIResponse.status === 200) {
      console.log("upload--", APIResponse);
      this.setState({ formLoader: false });
      if (APIResponse.data.desc === "File Upload Completed!!!") {
        this.onCloseModal1();
        const parameters = localStorage.getItem("userid");
        this.getLoans(parameters);
        // window.location.reload();

        const message = "Data Saved Successfully";
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });

        // const simpleres = await ContractStatus(this.state.poolId,token)
        // console.log("simpleres------"+simpleres)
      } else if (
        APIResponse.data.desc === "Zip File contains Unsupported file format!!!"
      ) {
        this.setState({ formLoader: false });
        const message = "Zip File contains Unsupported file format!!!";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      } else {
        this.setState({ formLoader: false });
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    } else {
      alert("Failed upload");
    }
  };

  addExcel = () => {
    console.log("poolid===" + this.state.poolId);
    console.log("poolname===" + this.state.poolName);

    this.setState({ msg: true, loadingmodal: false });
    console.log("excel ", this.pond1.getFiles().length);
    // alert(this.state.doc_type)

    if (this.pond1.getFiles().length === 0) {
      alert("Please upload the documents");
    } else {
      this.setState({ dig_flag: true });
      var formData = new FormData();

      var cnt = 0;

      this.pond1
        .getFiles()
        .map((fileItem) => fileItem.file)
        .forEach((file) => {
          formData.append("filename", file);
          cnt++;
          console.log("excelcnt", file);
        });
      formData.append("poolname", this.state.poolName);
      formData.append("poolid",this.state.poolId)

      formData.forEach((element) => {
        console.log("Asdasdas2", element);
      });

      this.uploadLms(formData);
    }
  };

  uploadLms = async (value) => {
    this.setState({ formLoader: true });
    const APIResponse = await uploadlms(value, token);

    if (APIResponse.status === 200) {
      console.log("upload--", APIResponse);
      if (APIResponse.data.isSuccess === true) {
        this.saveLms(APIResponse.data.filetype);
      } else {
        this.setState({ formLoader: false });
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    } else {
      alert("Failed upload");
    }
  };

  saveLms = async (value) => {
    var formData = new FormData();
    formData.poolname = this.state.poolName;
    formData.poolid = this.state.poolId;
    formData.filetype = value;

    const APIResponse = await savelms(formData, token);

    if (APIResponse.status === 200) {
      console.log("savelms--", APIResponse);
      if (APIResponse.data.isSuccess === true) {
        this.setState({ formLoader: false });
        const message = "Data Saved Successfully";
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.onCloseModal2();
        const parameters = localStorage.getItem("userid");
        this.getLoans(parameters);
        // window.location.reload()
        this.setState({ disable: false });
        // const simpleres = await LmsStatus(this.state.poolId, token);
        // console.log("simpleres------" + simpleres);
      }
    } else {
      this.setState({ formLoader: false });

      // alert('Failed saving');

      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
  };

  addLmsdata = () => {
    //   this.setState({ msg: true, loadingmodal: false })
    //   console.log("lmsdata", this.pond2.getFiles().length);
    //   // alert(this.state.doc_type)
    //   if (this.pond2.getFiles().length === 0) {
    //     alert("Please upload the documents")
    //   } else {
    //     this.setState({ dig_flag: true });
    //     var formData = new FormData();
    //     var cnt = 0;
    //     this.pond2.getFiles()
    //       .map(fileItem => fileItem.file)
    //       .forEach(file => {
    //         formData.append("userFile", file);
    //         cnt++;
    //         console.log("lmscnt", file);
    //         // console.log("lmsdata1", formData);
    //       });
    //       console.log("lmsdata1", formData);
    //     // formData.forEach(element => {
    //     //   console.log("Asdasdas2", element);
    //     //   });
    // }
  };

  async componentDidMount() {
    if (
      localStorage.getItem("token") == "" ||
      localStorage.getItem("token") == undefined
    ) {
      window.location.assign("/");
    }
    // localStorage.removeItem('dueDiligence');
    // const stateStatus = this.props.location.state;
    // console.log('stateStatus', stateStatus);

    // if (stateStatus === null) {
    //   alert('Please select the parameters');
    //   window.location.assign('/portfolio-setup-wizard/eligible-portfolio-import');
    // } else {
    //   const parameters = JSON.parse(localStorage.getItem('eligiblePortfolioImportParameter'));
    //   console.log('data', parameters);
    //   this.getLoans(parameters);
    // }
    const parameters = localStorage.getItem("userid");
    // localStorage.removeItem("poolid")
    // localStorage.removeItem("runddpoolname")
    this.getLoans(parameters);
  }

  async getLoans(value) {
    this.setState({ getLoansLoader: true });

    //   // console.log("getLoans Data", data);
    //   const dataString = 'assetType=' + data.assetType + '&assetCategory=' + data.assetCategory + '&hypothecatedWithLender=' + data.hypothecatedWithLender + '&rcCopyAvailable=' + data.rcCopyAvailable +
    //     '&insuranceCopyAvailable=' + data.insuranceCopyAvailable + '&lenderHypothecationOnInsuranceCopyAvailable=' + data.lenderHypothecationOnInsuranceCopyAvailable +
    //     '&loanAgreementAvailable=' + data.loanAgreementAvailable + '&anyEMIsRestructured=' + data.anyEMIsRestructured +
    //     '&cutoffDate=' + data.cutoffDate;
    //   console.log('getLoans dataString', dataString);
    //   try {
    //     const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/eligibleloans?' + dataString);
    var data = {};
    data.userid = value;
    const res = await getallpoolsbyid(data, token);

    console.log("3333", res.data);
    this.setState({ certIssuedd: res.data[0].certIssued });
    console.log("6666", this.state.certIssuedd);

    this.setState({ poolId: res.data.poolId });

    const resp = res.data;
    console.log("5555", resp[0].certIssued);
    if (res.status === 200) {
      console.log("eligibleloans", resp);
      this.setState({
        getLoansLoader: false,
        open: true,
        tableData: resp,
        loading: true,
      });
      console.log("tabledatata", this.state.tableData);
      // const obj =this.state.duplicate.data.filter((tag, index, array) => array.findIndex(t => t.date == tag.date) == index);

      // const obj = [...new Map(this.state.duplicate.data.map(item => [JSON.stringify(item), item])).values()];
      // console.log(obj);
      // const uniqueNames = this.state.duplicate.filter((val,id,array) => array.indexOf(val) == id);
      // console.log('uniquenames',obj)
      // this.setState({duplicateobject:obj})
      // const message = "Record Fetched Successfully";
      // this.props.enqueueSnackbar(message, {
      //   variant: 'info',
      //   autoHideDuration: 3000,
      // });
    } else {
      alert("Failed");
    }
    // } catch (error) {
    //   alert('Failed');
    //   console.log('getLoans API error', error);
    // }
  }

  onSubmit = () => {
    if (localStorage.getItem("dueDiligence") !== null) {
      const data = localStorage.getItem("dueDiligence");
      this.props.history.push({
        pathname: "/portfolio-setup-wizard/pool-formation",
        search: "",
        state: { eligiblePortfolioImportParameter: data },
      });
    }
  };
  // trial =() =>{
  //   console.log('tabledatat',this.state.tableData)
  // }

  render() {
    const options = {
      filterType: "dropdown",
      filter: true,
      search: true,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,

      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      customSort: (data, colIndex, order) => {
        console.log("customSort", data, colIndex, order);
        return data.sort((a, b) => {
          if (colIndex === 5) {
            return (
              (new Date(a.data[colIndex]) < new Date(b.data[colIndex])
                ? -1
                : 1) * (order === "desc" ? 1 : -1)
            );
          } else {
            return (
              (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
              (order === "desc" ? 1 : -1)
            );
          }
        });
      },

      // searchText: this.state.searchText,
      // searchPlaceholder: 'Search',
      // customSearch: (searchQuery, currentRow, columns) => {
      //   let isFound = false;
      //   currentRow.forEach(col => {
      //     if (col.toString().indexOf(searchQuery) >= 0) {
      //       isFound = true;
      //     }
      //   });
      //   return isFound;
      // },

      loading: true,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              "Sorry, there is no matching data to display"
            ) : (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
      },
    };

    const columns = [
      {
        name: "poolid",
        label: "Deal Id",
        options: {
          filter: true,
          // sort: true,
        },
      },
      {
        name: "poolname",
        label: "Deal Name",
        options: {
          filter: true,
          // sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return (
          //     <React.Fragment>
          //      {moment(value).format('MM-DD-YYYY')}
          //     </React.Fragment>
          //   );
          // },
        },
      },
      {
        name: "assetclass",
        label: "Asset Class",
        options: {
          filter: true,
          // sort: true,
        },
      },
      {
        name: "issuer",
        label: "Issuer",
        options: {
          filter: true,
          // sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return (
          //     <React.Fragment>
          //     {myFunction(value)}
          //     </React.Fragment>
          //   );
          // },
        },
      },

      // {
      //   name: 'noofloans',
      //   label: 'No. of Contracts',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <React.Fragment>
      //            <div className="text-center">{value}</div>
      //         </React.Fragment>
      //       );
      //     },
      //   },
      // },
      {
        name: "noofloans",
        label: "No. of Loans",
        options: {
          filter: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              console.log(order);
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          // sort:true,
          // customHeadRender: (columnMeta, updateDirection) => (
          //   <th style={{textAlign:'center',fontSize:'0.875rem'}}>
          //       {columnMeta.label}
          //   </th>
          // ),
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return (
          //     <React.Fragment>
          //        <div className="text-center">{value}</div>
          //     </React.Fragment>
          //   );
          // },
        },
      },
      {
        name: "datecreated",
        label: "Date Created",
        options: {
          filter: true,
          // sort: true,
        },
      },
      {
        name: "lastactivity",
        label: "Last Activity",
        options: {
          filter: true,
          // sort: true,
        },
      },

      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
           sort: true,
        },
      },
      {
        name: "poolid",
        label: "Add LMS Data",
        options: {
          filter: true,
          // sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className="text-center">
                {tableMeta.rowData[11] !== 0 ? (

                  <LoopIcon
                    style={{ cursor: "pointer", color: "#048c88" }}
                    onClick={() =>
                      this.handleClick2(value, tableMeta.rowData[1])
                    }
                  ></LoopIcon>
                  ) : (
                    <CloudUploadIcon
                    style={{ cursor: "pointer", color: "#048c88" }}
                    onClick={() =>
                      this.handleClick2(value, tableMeta.rowData[1])
                    }
                  ></CloudUploadIcon>
                )}
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "poolid",
        label: "Add Contracts",
        options: {
          filter: true,
          // sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className="text-center">
                {tableMeta.rowData[12] === 0 ? (

                  <CloudUploadIcon
                    style={{ cursor: "pointer", color: "#048c88" }}
                    disabled={this.state.disable == true ? true : false}
                    onClick={() =>
                      this.handleClick1(value, tableMeta.rowData[1])
                    }
                  ></CloudUploadIcon>
                  ) : (
                    <LoopIcon
                      style={{ cursor: "pointer", color: "#048c88" }}
                      disabled={this.state.disable === true}
                      onClick={() =>
                        this.handleClick1(value, tableMeta.rowData[1])
                      }
                    ></LoopIcon>
                  )}
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },

      {
        name: "poolid",
        label: "View Loans",
        options: {
          filter: true,
          // sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log("call",tableMeta.rowData)
            const handleViewLoansClick = () => {
              // Store the poolid in localStorage
              localStorage.setItem('runddpoolid', value);
              localStorage.setItem('runddpoolname',tableMeta.rowData[1])
            };
            console.log("cal",value)
            return (
              <React.Fragment>
                <div className="text-center">
                { tableMeta.rowData[7] === "Reconciled" ? (
                  <NavLink
                    // to={`/processor/rundd/${value.poolid}/${value.poolname}`}
                    to={"/ddreport"}
                    onClick={handleViewLoansClick}
                  >
                    <img
                      alt=""
                      src={require("../../../images/ViewLoans.png")}
                    ></img>
                  </NavLink>
                ) : (
                  <img
                    alt=""
                    src={require("../../../images/ViewLoans.png")}
                    className="disabled"
                  ></img>
                )}
                  {/* <NavLink
                    // to={`/processor/rundd/${value}/${tableMeta.rowData[1]}`}
                    to={"/ddreport"}
                    onClick={handleViewLoansClick}
                  >
                    <img
                      alt=""
                      src={require("../../../images/ViewLoans.png")}
                    ></img>
                  </NavLink> */}

                  {/* // <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value, tableMeta.rowData[1])} >
                   // <img alt="" src={require('../../../images/ViewLoans.png')}></img>
                 //   </Button> */}

                  {/* {tableMeta.rowData[8] == "completed" ?
                    :
                   ''
                  } */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "lms_status",
        label: "LMS Status",
        options: {
          display: "exclude",
        },
      },
      {
        name: "contract_status",
        label: "Contract Status",
        options: {
          display: "exclude",
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header">
              <Header pageTitle={"DASHBOARD"}></Header>
            </div>

            <div className="page-content">
              <React.Fragment>
                <MUIDataTable
                  title={"Deals"}
                  data={this.state.tableData}
                  columns={columns}
                  options={options}
                />

                <div id="modal">
                  <ReactModal
                    isOpen={this.state.open1}
                    onRequestClose={this.onCloseModal1}
                    // contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="shifting">
                        <h5 className="text-secondary"> Add Contract </h5>

                        <div className="row">
                          <div className="col">
                            {/* <Box className="dashed"> */}
                            <div className="text-center">
                              <SaveAltIcon className="upper"></SaveAltIcon>
                              <h6 className="text-success font-weight-bold">
                                Upload
                              </h6>
                            </div>

                            <ReactSelect
                              className="as-shifted"
                              options={this.state.processoroption}
                              placeholder="Select File"
                              closeMenuOnSelect={false}
                              maxMenuHeight={150}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={this.handleChange}
                              allowSelectAll={true}
                              value={this.state.processorarrray}
                              styles={customStyle}
                            />

                            {/* <FilePond
                                ref={ref => this.pond = ref}
                                oninit={() => this.handleInit()}
                                files={this.state.files}
                                allowMultiple={true}
                                allowReorder={true}
                                maxFiles={3}
                                name="file"
                                labelIdle='Drag And Drop or <span class="filepond--label-action">Browse</span>'
                                onupdatefiles={fileItems => {
                                  // Set currently active file objects to this.state
                                  this.setState({
                                    files: fileItems.map(fileItem => fileItem.file)
                                  });
                                }}
                              /> */}
                            {/* <p className="text-secondary">(Zip file or Multi-Select)</p> */}

                            {/* </Box> */}
                            {/* <Button variant="contained" color="primary" type="submit" onClick={this.digitize} >Upload</Button> */}
                          </div>

                          {/* <div className="col text-center">
                          <Box className="solid">
                          <SaveAltIcon className="upper"></SaveAltIcon>
                        <h6 className="text-success font-weight-bold">Add URL for Upload</h6>
                            
                            <div className="search_box">
                            <input type="text" class="input" placeholder="Add URL"></input>
                            <div className="btn btn_common"> Upload</div>
                            </div>
                            <Button variant="contained" color="primary" type="submit" >Done</Button>
                            
                        </Box>
                            
                          </div> */}
                        </div>
                      </div>

                      <div className="row topping">
                        <div className="spacing">
                          <Button
                            variant="outlined"
                            id="optionalbutton"
                            onClick={this.onCloseModal1}
                          >
                            {" "}
                            CANCEL{" "}
                          </Button>
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={this.addContract}
                        >
                          UPLOAD
                          {this.state.formLoader === true ? (
                            <CircularProgress size="25px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>

                <div id="modal">
                  <ReactModal
                    isOpen={this.state.open2}
                    onRequestClose={this.onCloseModal2}
                    // contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="shifting">
                        <h5 className="text-secondary"> Add Loan Data</h5>

                        <div className="row">
                          <div className="col text-center">
                            <Box className="dashedd">
                              <SaveAltIcon className="upper"></SaveAltIcon>
                              <div>
                                <h6 className="text-success font-weight-bold">
                                  Upload Excel
                                </h6>
                              </div>
                              <FilePond
                                ref={(ref) => (this.pond1 = ref)}
                                oninit={() => this.handleInit()}
                                files={this.state.files1}
                                allowMultiple={true}
                                allowReorder={true}
                                // onremovefile
                                acceptedFileTypes={[
                                  "application/vnd.ms-excel",
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                ]}
                                maxFiles={1}
                                name="file1"
                                labelIdle='<span class="filepond--label-action">Browse</span>'
                                onupdatefiles={(fileItems) => {
                                  // Set currently active file objects to this.state
                                  this.setState({
                                    files1: fileItems.map(
                                      (fileItem) => fileItem.file
                                    ),
                                  });
                                }}
                              />
                            </Box>
                          </div>

                          <div className="col text-center">
                            <Box className="solid">
                              <SaveAltIcon className="upper"></SaveAltIcon>
                              <div>
                                <h6 className="text-success font-weight-bold ">
                                  Upload from LMS
                                </h6>
                              </div>

                              <FilePond
                                ref={(ref) => (this.pond2 = ref)}
                                oninit={() => this.handleInit()}
                                files={this.state.files2}
                                allowMultiple={true}
                                allowReorder={true}
                                maxFiles={1}
                                name="file2"
                                labelIdle='<span class="filepond--label-action">Browse</span>'
                                onupdatefiles={(fileItems) => {
                                  // Set currently active file objects to this.state
                                  this.setState({
                                    files2: fileItems.map(
                                      (fileItem) => fileItem.file
                                    ),
                                  });
                                }}
                              />
                            </Box>
                          </div>
                        </div>
                      </div>

                      <div className="row topping">
                        <div className="spacing">
                          <Button
                            variant="outlined"
                            id="optionalbutton"
                            onClick={this.onCloseModal2}
                          >
                            {" "}
                            CANCEL{" "}
                          </Button>
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={this.addExcel}
                        >
                          UPLOAD
                          {this.state.formLoader === true ? (
                            <CircularProgress size="25px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(pdashboard);
