/* eslint-disable require-jsdoc */
import React, { Component } from "react";
// import { connect } from 'react-redux';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import MUIDataTable from "mui-datatables";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../../../components/loader";
import Snackbar from "../../../components/snackbar";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import { withTheme } from "react-jsonschema-form";
import { Theme as MuiTheme } from "rjsf-material-ui";
import ReactModal from "react-modal";
import {
  widgets,
  CustomFieldTemplate,
  customStyles,
  customStylesauto,
} from "../../../components/customscripts/customscript";
import FormLoader from "../../../components/loader/formLoader";
import Tooltip from "@material-ui/core/Tooltip";
import { jsonSchema } from "uuidv4";
import {
  getAllProcessors,
  getallpools,
  addPool,
  addProcessor,
  GetDealId,
  MapProcessor,
  GetAllAttributesPoolId,
  MapAttribute,
  deletePool,
  getUniqueDealNames
} from "../../../servies/services";
import LinearLoader from "../../../components/loader/LinearLoader";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import MySelect from "./MySelect.js";
import Select from "react-select";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const Form = withTheme(MuiTheme);
const Poolschema = require("./pool-schema.json");
const MapProcessorSchema = require("./map-processor-schema.json");
const Processorschema = require("./processor-schema.json");
const uiSchema = require("./ui-schema.json");

const token = localStorage.getItem("token");

const customStyle = {
  option: (provided, state) => ({
    ...provided,
    // minHeight: 20,
    // height: 20,
    color: state.isSelected ? "black" : "black",
    backgroundColor: state.isSelected ? "#eee" : "#fff",
  }),
  control: (base) => ({
    ...base,
    height: 53,
    minHeight: 53,
    fontWeight: "bold",
    borderStyle: "solid",
    borderColor: "grey",
    borderBottom: "1px solid grey",
  }),
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "Select all",
  value: "*",
};
const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = (props) => {
  let labelToBeDisplayed = `${props.data.label} `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};
class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecords: [],
      eligibleloans: {},
      tableData: [],
      loading: true,
      getLoansLoader: false,
      open: false,
      openPopup: false,
      selectedLoanId: [],
      searchText: "",
      rowsSelected: null,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      Poolschema: Poolschema,
      MapProcessorSchema: MapProcessorSchema,
      Processorschema: Processorschema,
      processorarrray: [],
      processoroption: [],
      mappoolid: "",
      mappoolids: "",
      formData1: null,
      formData2: {
        poolname: "",
        datecreated: "",
        assetclass: "",
        issuer: "",
      },
      tableData1: [],
      tableData2: [],
      tableData3: [],
      attribute: [],
      dealName : []
      // options : [
      //   { value: "1month", label: "1 Month" },
      //   { value: "2month", label: "2 Month" },
      //   { value: "3month", label: "3 Month" },
      //   { value: "4month", label: "4 Month" },
      //   { value: "5month", label: "5 Month" },
      //   { value: "6month", label: "6 Month" },
      //   { value: "7month", label: "7 Month" },
      //   { value: "8month", label: "8 Month" },
      //   { value: "9month", label: "9 Month" },
      //   { value: "10month", label: "10 Month" },
      //   { value: "11month", label: "11 Month" },
      //   { value: "12month", label: "12 Month" }
      // ],
      // selectedOption: [],
      // tags: []

      // noofpool:0,
      // noofprocessor:0
    };
  }
  // handleChange1 = (selectedOption) => {
  //   this.setState({selectedOption }, () =>
  //     console.log(`Option selected:`, this.state.selectedOption)
  //   );
  // };
  handleChange = (selected) => {
    this.setState({
      processorarrray: selected,
    });
    console.log("selecteddd", selected);
  };

  async selectedpoolid(selected) {
    const arr = [];

    for (var i = 0; i < selected.length; i++) {
      var j = selected[i];
      let PoolID = this.state.tableData[j].loanContractNumber;
      arr.push(PoolID);
    }
    console.log("selected Loans", arr);
    localStorage.setItem("dueDiligence", arr);
  }

  onchange = (e) => {
    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  };

  onOpenModal1 = () => {
    this.setState({ processorarrray: [], formData1: [] });
    console.log("inside modal1");
    this.setState({ open1: true });
  };
  onCloseModal1 = () => {
    this.setState({ open1: false });
  };

  onOpenModal2 = () => {
    this.setState({ formData2: [] });
    console.log("inside modal2");
    this.setState({ open2: true });
  };
  onCloseModal2 = () => {
    this.setState({ open2: false });
  };

  onOpenModal3 = (value, poolname) => {
    console.log("inside modal2");
    this.setState({
      open3: true,
      tableData1: [],
      tableData2: [],
      tableData3: [],
    });
    this.GetAllAttributesPoolId(value, poolname, token);
  };
  onCloseModal3 = () => {
    this.setState({ open3: false });
  };

  onOpenModal4 = (value, rowData) => {
    console.log("rowdata", rowData);
    let x = this.state.processoroption;
    console.log("xxxxxxxxxxxxxxxxxxx", x);
    let y = rowData[9];
    console.log("yyyyyyyyyyyyyyyyyyy", y);
    let z = y.split("#");

    let res = [];
    z.map((val) => {
      x.map((v) => {
        if (val === v.value) {
          res.push(v);
        }
      });
    });
    console.log("resssssssssss", res);
    this.setState({ processorarrray: res });

    console.log("inside modal1");
    this.setState({ open4: true });
  };
  onCloseModal4 = () => {
    this.setState({ open4: false });
  };

  onOpenModal5 = (value, poolname) => {
    console.log("inside modal2");
    this.setState({ open5: true, valuedealid: value, valuepoolname: poolname });
  };
  onCloseModal5 = () => {
    this.setState({ open5: false });
  };

  // addTag = (e) => {
  //   const { tags } = this.state;
  //   if (e.key === "Enter") {
  //     if (e.target.value.length > 0) {
  //       this.setState({ tags: [...tags, e.target.value] }, this.joinFunc);
  //       e.target.value = "";
  //     }
  //   }
  // };

  // joinFunc = () => {
  //   const newStr = this.state.tags.join("#");
  //   // console.log(newStr);
  //   this.setState({ newVal: newStr }, this.newKeyVal);
  // };

  // newKeyVal() {
  //   console.log(this.state.newVal);
  //   const abc={mailid:this.state.newVal}
  // }

  // removeTag = (removedTag) => {
  //   const { tags } = this.state;
  //   const newTags = tags.filter((tag) => tag !== removedTag);
  //   this.setState({ tags: [...newTags] }, this.joinFunc);
  // };

  async handleClick1(value, poolname) {
    // const res = await GetDealId(value,token)
    // const resp = res.data;

    // if (res.status === 200) {

    //   console.log('dealids', resp);

    //   this.setState({ getLoansLoader: false })
    //   localStorage.setItem("rundd", JSON.stringify(resp));
    //   localStorage.setItem('runddpoolname',poolname);

    this.props.history.push({
      pathname: "/status",
    });
    localStorage.setItem("poolname", value);

    // } else {
    //   alert('Failed in Getting DealIds');
    // }
  }

  savefields = () => {
    console.log("tabledata1", this.state.tableData1);
    console.log("tabledata2", this.state.tableData2);
    let data = this.state.tableData1.concat(this.state.tableData2);

    let data1 = {
      dealid: this.state.dealid,
      data1: this.state.tableData1,
      data2: this.state.tableData2,
      data3: this.state.tableData3,
    };

    console.log("tabledata3", data1);
    // this.onCloseModal3()
  };

  onAddingItem1 = (i) => (event) => {
    let a = this.state.tableData1;
    console.log("aaa----", a[i].matched);
    // console.log("a-----" , a[i].isChecked ,i,a[i].attributeId)
    a[i].matched = !a[i].matched;
    this.setState({ tableData1: a });
  };
  onAddingItem2 = (i) => (event) => {
    let a = this.state.tableData2;
    console.log("bbb----", a[i].matched);
    // console.log("a-----" , a[i].isChecked ,i,a[i].attributeId)
    // a[i].isChecked = !a[i].isChecked;
    a[i].matched = !a[i].matched;
    this.setState({ tableData2: a });
  };
  onAddingItem3 = (i) => (event) => {
    let a = this.state.tableData3;
    console.log("ccc----", a[i].matched);
    // console.log("a-----" , a[i].isChecked ,i,a[i].attributeId)
    // a[i].isChecked = !a[i].isChecked;
    a[i].matched = !a[i].matched;
    this.setState({ tableData3: a });
  };

  async handleClick4(value, rowData) {
    var dataform = this.state.formData2;
    console.log("dataform", dataform);
    dataform.poolname = rowData[1];
    dataform.datecreated = rowData[6];
    dataform.assetclass = rowData[2];
    dataform.issuer = rowData[3];
    this.setState({
      dealid: value,
      formData2: dataform,
      mappoolid: rowData[0],
    });
    this.onOpenModal4(value, rowData);
  }

  async handleClick3(value, poolname, rowData) {
    this.setState({ dealid: value, dealname: poolname });
    this.onOpenModal3(value, poolname);
    // this.setState({ getLoansLoader: true })

    // const res = await GetDealId(value,token)
    // const resp = res.data;

    // if (res.status === 200) {

    //   console.log('dealids', resp);

    //   this.setState({ getLoansLoader: false })
    //   localStorage.setItem("rundd", JSON.stringify(resp));
    //   localStorage.setItem('runddpoolname',poolname);

    //   this.props.history.push({
    //     pathname: '/ddreport',
    //   });

    // } else {
    //   alert('Failed in Getting DealIds');
    // }
  }
  handleClick5(value) {
    // console.log("hendle chick 5 is activated",value )

    this.deletePool(value, token);

    // this.getallpools(token)
  }

  async componentDidMount() {
    if(localStorage.getItem("token") == "" || localStorage.getItem("token") == undefined) {
      window.location.assign("/")
    }
    this.getallpools(token);
    this.getUniqueDealNames(token);
    this.getAllProcessors(token);

    //     const apiRespose =
    //   {
    //     "data": [
    //         {
    //         "_id": "621b22fa0977a800073f39ee",
    //         "attributeId": "fwbqNd3zDb",
    //         "attributeName": "loan set",
    //         "attributeCategory": "Loan",
    //         "matched":"true"
    // }, {
    //         "_id": "621b230f0977a800073f39f0",
    //         "attributeId": "zDzNcvOaEq",
    //         "attributeName": "Collateral Set",
    //         "attributeCategory": "Collateral",
    //         "matched":"false"
    // }, {
    //         "_id": "621b23170977a800073f39f1",
    //         "attributeId": "NaBYcYpejx",
    //         "attributeName": "dealid",
    //         "attributeCategory": "Loan",
    //         "matched":"true"
    // }, {
    //         "_id": "621ccadacf8acc000790ed23",
    //         "attributeId": "3eCrdum9hr",
    //         "attributeName": "dealid",
    //         "attributeCategory": "Borrower",
    //         "matched":"true"
    //     }
    //     ]}
    //       this.setState({tableData1:apiRespose.tableData1,tableData2:apiRespose.tableData2,tableData3:apiRespose.tableData3})

    //     this.getAllProcessors()

    //     const apiRespose =
    //   {
    //     "result": [
    //         {
    //             "_id": "60dc554293aa833a76c72229",
    //             "poolid": "9b918294-e9cb-481c-8971-c1bd81795229",
    //             "poolname": "pool1",
    //             "assetclass": "AC123",
    //             "issuer": "issuer1",
    //             "datecreated": "30-5-2021",
    //             "noofloans": 2,
    //             "loanhash": "NTM2NTk4LDUzNzkzOA=="
    //         },
    //         {
    //           "_id": "60dc554293aa833a76c72228",
    //           "poolid": "9b918294-e9cb-481c-8971-c1bd81795228",
    //           "poolname": "pool2",
    //           "assetclass": "AC456",
    //           "issuer": "issuer2",
    //           "datecreated": "29-5-2021",
    //           "noofloans": 5,
    //           "loanhash": "NTM2NTk4LDUzNzkzOA=="
    //       },
    //       {
    //         "_id": "60dc554293aa833a76c72227",
    //         "poolid": "9b918294-e9cb-481c-8971-c1bd81795227",
    //         "poolname": "poo3l",
    //         "assetclass": "AC789",
    //         "issuer": "issuer3",
    //         "datecreated": "31-5-2021",
    //         "noofloans": 4,
    //         "loanhash": "NTM2NTk4LDUzNzkzOA=="
    //     }
    //     ],
    //     "noofpool": 1,
    //     "noofprocessor": 1
    // }

    //   this.setState({tableData:apiRespose.result,noofpool:apiRespose.noofpool,noofprocessor:apiRespose.noofprocessor})
  }
  async componentWillUnmount(){
    this.state.Poolschema.properties.poolname.enum.length = 0;

  }

  
  //   async getLoans(data) {
  //     this.setState({getLoansLoader: true});
  //     // console.log("getLoans Data", data);
  //     const dataString = 'assetType=' + data.assetType + '&assetCategory=' + data.assetCategory + '&hypothecatedWithLender=' + data.hypothecatedWithLender + '&rcCopyAvailable=' + data.rcCopyAvailable +
  //       '&insuranceCopyAvailable=' + data.insuranceCopyAvailable + '&lenderHypothecationOnInsuranceCopyAvailable=' + data.lenderHypothecationOnInsuranceCopyAvailable +
  //       '&loanAgreementAvailable=' + data.loanAgreementAvailable + '&anyEMIsRestructured=' + data.anyEMIsRestructured +
  //       '&cutoffDate=' + data.cutoffDate;
  //     console.log('getLoans dataString', dataString);
  //     try {
  //       const res = await axios.get(process.env.react_app_base_url + 'api/V1/portfolio/eligibleloans?' + dataString);

  //       const resp = res.data;
  //       if (res.status === 200) {
  //         console.log('eligibleloans', resp);
  //         this.setState({getLoansLoader: false, open: true, tableData: resp, loading: true});
  //         const message = "Record Fetched Successfully";
  //         this.props.enqueueSnackbar(message, {
  //           variant: 'info',
  //           autoHideDuration: 3000,
  //         });
  //       } else {straing
  //         alert('Failed');
  //       }
  //     } catch (error) {
  //       alert('Failed');
  //       console.log('getLoans API error', error);
  //     }
  //   };
  GetAllAttributesPoolId = async (value, poolname, token) => {
    var data = new FormData();
    data.poolid = value;
    data.poolname = poolname;
    console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data);
    this.setState({ getLoansLoader: true });
    //       const APIResponse =
    //    [
    //         {
    //         "_id": "621b22fa0977a800073f39ee",
    //         "attributeId": "fwbqNd3zDb",
    //         "attributeName": "loan set",
    //         "attributeCategory": "Loan",
    //         "matched": true
    // }, {
    //         "_id": "621b230f0977a800073f39f0",
    //         "attributeId": "zDzNcvOaEq",
    //         "attributeName": "Collateral Set",
    //         "attributeCategory": "Collateral",
    //         "matched":false
    // }, {
    //         "_id": "621b23170977a800073f39f1",
    //         "attributeId": "NaBYcYpejx",
    //         "attributeName": "dealid",
    //         "attributeCategory": "Loan",
    //         "matched":true
    // }, {
    //         "_id": "621ccadacf8acc000790ed23",
    //         "attributeId": "3eCrdum9hr",
    //         "attributeName": "dealid",
    //         "attributeCategory": "Borrower",
    //         "matched":true
    //     }
    //     ]
    //     console.log('apiresponses',APIResponse)
    const APIResponse = await GetAllAttributesPoolId(data, token);

    APIResponse.data.map((value) => {
      console.log("value", value);
      if (value.attributeCategory === "Borrower") {
        console.log("table111111", this.state.tableData1);
        this.setState((prevState) => {
          return {
            tableData1: prevState.tableData1.concat(value),
          };
        });
        // this.setState({tableData1:[...this.state.tableData1,value]})
      } else if (value.attributeCategory === "Loan") {
        this.setState((prevState) => {
          return {
            tableData2: prevState.tableData2.concat(value),
          };
        });
      } else {
        this.setState((prevState) => {
          return {
            tableData3: prevState.tableData3.concat(value),
          };
        });
      }
    });

    if (APIResponse.status === 200) {
      console.log("Mapfields--", APIResponse);
      this.setState({
        getLoansLoader: false,
        open: true,
        tableDatas: APIResponse.data,
        loading: true,
      });
      const message = "Record Fetched Successfully";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      alert("Failed");
    }
  };

  deletePool = async (value, token) => {
    console.log("value: ", value);
    // console.log('efyegbyfgeyfgyr',this.state,valuedealid)
    const APIResponse = await deletePool(this.state.valuedealid, token);
    console.log(APIResponse.data.isSuccess);
    console.log(APIResponse.data.message);
    console.log(APIResponse.status);

    if (APIResponse.status !== 200) {
      console.log("abc111", APIResponse);
      const message = "Something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      this.onCloseModal5();
    } else {
      this.setState({ getLoansLoader: false, openPopup: true });

      if (APIResponse.data.isSuccess == false) {
        this.setState({ formLoader: false });
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 1000,
        });
        this.onCloseModal5();
      } else {
        this.setState({ formLoader: false });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 1000,
        });
        this.onCloseModal5();
        // this.getAllProcessors(token)
        this.getallpools(token);
        //  this.props.history.push("/admin/dashboard")
        //  window.location.reload()
      }
    }
  };

  MapAttribute = async (value) => {
    console.log("addPool" + JSON.stringify(data));
    let proarray = [];
    this.state.tableData1.map((val) => {
      if (val.matched) {
        proarray.push(val.attributeId);
      }
    });
    this.state.tableData2.map((val) => {
      if (val.matched) {
        proarray.push(val.attributeId);
      }
    });
    this.state.tableData3.map((val) => {
      if (val.matched) {
        proarray.push(val.attributeId);
      }
    });

    var data = {
      poolid: this.state.dealid,
      attributes: proarray.join("#"),
    };
    console.log("mapfields", data);
    const APIResponse = await MapAttribute(data, token);

    console.log("mapfields", data);
    if (APIResponse.status !== 200) {
      const message = "Something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
    } else {
      this.setState({ getLoansLoader: false, openPopup: true });

      if (APIResponse.isSuccess == false) {
        this.setState({ formLoader: false });
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 1000,
        });
        this.onCloseModal1();
      } else {
        this.setState({ formLoader: false });
        const message = "Attribute are mapped successfully";
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 1000,
        });
        this.onCloseModal3();
        //  this.props.history.push("/admin/dashboard")
        //  window.location.reload()
      }
    }
  };

  getUniqueDealNames = async (token) => {
    const APIResponse = await getUniqueDealNames(token);
    // const APIResponse = {
    //   "isSuccess": true,
    //   "poolNames": ["Bright", "Credibly", "Dynamic", "Eve", "Highrise", "IM Test", "IntainABS", "PayZen", "Stride", "StrideFW", "UOWN"]
    // }
    if (APIResponse && APIResponse.data && APIResponse.data.poolNames) {
      console.log("getAllProcessors", APIResponse);
      //const DealNameOption = [];
  
      for (let i = 0; i < APIResponse.data.poolNames.length; i++) {
        // DealNameOption.push(APIResponse.data.poolNames[i]);
        this.state.Poolschema.properties.poolname.enum.push(APIResponse.data.poolNames[i]);
        console.log('data',APIResponse.data.poolNames[i])
      }
      //console.log('dealname',DealNameOption)
      // this.setState({
      //   dealName : DealNameOption
      // })
      // for (let a = 0; a < DealNameOption.length; a++) {
      //   console.log('dealnameoption', DealNameOption[a]);
      //   this.state.Poolschema.properties.poolname.enum.push(DealNameOption[a]);
      // }
  
      // this.setState({ schemaState: Poolschema }, () => console.log("schemaState", this.state.schemaState));
    }
  };
  

  getallpools = async (token) => {
    this.setState({ getLoansLoader: true, tableData: [] });
    const APIResponse = await getallpools(token);

    if (APIResponse.status === 200) {
      if (APIResponse.data.result.length == 0) {
        console.log("Allpools--", APIResponse);
        this.setState({
          getLoansLoader: false,
          open: true,
          tableData: APIResponse.data.result,
          loading: false,
          noofpool: APIResponse.data.noofpool,
          noofprocessor: APIResponse.data.noofprocessor,
        });
      } else {
        console.log("Allpools--", APIResponse);
        this.setState({
          getLoansLoader: false,
          open: true,
          tableData: APIResponse.data.result,
          loading: true,
          noofpool: APIResponse.data.noofpool,
          noofprocessor: APIResponse.data.noofprocessor,
        });
      }

      // const message = "Record Fetched Successfully";
      // this.props.enqueueSnackbar(message, {
      //   variant: 'info',
      //   autoHideDuration: 3000,
      // });
    } else {
      alert("Failed");
    }
  };

  addPool = async (value) => {
    let data = value.formData;
    console.log("data", data);
    console.log("addPool" + JSON.stringify(data));

    if (this.state.processorarrray.length <= 0) {
      const message = "Please fill the required fields";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 4000,
      });
    } else {
      let proc = [];
      this.state.processorarrray.forEach(function (key, value) {
        console.log("key", key.userid);
        console.log("value", value);
        if (key.value !== "*") {
          proc.push(key.value);
        }
      });
      console.log(this.state.processorarrray);
      console.log("arrayproc", proc.join("#"));
      data.processor = proc.join("#");

      // if(this.state.tags.length <= 0){
      //   const message = "Please fill the required fields";
      //     this.props.enqueueSnackbar(message, {
      //       variant: 'error',
      //       autoHideDuration: 4000,
      //     });
      // }
      // else{
      //   const certificate = {mailid:this.state.newVal}
      //   data={...data, ...certificate}
      //   console.log('certificate',data)

      //   if(this.state.selectedOption.length <= 0){
      //     const message = "Please fill the required fields";
      //       this.props.enqueueSnackbar(message, {
      //         variant: 'error',
      //         autoHideDuration: 4000,
      //       });
      //   }
      //   else{
      //     let interval = {abshdhfru : this.state.selectedOption.label}
      //     interval[this.state.selectedOption.value]=interval['abshdhfru']
      //     delete(interval["abshdhfru"])
      //     data={...data, ...interval}

      console.log("addPool" + JSON.stringify(data));

      const APIResponse = await addPool(data, token);

      if (APIResponse.status !== 200) {
        const message = "Something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        this.setState({ getLoansLoader: false, openPopup: true });

        if (APIResponse.isSuccess == false) {
          this.setState({ formLoader: false });
          const message = "Something went wrong, please try again";
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 1000,
          });
          this.onCloseModal1();
        } else {
          this.setState({ formLoader: false });
          const message = "Pool Added successfully!";
          this.props.enqueueSnackbar(message, {
            variant: "info",
            autoHideDuration: 1000,
          });
          this.onCloseModal1();
          this.getallpools(token);
          //  this.props.history.push("/admin/dashboard")
          //  window.location.reload()
        }
      }
    }
  };

  // }
  // }

  // const APIResponse = await addPool(data,token)

  // if (APIResponse.status !== 200) {
  //   const message = "Something went wrong, please try again";
  //   this.props.enqueueSnackbar(message, {
  //     variant: 'error',
  //     autoHideDuration: 5000,
  //   });

  // }
  // else {

  //   this.setState({ getLoansLoader: false, openPopup: true });

  //   if (APIResponse.isSuccess == false) {

  //     this.setState({ formLoader: false })
  //     const message = "Something went wrong, please try again";
  //     this.props.enqueueSnackbar(message, {
  //       variant: 'error',
  //       autoHideDuration: 2000,
  //     });
  //         this.onCloseModal1()

  //   } else {
  //     this.setState({ formLoader: false })
  //     const message = "Pool Added successfully!";
  //     this.props.enqueueSnackbar(message, {
  //       variant: 'info',
  //       autoHideDuration: 2000,
  //     });
  //        this.onCloseModal1()
  //     //  this.props.history.push("/admin/dashboard")
  //      window.location.reload()
  //   }
  // const APIResponse = await addPool(data,token)

  // if (APIResponse.status !== 200) {
  //   const message = "Something went wrong, please try again";
  //   this.props.enqueueSnackbar(message, {
  //     variant: 'error',
  //     autoHideDuration: 5000,
  //   });

  // }
  // else {

  //   this.setState({ getLoansLoader: false, openPopup: true });

  //   if (APIResponse.isSuccess == false) {

  //     this.setState({ formLoader: false })
  //     const message = "Something went wrong, please try again";
  //     this.props.enqueueSnackbar(message, {
  //       variant: 'error',
  //       autoHideDuration: 2000,
  //     });
  //         this.onCloseModal1()

  //   } else {
  //     this.setState({ formLoader: false })
  //     const message = "Pool Added successfully!";
  //     this.props.enqueueSnackbar(message, {
  //       variant: 'info',
  //       autoHideDuration: 2000,
  //     });
  //        this.onCloseModal1()
  //     //  this.props.history.push("/admin/dashboard")
  //      window.location.reload()
  //   }
  // }
  // }

  addProcessor = async (value) => {
    // this.setState({ formLoader: true })
    // let data = value.formData
    // console.log('data',data)
    // let oldSchema = this.state.Processorschema;
    // console.log("oldstagedata inside addprocessor", oldSchema);
    // oldSchema.properties.name.default = data.name;
    // oldSchema.properties.userLastName.default = data.userLastName;
    // oldSchema.properties.emailid.default = data.emailid;
    // const newSchema = Object.assign({}, oldSchema);
    // console.log("WRITE oldSchema", newSchema);

    // this.setState({formLoader: true, Processorschema:newSchema})

    // const newdata = new FormData();
    // newdata.append("name", data.name);
    // newdata.append('emailid', data.emailid);
    // newdata.append("userrole",'Processor');
    // newdata.forEach(element => {
    //   console.log("Asdasdas2", element);
    //   });
    // console.log("addprocessor---"+JSON.parse(newdata))
    var data = value.formData;

    console.log("addprocessor1---" + JSON.stringify(value));

    const APIResponse = await addProcessor(data, token);
    console.log(APIResponse);
    if (APIResponse.status !== 200) {
      const message = "Something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      this.setState({ formLoader: false });
    } else {
      this.setState({ getLoansLoader: false, openPopup: true });

      if (APIResponse.data.isSuccess == false) {
        this.setState({ formLoader: false });
        const message = "Processor with emailid already exists";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 1000,
        });
      } else {
        this.setState({ formLoader: false });
        const message = "Processor Added successfully!";
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 1000,
        });
        this.onCloseModal2();
        this.getAllProcessors(token);
        //  this.props.history.push("/admin/dashboard")
        // window.location.reload()
      }
    }
  };

  getAllProcessors = async (token) => {
    const APIResponse = await getAllProcessors(token);
    if (APIResponse != null) {
      console.log("getAllProcessors", APIResponse);
      //   this.setState({ OrgLoading: false })

      let ProcessorOptions = [];
      if (APIResponse.data.length !== 0) {
        APIResponse.data.forEach(function (key, value) {
          console.log("key", key.userid);
          console.log("value", value);
          var obj = {
            value: key.userid,
            label: key.username + " " + key.userLastName,
          };
          ProcessorOptions.push(obj);
          // Value.push(key.userid);
          // Label.push(key.username);
        });
      }
      console.log("processoroption.data", ProcessorOptions);
      this.setState({ processoroption: ProcessorOptions });
    }
  };

  MapProcessor = async (value) => {
    // let a=x.split("#")
    // let res=[]
    // y.map((val)=>{a.map((v)=>{if(val.pr===v){res.push(val)}})})
    // console.log('resssssssssss',res)
    var data = {
      poolid: this.state.mappoolid,
      processor: "",
    };
    let proc = [];
    this.state.processorarrray.forEach(function (key, value) {
      console.log("key", key.userid);
      console.log("value", value);

      proc.push(key.value);
    });
    console.log(this.state.processorarrray);
    console.log("arrayproc", proc.join("#"));
    data.processor = proc.join("#");
    console.log("addPool" + JSON.stringify(data));
    console.log("data", data);

    const APIResponse = await MapProcessor(data, token);
    console.log("mapping", APIResponse);
    if (APIResponse.status !== 200) {
      const message = "Something went wrong, please try again";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 5000,
      });
      this.setState({ formLoader: false });
    } else {
      this.setState({ getLoansLoader: false, openPopup: true });

      if (APIResponse.data.isSuccess == false) {
        this.setState({ formLoader: false });
        const message = "Enter Correct Parameter";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 1000,
        });
      } else {
        this.setState({ formLoader: false });
        const message = "Processor Mapped successfully!";
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 1000,
        });
        this.onCloseModal4();
        this.getallpools(token);
        this.getAllProcessors(token);

        //  this.props.history.push("/admin/dashboard")
        // window.location.reload()
      }
    }
  };

  //     let processor_id = []
  //     let processor_name = []
  //     if (APIResponse.data.length !== 0) {
  //       APIResponse.data.forEach(function (key, value) {
  //         console.log("key", key.userid)
  //         console.log("value", value)
  //         // var obj = { name: key, label: key }
  //         processor_id.push(key.userid);
  //         processor_name.push(key.username);
  //       });
  //     }
  //     console.log("APIResponse.data", APIResponse.data);
  //     let oldSchema = this.state.Poolschema;
  //     console.log("oldstagedata", oldSchema);
  //     oldSchema.properties.processor.enum = processor_id;
  //     oldSchema.properties.processor.enumNames = processor_name;
  //     const newSchema = Object.assign({}, oldSchema);
  //     console.log("WRITE oldSchema", newSchema);
  //     this.setState({ Poolschema: newSchema });
  //     // this.setState({ OrgLoading: true, invoiceDetails: APIResponse.data })
  //     sessionStorage.setItem("OrgName", JSON.stringify(newSchema))
  //   }
  //   //else {
  //   //   this.setState({ OrgLoading: true })
  //   // }
  // }
  onFormChangedpool = (value) => {
    this.setState({ formData1: value.formData });
  };
  onFormChangedpool1 = (value) => {
    this.setState({ formData2: value.formData });
  };

  onSubmit = () => {
    if (localStorage.getItem("dueDiligence") !== null) {
      const data = localStorage.getItem("dueDiligence");
      this.props.history.push({
        pathname: "/portfolio-setup-wizard/pool-formation",
        search: "",
        state: { eligiblePortfolioImportParameter: data },
      });
    }
  };
  print = () => {
    console.log("Printing....");
    this.setState({ open1: false });
    this.setState({ open2: true });
  };

  backBtn = () => {
    this.props.history.push({
      pathname: "/portfolio-setup-wizard/eligible-portfolio-import",
    });
  };

  matchedRows = (tabledata, num) => {
    // console.log('tabledata',tabledata)
    return tabledata.map((tr_item, i) => {
      // console.log('tritemsss',tr_item)
      return (
        <React.Fragment>
          <tr>
            <td style={{ width: "30%" }}>
              <Checkbox
                style={{ color: "#048C88" }}
                type="checkbox"
                id={i}
                name={tr_item.name}
                value={tr_item.name}
                // onClick="this.checked=!this.checked;"
                onChange={
                  num == 0
                    ? this.onAddingItem1(i)
                    : num == 1
                    ? this.onAddingItem2(i)
                    : this.onAddingItem3(i)
                }
                checked={tr_item.matched}
              ></Checkbox>

              {tr_item.attributeName}
              <label
                style={{ cursor: "pointer" }}
                onClick={
                  num == 0
                    ? this.onAddingItem1(i)
                    : num == 1
                    ? this.onAddingItem2(i)
                    : this.onAddingItem3(i)
                }
              >
                {tr_item.name}
              </label>
            </td>
            <td></td>
          </tr>
        </React.Fragment>
      );
    });
  };

  render() {
    // const { tags } = this.state;
    const { tableData } = this.state;
    const options = {
      filterType: "dropdown",
      filter: true,
      search: true,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,

      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      customSort: (data, colIndex, order) => {
        console.log("customSort", data, colIndex,order)
        return data.sort((a, b) => {
          if (colIndex === 6) {
            return (
              (new Date(a.data[colIndex]) < new Date(b.data[colIndex])
                ? -1
                : 1) * (order === "desc" ? 1 : -1)
            );
          } else {
            return (
              (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
              (order === "desc" ? 1 : -1)
            );
          }
        });
      },


      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      // loading:false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
      },
    };

    const columns = [
      {
        name: "poolid",
        label: "Deal Id",
        options: {
          filter: true,
        //   sort: true,
        },
      },
      {
        name: "poolname",
        label: "Deal Name",
        options: {
          filter: true,
        //   sort: true,
        //   customBodyRender: (value, tableMeta, updateValue) => {
        //     return (
        //       <React.Fragment>
        //        {moment(value).format('MM-DD-YYYY')}
        //       </React.Fragment>
        //     );
        //   },
        },
      },
      {
        name: "assetclass",
        label: "Asset Class",
        options: {
          filter: true,
        //   sort: true,
        },
      },
      {
        name: "issuer",
        label: "Issuer",
        options: {
          filter: true,
        //   sort: true,
        //   customBodyRender: (value, tableMeta, updateValue) => {
        //     return (
        //       <React.Fragment>
        //       {/* {myFunction(value)} */}
        //       </React.Fragment>
        //     );
        //   },
        },
      },

      {
        name: "noofprocessor",
        label: "Processor",
        options: {
          filter: true,
        //   sort: true,
        // customHeadRender: (columnMeta, updateDirection) => (
        //   <th style={{textAlign:'center',fontSize:'0.875rem'}}>
        //       {columnMeta.label}
        //   </th>
        // ),
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <React.Fragment>
        //        <div className="text-center">{value}</div>
        //     </React.Fragment>
        //   );
        // },
        },
      },
      {
        name: "noofloans",
        label: "No. of Loans",
        options: {
          filter: true,
          sortCompare: (order) => {
            return (obj1, obj2) => {
              console.log(order);
              let val1 = parseInt(obj1.data, 10);
              let val2 = parseInt(obj2.data, 10);
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }
        // customHeadRender: (columnMeta, updateDirection) => (
        //   <th style={{textAlign:'center',fontSize:'0.875rem'}}>
        //       {columnMeta.label}
        //   </th>
        // ),
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <React.Fragment>
        //        <div className="text-center">{value}</div>
        //     </React.Fragment>
        //   );
        // },
        },
      },
      {
        name: "datecreated",
        label: "Date Created",
        options: {
          filter: true,
        // sortCompare: (value, dataIndex, rowIndex) => {
        //   console.log('tableData',value,dataIndex)
        //     return value.sort((a, b) => {
        //       const dateA = new Date(a.value[dataIndex]).getTime();
        //       const dateB = new Date(b.value[dataIndex]).getTime();
        //       console.log(dateA);
        //       console.log(dateB);
        //       return (dateA < dateB ? -1 : 1) * (rowIndex === "desc" ? 1 : -1);
        //     });
        // },
        // sortCompare: (order) => {
        //   return (obj1, obj2) => {
        //     console.log(order);
        //     let val1 = parseInt(obj1.data, 10);
        //     let val2 = parseInt(obj2.data, 10);
        //     return (val1 - val2) * (order === 'asc' ? 1 : -1);
        //   };
        // }

        // customHeadRender: (columnMeta, updateDirection) => (
        //   <th style={{textAlign:'center',fontSize:'0.875rem'}}>
        //       {columnMeta.label}
        //   </th>
        // ),
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <React.Fragment>
        //        <div className="text-center">{value}</div>
        //     </React.Fragment>
        //   );
        // },
        },
      },
      // {
      //   name: 'poolname',
      //   label: 'Status',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     customHeadRender: (columnMeta, updateDirection) => (
      //       <th style={{textAlign:'center',fontSize:'0.875rem'}}>
      //           {columnMeta.label}
      //       </th>
      //     ),
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <React.Fragment>
      //           <div className="text-center">
      //              {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value)} > */}
      //                 {/* <img alt="" src={require('../../../images/ViewLoans.png')} onClick={() => this.handleClick(value)}></img> */}
      //                <VisibilityIcon  style={{cursor: "pointer"}} onClick={() => this.handleClick1(value,tableMeta.rowData[1])}> </VisibilityIcon>
      //               {/* </Button> */}

      //           </div>
      //         </React.Fragment>
      //       );
      //     },

      //   },

      // },
      {
        name: "poolid",
        label: "Map Fields",
        options: {
          filter: true,
        //   sort: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
            {columnMeta.label}
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>
              <div className="">
                {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value)} > */}
                {/* <img alt="" src={require('../../../images/ViewLoans.png')} onClick={() => this.handleClick(value)}></img> */}
                <div className="text-center">
                  <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleClick3(value, tableMeta.rowData[1])
                    }
                  >
                    {" "}
                  </VisibilityIcon>{" "}
                </div>
                {/* </Button> */}
              </div>
            </React.Fragment>
          );
        },

        },
      },

      {
        name: "poolid",
        label: "Map Processor",
        options: {
          filter: true,
        //   sort: false,
        customHeadRender: (columnMeta, updateDirection) => (
          <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
            {columnMeta.label}
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>
              <div className="">
                {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value)} > */}
                {/* <img alt="" src={require('../../../images/ViewLoans.png')} onClick={() => this.handleClick(value)}></img> */}
                <div className="text-center">
                  <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleClick4(value, tableMeta.rowData)}
                  >
                    {" "}
                  </VisibilityIcon>{" "}
                </div>
                {/* </Button> */}
              </div>
            </React.Fragment>
          );
        },

        },
      },

      {
        name: "processor",
        label: "Processorsss",
        options: {
          display: "exclude",
        },
      },
      {
        name: "poolid",
        label: "Delete Deal",
        options: {
          customHeadRender: (columnMeta, updateDirection) => (
            <th style={{ textAlign: "center", fontSize: "0.875rem" }}>
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className="">
                  {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={() => this.handleClick(value)} > */}
                  {/* <img alt="" src={require('../../../images/ViewLoans.png')} onClick={() => this.handleClick(value)}></img> */}
                  <div className="text-center">
                    <DeleteOutlineIcon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.onOpenModal5(value, tableMeta.rowData[1])
                      }
                    >
                      {" "}
                    </DeleteOutlineIcon>{" "}
                  </div>
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
            <div className="header">
              <Header pageTitle={"DASHBOARD"}></Header>
            </div>
            {this.state.getLoansLoader == false ? (
              ""
            ) : (
              <LinearLoader></LinearLoader>
            )}

            <div className="row1">
              <div className="col-md-6 col-sm-12">
                <div className="page-content text-center hello">
                  <div className="row align-items-center">
                    <div className="col text-secondary shift">
                      <h4 className="font-weight-bold">
                        {this.state.noofpool}
                      </h4>
                      <p>NO. OF DEALS</p>
                    </div>
                    <div className="vertical-divider"></div>
                    <div className="col text-secondary shifts">
                      <Button onClick={this.onOpenModal1.bind(this)}>
                        <AddIcon></AddIcon>
                      </Button>
                      <p>ADD DEAL</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="page-content text-center hello">
                  <div className="row align-items-center">
                    <div className="col text-secondary shift">
                      <h4 className="font-weight-bold">
                        {this.state.noofprocessor}
                      </h4>
                      <p>NO. OF PROCESSOR</p>
                    </div>
                    <div className="vertical-divider"></div>
                    <div className="col text-secondary shifts">
                      <Button onClick={this.onOpenModal2.bind(this)}>
                        <AddIcon></AddIcon>
                      </Button>
                      <p>ADD PROCESSOR</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 col-sm-12">
                    <TextField id="outlined-basic" value={this.state.searchText} onChange={this.onchange} label="Search" variant="filled" size="small" />
                    {this.state.searchText.length !== 0 ?  <CloseIcon className="closeiconstyle" onClick={() => this.setState({ searchText: '' })}/>  : ''}
                    <div className="page-content" style={{display:'flex'}}>
                    
                        <div className="col-md-6 col-sm-6">Pool</div>
                        
                            <div className="col-md-6 col-sm-6">
                                <Button variant="contained" onClick={this.onOpenModal1.bind(this)} > <AddIcon ></AddIcon></Button>
                                    
                            </div>
                
                        </div>
                    
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <div className="page-content" style={{display:'flex'}}>
                            <div className="col-md-6 col-sm-6">Processor</div>
                        
                            <div className="col-md-6 col-sm-6">
                                <Button variant="contained" onClick={this.onOpenModal2.bind(this)} > <AddIcon></AddIcon> </Button>
                            </div>
                    
                        </div>
                    </div> */}
            </div>

            <div className="page-content">
              <React.Fragment>
                {/* <h3 className="text-secondary alignment">Pools</h3> */}
                <MUIDataTable
                  title={"Deals"}
                  data={this.state.tableData}
                  columns={columns}
                  options={options}
                />

                {/* <div className="navbarSteps navbarStepsBtm">
                    <div className="row justify-content-end">


                      <Button variant="contained" id="optionalbutton" onClick={this.backBtn} > Back  </Button>

                    {  this.state.rowsSelected !== null && this.state.rowsSelected.length !== 0 ? 
                      <Button onClick={this.onSubmit} variant="contained" color="primary" type="submit" disabled={this.state.getLoansLoader === true ? true : false} >
                        {this.state.getLoansLoader === true ? <CircularProgress size="30px" color="secondary" /> : ''}

                        Run DD  </Button>:
                         <Button onClick={this.onSubmit} variant="contained"  disabled color="primary" type="submit"  >
                    onOpenModal1
                         Run DD  </Button>
                    }
                    </div>
                  </div> */}

                <div id="modal">
                  {/* <Modal open={open1} onClose={this.onCloseModal1} center>
                    <div id="modalheader">
                      <h5>Create a Pool</h5>

                    </div> */}
                  <ReactModal
                    isOpen={this.state.open1}
                    onRequestClose={this.onCloseModal1}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h2> Add Deal </h2>
                        <Button
                          className="closePopup"
                          style={{ minWidth: "30px" }}
                          variant="text"
                          color="primary"
                          onClick={this.onCloseModal1}
                        >
                          {" "}
                          <CloseIcon></CloseIcon>{" "}
                        </Button>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div>
                          <Form
                            schema={this.state.Poolschema}
                            uiSchema={uiSchema}
                            onSubmit={this.addPool}
                            onChange={this.onFormChangedpool}
                            formData={this.state.formData1}
                            widgets={widgets}
                            FieldTemplate={CustomFieldTemplate}
                          >
                            <MySelect
                              options={this.state.processoroption}
                              isMulti
                              placeholder="Select Processor"
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                                MultiValue,
                                ValueContainer,
                              }}
                              onChange={this.handleChange}
                              allowSelectAll={true}
                              value={this.state.processorarrray}
                              styles={customStyle}
                            />
                            {/* <br></br>
                          <div className="tag-container">
                                    {tags.map((tag, index) => {
                                      return (
                                        <div key={index} className="tag">
                                          {tag} <span onClick={() => this.removeTag(tag)}>x</span>
                                        </div>
                                      );
                                    })}

                                    <input onKeyDown={this.addTag} placeholder="Recipient Mail IDs" />
                                  </div>
                                  <br></br>
                               <Select
                                    placeholder='Frequency Of Certificate'
                                    defaultValue={selectedOption}
                                    onChange={this.handleChange1}
                                    options={this.state.options}
                                    styles={customStyle}
                                  /> */}
                            <Tooltip title="Add Processor" placement="right">
                              <AddIcon
                                onClick={this.print}
                                fontSize="large"
                                style={{ marginLeft: "15px", marginTop: "5px" }}
                              />
                            </Tooltip>
                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <hr className="hrbottom" />
                                <div className="row justify-content-end">
                                  <Button
                                    variant="contained"
                                    id="optionalbutton"
                                    onClick={this.onCloseModal1}
                                  >
                                    {" "}
                                    CANCEL{" "}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  >
                                    {" "}
                                    ADD DEAL{" "}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>

                          {/* {this.state.getLoansLoader === false ? '' : <FormLoader></FormLoader>}

                          {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Pool Added Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>

                <div id="modal">
                  {/* <Modal open={open1} onClose={this.onCloseModal1} center>
                    <div id="modalheader">
                      <h5>Create a Pool</h5>

                    </div> */}
                  <ReactModal
                    isOpen={this.state.open2}
                    onRequestClose={this.onCloseModal2}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h2> Add Processor </h2>
                        <Button
                          className="closePopup"
                          style={{ minWidth: "30px" }}
                          variant="text"
                          color="primary"
                          onClick={this.onCloseModal2}
                        >
                          {" "}
                          <CloseIcon></CloseIcon>{" "}
                        </Button>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div>
                          <Form
                            schema={this.state.Processorschema}
                            // uiSchema={uiSchema}
                            onSubmit={this.addProcessor}
                            widgets={widgets}
                            FieldTemplate={CustomFieldTemplate}
                          >
                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <hr className="hrbottom" />
                                <div className="row justify-content-end">
                                  <Button
                                    variant="contained"
                                    id="optionalbutton"
                                    onClick={this.onCloseModal2}
                                  >
                                    {" "}
                                    CANCEL{" "}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  >
                                    {" "}
                                    ADD PROCESSOR
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="25px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>

                          {this.state.getLoansLoader === false ? (
                            ""
                          ) : (
                            <FormLoader></FormLoader>
                          )}

                          {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
                {/*---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
                {/*--------------FIELDS POPUP-------------------------------FIELDS POPUP------------------------------------------------------------------------------------------------------------------------------*/}
                {/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
                <div id="modal">
                  <ReactModal
                    isOpen={this.state.open3}
                    onRequestClose={this.onCloseModal3}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h2> Map Fields </h2>
                        <Button
                          className="closePopup"
                          style={{ minWidth: "30px" }}
                          variant="text"
                          color="primary"
                          onClick={this.onCloseModal3}
                        >
                          {" "}
                          <CloseIcon></CloseIcon>{" "}
                        </Button>

                        <div>
                          <React.Fragment>
                            <div className="contentfields" text-left>
                              <div className="rowmapfield">
                                <div className="col-md-1">
                                  <InfoOutlinedIcon
                                    fontSize="large"
                                    className="imgcolor"
                                  ></InfoOutlinedIcon>
                                </div>
                                <div className="col-md-11">
                                  <p>
                                    Please enable fields that need to be
                                    validated. We also recommend disabling
                                    fields that are not needed as this will
                                    speed up the verification process.{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 mb-3">
                                {/* <Button variant="outlined" color="primary" style={{margin:"15px"}} onClick={this.addfieldbtn}>+ Add Field</Button> */}
                                <TextField
                                  label={"Name"}
                                  variant="filled"
                                  name="name"
                                  disabled
                                  value={this.state.dealname}
                                ></TextField>
                              </div>

                              {this.state.getLoansLoader == false ? (
                                ""
                              ) : (
                                <LinearLoader></LinearLoader>
                              )}

                              <div className="col-md-12 col-sm-12">
                                <React.Fragment>
                                  {this.state.tableData1 == null ? (
                                    ""
                                  ) : this.state.tableData1.length != 0 ? (
                                    <table
                                      className="table table-bordered fieldstable"
                                      id="fieldtable1"
                                    >
                                      <thead>
                                        <tr>
                                          <th colSpan="2">Borrower</th>
                                        </tr>
                                      </thead>
                                      <tbody className="setwidth">
                                        {this.matchedRows(
                                          this.state.tableData1,
                                          0
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                                <React.Fragment>
                                  {this.state.tableData2 == null ? (
                                    ""
                                  ) : this.state.tableData2.length != 0 ? (
                                    <table
                                      className="table table-bordered fieldstable"
                                      id="fieldtable2"
                                    >
                                      <thead>
                                        <tr>
                                          <th colSpan="2">Loan</th>
                                        </tr>
                                      </thead>
                                      <tbody className="setwidth">
                                        {this.matchedRows(
                                          this.state.tableData2,
                                          1
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                                <React.Fragment>
                                  {this.state.tableData3 == null ? (
                                    ""
                                  ) : this.state.tableData3.length != 0 ? (
                                    <table
                                      className="table table-bordered fieldstable"
                                      id="fieldtable2"
                                    >
                                      <thead>
                                        <tr>
                                          <th colSpan="2">Collateral</th>
                                        </tr>
                                      </thead>
                                      <tbody className="setwidth">
                                        {this.matchedRows(
                                          this.state.tableData3,
                                          2
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              </div>
                            </div>
                          </React.Fragment>
                          <div className="modalsubmit col-md-12">
                            <div className="submitbuttonbg">
                              <hr className="hrbottom" />
                              <div className="mt-1 mb-1">
                                <div className="row justify-content-end">
                                  <Button
                                    variant="contained"
                                    id="optionalbutton"
                                    onClick={this.onCloseModal3}
                                  >
                                    {" "}
                                    CANCEL{" "}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.MapAttribute}
                                  >
                                    {" "}
                                    SAVE FIELDS
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="25px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>

                {/*---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
                {/*--------------MAP PROCESSOR POPUP-------------------------------MAP PROCESSOR POPUP------------------------------------------------------------------------------------------------------------------------------*/}
                {/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}

                <div id="modal">
                  {/* <Modal open={open1} onClose={this.onCloseModal1} center>
                    <div id="modalheader">
                      <h5>Create a Pool</h5>

                    </div> */}
                  <ReactModal
                    isOpen={this.state.open4}
                    onRequestClose={this.onCloseModal4}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h2> Map Processor </h2>
                        <Button
                          className="closePopup"
                          style={{ minWidth: "30px" }}
                          variant="text"
                          color="primary"
                          onClick={this.onCloseModal4}
                        >
                          {" "}
                          <CloseIcon></CloseIcon>{" "}
                        </Button>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div>
                          <Form
                            schema={this.state.MapProcessorSchema}
                            // uiSchema={uiSchema}
                            onSubmit={this.MapProcessor}
                            onChange={this.onFormChangedpool1}
                            formData={this.state.formData2}
                            widgets={widgets}
                            FieldTemplate={CustomFieldTemplate}
                          >
                            <ReactSelect
                              options={this.state.processoroption}
                              isMulti
                              placeholder="Select Processor"
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={this.handleChange}
                              allowSelectAll={true}
                              value={this.state.processorarrray}
                              styles={customStyle}
                            />
                            <Tooltip title="Add Processor" placement="right">
                              <AddIcon
                                onClick={this.print}
                                fontSize="large"
                                style={{ marginLeft: "15px", marginTop: "5px" }}
                              />
                            </Tooltip>
                            <div className="modalsubmit">
                              <div className="submitbuttonbg">
                                <hr className="hrbottom" />
                                <div className="row justify-content-end">
                                  <Button
                                    variant="contained"
                                    id="optionalbutton"
                                    onClick={this.onCloseModal4}
                                  >
                                    {" "}
                                    CANCEL{" "}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  >
                                    {" "}
                                    MAP PROCESSOR{" "}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>

                          {/* {this.state.getLoansLoader === false ? '' : <FormLoader></FormLoader>}

                          {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Pool Added Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
                <div id="modal">
                  <ReactModal
                    isOpen={this.state.open5}
                    onRequestClose={this.onCloseModal5}
                    contentLabel="Minimal Modal Example"
                    style={customStylesauto}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h2> Delete Deal </h2>
                        <Button
                          className="closePopup"
                          style={{ minWidth: "30px" }}
                          variant="text"
                          color="primary"
                          onClick={this.onCloseModal5}
                        >
                          {" "}
                          <CloseIcon></CloseIcon>{" "}
                        </Button>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div>
                          <p className="popup-title">
                            Are you sure you wish to Delete this Deal? Doing so,
                            will remove linked data
                          </p>

                          <div className="modalsubmit1">
                            <div className="submitbuttonbg">
                              <hr className="hrbottom" />
                              <div className="row justify-content-end">
                                <Button
                                  variant="contained"
                                  id="optionalbutton"
                                  onClick={this.onCloseModal5}
                                >
                                  {" "}
                                  No{" "}
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  onClick={() =>
                                    this.deletePool(
                                      this.state.valuedealid,
                                      token
                                    )
                                  }
                                >
                                  {" "}
                                  Yes
                                </Button>
                              </div>
                            </div>
                          </div>

                          {/* {this.state.getLoansLoader === false ? '' : <FormLoader></FormLoader>}

                          {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Pool Added Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(dashboard);
