/* eslint-disable require-jsdoc */
import React, { Component } from 'react'
import MUIDataTable from 'mui-datatables'
import Loader from '../../components/loader'
import Snackbar from '../../components/snackbar'
import axios from 'axios'
import GetAppIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import { withSnackbar } from 'notistack'

class ViewDocumentsTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      getLoansLoader: false
    }
  }
  
  async componentDidMount() {
    var loan_id = this.props.dataFromParent;
    const peer = localStorage.getItem('peer');
    const token = localStorage.getItem('token');
    this.loanDetails(loan_id, peer, token)
}

  async loanDetails(loan_id, peer, token) {
    try {
        const res = await axios.get(process.env.react_app_base_url + 'api/V1/datapreprocessing/docloans?LoanID=' + loan_id + "&peer=" + peer + "&token=" + token);
        const resp = res.data;
        console.log("loandetails", res.data);
        if (res.status === 200) {
            console.log("loandetails", resp);
            var result = resp;
            this.setState({ getLoansLoader: false, open: true, loading: true,tableData: resp });
            const message = "Successfully fetched the data";
            this.props.enqueueSnackbar(message, {
                variant: 'info',
                autoHideDuration: 2000,
            });
        } else {
            alert("Failed");
        }
    } catch (error) {
        alert("Failed");
        console.log("getLoans API error", error);
    }
}

  render () {
    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        const selected = allRows.map(item => item.index)
        console.log('valuee' + selected)
        console.log(allRows)
        this.setState({
          rowsSelected: selected
        })
      },

      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET'
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    }
    const columns = [
      {
        name: 'LoanID',
        label: 'LoanID',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'LDocID',
        label: 'Document ID',
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: 'LDocName',
        label: 'Document Name',
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: 'LDocPath',
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <a
                  target='_blank'
                  href={
                    process.env.react_app_base_url +
                    'root_folder' +
                    value
                  }
                >
                  <IconButton
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                  >
                    <GetAppIcon />
                  </IconButton>
                </a>
              </React.Fragment>
            )
          }
        }
      }
    ]

    return (
      <React.Fragment>
        <div className='page-content'>
          
          {this.state.loading === true ? (
            <React.Fragment>
              <MUIDataTable
                data={this.state.tableData}
                columns={columns}
                options={options}
              />
            </React.Fragment>
          ) : (
            <Loader msg={'Please wait, Loading document details'} />
          )}

          {this.state.open === true ? (
            <React.Fragment>
              <Snackbar msg={'Record Fetched Successfully'} open='true' />{' '}
            </React.Fragment>
          ) : (
            ' '
          )}

        </div>
      </React.Fragment>
    )
  }
}

export default withSnackbar(ViewDocumentsTable)
