/* eslint-disable require-jsdoc */
import React, { Component } from 'react';

import MUIDataTable from 'mui-datatables';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from "axios";

// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppIcon from '@material-ui/icons/GetApp';

import { withSnackbar } from 'notistack';

// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IconButton from '@material-ui/core/IconButton';
// import { NavLink } from 'react-bootstrap';
// import {
  
   
//    NavLink
//   } from "react-router-dom";



class DocumentDetailsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      getLoansLoader: false,
     
    };
   

  }
  
 


  
  async componentDidMount() {
    var poolId=this.props.dataFromParent;
console.log("pooId"+poolId)

var role=localStorage.getItem("OrgName");
var ownerId=localStorage.getItem("user_id");
const token = localStorage.getItem('token');
  
const peer = localStorage.getItem('peer')
    try {
      const res = await axios.get(process.env.react_app_base_url + 'api/V1/pool/fetchdoc?poolid=' + poolId + "&role=" + role + "&ownerId="+ownerId+ "&peer=" + peer + "&token=" + token);
      const resp = res.data;
      if (res.status === 200) {
        console.log("poolloans", resp);
        if(resp.token == '-1'){
    
          const message = "Invalid Token!";
          this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 2000
        });
        this.props.history.push({
          pathname: '/'
        })
    
        } else {
          const message = "Successfully fetched the data";     
          this.props.enqueueSnackbar(message, { 
            variant: 'info',
            autoHideDuration: 2000,
        });
  
        this.setState({ tableData: resp, loading: true });
        }
      } else {
        alert("Failed");
      }
    } catch (error) {
      if (error.response.status == 400) {

        console.log("getLoans API error", error);
        console.log("error.response", error.response.data.message);

        const message = error.response.data.message;
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });

    } else {

        const message = "something went wrong, please try again";
        this.props.enqueueSnackbar(message, {
            variant: 'error',
            autoHideDuration: 3000,
        });


    }
    }

  };

  render() {
 

    const options = {
      filterType: 'textField',
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,

      rowsSelected: this.state.rowsSelected,
      onRowsSelect: (rowsSelected, allRows) => {
        const selected = allRows.map((item) => item.index);
        console.log("valuee" + selected);
        console.log(allRows);
        this.setState({
          rowsSelected: selected,


        });

        


      },


      textLabels: {
        body: {
          noMatch: 'Sorry, no matching records found',
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };
    const columns = [

      
      {
        name: 'DocID',
        label: 'Document ID',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'DocName',
        label: 'Document Name',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'DocType',
        label: 'Document Type',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'DocTag',
        label: 'Document Tag',
        options: {
          filter: true,
          sort: false,
        },
      },
    
      
      {
        name: "DocPath",
        label: 'Action',
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return ( 
         
              <React.Fragment>
                  
                  <a target="_blank" href={process.env.react_app_base_url+"root_folder/"+value}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                //   onClick={this.downloadDoc.bind(this, value)}
                >
                  <GetAppIcon />
                </IconButton>
                </a>
              </React.Fragment>
            )}
 
          
               
         



            
        }
      },
      {
        name: 'OwnerID',
        label: 'Owner ID',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'OwnerTag',
        label: 'Owner Tag',
        options: {
          filter: true,
          sort: false,
        },
      },
    ];



    return (
     
      <React.Fragment>
       
            
            <div className="page-content">

              {this.state.loading === true ?
                <React.Fragment>
                  <MUIDataTable
                    data={this.state.tableData}
                    columns={columns}
                    options={options}

                  />

                </React.Fragment>

                : <Loader msg={"Please wait, Loading document details"} />}

              {/* {this.state.open === true ? <React.Fragment><Snackbar msg={"Record Fetched Successfully"} open="true" /> </React.Fragment> : ' '} */}
            </div>
       
       
      </React.Fragment>
    );
  }
}

export default withSnackbar(DocumentDetailsTable);