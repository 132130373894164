/* eslint-disable require-jsdoc */
import React, {Component} from 'react';
// import { connect } from 'react-redux';
import Header from '../../../components/header';
import Sidebar from '../../../components/sidebar';
import MUIDataTable from 'mui-datatables';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/loader';
import Snackbar from '../../../components/snackbar';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import ReactModal from 'react-modal';
import { widgets, CustomFieldTemplate, customStyles, customStylesauto } from '../../../components/customscripts/customscript';
import FormLoader from '../../../components/loader/formLoader';
import {viewLoans,uploadlms,savelms} from '../../../servies/services';
import Match from '../../../images/match.png';
import Mismatch from '../../../images/mismatch.png';

const token = localStorage.getItem('token')



class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRecords: [],
      eligibleloans: {},
      tableData: [],
      loading: false,
      getLoansLoader: false,
      open: false,
      openPopup: false,
      selectedLoanId: [],
      searchText: '',
      rowsSelected:null,
      open1: false,
      open2: false,
      poolName:localStorage.getItem('poolname')

    };

  }

  // {loanId: "Frankie Long", loanNumber: "Industrial Analyst", constatus: "Uploaded", lmsstatus: "Digitised"},
  //     {loanId: "Brynn Robbins", loanNumber: "Business Analyst", constatus: "Digitised", lmsstatus: "Reviewd"},
  //     {loanId: "Justice Mann", loanNumber: "Business Consultant", constatus: "Reviewd", lmsstatus: "Uploaded"},
  
  
   async selectedpoolid(selected) {
    const arr = []

    for (var i = 0; i < selected.length; i++) {
     
      var j = selected[i];
      let PoolID = this.state.tableData[j].dealid;
      arr.push(PoolID);
    }
    console.log("selected Loans",arr);
    localStorage.setItem("rundd", JSON.stringify(arr));
  }

  onchange = e => {
    this.setState({ searchText: e.target.value, searchType: true });
    console.log("search: e.target.value", e.target.value);
  };


  async componentDidMount() {
    const APIResponse =  {
      "message": "successfull",
      "isSuccess": true,
      "data": [{
          "contract": "UOWN268477",
          "name": "Armjio-Nicholas",
          "agreementid":"AGWN268477",
          "datereceived":"08-02-2022",
          "contractdate":"08-02-2022",
          "signedelectronically":1,
          "filerecieved":1,
          "lesseename":1,
          "contractnumber":1,
          "signed":0,
          "exceptions":0,
          "exceptiondetails":"No signature",
        },
        {
          "contract": "UOWN268433",
          "name": "Bove-Mario",
          "agreementid":"AGWN268443",
          "datereceived":"08-02-2022",
          "contractdate":"08-02-2022",
          "signedelectronically":1,
          "filerecieved":1,
          "lesseename":1,
          "contractnumber":1,
          "signed":1,
          "exceptions":1,
          "exceptiondetails":"Mario A.Bove",
        },
        {
          "contract": "UOWN268096",
          "name": "Adams-Sandra",
          "agreementid":"AGWN268096",
          "datereceived":"08-02-2022",
          "contractdate":"08-02-2022",
          "signedelectronically":1,
          "filerecieved":1,
          "lesseename":1,
          "contractnumber":1,
          "signed":1,
          "exceptions":1,
          "exceptiondetails":"-",
        },
    
        {
          "contract": "UOWN268463",
          "name": "Braden-Cynthia",
          "agreementid":"AGWN268463",
          "datereceived":"08-02-2022",
          "contractdate":"08-02-2022",
          "signedelectronically":1,
          "filerecieved":1,
          "lesseename":1,
          "contractnumber":1,
          "signed":1,
          "exceptions":1,
          "exceptiondetails":"-",
        },
      ],
    }
    this.setState({tableData:APIResponse.data})
    // this.getAllProcessors(token)
  }


  render() {

    const options = {
      customToolbar: ()=>{return(<Button variant="contained" color="primary"  onClick={this.Publish}>PUBLISH</Button>)},
      filterType: 'textField',
      filter:false,
      search: true,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: true,
      selectableRows: true,
      onRowClick: this.onRowClick,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,

      onRowsSelect: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
    },

      searchText: this.state.searchText,
      searchPlaceholder: 'Search',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

//
      loading:false,
      textLabels: {
        body: {
          noMatch: this.state.loading === true ?
          'Sorry, there is no matching data to display':
          <Loader msg={"Please wait, Loading Loan Data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };



    const columns = [
     
          {
            name: "contract",
            label: 'Contract',
            options: {
              filter: true,
              sort: false,
            }
          },

          {
            name:'name',
            label: 'Name',
            options: {
              filter: true,
              sort: true,
            }
          },

          {
            name:'agreementid',
            label: 'Agreement ID',
            options: {
              filter: true,
              sort: true,
            }
          },

          {
            name:'datereceived',
            label: 'Date Received',
            options: {
              filter: true,
              sort: true,
            }
          },

          {
            name:'contractdate',
            label: 'Contract Date',
            options: {
              filter: true,
              sort: true,
            }
          },
          
          {
            name: "signedelectronically",
            label: 'Signed Electronically',
            options: {
              filter: true,
              sort: false,
              empty: true,
              customHeadRender: (columnMeta, updateDirection) => (
                <th style={{textAlign:'center',fontSize:'0.875rem'}}>
                    {columnMeta.label}
                </th>
              ),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <React.Fragment>
                    {/* {JSON.stringify(value)} */}
                    <div className="text-center">  {value === 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
                  </React.Fragment>
                );
              },
              
            }
          },
    
          {
            name: "filerecieved",
            label: 'File Recieved',
            options: {
              filter: false,
              sort: false,
              empty: true,
              customHeadRender: (columnMeta, updateDirection) => (
                <th style={{textAlign:'center',fontSize:'0.875rem'}}>
                    {columnMeta.label}
                </th>
              ),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <React.Fragment>
                    {/* {JSON.stringify(value)} */}
                    <div className="text-center">  {value === 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
                  </React.Fragment>
                );
              },
              
            },
          },
          {
            name: "lesseename",
            label: 'Lessee Name',
            options: {
              filter: false,
              sort: false,
              empty: true,
              customHeadRender: (columnMeta, updateDirection) => (
                <th style={{textAlign:'center',fontSize:'0.875rem'}}>
                    {columnMeta.label}
                </th>
              ),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <React.Fragment>
                    {/* {JSON.stringify(value)} */}
                    <div className="text-center">  {value === 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
                  </React.Fragment>
                );
              },
              
            },
          },
          {
            name: "contractnumber",
            label: 'Contract Number',
            options: {
              filter: false,
              sort: false,
              empty: true,
              customHeadRender: (columnMeta, updateDirection) => (
                <th style={{textAlign:'center',fontSize:'0.875rem'}}>
                    {columnMeta.label}
                </th>
              ),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <React.Fragment>
                    {/* {JSON.stringify(value)} */}
                    <div className="text-center">  {value === 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
                  </React.Fragment>
                );
              },
              
            },
          },
          {
            name: "signed",
            label: 'Signed',
            options: {
              filter: false,
              sort: false,
              empty: true,
              customHeadRender: (columnMeta, updateDirection) => (
                <th style={{textAlign:'center',fontSize:'0.875rem'}}>
                    {columnMeta.label}
                </th>
              ),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <React.Fragment>
                    {/* {JSON.stringify(value)} */}
                    <div className="text-center">  {value === 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
                  </React.Fragment>
                );
              },
              
            },
          },
          {
            name: "exceptions",
            label: 'Exceptions',
            options: {
              filter: false,
              sort: false,
              empty: true,
              customHeadRender: (columnMeta, updateDirection) => (
                <th style={{textAlign:'center',fontSize:'0.875rem'}}>
                    {columnMeta.label}
                </th>
              ),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <React.Fragment>
                    {/* {JSON.stringify(value)} */}
                    <div className="text-center">  {value == 1 ? <img alt="" src={Match} /> : <img alt="" src={Mismatch} />}</div>
                  </React.Fragment>
                );
              },
              
            },
          },
          {
            name: "exceptiondetails",
            label: 'Exception Details',
            options: {
              filter: false,
              sort: false,
              
            },
          },

    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar></Sidebar>
          <div className="content">
          <div className="header"><Header pageTitle={'REPORTS'} ></Header></div>
            {/* {this.state.getLoansLoader == false ? '' : <LinearLoader></LinearLoader>} */}

           
              <div className="row12">

            <div className="col-md-2 col-sm-12">
                <div className="page-contentss">
              
                  <div className="rows1 align-items-center">
                  <div className="col-md-1 col-sm-2 numbershift1">
                      <h4 className="font-weight-bold ms-auto">747<span className="grid-divider1"></span></h4>
                  </div>
                  <div className="col text-secondary contentshift">
                      <p>File Count per Receivable Schedule</p>
                  </div>
                  </div>
                  
                </div>
              </div>


              <div className="col-md-2 col-sm-12">
                <div className="page-contentss">
              
                  <div className="rows1 align-items-center">
                  <div className="col-md-1 col-sm-2 numbershift">
                      <h4 className="font-weight-bold">37<span className="grid-divider"></span></h4>
                  </div>
                  <div className="col text-secondary contentshift">
                      <p>Verify 5% of Receivable Files</p>
                  </div>
                  </div>
                  
                </div>
              </div>

              <div className="col-md-2 col-sm-12">
                <div className="page-contentss">
              
                  <div className="rows1 align-items-center">
                  <div className="col-md-1 col-sm-2 numbershift">
                      <h4 className="font-weight-bold">02<span className="grid-divider"></span></h4>
                  </div>
                  <div className="col text-secondary contentshift">
                      <p>Exceptions</p>
                  </div>
                  </div>
                  
                </div>
              </div>

              <div className="col-md-2 col-sm-12">
                <div className="page-contentss">
              
                  <div className="rows1 align-items-center">
                  <div className="col-md-1 numbershift">
                      <h4 className="font-weight-bold">747<span className="grid-divider"></span></h4>
                  </div>
                  <div className="col text-secondary contentshift">
                      <p>File Count In FTP (Enter)</p>
                  </div>
                  </div>
                  
                </div>
              </div>

              <div className="col-md-2 col-sm-12">
                <div className="page-contentss1">
              
                  <div className="rows1s align-items-center">
                  <div className="col-md-1 numbershifts1">
                      <h4 className="font-weight-bold">00<span className="grid-dividers1"></span></h4>
                  </div>
                  <div className="col text-secondary contentshifts">
                      <p>File Count Discrepancy between Receivable Schedule and FTP</p>
                  </div>
                  </div>
                  
                </div>
              </div>
              </div>



              <div className="row12">

<div className="col-md-2 col-sm-12">
    <div className="page-contentss">
  
      <div className="rows1 align-items-center">
      <div className="col-md-1 numbershift">
          <h4 className="font-weight-bold">00<span className="grid-divider"></span></h4>
      </div>
      <div className="col text-secondary contentshift">
          <p>Assignment Received</p>
      </div>
      </div>
      
    </div>
  </div>


  <div className="col-md-2 col-sm-12">
    <div className="page-contentss">
  
      <div className="rows1 align-items-center">
      <div className="col-md-1 numbershift1">
          <h4 className="font-weight-bold">747<span className="grid-divider1"></span></h4>
      </div>
      <div className="col text-secondary contentshift">
          <p>File Count per Notice of Pledge (Enter)</p>
      </div>
      </div>
      
    </div>
  </div>

  <div className="col-md-2 col-sm-12">
    <div className="page-contentss1">
  
      <div className="rows1s align-items-center">
      <div className="col-md-1 numbershifts">
          <h4 className="font-weight-bold">00<span className="grid-dividers"></span></h4>
      </div>
      <div className="col text-secondary contentshifts">
          <p>File Count Discrepancy between Notice of Pledge and FTP</p>
      </div>
      </div>
      
    </div>
  </div>

  <div className="col-md-2 col-sm-12">
    <div className="page-contentss">
  
      <div className="rows1 align-items-center">
      <div className="col-md-1 numbershift">
          <h4 className="font-weight-bold">40<span className="grid-divider"></span></h4>
      </div>
      <div className="col text-secondary contentshift">
          <p>Verified</p>
      </div>
      </div>
      
    </div>
  </div>

  <div className="col-md-2 col-sm-12">
    <div className="page-contentss">
  
      <div className="rows1 align-items-center">
      <div className="col-md-1 numbershift">
          <h4 className="font-weight-bold">40<span className="grid-divider"></span></h4>
      </div>
      <div className="col text-secondary contentshift">
          <p>Verfication Complete</p>
      </div>
      </div>
      
    </div>
  </div>
  </div>

            <div className="page-content">

                <React.Fragment>

                  <MUIDataTable
                  title='Reports'
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                  />

                </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default withSnackbar(Status);
