import React, { Component } from 'react';
import UserHeader from '../../../components/header/UserHeader';
import Button from '@material-ui/core/Button';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MuiTheme } from 'rjsf-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import LinearLoader from '../../../components/loader/LinearLoader';
import Loader from '../../../components/loader';
import { widgets, CustomFieldTemplate, ObjectFieldTemplate } from '../../../components/customscripts/customscript';
import { GetAllDeals, generateYears, months } from '../../../servies/services';
import Header from '../../../components/header';

const Form = withTheme(MuiTheme);
const schema = require('./schema.json');

class servicerDataDB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: schema,
            getLoansLoader: false,
            years: generateYears(),
            months: months,
            getLoader: false,
            loadingForm: false,
            DealType: localStorage.getItem('DealType'),
            pageTitle: "Download Servicer Data",
            all_deals: JSON.parse(localStorage.getItem("all_deals"))
        };
    }

    async componentDidMount() {

        console.log("componentDidMount DealId", this.props.match.params.DealId, this.props.match.params.DealMonth, this.props.match.params.DealYear);

        const DealId = this.props.match.params.DealId;
        const DealMonth = this.props.match.params.DealMonth;
        const DealYear = this.props.match.params.DealYear;

        this.callMethod(DealId, DealMonth, DealYear);
        this.onSubmitFunction(DealId, DealMonth, DealYear)

    }

    async componentWillReceiveProps(nextProps) {
        console.log("nextProps", nextProps)
        let DealId = nextProps.match.params.DealId;
        let DealMonth = nextProps.match.params.DealMonth;
        let DealYear = nextProps.match.params.DealYear;
        this.callMethod(DealId, DealMonth, DealYear);
        this.onSubmitFunction(DealId, DealMonth, DealYear)
    }


    callMethod(DealId, DealMonth, DealYear) {
        const formData = {
            dealId: DealId,
            month: DealMonth,
            year: DealYear
        }

        this.setState({ formData: formData })
        const DealType = this.state.DealType
        this.GetAllDeals(DealType)
    }


    onSubmitFunction = (DealId, DealMonth, DealYear) => {
        
        if (DealId != "null" && DealId != undefined &&  DealMonth != "null"  && DealMonth != undefined && DealYear != "null" && DealYear != undefined) {
            let value = {
                "formData": {
                    "dealId": DealId,
                    "month": DealMonth,
                    "year": DealYear,
                }
            }
            this.onSubmit(value);
        } else {

        }
    }


    onSubmit = async (value) => {
        this.setState({formData:value.formData})
        let data = value.formData;
        let m = parseInt(data.month);
        console.log("month" + m)
        let file = null;
        let file2 = null;

        if (m < 10) {

            file = data.dealId + "-0" + data.month + "-" + data.year + ".xlsx"
            file2 = data.dealId + "-0" + data.month + "-" + data.year + ".xls"
            console.log("file====" + file)
        }
        else {
            file = data.dealId + "-" + data.month + "-" + data.year + ".xlsx"
            file2 = data.dealId + "-" + data.month + "-" + data.year + ".xls"
            console.log("file====" + file)
        }


        if (this.state.DealType == "Lima") {
            window.open(process.env.react_app_base_url_lima + 'downloadfile?file=' + file + '&file2=' + file2, '_blank');
        } else if(this.state.DealType == "trusteedeal"){
            window.open(process.env.react_app_base_url_lima + 'downloadfile?file=' + file + '&file2=' + file2, '_blank');
        } else {
            window.open(process.env.react_app_base_url_lima + 'downloadfile?file=' + file + '&file2=' + file2, '_blank');
        }

    }


    GetAllDeals = async (DealType) => {

        // const APIResponse = await GetAllDeals(DealType)
        // console.log("APIResponse", APIResponse, this.state.years, this.state.months)

        let all_deals = this.state.all_deals;

        let deal_name = []
    
        if (all_deals.length !== 0) {
            all_deals.map((item) => {
                console.log("all_deals item", item.deal_id);
                deal_name.push(item.deal_id);
            })
        }

        let month_name = []
        let month_value = []
        if (this.state.months.length !== 0) {
            this.state.months.map((item) => {
                console.log("item", item);
                month_name.push(item.label);
                month_value.push(item.value);
            })
        }

        let year_name = []
        let year_value = []
        if (this.state.years.length !== 0) {
            this.state.years.map((item) => {
                console.log("item", item);
                year_name.push(item.key);
                year_value.push(item.value);
            })
        }

        // console.log("currency_list_static", currency_list_static);
        let oldSchema = this.state.schema;
        console.log("oldstagedata", oldSchema);
        oldSchema.properties.dealId.enum = deal_name;
        oldSchema.properties.dealId.enumNames = deal_name;

        oldSchema.properties.month.enum = month_value;
        oldSchema.properties.month.enumNames = month_name;

        oldSchema.properties.year.enum = year_value;
        oldSchema.properties.year.enumNames = year_name;

        const newSchema = Object.assign({}, oldSchema);
        console.log("WRITE oldSchema", newSchema);
        // this.setState({ schema: newSchema});
        this.setState({ schema: newSchema, loadingForm: true });
    };





    render() {
        return (

            <React.Fragment>
                <div className="page">
                    <div className="content">
                        <div className="header"><Header pageTitle={this.state.pageTitle}></Header>
                            {this.state.getLoader == false ? '' : <LinearLoader></LinearLoader>}
                        </div>
                        <div className="page-content text-center">
                            <div className="row">
                                <div className="col-md-12 text-left">
                                    <h3 className="title-page">{!this.state.pageTitle ? '' : this.state.pageTitle}</h3>
                                </div>
                                <div className="col-md-12">
                                    <div class="threecolform">
                                        {this.state.loadingForm == true ?
                                            <Form
                                                schema={this.state.schema}
                                                onSubmit={this.onSubmit}
                                                onChange={this.onFormChanged}
                                                widgets={widgets}
                                                omitExtraData={true}
                                                liveOmit={true}
                                                FieldTemplate={CustomFieldTemplate}
                                                formData={this.state.formData}
                                                uiSchema={this.state.uiSchema}
                                                ObjectFieldTemplate={ObjectFieldTemplate}
                                            >
                                                <div className='row justify-content-center'>
                                                    <Button variant='contained' color='primary' type='submit'>
                                                        Download{' '}
                                                    </Button>
                                                    {this.state.getLoansLoader === true ? (
                                                        <CircularProgress size='30px' color='primary' />
                                                    ) : (
                                                            ''
                                                        )}
                                                </div>
                                            </Form>
                                            : <Loader></Loader>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>


        );
    }
}

export default withSnackbar(servicerDataDB)